import { Box, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const Interviews = () => {
  const theme = useTheme();

  const { inputStyle, inputsSection } = useFormInputsStyle(theme);

  const { interviewsSection } = useTranslations();
  const {
    state: { isReadOnly },
  } = useContext(EditPlacementContext);

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ register, control, formState: { errors } }) => (
        <Box>
          <SectionHeader>
            {interviewsSection.interviewsSectionTitle}
          </SectionHeader>

          <Grid {...ContainerSpacing}>
            <Grid {...inputsSection}>
              <Grid {...inputStyle(4, 3.5)}>
                <DropdownYesNo
                  inputId={interviewsSection.hostCompanyId}
                  inputLabel={interviewsSection.hostCompany}
                  errorMessage={
                    errors.interviews?.isHostCompanyInterviewRequired?.message
                  }
                  control={control}
                  register={register(
                    'interviews.isHostCompanyInterviewRequired'
                  )}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};

export default Interviews;
