import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useHostService } from 'hooks/services/hosts';
import { HousingStatus } from 'hooks/services/hosts/interfaces/housingStatus.enum';
import { StatusProps } from 'pages/placements/placementsList/components/placementsTable/components/interfaces/statusProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import Housing from './housing';
import { HousingRow } from './interfaces/housingRow';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const HousingContainer = () => {
  const translations = useTranslations();
  const alert = useAlert();
  const [rows, setRows] = useState<HousingRow[]>([]);

  const theme = useTheme();
  const navigate = useNavigate();
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);
  const { getHousings, patchCompanyHousingUpdateStatus } = useHostService();
  const { active, inactive } = translations.companyHousing.statusButtons;

  const getHousingTableList = async () => {
    const response = await getHousings({ hostId: parsedHostId });

    const covertedRows: HousingRow[] = response.map((housing) => {
      const { id, name, status, address, city, zipCode, published, isEditable } = housing;
      const statusEnum = StatusProps[status];
      const isToActivate =
        statusEnum === StatusProps.Inactive || statusEnum === StatusProps.Draft;
      return {
        id: id.toString(),
        name,
        status: statusEnum,
        address,
        city,
        zip: zipCode,
        published,
        isEditable,
        action: {
          title: isToActivate ? active : inactive,
          onClick: () => {
            const statusToChange = isToActivate
              ? StatusProps.Active
              : StatusProps.Inactive;
            handleUpdateStatus(id, statusToChange);
          },
        },
        edit: () => {
          navigateToEditForm(id.toString());
        },
      };
    });

    setRows(covertedRows);
  };

  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const navigateToCreateForm = () => {
    navigate(
      ApplicationRoutes.companyHousingForm
        .replace(':hostId', hostId as string)
        .replace(':housingId', 'new')
    );
  };

  const navigateToEditForm = (id: string) => {
    navigate(
      ApplicationRoutes.companyHousingForm
        .replace(':hostId', hostId as string)
        .replace(':housingId', id)
    );
  };

  const handleUpdateStatus = async (housingId: number, status: StatusProps) => {
    try {
      await patchCompanyHousingUpdateStatus({
        hostId: parsedHostId,
        housingId,
        body: {
          status: HousingStatus[status],
        },
      });

      alert.success(translations.alerts.programs.housingStatusUpdated);
    } catch (error) {
      console.log(error);
    }
    getHousingTableList();
  };

  useEffect(() => {
    getHousingTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Housing
      rows={rows}
      isMobileView={isMobileView}
      navigateToCreateForm={navigateToCreateForm}
    />
  );
};

export default HousingContainer;
