import { useTranslations } from 'hooks/useTranslations';
import ActionsDropdown from './actionsDropdown';

import { Action } from 'pages/interviews/interfaces/interviews';
import { useActionsModal } from '../actionsModal/useActionsModal';
import ActionsModal from '../actionsModal/actionsModal';
import { InterviewResponse } from 'hooks/services/hosts/interfaces/interviews.response';
import { useEffect } from 'react';

const ActionsDropdownContainer = ({
  anchorEl,
  open,
  handleCloseActionsDropdown,
  interview,
  fetchInterviews,
  openDeclineInterviewModal,
  openInterviewReportModal
}: {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleCloseActionsDropdown: () => void;
  interview: InterviewResponse;
  fetchInterviews: () => void;
  openDeclineInterviewModal: () => void;
  openInterviewReportModal: () => void;
}) => {
  const { allInterviews } = useTranslations();
  const { decline, approve, noShow, noHired, interviewReport } =
    allInterviews.actions;

  const {
    selectedAction,
    setSelectedAction,
    modalOpen,
    setModalOpen,
    handleOpenModal,
  } = useActionsModal(openDeclineInterviewModal, openInterviewReportModal);

  const actions: Action[] = [
    { id: 'interviewReport', label: interviewReport },
    { id: 'decline', label: decline },
    { id: 'approve', label: approve },
    { id: 'no-show', label: noShow },
    { id: 'not-hired', label: noHired },
  ];

  useEffect(() => {
    if (!open) {
      setSelectedAction(null);
    }
  }, [open, setSelectedAction]);

  return (
    <>
      <ActionsDropdown
        {...{
          anchorEl,
          open,
          handleCloseActionsDropdown,
          actions,
          handleOpenModal,
        }}
      />
      <ActionsModal
        {...{
          interview,
          fetchInterviews,
          handleCloseActionsDropdown,
          selectedAction,
          setSelectedAction,
          modalOpen,
          setModalOpen,
        }}
      />
    </>
  );
};

export default ActionsDropdownContainer;
