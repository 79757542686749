import { useCallback, useEffect, useState } from 'react';
import { useHostService } from 'hooks/services/hosts';
import { useParams } from 'react-router-dom';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { InterviewToBookResponse } from 'hooks/services/hosts/interfaces/interviewsToBook.response';
import { InterviewToBookRequest } from 'hooks/services/hosts/interfaces/interviewsToBook.request';
import InterviewsToBookTable from './components/interviewsToBookTable';

const InterviewsToBookContainer = ({
  pageHeaderHeight,
  handleClick,
}: {
  pageHeaderHeight: number;
  handleClick: (applicantId: number) => void;
}) => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const { getInterviewsToBook } = useHostService();
  const [rows, setRows] = useState<InterviewToBookResponse[]>([]);
  const [rowsTotal, setRowsTotal] = useState(100);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });

  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await getInterviewsToBookHandler({
      ...data,
      hostId: parsedHostId,
    });
  };

  const getInterviewsToBookHandler = async (
    data: InterviewToBookRequest & { hostId: number; }
  ) => {
    const response = await getInterviewsToBook(data);
    setRowsTotal(response.total);
    setRows(response.records);
  };

  const fetchInterviewsToBook = useCallback(async () => {
    try {
      const data = {
        ...tableParams,
        hostId: parsedHostId,
      };
      await getInterviewsToBookHandler(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchInterviewsToBook();
  }, [fetchInterviewsToBook]);

  return (
    <InterviewsToBookTable
      {...{
        tableProps: {
          rows,
          rowsTotal,
          handleTableChanged,
        },
        heightOfTopComponents: pageHeaderHeight,
        handleClick,
        fetchData: fetchInterviewsToBook
      }}
    />
  );
};

export default InterviewsToBookContainer;
