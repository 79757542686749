import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { useHostService } from 'hooks/services/hosts';
import { InterviewRequest } from 'hooks/services/hosts/interfaces/interviews.request';
import { InterviewResponse } from 'hooks/services/hosts/interfaces/interviews.response';
import AllInterviewsTable from './table';

const AllInterviewsContainer = ({
  pageHeaderHeight,
  handleClick,
}: {
  pageHeaderHeight: number;
  handleClick: (applicantId: number) => void;
}) => {
  const [rows, setRows] = useState<InterviewResponse[]>([]);
  const [rowsTotal, setRowsTotal] = useState(100);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });

  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);
  const { getInterviews } = useHostService();

  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await getInterviewsHandler({
      ...data,
      hostId: parsedHostId,
    });
  };

  const getInterviewsHandler = async (
    data: InterviewRequest & { hostId: number; }
  ) => {
    const response = await getInterviews(data);
    setRowsTotal(response.total);
    setRows(response.records);
  };

  const fetchInterviews = useCallback(async () => {
    try {
      const data = {
        ...tableParams,
        hostId: parsedHostId,
      };
      await getInterviewsHandler(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchInterviews();
  }, [fetchInterviews]);

  return (
    <AllInterviewsTable
      {...{
        rows,
        rowsTotal,
        handleTableChanged,
        heightOfTopComponents: pageHeaderHeight,
        fetchInterviews,
        handleClick,
        fetchData: fetchInterviews
      }}
    />
  );
};

export default AllInterviewsContainer;
