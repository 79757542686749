import { Box, IconButton } from '@mui/material';
import { OptionalTooltip } from 'components/tooltip/tooltip';
import { ButtonWithTooltipProps } from '../../interfaces/buttonWithTooltipProps';

export const ButtonWithToolltip = ({
  onClick,
  title,
  icon,
}: ButtonWithTooltipProps) => {
  return (
    <OptionalTooltip text={title} show={true} placement='bottom'>
      <IconButton {...{ onClick }}>
        <Box component='img' src={icon} />
      </IconButton>
    </OptionalTooltip>
  );
};
