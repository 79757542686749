import { format, isAfter, isValid, parse, parseISO } from 'date-fns';
import moment from 'moment-timezone';
import { rubyToMomentMapping } from './timezones';

export const convertToDate = (date?: Date | string | null, format?: string) => {
  return !date ? null : parseStringOrDate(date, format);
};

export const convertToDateOrUndefined = (
  date?: Date | string | null,
  format?: string
) => {
  return !date ? undefined : parseStringOrDate(date, format);
};

const parseStringOrDate = (date: Date | string, format?: string) => {
  if (date instanceof Date) {
    return date;
  }

  return parse(date, format ? format : DEFAULT_FORMAT, new Date());
};

export const formatDate = (
  date?: Date | string | null,
  formatDate?: string,
  displayFormat?: string
) => {
  if (!date) {
    return '';
  }
  const parsedDate = parseStringOrDate(date, formatDate);

  if (!isValid(parsedDate)) return '';

  return format(parsedDate, displayFormat ? displayFormat : DEFAULT_FORMAT);
};

export const transformDate = (curr: any, orig: any) =>
  orig === '' ? null : curr;

export const DEFAULT_FORMAT = 'MM/dd/yyyy';

export const BE_DATE_FORMAT = 'yyyy-MM-dd';

export const formDateDDMonYYYY = (val?: Date | string) => {
  if (!val) return;
  const date: Date = new Date(val);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate: string = date.toLocaleDateString('en-GB', options);
  return formattedDate;
};

export const parseStringToDate = (val: string): Date =>
  parse(val, 'yyyy-MM-dd', new Date());

export const parseStringWeekDayMMDD = (val: string): string =>
  format(parseISO(val), 'EEEE, MMMM d');

export function convertDateTimeToTime(dateTime: Date): string {
  const formattedTime = format(dateTime, 'hh:mm aa').toLowerCase();
  return formattedTime;
}

export function convertUTCDateToOffset(
  datetimeStr: string,
  timeZoneRuby?: string
): Date {
  const timeZone = timeZoneRuby
    ? rubyToMomentMapping.get(timeZoneRuby)
    : undefined;

  let date = moment.utc(datetimeStr);

  if (timeZone) {
    date = date.tz(timeZone);
  }

  const year = date.year();
  const month = date.month();
  const day = date.date();
  const hours = date.hours();
  const minutes = date.minutes();
  const seconds = date.seconds();

  return new Date(year, month, day, hours, minutes, seconds);
}

export function convertDateTimeUserTimezoneToUTC(
  date: Date,
  time: string,
  timeZone: string
) {
  const formattedTime = format(parse(time, 'hh:mm aa', new Date()), 'HH:mm');
  const datePart = format(date, 'yyyy-MM-dd'); // Extract the date part in YYYY-MM-DD format
  const dateTimeUserTimezone = moment.tz(
    `${datePart} ${formattedTime}`,
    timeZone
  );

  return dateTimeUserTimezone.utc().toISOString();
}

export const formDateDDMonthYYYYTime = (val?: Date): string => {
  if (!val) return '';
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate: string = val.toLocaleDateString('en-GB', options);
  return formattedDate;
};

export function formatDateToWeekDayDDMM(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  };
  const dateString = date.toLocaleDateString('en-US', options);
  const day = date.getDate();
  const ordinalIndicator = getOrdinalIndicator(day);

  return dateString.replace(`${day}`, `${day}${ordinalIndicator}`);
}

function getOrdinalIndicator(day: number): string {
  if (day >= 11 && day <= 13) {
    return 'th';
  }

  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const isDateAfterCurrentDate = (
  interviewDate: string,
  timeZone?: string,
  fixedCurrentDate?: Date
) => {
  const currentDate = convertUTCDateToOffset(
    fixedCurrentDate
      ? fixedCurrentDate.toISOString()
      : new Date().toISOString(),
    timeZone
  );
  const interviewDateVal = convertUTCDateToOffset(interviewDate, timeZone);
  return isAfter(interviewDateVal, currentDate);
};
