import * as yup from 'yup';

import { transformStringToNumberOrUndefined } from 'utils/extensions/numberInputTransformer';

export const schema = yup.object().shape({
  housingModel: yup.string().required().label('Housing Model'),
  housingType: yup.string().required().label('Housing Type'),
  propertyName: yup.string().required().label('Property Name').max(250),
  contactName: yup.string().required().label('Contact Name').max(50),
  propertyManagement: yup
    .string()
    .nullable()
    .max(250)
    .label('Property Management'),
  canBeCoEd: yup.string().nullable().label('Can Be Co-Ed'),
  phoneNumber: yup.string().nullable().max(15).label('Phone Number'),
  website: yup.string().url().nullable().label('Website'),
  emailForReservationEnquiries: yup
    .string()
    .email()
    .nullable()
    .max(70)
    .label('Email for reservation enquiries'),
  description: yup.string().nullable().max(1000).label('Description'),
  addressStreet: yup.string().required().label('Address Street').max(50),
  addressCity: yup.string().required().label('Address City').max(50),
  addressState: yup.string().required().label('Address State'),
  addressZipCode: yup.string().required().label('Address Zip code').max(10),
  beddingAndTowels: yup.string().nullable().label('Bedding And Towels'),
  costOfBeddingAndTowels: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .nullable()
    .label('Cost Of Bedding And Towels')
    .max(99999999)
    .min(0),
  kitchenFacilities: yup
    .string()
    .nullable()
    .max(250)
    .label('Kitchen Facilities'),
  additionalItemsMustBring: yup
    .string()
    .nullable()
    .max(500)
    .label('Additional Items Must Bring'),
  additionalComments: yup
    .string()
    .nullable()
    .max(250)
    .label('Additional Comments'),
  housingCost: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .required()
    .label('Housing Cost')
    .max(9999999)
    .min(0.1),
  rentPeriodicity: yup.string().required().label('Rent Periodicity'),
  isWeeklyRentPayrollDeducted: yup
    .string()
    .required()
    .label('Is Weekly Rent Payroll Deducted'),
  includedInCost: yup.string().nullable().max(500).label('Included In Cost'),
  additionalItemsIncludedInCost: yup
    .string()
    .nullable()
    .max(250)
    .label('Additional Items Included In Cost'),
  administrationFee: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .nullable()
    .max(9999999)
    .label('Administration Fee'),
  administrationFeeDue: yup
    .string()
    .nullable()
    .max(100)
    .label('Administration Fee Due'),
  housingDepositAmount: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .required()
    .max(9999999)
    .min(0.1)
    .label('Housing Deposit Amount'),
  isHousingDepositPayrollDeducted: yup
    .string()
    .required()
    .label('Is Housing Deposit Payroll Deducted'),
  housingDepositRefundable: yup
    .string()
    .required()
    .label('Housing Deposit Refundable'),
  furtherInformationOnHousingRefundPolicy: yup
    .string()
    .nullable()
    .max(250)
    .label('Further Information On Housing Refund Policy'),
  isLeaseRequired: yup.string().nullable().label('Lease Required'),
  lengthOfLease: yup.string().nullable().max(50).label('Length Of Lease'),
  timeUnit: yup.string().nullable().label('Time Unit'),
  furtherInformationOnLengthOfLease: yup
    .string()
    .nullable()
    .max(250)
    .label('Further Information On Length Of Lease'),
  feesAdditionalComments: yup
    .string()
    .nullable()
    .max(1000)
    .label('Fees Additional Comments'),
  isDepositRefundPolicy: yup.string().nullable().label('Deposit Refund Policy'),
  isRentersInsuranceRequired: yup
    .string()
    .nullable()
    .label('Renters Insurance Required'),
  housingAmentities: yup
    .string()
    .nullable()
    .max(250)
    .label('Housing Amentities'),
  distanceFromWorkSiteToHousing: yup
    .string()
    .nullable()
    .max(250)
    .label('Distance From Work Site To Housing'),
  transportationDetails: yup
    .string()
    .nullable()
    .max(250)
    .label('Transportation Details'),
});
