import { Checkbox, FormControlLabel, FormGroup, useTheme } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { SingleCheckboxInputProps } from '../../interfaces/SingleCheckboxInputProps';

export default function SingleCheckbox<TFieldValues extends FieldValues>({
  item,
  control,
  name,
  hideLabel,
  disabled,
}: SingleCheckboxInputProps<TFieldValues>) {
  const theme = useTheme();
  const { checkbox } = theme.tables;

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <FormControlLabel
              key={item.key}
              name={name}
              label={hideLabel ? '' : item.value}
              control={
                <Checkbox
                  disableRipple
                  sx={checkbox}
                  value={JSON.stringify(item)}
                  checked={
                    value
                      ? value.some(
                          (x: { id: number }) => x.id === Number(item.key)
                        )
                      : undefined
                  }
                  onChange={(event, checked) => {
                    const values: { id: number }[] = value;
                    if (checked) {
                      values.push({ id: Number(item.key) });
                      onChange(values);
                    } else {
                      onChange(values.filter((x) => x.id !== Number(item.key)));
                    }
                  }}
                  disabled={disabled}
                />
              }
            />
          </>
        )}
      />
    </FormGroup>
  );
}
