import { Button, Grid } from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import { ButtonsContainer, ContainerSpacing } from 'utils/formHeader';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import { ContactsProps } from './interfaces/contactsProps';
import SimpleTable from '../../../components/tables/simpleTable';
import ResponsiveListView from '../../../components/responsiveListView/components/responsiveListView';

export const Contacts = ({
  hostName,
  title,
  newContactButton,
  tableProps,
  paginationProps,
}: ContactsProps) => {
  return (
    <Grid
      {...ContainerSpacing}
      sx={{
        height: 'unset',
        overflow: 'auto',
        ...NoScrollBar,
      }}
    >
      <Grid item xs={12} md={9}>
        <PageHeader>{`${title} ${hostName}`}</PageHeader>
      </Grid>

      <Grid item xs={12} md={3}>
        <Grid {...ButtonsContainer}>
          <Button
            fullWidth={false}
            variant='contained'
            onClick={newContactButton.onClick}
          >
            {newContactButton.label}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveListView
          listView={<SimpleTable {...tableProps} />}
          paginationProps={paginationProps}
        />
      </Grid>
    </Grid>
  );
};
