import DatePickerInput from 'components/datePickerInput';
import DropdownMultiselectInput from 'components/dropdownInputMultiselect';
import { useTranslations } from 'hooks/useTranslations';
import { FiltersFormProps } from './interfaces/filtersFormProps';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import TextareaInput from 'components/textAreaInput/textAreaInput';

const Host = ({
  control,
  hostEntities,
  register,
}: Pick<FiltersFormProps, 'control' | 'hostEntities' | 'register'>) => {
  const translations = useTranslations();

  return (
    <DropdownInput
      control={control}
      register={register('host')}
      inputId={translations.visaArrivalInfo.filters.host}
      inputLabel={translations.visaArrivalInfo.filters.host}
      items={hostEntities}
      labelHeight={23}
      disabled={hostEntities.length === 0}
    />
  );
};

const Programs = ({
  control,
  programs,
}: Pick<FiltersFormProps, 'control' | 'programs'>) => {
  const translations = useTranslations();
  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.visaArrivalInfo.filters.programs}
      inputLabel={translations.visaArrivalInfo.filters.programs}
      items={programs}
      name='programs'
      labelHeight={23}
      disabled={programs.length === 0}
    />
  );
};

const EmbassyDate = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.visaArrivalInfo.filters.embassyDate}
      control={control}
      name='embassyDate'
      getValue={() => getValues('embassyDate')}
      labelHeight={23}
    />
  );
};

const ArrivalDate = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.visaArrivalInfo.filters.arrivalDate}
      control={control}
      name='arrivalDate'
      getValue={() => getValues('arrivalDate')}
      labelHeight={23}
    />
  );
};

const ApplicantIdSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.applicantId}
      inputLabel={translations.visaArrivalInfo.filters.applicantId}
      register={register('applicantId')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const EmailSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.email}
      inputLabel={translations.visaArrivalInfo.filters.email}
      register={register('email')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const PositionSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.position}
      inputLabel={translations.visaArrivalInfo.filters.position}
      register={register('position')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const PlacementIdSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.placementId}
      inputLabel={translations.visaArrivalInfo.filters.placementId}
      register={register('placementId')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};
const ArrivalAirportSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.arrivalAirport}
      inputLabel={translations.visaArrivalInfo.filters.arrivalAirport}
      register={register('arrivalAirport')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const AirlineSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.airline}
      inputLabel={translations.visaArrivalInfo.filters.airline}
      register={register('airline')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const FlightNumberSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.visaArrivalInfo.filters.flightNumber}
      inputLabel={translations.visaArrivalInfo.filters.flightNumber}
      register={register('flightNumber')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

export {
  Programs,
  Host,
  PositionSearch,
  EmbassyDate,
  ArrivalDate,
  ApplicantIdSearch,
  EmailSearch,
  PlacementIdSearch,
  ArrivalAirportSearch,
  AirlineSearch,
  FlightNumberSearch,
};
