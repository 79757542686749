import { convertStringToBoolean } from 'utils/string/stringConverters';
import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    isSponsorInterviewRequired: yup
      .boolean()
      .required()
      .transform(convertStringToBoolean)
      .label('Sponsor Interview'),
    isHostCompanyInterviewRequired: yup
      .boolean()
      .required()
      .transform(convertStringToBoolean)
      .label('Host Company Interview'),
    isApplicationVideoRequired: yup
      .boolean()
      .required()
      .transform(convertStringToBoolean)
      .label('Application Video'),
  });
