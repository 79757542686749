import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Row } from './components/row';
import { SimpleTableProps } from './interfaces/simpleTableProps';
import { Header } from '../components/header';
import { RowNotFound } from '../components/rowNotFound';

const SimpleTable = ({
  headers,
  rows,
  align,
  customMessageNoDataFound,
}: SimpleTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <Header columns={headers} align={align} />
        <TableBody>
          {rows &&
            rows.map((row, index) => (
              <Row key={index} rows={row.values} align={align} />
            ))}
          {rows && rows.length === 0 && (
            <RowNotFound
              colSpan={headers.length}
              customMessageNoDataFound={customMessageNoDataFound}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
