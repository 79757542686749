import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { OfferDetailsFormInput } from '../../interfaces/offerDetailsForm.formInput';

export const ScreeningOfferDetails = ({
  isDisabled,
}: {
  isDisabled: boolean;
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  const { numberInput, yesOrNoInput, halfToFullPageInput, inputsSection } =
    useFormInputsStyle(theme);

  const {
    title,
    will_ev_incur_a_cost_for_screening,
    will_company_require_a_drug_test,
    drug_screening_policy,
    drug_test_provided_by_co,
    drug_screening_cost_to_student,
    drug_screening_cost_due,
  } = translations.offerDetailsForm.sections.screening;

  return (
    <ConnectForm<OfferDetailsFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'willEVIncurCostForScreening'}
                  inputLabel={will_ev_incur_a_cost_for_screening}
                  errorMessage={
                    errors?.screening?.willEVIncurCostForScreening?.message
                  }
                  control={control}
                  register={register('screening.willEVIncurCostForScreening')}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'willCompanyRequireDrugTest'}
                  inputLabel={will_company_require_a_drug_test}
                  errorMessage={
                    errors?.screening?.willCompanyRequireDrugTest?.message
                  }
                  control={control}
                  register={register('screening.willCompanyRequireDrugTest')}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'drugScreeningPolicy'}
                  inputLabel={drug_screening_policy}
                  register={register('screening.drugScreeningPolicy')}
                  errorMessage={errors?.screening?.drugScreeningPolicy?.message}
                  maxLength={250}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'isDrugTestProvidedByHostCompany'}
                  inputLabel={drug_test_provided_by_co}
                  labelHeight={66}
                  errorMessage={
                    errors?.screening?.isDrugTestProvidedByHostCompany?.message
                  }
                  control={control}
                  register={register(
                    'screening.isDrugTestProvidedByHostCompany'
                  )}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={drug_screening_cost_to_student}
                  labelHeight={66}
                  useDecimals={true}
                  errorMessage={
                    errors?.screening?.drugScreeningCostToStudent?.message
                  }
                  control={control}
                  name='screening.drugScreeningCostToStudent'
                  getValue={() =>
                    getValues('screening.drugScreeningCostToStudent')
                  }
                  disabled={isDisabled}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={drug_screening_cost_due}
                  labelHeight={66}
                  useDecimals={true}
                  errorMessage={
                    errors?.screening?.drugScreeningCostDue?.message
                  }
                  control={control}
                  name='screening.drugScreeningCostDue'
                  getValue={() => getValues('screening.drugScreeningCostDue')}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
