import { IconButton } from '@mui/material';
import { ArrowButtonProps } from 'components/numberInput/interfaces/ArrowButtonProps';

const ArrowButton = ({ isUp, icon, name, handleStep }: ArrowButtonProps) => {
  return (
    <IconButton
      id={name}
      aria-label={name}
      edge='end'
      disableFocusRipple={true}
      onClick={() => {
        handleStep(isUp);
      }}
      style={{
        padding: 0,
        margin: 0,
        width: '25px',
        height: '15px',
      }}
    >
      {icon}
    </IconButton>
  );
};

export default ArrowButton;
