import convertDocumentToFormData from './helpers/convertDocumentToFormData';
import { convertPlacementStatus } from './helpers/convertPlacementStatus';
import { ClonePlacementBody, ClonePlacementRequestParam } from './interfaces/cloneParams.request';
import { DeleteDocumentRequestParam } from './interfaces/deleteParams.request';
import { GetRequestParam } from './interfaces/getParams.request';
import { PatchRequestParam } from './interfaces/patchParams.request';
import { PlacementResponse } from './interfaces/placement.response';
import { PlacementsFiltersResponse } from './interfaces/placementsFilters.response';
import { PlacementsSummaryRequest } from './interfaces/placementsSummary.request';
import { PlacementSummaryResponse } from './interfaces/placementSummary.response';
import { PlacementWithAllDocumentsResponse } from './interfaces/placementWithAllDocuments.response';
import { PostRequestParam } from './interfaces/postParams.request';
import { RecordCreatedResponse } from './interfaces/recordCreated.response';
import { UploadDocumentRequestParam } from './interfaces/uploadDocument.request';
import { useFetch } from '../../useFetch';
import { PagedResponse } from '../commons/interfaces/paged.response';
import { makeQueryParamsFromObject } from '../helpers/queryParamsHelpers';

const usePlacementService = () => {
  const { get, post, patch, del } = useFetch();
  const controllerParentName = 'hosts';
  const controllerName = 'placements';

  const getPlacement = async ({
    hostId,
    id,
  }: GetRequestParam): Promise<PlacementResponse> => {
    const response = await get<PlacementResponse>(
      `${controllerParentName}/${hostId}/${controllerName}/${id}`
    );

    return convertPlacementStatus(response);
  };

  const getPlacements = async (
    params: PlacementsSummaryRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    let resource = `${controllerParentName}/${hostId}/${controllerName}${query}`;

    const data = await get<PagedResponse<PlacementSummaryResponse>>(resource);

    const response = {
      ...data,
      records: data.records.map((r) => convertPlacementStatus(r)),
    };

    return response;
  };

  const postPlacement = async ({
    body,
    hostId,
  }: PostRequestParam): Promise<RecordCreatedResponse> => {
    return await post<RecordCreatedResponse>(
      `${controllerParentName}/${hostId}/${controllerName}`,
      {
        body,
      }
    );
  };

  const patchPlacement = async ({ body, hostId, id }: PatchRequestParam) => {
    return await patch<PlacementResponse>(
      `${controllerParentName}/${hostId}/${controllerName}/${id}`,
      {
        body,
      }
    );
  };

  const deleteDocument = async ({
    hostId,
    placementId,
    id,
  }: DeleteDocumentRequestParam) => {
    return await del(
      `${controllerParentName}/${hostId}/${controllerName}/${placementId}/documents/${id}`
    );
  };

  const uploadDocument = async ({
    hostId,
    placementId,
    body,
  }: UploadDocumentRequestParam): Promise<RecordCreatedResponse> => {
    const formData = convertDocumentToFormData(body);

    return await post<RecordCreatedResponse>(
      `${controllerParentName}/${hostId}/${controllerName}/${placementId}/documents`,
      {
        body: formData,
      }
    );
  };

  const postClone = async ({
    body,
    hostId,
    id,
  }: ClonePlacementRequestParam): Promise<void> => {
    return await post(
      `${controllerParentName}/${hostId}/${controllerName}/${id}/clone`,
      {
        body,
      }
    );
  };

  const getForClone = async (
    hostId: number,
    id: number
  ): Promise<ClonePlacementBody> => {
    return await get<ClonePlacementBody>(
      `${controllerParentName}/${hostId}/${controllerName}/${id}/clone`
    );
  };

  const getPlacementsFilters = async (hostId: number) => {
    return await get<PlacementsFiltersResponse>(
      `${controllerParentName}/${hostId}/${controllerName}/placements-filters`
    );
  };

  const confirmPlacement = async (hostId: number, id: number) => {
    return await patch<PlacementSummaryResponse>(
      `${controllerParentName}/${hostId}/${controllerName}/${id}/confirm`
    );
  };

  const getPlacementPdf = async (hostId: number, id: string) => {
    return await get<Blob>(
      `${controllerParentName}/${hostId}/${controllerName}/${id}/pdf`
    );
  };

  const previewPlacement = async ({
    hostId,
    id,
  }: GetRequestParam): Promise<PlacementWithAllDocumentsResponse> => {
    const response = await get<PlacementWithAllDocumentsResponse>(
      `${controllerParentName}/${hostId}/${controllerName}/${id}/preview`
    );

    return {
      ...response,
      placement: convertPlacementStatus(response.placement),
    };
  };

  const submitPlacement = async (hostId: number, id: number): Promise<void> => {
    return await post(
      `${controllerParentName}/${hostId}/${controllerName}/${id}/submit`
    );
  };

  return {
    getPlacement,
    getPlacements,
    postPlacement,
    patchPlacement,
    deleteDocument,
    uploadDocument,
    postClone,
    getForClone,
    getPlacementsFilters,
    confirmPlacement,
    getPlacementPdf,
    previewPlacement,
    submitPlacement,
  };
};

export { usePlacementService };
