import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { useHeightRef } from 'hooks/useHeightRef';
import { CellHeaderId } from './interfaces/interviewsToBookCellHeader';
import { InterviewsToBookTableProps } from './interfaces/interviewsToBookTableProps';
import InterviewsToBookTable from './interviewsToBookTable';
import ScheduleInterview from 'pages/interviews/scheduleInterview';

import { InterviewToBookResponse } from 'hooks/services/hosts/interfaces/interviewsToBook.response';
import { useScheduleInterview } from 'pages/interviews/scheduleInterview/hooks/useScheduleInterview';
import DeclineInterviewModal from 'pages/interviews/declineInterviewModal';
import { useState } from 'react';
import { Nullable } from 'utils/interfaces';

const InterviewsToBookTableContainer = ({
  heightOfTopComponents,
  tableProps: { rows, rowsTotal, handleTableChanged: handleTablePropsChanged },
  handleClick,
  fetchData,
}: {
  heightOfTopComponents: number;
  tableProps: InterviewsToBookTableProps;
  handleClick: (applicantId: number) => void;
  fetchData: () => void;
}) => {
  const [interview, setInterview] =
    useState<Nullable<InterviewToBookResponse>>(null);
  const [declineOpen, setDeclineOpen] = useState(false);

  const [paginationHeight, paginationRef] = useHeightRef();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    order,
    orderBy,
    sortHandler,
  } = usePaginationHook<CellHeaderId>(handleTablePropsChanged);

  const heightToSkip = heightOfTopComponents + paginationHeight;
  const {
    activeRow,
    setActiveRow,
    handleInterviewAction
  } = useScheduleInterview<InterviewToBookResponse>();

  const openDeclineInterviewModal = (interview: InterviewToBookResponse) => {
    setInterview(interview);
    setDeclineOpen(true);
  };

  return (
    <>
      <InterviewsToBookTable
        heightToSkip={heightToSkip}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        paginationRef={paginationRef}
        rowsPerPage={rowsPerPage}
        tableProps={{
          rows,
          rowsTotal,
          handleTableChanged: handleTablePropsChanged,
        }}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
        handleClick={handleClick}
        handleInterviewAction={handleInterviewAction}
        openDeclineInterviewModal={openDeclineInterviewModal}
      />

      {interview && (
        <DeclineInterviewModal
          modalOpen={declineOpen}
          setModalOpen={setDeclineOpen}
          entity='interviews-to-book'
          participantName={interview.applicantName}
          jobPosition={interview.position}
          interviewId={interview.id}
          fetchData={fetchData}
        />
      )}

      {activeRow && (
        <ScheduleInterview
          open={true}
          onClose={() => {
            setActiveRow(null);
          }}
          applicantId={activeRow.applicantId}
          applicantTimeZone={activeRow.applicantTimeZone}
          applicantTimeZoneOffset={activeRow.applicantTimeZoneOffset}
          interviewId={activeRow.id}
          applicantName={activeRow.applicantName}
          countryOfResidence={activeRow.countryOfResidence}
          fetchData={fetchData}
          actionType='book'
        />
      )}
    </>
  );
};

export default InterviewsToBookTableContainer;
