import { PatchPlacementBody } from 'hooks/services/placements/interfaces/patchParams.request';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';
import { makeEmployment } from './makeEmployment';
import { makePaymentPatchRequest } from './makePayment';
import { makeTrainingOperationPatchRequest } from './makeTrainingOperation';
import { makeGroomingPatchRequest } from './makeGrooming';
import { makeInterviewsPatchRequest } from './makeInterviews';
import { makeArrivalInstructions } from './makeArrivalInstructions';
import { makeHousing } from './makeHousing';

export default function makePatchRequestFromFormInput(
  data: EditPlacementFormInput
): PatchPlacementBody {
  return {
    employmentPosition: makeEmployment(data),
    payment: makePaymentPatchRequest(data),
    trainingOrientation: makeTrainingOperationPatchRequest(data),
    grooming: makeGroomingPatchRequest(data),
    interviewRequirements: makeInterviewsPatchRequest(data),
    arrivalInstructions: makeArrivalInstructions(data),
    housing: makeHousing(data),
  };
}
