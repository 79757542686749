import { styled } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Action } from 'pages/interviews/interfaces/interviews';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const ActionsDropdown = ({
  anchorEl,
  open,
  handleCloseActionsDropdown,
  actions,
  handleOpenModal,
}: {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleCloseActionsDropdown: () => void;
  actions: Action[];
  handleOpenModal: (action: Action) => void;
}) => {
  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseActionsDropdown}
    >
      {actions.map((item) => (
        <MenuItem
          key={item.id}
          disableRipple
          onClick={() => {
            handleOpenModal(item);
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export default ActionsDropdown;
