import { useTheme } from '@mui/material';

import { ResetPasswordProps } from './interfaces/resetPasswordProps';
import Wrapper from 'components/login/wrapper';
import { Container } from 'components/login/container/styledContainer';
import Header from 'components/login/header';
import MuiButton from 'components/button';
import EmailInput from 'components/login/emailInput';
import LoginLink from 'components/login/loginLink';
import { InputWrapper } from 'components/login/inputWrapper/styledInputWrapper';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';

const ResetPassword = ({
  onSubmit,
  register,
  formErrors,
  isLoading,
}: ResetPasswordProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const { recoverPasswordPage } = useTranslations();

  return (
    <Wrapper>
      <Container
        item
        xs={4}
        md={8}
        lg={4}
        xl={3}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Header
          title={recoverPasswordPage.recoverPasswordTitle}
          subtitle={recoverPasswordPage.recoverPasswordSubtitle}
        />

        <form
          onSubmit={onSubmit()}
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <InputWrapper
            sx={{
              height: theme.spacing(10),
              marginBottom: 5,
            }}
          >
            <EmailInput
              defaultValue={null}
              register={register}
              formErrors={formErrors}
            />
          </InputWrapper>

          <MuiButton
            dataTestid='recover-password'
            isLoading={isLoading}
            text={recoverPasswordPage.recoverPasswordButton}
          />
        </form>

        <LoginLink
          link={ApplicationRoutes.signIn}
          text={translations.returnToLogin}
        />
      </Container>
    </Wrapper>
  );
};

export default ResetPassword;
