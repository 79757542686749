import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHostService } from 'hooks/services/hosts';
import { useTranslations } from 'hooks/useTranslations';
import { AddressForm } from './addressForm';
import { AddressInputsProps } from './interfaces/addressInputsProps';
import { initValues } from './util/initValues';
import { setValues } from './util/setValues';

const AddressContainer = () => {
  const { hostId } = useParams<{ hostId: string }>();
  const { getAddress } = useHostService();
  const translations = useTranslations();

  const { commonLabes, physicalAddress, mailingAddress, title, subtitle } =
    translations.hostAddress;

  const formMethods = useForm<AddressInputsProps>({
    defaultValues: initValues,
  });

  const fetchAddress = async () => {
    const address = await getAddress({ hostId: Number(hostId) });
    setValues(address, formMethods.setValue);
  };

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <AddressForm
        {...{ title, subtitle, commonLabes, physicalAddress, mailingAddress }}
      />
    </FormProvider>
  );
};

export default AddressContainer;
