import { Step, StepButton, Stepper, useTheme } from '@mui/material';
import { Link } from 'react-scroll';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import StepperProps from './interfaces/stepperProps';

const MobileStepper = ({ activeStep, handleStep, steps }: StepperProps) => {
  const theme = useTheme();

  return (
    <Stepper
      nonLinear
      activeStep={activeStep}
      orientation='horizontal'
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        mt: { xs: 2, sm: 4 },
        mb: 2,
        overflowX: 'auto',
        overflowY: 'hidden',
        ...NoScrollBar,
        msOverflowX: 'none',
        scrollbarWidth: 'none',
        '.MuiStepConnector-root': {
          justifyContent: { lg: 'flex-start' },
          margin: theme.spacing(2, 0),
          marginLeft: { lg: theme.spacing(6) },
        },
        '.MuiStepConnector-line': {
          minWidth: {
            xs: 30,
            md: 80,
          },
        },
      }}
    >
      {steps &&
        steps.map((item, index) => (
          <Step
            key={item.id}
            sx={{
              margin: theme.spacing(2, 0),
              padding: 0,
            }}
          >
            <Link
              to={item.id}
              spy={true}
              smooth={true}
              duration={250}
              containerId='containerElement'
              onSetActive={() => handleStep(index)}
            >
              <StepButton
                onClick={() => handleStep(index)}
                icon={' '}
                sx={{
                  padding: theme.spacing(2, 1),
                  width: 95,

                  '.MuiStepIcon-root': {
                    marginBottom: theme.spacing(1),
                  },

                  '.MuiStepLabel-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  },

                  '.MuiStepLabel-label': {
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 12,
                    wordWrap: 'break-word',
                  },
                }}
              >
                {item.title}
              </StepButton>
            </Link>
          </Step>
        ))}
    </Stepper>
  );
};

export default MobileStepper;
