import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FiltersFormInputs } from '../interfaces/filtersFormInput';
import { FiltersFormProps } from '../interfaces/filtersFormProps';
import { FiltersProps } from '../interfaces/filtersProps';

const useFilterHook = ({
  onApplyFilter,
  filters,
  allPrograms,
}: FiltersProps): FiltersFormProps | null => {
  const [, setIsLoading] = useState(false);
  const [isITPProgram, setIsITPProgram] = useState(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    resetField,
    watch,
    formState: { isDirty },
  } = useForm<FiltersFormInputs>({
    defaultValues: {
      availableFrom: null,
      availableTo: null,
      englishProficiencies: '',
      skills: [],
      programs: [],
      availableAtShortNotice: false,
      programTypes: [],
      placementCategories: [],
      residenceCountries: [],
    },
  });

  const programsValue = useWatch({ control, name: 'programs' });
  const availableFromValue = useWatch({ control, name: 'availableFrom' });
  const availableToValue = useWatch({ control, name: 'availableTo' });

  useEffect(() => {
    const selectedProgramsCategories: string[] = allPrograms
      .filter((item) =>
        programsValue.some((value) => value === item.id.toString())
      )
      .map((item) => item.categoryId);
    const ITPExists = selectedProgramsCategories?.some((item) =>
      item?.includes('itp')
    );
    if (ITPExists) {
      setIsITPProgram(true);
    } else {
      setIsITPProgram(false);
      resetField('programTypes', { defaultValue: [] });
      resetField('placementCategories', { defaultValue: [] });
      resetField('residenceCountries', { defaultValue: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsValue, allPrograms]);

  useEffect(() => {
    if (
      availableFromValue &&
      availableToValue &&
      availableFromValue > availableToValue
    ) {
      resetField('availableTo', { defaultValue: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFromValue]);

  useEffect(() => {
    if (
      availableFromValue &&
      availableToValue &&
      availableFromValue > availableToValue
    ) {
      resetField('availableFrom', { defaultValue: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableToValue]);

  const applyFilter = useCallback(
    async (data?: FiltersFormInputs) => {
      setIsLoading(true);

      await onApplyFilter(data);

      setIsLoading(false);
    },
    [onApplyFilter]
  );

  const submitHandler = handleSubmit(applyFilter);

  const handleReset = async () => {
    reset();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const subscription = watch(() => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        handleSubmit(applyFilter)();
      }, 500);
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, applyFilter, reset]);

  if (!filters) return null;

  const {
    programs,
    englishProficiencies,
    skills,
    placementCategories,
    programTypes,
    residenceCountries,
  } = filters;

  return {
    control,
    getValues,
    onSubmit: submitHandler,
    register,
    isDirty,
    clearFilters: handleReset,
    programs,
    englishProficiencies,
    skills,
    placementCategories,
    programTypes,
    residenceCountries,
    isITPProgram,
  };
};

export default useFilterHook;
