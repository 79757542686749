import { FC } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { SwitchDisplayProps } from './interfaces/switchDisplay.props';
import { SwitchDisplayDesktop } from './swtichDisplay.desktop';
import { SwitchDisplayMobile } from './swtichDisplay.mobile.';

export const SwitchDisplayContainer: FC<SwitchDisplayProps> = (props) => {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));

  return isSmallScreen ? (
    <SwitchDisplayMobile {...props} />
  ) : (
    <SwitchDisplayDesktop {...props} />
  );
};
