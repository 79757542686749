import * as yup from 'yup';

export const schema = yup.object().shape({
  addressOfNearestSocialSecurityOffice: yup.string().required().label('Address of the nearest social security office').max(250),
  
  distanceOfSSOFromSOA: yup.string().nullable().label('Distance of SSO from SOA').max(100),

  willHostEntityProvideTransportationToTheNearestSocialSecurityOffice: yup
  .string()
  .required()
  .label('Will Host Entity provide transportation to the nearest Social Security office'),

  willHostEntityHelpEVMakeCopiesOfNecessaryDocuments: yup
  .string()
  .required()
  .label('Will Host Entity help EV make copies of the necessary documents'),

  otherSocialSecurityAssistanceProvided: yup
  .string()
  .required()
  .label('Other Social Security assistance provided')
  .max(500)
});