import { Grid } from '@mui/material';
import ButtonWithProgress from 'components/button/buttonWithProgress';
import { PageHeader } from 'components/page/header/styledHeader';
import { ButtonsContainer, ContainerSpacing, PrimaryButtonResponsiveMargin } from 'components/page/header/utils';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import { FiltersProps } from './components/filters/interfaces/filtersProps';
import PlacementsTable from './components/placementsTable';
import { PlacementsTableProps } from './components/placementsTable/interfaces/placementsTableProps';

const PlacementsList = ({
  onCreatePlacement,
  tableProps,
  filterProps,
}: {
  onCreatePlacement: () => void;
  tableProps: PlacementsTableProps;
  filterProps: FiltersProps;
}) => {
  const translations = useTranslations();

  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();

  const heightOfTopComponents = pageHeaderHeight + buttonsHeight;
  const containerProps = { ...ContainerSpacing, height: 'unset' };

  return (
    <Grid {...containerProps}>
      <Grid item xs={12} md={9} ref={pageHeaderRef}>
        <PageHeader>{translations.placementsList.title}</PageHeader>
      </Grid>

      <Grid item xs={12} md={3} ref={buttonsRef}>
        <Grid {...ButtonsContainer}>
          <Grid
            sx={{
              width: { xs: '100%', md: 'unset' },
              ...PrimaryButtonResponsiveMargin,
            }}
          >
            <ButtonWithProgress
              dataTestid='create'
              text={translations.placementsList.createPlacement}
              type='button'
              onClick={onCreatePlacement}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PlacementsTable
          heightOfTopComponents={heightOfTopComponents}
          tableProps={tableProps}
          filterProps={filterProps}
        />
      </Grid>
    </Grid>
  );
};

export default PlacementsList;
