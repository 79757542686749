const SectionContainerStyle = {
  position: 'relative',
  height: '100%',
  overflow: 'auto',
  '> *:not(:last-child)': {
    mb: {
      xs: 5,
      md: 2.7,
    }
  },
};

export default SectionContainerStyle;
