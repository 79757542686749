import { useAlert } from 'providers/alertProvider';

export type FetchAlertsType = {
  [key: number]: { type: string; message: string };
  default: { type: string; message: string };
};

export const fetchAlerts: FetchAlertsType = {
  401: {
    type: 'warning',
    message:
      'We are afraid only administrators have access to this page or action. Please reach out to your Account Manager should you have any questions.',
  },
  403: {
    type: 'warning',
    message:
      'We are afraid only administrators have access to this page or action. Please reach out to your Account Manager should you have any questions.',
  },
  404: {
    type: 'warning',
    message: 'The page cannot be found. Make sure you access the right one.',
  },
  429: {
    type: 'warning',
    message:
      'Our systems are a bit busy at the moment, please take a break and try again soon.',
  },
  500: {
    type: 'error',
    message:
      'Our systems are a bit busy at the moment, please take a break and try again soon.',
  },
  503: {
    type: 'error',
    message:
      'Our server is not ready to handle the request by now. Please take a break and try again soon.',
  },
  504: {
    type: 'error',
    message:
      'Our server is down for maintenance. Please come back in a while and reload this page.',
  },
  default: {
    type: 'warning',
    message: 'Something went wrong. Please take a break and try again soon.',
  },
};

export const useFetchAlertHandler = () => {
  const alert = useAlert();

  const showAlert = (status: number) => {
    const alertMessage = fetchAlerts[status] || fetchAlerts.default;

    alert[alertMessage.type](alertMessage.message);
  };

  return showAlert;
};
