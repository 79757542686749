import { Grid, useTheme } from '@mui/material';
import { ScrollableContentProps } from './interfaces/scrollableContentProps';
import { PageHeader } from 'components/page/header/styledHeader';
import { ButtonsContainer } from 'utils/formHeader';

const ScrollableContent = ({
  pageHeaderTitle,
  buttonsComponent,
  children,
  pageHeaderRef,
  buttonsRef,
  general,
  matchDownMd,
}: ScrollableContentProps) => {
  const theme = useTheme();

  return (
    <>
      <Grid container item xs={12} sx={{ padding: theme.spacing(0.1, 0) }}>
        <Grid item xs={12} md={4} order={1} pb={{ md: 4 }} ref={pageHeaderRef}>
          <PageHeader>{pageHeaderTitle}</PageHeader>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 3, md: 2 }}
          pt={{ xs: 0 }}
          pb={4}
          ref={buttonsRef}
        >
          <Grid {...ButtonsContainer}>{buttonsComponent}</Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          height: matchDownMd
            ? `calc(100% - ${general}px)`
            : `calc(100% - ${theme.spacing(9)})`,
          overflow: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </Grid>
    </>
  );
};

export default ScrollableContent;
