import { useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import ButtonWithProgress from 'components/button';
import { DocumentInfo } from 'components/documentsTable/interfaces/documentInfo';
import StepperStep from 'components/stepper/interfaces/stepperStep';
import { useCommonService } from 'hooks/services/commons';
import { useHostService } from 'hooks/services/hosts';
import { ArrivalInstructionsResponse } from 'hooks/services/hosts/interfaces/arrivalInstruction.response';
import { HousingResponse } from 'hooks/services/hosts/interfaces/housing.response';
import { usePlacementService } from 'hooks/services/placements';
import { useCreateReducer } from 'hooks/useCreateReducer';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import { downloadBlob } from 'utils/file/fileUtilities';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import {
  initialState,
  PreviewPlacementInitialState,
} from './placementInitialStateWithAllDocuments';
import ReadOnlyDocuments from './sections/readOnlyDocuments';
import useProgramWithTypeHook from '../common/hooks/useProgramWithTypeHook';
import EditPlacement from '../editPlacement/editPlacement';
import EditPlacementContext from '../editPlacement/editPlacement.context';
import { EditPlacementFormInput } from '../editPlacement/interfaces/editPlacementFormInput';
import ArrivalInstructions from '../editPlacement/sections/arrivalInstructions/arrivalInstructions';
import Documents from '../editPlacement/sections/documents';
import Employment from '../editPlacement/sections/employment/employment';
import Grooming from '../editPlacement/sections/grooming/grooming';
import Housing from '../editPlacement/sections/housing/housing';
import Interviews from '../editPlacement/sections/interviews/interviews';
import Payment from '../editPlacement/sections/payment/payment';
import TrainingOperation from '../editPlacement/sections/trainingOrientation/trainingOrientation';
import {
  getEmptyEmploymentValues,
  getEmptyHousingValues,
  getEmptyTrainingValues,
} from '../editPlacement/utils/setFormValues';
import { getEmptyGroomingValues } from '../editPlacement/utils/setFormValues/getEmptyGroomingValues';
import { getEmptyInterviewsValues } from '../editPlacement/utils/setFormValues/getEmptyInterviewsValues';
import { getEmptyPaymentValues } from '../editPlacement/utils/setFormValues/getEmptyPaymentValues';
import { setValues } from '../editPlacement/utils/setFormValues/setValues';

const PreviewContainer = () => {
  const { hostId, placementId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const translations = useTranslations();

  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();
  const [stepperHeight, stepperRef] = useHeightRef();

  const {
    getPositions,
    getContacts,
    getArrivalInstructions,
    getHousings,
    getDocumentFile,
  } = useHostService();

  const {
    getEnglishProficiencyOptions,
    getPaymentSchedulesOptions,
    getSalaryWagePerOptions,
    getGenderRequirementsOptions,
    getHousingOptions,
  } = useCommonService();

  const { previewPlacement, confirmPlacement } = usePlacementService();

  const contextValue = useCreateReducer<PreviewPlacementInitialState>({
    initialState,
  });

  const {
    state: {
      id,
      intHostId,
      programId,
      allArrivalInstructions,
      isLoading,
      housingsDocuments,
      jobOfferDocuments,
      isConfirmed,
    },
    dispatch,
  } = contextValue;

  const handleDownload = async (docInfo: DocumentInfo) => {
    const blob = await getDocumentFile(intHostId, docInfo.id.toString());

    downloadBlob(blob, docInfo.title);
  };

  const sections: StepperStep[] = [
    {
      id: 'Employment',
      title: 'Employment Position Information',
    },
    {
      id: 'Payment',
      title: 'Payments',
    },
    {
      id: 'Training',
      title: 'Training/Orientation',
    },
    {
      id: 'Grooming',
      title: 'Grooming',
    },
    {
      id: 'Arrival',
      title: 'Arrival Instruction',
    },
    {
      id: 'Housing',
      title: 'Housing',
    },
    {
      id: 'Interviews',
      title: 'Interviews',
    },
    {
      id: 'Documents',
      title: 'Documents',
    },
    {
      id: 'HousingsDocuments',
      title: 'Housings Documents',
    },
    {
      id: 'JobOfferDocuments',
      title: 'Placement Offer Documents',
    },
  ];

  const components: { [key: string]: ReactElement } = {
    Employment: <Employment />,
    Payment: <Payment />,
    Training: <TrainingOperation />,
    Grooming: <Grooming />,
    Documents: <Documents />,
    Arrival: <ArrivalInstructions />,
    Housing: <Housing />,
    Interviews: <Interviews />,
    HousingsDocuments: (
      <ReadOnlyDocuments
        title={translations.previewPlacement.housingsDocuments}
        rows={housingsDocuments}
        handleDownload={handleDownload}
      />
    ),
    JobOfferDocuments: (
      <ReadOnlyDocuments
        title={translations.previewPlacement.jobOfferDocuments}
        rows={jobOfferDocuments}
        handleDownload={handleDownload}
      />
    ),
  };

  const sectionComponents = sections.map((section: any) => (
    <Element key={section.id} name={section.id}>
      {components[section.id]}
    </Element>
  ));

  const general = pageHeaderHeight + buttonsHeight + stepperHeight;

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const formMethods = useForm<EditPlacementFormInput>({
    mode: 'onSubmit',

    defaultValues: {
      employment: getEmptyEmploymentValues(),
      trainingOrientation: getEmptyTrainingValues(),
      payment: getEmptyPaymentValues(),
      grooming: getEmptyGroomingValues(),
      interviews: getEmptyInterviewsValues(),
      housing: getEmptyHousingValues(),
    },
  });

  const { handleSubmit, reset, resetField, control, setValue, getValues } =
    formMethods;

  const EmploymentProgramWatch = useWatch({
    control,
    name: 'employment.program',
  });

  const { availableProgramTypes, availablePrograms } = useProgramWithTypeHook({
    program: EmploymentProgramWatch,
    hostId: Number(hostId),
    resetProgramTypes: () =>
      resetField('employment.programTypes', { defaultValue: [] }),
  });

  const fetchHousings = async (hostId: number) => {
    if (hostId === 0) return;

    const housings: HousingResponse[] = await getHousings({ hostId });

    dispatch({
      type: 'change',
      field: 'housings',
      value: housings,
    });
  };

  const getAvailablePositionTitles = async (programId: number) => {
    if (programId === 0) return;

    const positions = await getPositions({
      hostId: intHostId,
      programId,
    });

    dispatch({
      type: 'change',
      field: 'availablePositionTitles',
      value: positions,
    });

    if (
      !positions.find((i) => i.key === getValues('employment.positionTitle'))
    ) {
      resetField('employment.positionTitle', { defaultValue: '' });
    }
  };

  const getAvailableContacts = async (hostId: number) => {
    if (hostId === 0) return;

    const response = await getContacts({ hostId });

    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.id.toString(),
      value: option.name,
    }));

    dispatch({
      type: 'change',
      field: 'availableContacts',
      value: mapped,
    });
  };

  const getEnglishLevels = async () => {
    const response = await getEnglishProficiencyOptions();
    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.key.toString(),
      value: option.value,
    }));

    dispatch({
      type: 'change',
      field: 'englishLevels',
      value: mapped,
    });
  };

  const getWageOptions = async () => {
    const response = await getSalaryWagePerOptions();
    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.key.toString(),
      value: option.value,
    }));

    dispatch({
      type: 'change',
      field: 'wageOptions',
      value: mapped,
    });
  };

  const getScheduleOptions = async () => {
    const response = await getPaymentSchedulesOptions();
    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.key.toString(),
      value: option.value,
    }));

    dispatch({
      type: 'change',
      field: 'paymentScheduleOptions',
      value: mapped,
    });
  };

  const fetchPlacement = async (hostId: number, id: number) => {
    if (id === 0 || intHostId === 0) return;

    const response = await previewPlacement({ hostId, id });

    if (!response) {
      return;
    }

    setValues(response.placement, setValue);

    dispatch({
      type: 'change',
      field: 'isConfirmed',
      value: response.placement.isConfirmed,
    });

    if (response.placement.documents) {
      dispatch({
        type: 'change',
        field: 'documents',
        value: response.placement.documents,
      });
    }

    if (response.housingsDocuments) {
      dispatch({
        type: 'change',
        field: 'housingsDocuments',
        value: response.housingsDocuments,
      });
    }

    if (response.jobOfferDocuments) {
      dispatch({
        type: 'change',
        field: 'jobOfferDocuments',
        value: response.jobOfferDocuments,
      });
    }
  };

  const fetchAllArrivalInstructions = async () => {
    if (intHostId === 0) return;

    const response = await getArrivalInstructions({
      hostId: intHostId,
    });

    const arrivalInstructionsMap = response.reduce((all, el) => {
      const strProgId = el.programId.toString();

      if (all.has(strProgId)) {
        all.get(strProgId)?.push(el);
      } else {
        all.set(strProgId, [el]);
      }

      return all;
    }, new Map<string, ArrivalInstructionsResponse>());

    dispatch({
      type: 'change',
      field: 'allArrivalInstructions',
      value: arrivalInstructionsMap,
    });

    return arrivalInstructionsMap;
  };

  const getAvailableArrivalInstructions = async (programId: number) => {
    if (programId === 0) return;

    const response = allArrivalInstructions.get(programId.toString()) ?? [];

    dispatch({
      type: 'change',
      field: 'arrivalInstructions',
      value: response,
    });
  };

  const getGenderOptionsAndMap = async () => {
    const genderResponse = await getGenderRequirementsOptions();
    dispatch({
      type: 'change',
      field: 'genders',
      value: genderResponse.map((g) => ({
        key: g.key,
        value: g.value,
      })),
    });
  };

  const getHousingsOptionsAndMap = async () => {
    const housingOptions = await getHousingOptions();

    dispatch({
      type: 'change',
      field: 'housingTypes',
      value: housingOptions.map((h) => ({
        key: h.key,
        value: h.value,
      })),
    });
  };

  const handleCancelBtn = () => {
    reset();
    navigate(ApplicationRoutes.placements.replace(':hostId', hostId as string));
  };

  const submitHandler = async (data: EditPlacementFormInput, event: any) => {};
  const confirmHandler = async () => {
    dispatch({
      type: 'change',
      field: 'isLoading',
      value: true,
    });

    try {
      await confirmPlacement(intHostId, id);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: 'change',
        field: 'isLoading',
        value: false,
      });

      navigate(
        ApplicationRoutes.placements.replace(':hostId', hostId as string)
      );
    }
  };

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    getEnglishLevels();
    getWageOptions();
    getScheduleOptions();
    getGenderOptionsAndMap();
    getHousingsOptionsAndMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programId) {
      getAvailablePositionTitles(programId);
      getAvailableArrivalInstructions(programId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  useEffect(() => {
    fetchAllArrivalInstructions();
    getAvailableContacts(intHostId);
    fetchHousings(intHostId);
    fetchPlacement(intHostId, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, intHostId, placementId]);

  useEffect(() => {
    if (EmploymentProgramWatch) {
      dispatch({
        type: 'change',
        field: 'programId',
        value: parseInt(EmploymentProgramWatch),
      });
    }
  }, [EmploymentProgramWatch, dispatch]);

  useEffect(() => {
    if (hostId)
      dispatch({
        type: 'change',
        field: 'intHostId',
        value: parseInt(hostId),
      });

    if (placementId)
      dispatch({
        type: 'change',
        field: 'id',
        value: parseInt(placementId),
      });
  }, [hostId, placementId, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'change',
      field: 'availableProgramTypes',
      value: availableProgramTypes,
    });
  }, [availableProgramTypes, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'change',
      field: 'availablePrograms',
      value: availablePrograms,
    });
  }, [availablePrograms, dispatch]);

  return (
    <EditPlacementContext.Provider value={contextValue}>
      <FormProvider {...formMethods}>
        <EditPlacement
          onSubmit={onSubmit}
          handleCancelBtn={handleCancelBtn}
          pageHeaderRef={pageHeaderRef}
          buttonsRef={buttonsRef}
          stepperRef={stepperRef}
          matchDownMd={matchDownMd}
          general={general}
          sections={sections}
          sectionComponents={sectionComponents}
          primaryButton={
            <ButtonWithProgress
              dataTestid='confirm'
              isLoading={isLoading}
              text={translations.previewPlacement.confirmButton}
              type='button'
              onClick={() => confirmHandler()}
              isDisabled={isConfirmed}
            />
          }
          pageTitle={translations.previewPlacement.pageTitle}
        />
      </FormProvider>
    </EditPlacementContext.Provider>
  );
};

export default PreviewContainer;
