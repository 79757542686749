import { Box } from '@mui/material';
import ColoredChip from 'components/coloredChip';
import { ChipColor } from 'components/coloredChip/interfaces/chipColor';
import { PlacementStatus } from 'hooks/services/placements/interfaces/placementStatus';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { useTranslations } from 'hooks/useTranslations';

const Status = ({
  placement: { status, confirmed },
}: {
  placement: PlacementSummaryResponse;
}) => {
  const {
    placementsList: {
      placementTable: { confirmation },
    },
  } = useTranslations();
  let statusColor: ChipColor;
  let confirmColor: ChipColor = confirmed ? 'statusGreen' : 'statusBlue';

  switch (status) {
    case PlacementStatus.Draft:
      statusColor = 'statusGrayed';

      break;
    case PlacementStatus.PendingReview:
    case PlacementStatus.OpenPendingReview:
      statusColor = 'statusPurple';
      break;
    case PlacementStatus.NeedsReview:
      statusColor = 'statusOrange';
      break;
    default:
      statusColor = 'statusBlue';
      break;
  }

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      {!confirmed && (
        <ColoredChip text={status.toString()} color={statusColor} />
      )}
      {confirmed && (
        <ColoredChip
          text={
            confirmed
              ? confirmation.confirmed
              : confirmation.pendingConfirmation
          }
          color={confirmColor}
        />
      )}
    </Box>
  );
};

export default Status;
