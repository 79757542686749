export const StyledBoldedParagraph = ({ text }: { text: string }) => {
  return (
    <p
      style={{
        fontWeight: 'bold',
      }}
    >
      {text}
    </p>
  );
};
