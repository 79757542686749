import { ReactElement, useCallback, useEffect, useState } from 'react';
import UserContext from 'context/user.context';
import { useCommonService } from 'hooks/services/commons';
import { HostResponse } from 'hooks/services/commons/interfaces/host.response';
import { useAuth } from 'hooks/useAuth';
import useLocalStorage from 'hooks/useLocalStorage';
import { UserResponse } from 'hooks/services/accounts/interfaces/user.response';
import { useAccountsService } from 'hooks/services/accounts';

const UserProvider = ({ children }: { children: ReactElement; }) => {
  const { isLoggedIn } = useAuth();
  const { getAvailableHosts } = useCommonService();
  const { getUserInfo } = useAccountsService();

  const [hosts, setHosts] = useState<HostResponse[]>([]);
  const [host, setHost] = useState<HostResponse | null>(null);
  const [user, setUser] = useState<UserResponse | null>(null);
  const [hostId, setHostId] = useLocalStorage('hostId', '');

  const getHostsApi = useCallback(async () => {
    try {
      const hosts = await getAvailableHosts();
      setHosts(hosts);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserInfo = useCallback(async (hostId: number) => {
    try {
      const response = await getUserInfo(hostId);
      setUser(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getHostsApi();
    } else {
      setHostId('');
      setHosts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, getHostsApi]);

  useEffect(() => {
    if (hosts.length > 0) {
      if (!hostId) {
        setHost(hosts[0]);
        setHostId(hosts[0].id.toString());
      } else {
        const filtered = hosts.filter(
          (item) => item.id === parseInt(hostId)
        )[0];
        if (filtered) {
          setHost(filtered);
        } else {
          setHost(hosts[0]);
          setHostId(hosts[0].id.toString());
        }
      }
    }
  }, [hosts, hostId, setHostId]);

  useEffect(() => {
    if (host && hostId) {
      setHostId(host.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host, setHostId]);

  useEffect(() => {
    if (host)
      fetchUserInfo(host.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);

  return (
    <UserContext.Provider
      value={{
        hosts,
        setHosts,
        host,
        setHost,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
