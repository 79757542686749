import * as yup from 'yup';

import { convertStringToBoolean } from 'utils/string/stringConverters';

export const schema = yup.object().shape({
  gated: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Gated'),
  areThereActiveSecurityCamerasOnSite: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Are there active security cameras on site?'),
  secured: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Secured'),
  isThereARepresentativeOnSite24For7: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Is there a representative on site 24/7?'),
  contactName: yup.string().nullable().max(50).label('Contact Name'),
  contactPhone: yup.string().nullable().max(15).label('Contact Phone'),
  contactEmail: yup.string().email().nullable().max(70).label('Contact Email'),
  bus: yup.boolean().transform(convertStringToBoolean).nullable().label('Bus'),
  frequencyOfBus: yup.string().nullable().max(50).label('Frequency of bus'),
  subway: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Subway'),
  frequencyOfSubway: yup
    .string()
    .nullable()
    .max(50)
    .label('Frequency of subway'),
  train: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Train'),
  frequencyOfTrain: yup.string().nullable().max(50).label('Frequency of train'),
  otherPublicTransport: yup
    .string()
    .nullable()
    .max(50)
    .label('Other public transport'),
  isThereAWebsiteForPublicTransportation: yup
    .string()
    .url()
    .nullable()
    .label('Is there a website for public transportation?'),
  isItConsideredSafeToWalkToTheNearestPublicTransportationStop: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label(
      'Is it considered safe to walk to the nearest public transportation stop?'
    ),
  howFarIsIt: yup.string().nullable().max(50).label('How far is it?'),
  doesItRunToTheJobsite: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable()
    .label('Does it run to the jobsite'),
});
