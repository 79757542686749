import { Theme } from '@mui/material';

export const useFormInputsStyle = ({ spacing }: Theme) => {
  const inputStyle = (mdValue?: number, lgValue?: number) => {
    const md = mdValue ? mdValue : 6;
    const lg = lgValue ? lgValue : 4;

    return {
      item: true,
      xs: 12,
      md: md,
      lg: lg,
      padding: {
        xs: spacing(0, 0.5, 2, 0.5),
        md: spacing(0, 1.2, 2, 1.2),
      },
    };
  };

  const inputsSection = {
    item: true,
    xs: 12,
    sx: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'rows',
      alignItems: 'start',
      paddingTop: '0px !important',
      marginBottom: {
        md: 2,
      },
    },
  };

  const defaultInput = inputStyle();
  const smallInput = inputStyle(3.5, 2);
  const datePickerInput = smallInput;
  const numberInput = smallInput;
  const yesOrNoInput = smallInput;
  const halfToFullPageInput = inputStyle(12, 6);
  const inputsGridFullWidth = inputStyle(12, 12);

  return {
    inputStyle,
    defaultInput,
    smallInput,
    datePickerInput,
    numberInput,
    yesOrNoInput,
    halfToFullPageInput,
    inputsGridFullWidth,
    inputsSection,
  };
};
