import TimeSlot from '../timeSlots/timeSlot';
import { Grid, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslations } from 'hooks/useTranslations';
import AddIcon from '@mui/icons-material/Add';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { AvailableDateContext } from '../../utils/AvailableDateContext';

const AvailableDateItem = ({
  dateIndex,
  isLastItem,
  showPeriods,
  setShowPeriods,
  weekDateLabel,
  addTimeSlot,
  fields,
  startTimeArray,
  endTimeArray,
  remove,
  append,
  disabled
}: {
  dateIndex: number;
  isLastItem: boolean;
  showPeriods: boolean;
  setShowPeriods: (value: boolean) => void;
  weekDateLabel: string;
  addTimeSlot: () => void;
  fields: Record<'id', string>[];
  startTimeArray: KeyValueItemProps[];
  endTimeArray: KeyValueItemProps[];
  remove: (index: number) => void;
  append: (data: any) => void;
  disabled: boolean;
}) => {
  const theme = useTheme();
  const { unavailable, multipleTimesSelected } =
    useTranslations().availability.setAvailability;

  return (
    <Grid
      container
      item
      xs={12}
      gap={1}
      borderBottom={!isLastItem ? `1px solid ${theme.palette.grey['400']}` : ''}
      py={theme.spacing(2)}
    >
      <AvailableDateContext.Provider value={{ remove, append }}>
        <Grid item minWidth={24} sx={{ cursor: 'pointer' }}>
          {fields.length > 1 && (
            <ExpandMoreIcon
              color='primary'
              sx={{
                transition: 'transform 0.3s ease-in-out',
                transform: showPeriods ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              onClick={() => {
                setShowPeriods(!showPeriods);
              }}
            />
          )}
        </Grid>
        <Grid item mr={theme.spacing(1)} minWidth={50}>
          <Typography
            variant='body1'
            fontWeight={700}
            color={theme.palette.primary.main}
          >
            {weekDateLabel}
          </Typography>
        </Grid>

        {fields?.length > 0 ? (
          showPeriods ? (
            <Grid item>
              {fields.map((field, index) => (
                <TimeSlot
                  index={index}
                  startName={`dates.${dateIndex}.periods.${index}.startHour`}
                  endName={`dates.${dateIndex}.periods.${index}.endHour`}
                  key={field.id}
                  isLastItem={fields.length === index + 1}
                  startTimeArray={startTimeArray}
                  endTimeArray={endTimeArray}
                  disabled={disabled}
                />
              ))}
            </Grid>
          ) : (
            <Grid item>
              <Typography variant='body1' color={theme.palette.primary.main}>
                {multipleTimesSelected}
              </Typography>
            </Grid>
          )
        ) : (
          <Grid item>
            <Typography variant='body1' color={theme.palette.grey['600']}>
              {unavailable}
            </Typography>
          </Grid>
        )}

        {!disabled && (
          <Grid
            item
            sx={{ cursor: 'pointer', marginLeft: 'auto', pr: theme.spacing(2) }}
            onClick={addTimeSlot}
          >
            <AddIcon color='primary' />
          </Grid>
        )}
      </AvailableDateContext.Provider>
    </Grid>
  );
};

export default AvailableDateItem;
