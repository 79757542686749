import { Box, Button, IconButton } from '@mui/material';
import Cross from 'assets/icons/cross.svg';
import ButtonWithProgress from 'components/button/buttonWithProgress';
import MobileBottomDrawer from 'components/responsiveListView/components/mobileBottomDrawer';
import MobileFiltersBar from 'components/responsiveListView/components/mobileFilterView';
import useMobileFiltersHook from 'components/responsiveListView/hooks/useMobileFiltersHook';
import { useTranslations } from 'hooks/useTranslations';
import { ApprovedDate, Positions, Programs, ShowOnlyMatched, ShowOnlyNotConfirmed, Statuses } from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const PlacementsMobileFilters = ({
  filterProps: {
    onSubmit,
    control,
    getValues,
    programs,
    titles,
    clearFilters,
    statuses,
    register,
  },
}: {
  filterProps: FiltersFormProps;
}) => {
  const translations = useTranslations();
  const { open, setOpen } = useMobileFiltersHook();

  const handleClose = () => {
    setOpen(false);
  };
  const onClearFilters = () => {
    clearFilters();
    handleClose();
  };

  return (
    <>
      <MobileBottomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        header={
          <Box display='flex' justifyContent='flex-end' m={1}>
            <IconButton onClick={() => handleClose()}>
              <Box component='img' alt='Close' src={Cross} />
            </IconButton>
          </Box>
        }
        footer={
          <Box
            display='flex'
            justifyContent='space-between'
            p={2}
            sx={{ '> *': { flex: '0 0 48%' } }}
          >
            <Button variant='outlined' onClick={() => onClearFilters()}>
              {translations.placementsList.actions.clearFilters}
            </Button>
            <ButtonWithProgress
              dataTestid='submit'
              text={translations.placementsList.actions.applyFilters}
              type='submit'
              onClick={() => handleClose()}
            />
          </Box>
        }
      >
        <form onSubmit={onSubmit}>
          <Box
            display='flex'
            flexDirection='column'
            p={2}
            sx={{ '> *:not(:last-child)': { mb: 2 } }}
          >
            <Box>
              <Programs control={control} programs={programs} />
            </Box>

            <Box>
              <Positions control={control} titles={titles} />
            </Box>

            <Box>
              <Statuses control={control} statuses={statuses} />
            </Box>

            <Box>
              <ApprovedDate getValues={getValues} control={control} />
            </Box>

            <Box sx={{ mt: 2.5, flex: '0 0 auto' }}>
              <ShowOnlyMatched control={control} />
            </Box>
            <Box sx={{ mt: 2.5, flex: '0 0 auto' }}>
              <ShowOnlyNotConfirmed control={control} />
            </Box>
          </Box>
        </form>
      </MobileBottomDrawer>

      <MobileFiltersBar setOpen={setOpen} />
    </>
  );
};

export default PlacementsMobileFilters;
