import * as yup from 'yup';

import { transformStringToNumberOrUndefined } from 'utils/extensions/numberInputTransformer';

export const schema = yup.object().shape({
  willEVIncurCostForScreening: yup
  .string()
  .required()
  .label('Will EV incur a cost for screening'),

  willCompanyRequireDrugTest: yup
  .string()
  .required()
  .label('Will company require a drug test'),

  drugScreeningPolicy: yup.string().required().label('Drug screening policy').max(250),

  isDrugTestProvidedByHostCompany: yup
  .string()
  .required()
  .label('Is drug test provided by host company'),

  drugScreeningCostToStudent: yup
  .number()
  .transform(transformStringToNumberOrUndefined)
  .required()
  .label('Drug screening cost to student(s)')
  .min(0.1)
  .max(9999999.99),

  drugScreeningCostDue: yup
  .number()
  .transform(transformStringToNumberOrUndefined)
  .required()
  .label('Drug screening cost due')
  .min(0.1)
  .max(9999.99),
});