import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import PrimarySubmitButton from 'components/button';
import { useHostService } from 'hooks/services/hosts';
import { useTranslations } from 'hooks/useTranslations';
import {
  ButtonResponsiveProps,
  PrimaryButtonResponsiveMargin,
} from 'utils/formHeader';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { ContactForm } from './contactForm';
import { ContactInputsProps } from './interfaces/ContactInputsProps';
import { initWithEmptyValues } from './utils/initValues';
import { makeRequest } from './utils/makeRequest';
import { setValues } from './utils/setValues';
import { schema } from './validation/schema.validation';
import { useAlert } from 'providers/alertProvider';

export const ContactFormContainer = () => {
  const alert = useAlert();
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);

  const navigate = useNavigate();
  const translations = useTranslations();
  const { createTitle, editTitle } = translations.contacts.form;

  const {
    setValue,
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = useForm<ContactInputsProps>({
    mode: 'onSubmit',
    defaultValues: initWithEmptyValues,
    resolver: yupResolver(schema),
  });

  const { hostId, contactId } = useParams<{
    hostId: string;
    contactId: string;
  }>();

  const { getContactById, patchUpdateContactById, postCreateContact } =
    useHostService();

  const handleCancelButton = () => {
    if (!hostId) return;

    navigate(ApplicationRoutes.contacts.replace(':hostId', hostId));
  };

  const Buttons = (
    <>
      <Button
        variant='outlined'
        sx={{ ...ButtonResponsiveProps }}
        onClick={handleCancelButton}
      >
        {translations.cancelButton}
      </Button>

      <Grid
        sx={{
          ...ButtonResponsiveProps,
          ...PrimaryButtonResponsiveMargin,
        }}
      >
        <PrimarySubmitButton
          dataTestid='save'
          isLoading={false}
          text={translations.saveButton}
        />
      </Grid>
    </>
  );

  const isEdit = contactId === 'new' ? false : true;

  const pageHeaderTitle = isEdit
    ? editTitle.concat(` #${contactId}`)
    : createTitle;

  const fetchContactById = async () => {
    const contact = await getContactById({
      hostId: Number(hostId),
      contactId: Number(contactId),
    });
    setValues(contact, setValue);
  };

  const contactTypes: KeyValueItemProps[] = [
    { key: 'Primary', value: 'Primary' },
    { key: 'Secondary', value: 'Secondary' },
  ];

  const submitHandler = async (data: ContactInputsProps) => {
    const body = makeRequest(data);

    if (contactId && contactId !== 'new') {
      try {
        await patchUpdateContactById({
          hostId: Number(hostId),
          contactId: Number(contactId),
          body,
        });

        alert.success(translations.alerts.contactForm.contactUpdated);
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        await postCreateContact({
          hostId: Number(hostId),
          body,
        });

        alert.success(translations.alerts.contactForm.contactCreated);
      } catch (error: any) {
        console.log(error);
      }
    }

    if (!hostId) return;

    navigate(ApplicationRoutes.contacts.replace(':hostId', hostId));
  };

  useEffect(() => {
    if (contactId && contactId !== 'new') {
      fetchContactById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPrimaryContact(watch('type') === 'Primary');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('type')]);

  return (
    <ContactForm
      isPrimaryContact={isPrimaryContact}
      Buttons={Buttons}
      pageHeaderTitle={pageHeaderTitle}
      contactTypes={contactTypes}
      errors={errors}
      control={control}
      register={register}
      onSubmit={handleSubmit(submitHandler)}
    />
  );
};
