import { Box, Collapse, TableCell, TableRow, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import SingleCheckbox from 'components/tables/collapsibleTable/components/singleCheckBox';
import { RowProps } from '../../interfaces/rowProps';
import { ArrowButton } from '../arrowButton';

export const Row = <TFieldValues extends FieldValues>({
  values,
  subContent,
  align,
  control,
  item,
  name,
  disabled,
}: RowProps<TFieldValues>) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { backgroundColor } = theme.tables.collapsibleTable;

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <SingleCheckbox
              {...{
                item,
                control,
                name,
                hideLabel: true,
                disabled,
              }}
            />

            <ArrowButton open={open} setOpen={setOpen} />
          </div>
        </TableCell>
        {values &&
          values.map((value, index) => (
            <TableCell key={index} align={align}>
              {value}
            </TableCell>
          ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor,
          }}
          colSpan={values.length + 1}
        >
          <Collapse in={open}>
            <Box sx={{ margin: 1 }}>{subContent}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
