import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { OfferDetailsFormInput } from '../../interfaces/offerDetailsForm.formInput';

export const GroomingOfferDetails = ({
  isDisabled,
}: {
  isDisabled: boolean;
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  const { inputsGridFullWidth } = useFormInputsStyle(theme);

  const { title, grooming_standards } =
    translations.offerDetailsForm.sections.grooming;

  return (
    <ConnectForm<OfferDetailsFormInput>>
      {({ register, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid {...inputsGridFullWidth}>
            <TextAreaInput
              inputId={'groomingStandards'}
              inputLabel={grooming_standards}
              register={register('grooming.groomingStandards')}
              errorMessage={errors?.grooming?.groomingStandards?.message}
              maxLength={500}
              disabled={isDisabled}
            />
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
