import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { useHeightRef } from 'hooks/useHeightRef';
import { useUser } from 'hooks/useUser';
import { CellHeaderId } from './interfaces/visaArrivalInfoCellHeader';
import { VisaArrivalInfoTableProps } from './interfaces/visaArrivalInfoTableProps';
import VisaArrivalInfoTable from './visaArrivalInfoTable';
import useFilterHook from '../filters/hooks/useFilterHook';
import { FiltersProps } from '../filters/interfaces/filtersProps';

const VisaArrivalInfoTableContainer = ({
  heightOfTopComponents,
  filterProps,
  tableProps: { rows, rowsTotal, handleTableChanged: handleTablePropsChanged },
}: {
  heightOfTopComponents: number;
  filterProps: FiltersProps;
  tableProps: VisaArrivalInfoTableProps;
}) => {
  const { user } = useUser();
  const timeZone = user?.timeZone;
  const [, filtersRef, filtersHeightWithPaddings] = useHeightRef();
  const [paginationHeight, paginationRef] = useHeightRef();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    order,
    orderBy,
    sortHandler,
  } = usePaginationHook<CellHeaderId>(handleTablePropsChanged);
  const useFilterHookProps = useFilterHook(filterProps);

  const heightToSkip =
    heightOfTopComponents + filtersHeightWithPaddings + paginationHeight;

  return (
    <VisaArrivalInfoTable
      filterProps={useFilterHookProps}
      filtersRef={filtersRef}
      heightToSkip={heightToSkip}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      paginationRef={paginationRef}
      rowsPerPage={rowsPerPage}
      tableProps={{
        rows,
        rowsTotal,
        handleTableChanged: handleTablePropsChanged,
      }}
      order={order}
      orderBy={orderBy}
      sortHandler={sortHandler}
      timeZone={timeZone}
    />
  );
};

export default VisaArrivalInfoTableContainer;
