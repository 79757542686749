import { Typography, useTheme } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { convertDataToString } from '../../utils/curPrevValueUtils';

const DisplayValue = ({
  data,
  isPrev = false,
}: {
  data?: string | boolean | number | Date;
  isPrev?: boolean;
}) => {
  const theme = useTheme();
  const {
    currPrevValueTableCell: { yes, no, empty },
  } = useTranslations();
  return (
    <Typography
      variant='table'
      color={isPrev ? theme.palette.grey[600] : undefined}
    >
      {data === undefined || data === null
        ? empty
        : convertDataToString(data, yes, no)}
    </Typography>
  );
};

export default DisplayValue;
