import { useState } from 'react';
import HousingMobileTable from './housingMobileTable';
import { HousingRow } from '../../interfaces/housingRow';

const HousingMobileTableContainer = ({
  rows,
  top,
}: {
  rows: HousingRow[];
  top: number;
}) => {
  const [housingDetails, setHousingDetails] = useState<HousingRow>();

  return (
    <HousingMobileTable
      rows={rows}
      housingDetails={housingDetails}
      showDetails={(details: HousingRow) => setHousingDetails(details)}
      top={top}
    />
  );
};

export default HousingMobileTableContainer;
