import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonService } from 'hooks/services/commons';
import { useHostService } from 'hooks/services/hosts';
import { usePlacementService } from 'hooks/services/placements';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import ClonePlacement from './clonePlacement';
import { ClonePlacementFormInput } from './interfaces/clonePlacementFormInput';
import { makeRequest } from './utils/makeRequest';
import { setValues } from './utils/setCloneValues';
import { cloneSchema } from './validation/validation.schema';

const ClonePlacementContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [availableContacts, setAvailableContacts] = useState(
    [] as KeyValueItemProps[]
  );
  const [wageOptions, setWageOptions] = useState([] as KeyValueItemProps[]);
  const [positionTitle, setPositionTitle] = useState<string>('');

  const navigate = useNavigate();
  const { hostId, placementId } = useParams();
  const parsedHostId = parseInt(hostId as string);
  const id = parseInt(placementId as string);

  const { getPlacement, postClone } = usePlacementService();
  const { getContacts, mapped, mappedContacts } = useHostService();
  const { getSalaryWagePerOptions } = useCommonService();

  const formMethods = useForm<ClonePlacementFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(cloneSchema),
    defaultValues: {
      earliestEndDate: new Date(),
      earliestStartDate: new Date(),
      guaranteedSalaryWageBeforeDeduction: '',
      guaranteedSalaryWagePeriod: '',
      latestEndDate: new Date(),
      latestStartDate: new Date(),
      supervisor: '',
      totalPositions: '',
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    getValues,
    reset,
  } = formMethods;

  const submitHandler = async (data: ClonePlacementFormInput, event: any) => {
    event.preventDefault();

    setIsLoading(true);
    const body = makeRequest(data);

    try {
      await postClone({ body, hostId: parsedHostId, id });
      navigate(
        ApplicationRoutes.placements.replace(':hostId', hostId as string)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = handleSubmit(submitHandler, (errors) => {
    console.log('errors', errors);
  });

  const handleGetPlacement = async () => {
    const response = await getPlacement({ hostId: parsedHostId, id });

    if (!response) return;
    if (response.employmentPosition.position)
      setPositionTitle(response.employmentPosition.position?.toString());
    setValues(response, setValue);
  };

  const getWageOptions = async () => {
    const response = await getSalaryWagePerOptions();
    const mappedResponse = mapped(response);
    setWageOptions(mappedResponse);
  };

  const getAvailableContacts = async () => {
    const response = await getContacts({ hostId: parsedHostId });
    const mappedResponse = mappedContacts(response);
    setAvailableContacts(mappedResponse);
  };

  const handleReviewedCheckbox = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleCancelBtn = () => {
    reset();
    navigate(ApplicationRoutes.placements.replace(':hostId', hostId as string));
  };

  const fetchInitialData = () => {
    Promise.all([
      handleGetPlacement(),
      getWageOptions(),
      getAvailableContacts(),
    ]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchInitialData, []);

  return (
    <ClonePlacement
      wageOptions={wageOptions}
      supervisors={availableContacts}
      control={control}
      register={register}
      errors={errors}
      setValue={setValue}
      getValues={getValues}
      isLoading={isLoading}
      isCheckboxChecked={isCheckboxChecked}
      handleReviewedCheckbox={handleReviewedCheckbox}
      onSubmit={onSubmit}
      handleCancelBtn={handleCancelBtn}
      placementId={id}
      positionTitle={positionTitle}
    />
  );
};

export default ClonePlacementContainer;
