import { UseFormSetValue } from 'react-hook-form';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const setArrivalInstructionsValues = (
  values: number[],
  setValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!values) {
    return;
  }

  setValue(
    'arrivalInstructions',
    values.map((i) => ({ id: i }))
  );
};
