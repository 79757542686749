import { UseFormSetValue } from 'react-hook-form';
import { HouseResponse } from 'hooks/services/placements/interfaces/placement.response';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const setHousingValues = (
  value: HouseResponse,
  setValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!value) {
    return;
  }

  const { genderRequirement, housingType, selectedHousingsIds } = value;

  if (selectedHousingsIds) {
    setValue(
      'housing.selectedHousingsIds',
      selectedHousingsIds.map((i) => ({ id: i }))
    );
  }

  if (housingType) {
    setValue('housing.housingType', housingType);
  }

  if (genderRequirement) {
    setValue('housing.genderRequirement', genderRequirement);
  }
};
