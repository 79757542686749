import { Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import ConnectForm from 'components/connectForm';
import DateRange from 'components/dateRange';
import DropdownInput from 'components/dropdownInput';
import DropdownMultiselectInput from 'components/dropdownInputMultiselect';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import TextareaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const Employment = () => {
  const theme = useTheme();

  const {
    inputStyle,
    defaultInput,
    smallInput,
    numberInput,
    inputsGridFullWidth,
    inputsSection,
  } = useFormInputsStyle(theme);

  const {
    employmentSection,
    createPlacement: { programType },
  } = useTranslations();

  const {
    state: {
      availablePrograms,
      availablePositionTitles,
      availableContacts,
      englishLevels,
      availableProgramTypes,
      isReadOnly,
    },
  } = useContext(EditPlacementContext);

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Grid item xs={12}>
          <SectionHeader>
            {employmentSection.employmentSectionTitle}
          </SectionHeader>

          <Grid {...ContainerSpacing}>
            <Grid {...inputsSection}>
              <Grid {...inputStyle(8, 4)}>
                <DropdownInput
                  inputId={employmentSection.programId}
                  inputLabel={employmentSection.program}
                  items={availablePrograms}
                  errorMessage={errors?.employment?.program?.message}
                  control={control}
                  register={register('employment.program')}
                  disabled={isReadOnly}
                />
              </Grid>

              {availableProgramTypes?.length > 0 && (
                <Grid {...inputStyle(3.5, 3.5)}>
                  <DropdownMultiselectInput
                    inputId='programType'
                    inputLabel={programType}
                    items={availableProgramTypes}
                    errorMessage={errors?.employment?.programTypes?.message}
                    control={control}
                    name='employment.programTypes'
                    disabled={isReadOnly}
                  />
                </Grid>
              )}

              <Grid {...numberInput}>
                <NumberInput
                  label={employmentSection.totalPositions}
                  useDecimals={false}
                  errorMessage={errors?.employment?.totalPositions?.message}
                  control={control}
                  name='employment.totalPositions'
                  getValue={() => getValues('employment.totalPositions')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...inputStyle(4.5, 4.5)}>
                <DropdownInput
                  inputId={employmentSection.positionTitleId}
                  inputLabel={employmentSection.positionTitle}
                  items={availablePositionTitles}
                  errorMessage={errors?.employment?.positionTitle?.message}
                  control={control}
                  register={register('employment.positionTitle')}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownInput
                  inputId={employmentSection.supervisorId}
                  inputLabel={employmentSection.supervisor}
                  items={availableContacts}
                  errorMessage={errors?.employment?.supervisor?.message}
                  control={control}
                  register={register('employment.supervisor')}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={employmentSection.departmentId}
                  inputLabel={employmentSection.department}
                  numberOfRows={1}
                  register={register('employment.department')}
                  errorMessage={errors?.employment?.department?.message}
                  singleLine
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...smallInput}>
                <DropdownInput
                  inputId={employmentSection.englishLevelId}
                  inputLabel={employmentSection.englishLevel}
                  items={englishLevels}
                  errorMessage={errors?.employment?.englishLevel?.message}
                  control={control}
                  register={register('employment.englishLevel')}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(5, 3)}>
                <DateRange
                  control={control}
                  nameEndDate='employment.endDate'
                  nameStartDate='employment.startDate'
                  labelStartDate={employmentSection.startDate}
                  labelEndDate={employmentSection.endDate}
                  startDateValidationMessage={
                    errors?.employment?.startDate?.message
                  }
                  endDateValidationMessage={
                    errors?.employment?.endDate?.message
                  }
                  getStartDate={() => getValues('employment.startDate')}
                  getEndDate={() => getValues('employment.endDate')}
                  labelBetween=' '
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...inputStyle(5, 3)}>
                <DateRange
                  control={control}
                  nameEndDate='employment.latestEndDate'
                  nameStartDate='employment.latestStartDate'
                  labelStartDate={employmentSection.latestStartDate}
                  labelEndDate={employmentSection.latestEndDate}
                  startDateValidationMessage={
                    errors?.employment?.latestStartDate?.message
                  }
                  endDateValidationMessage={
                    errors?.employment?.latestEndDate?.message
                  }
                  getStartDate={() => getValues('employment.latestStartDate')}
                  getEndDate={() => getValues('employment.latestEndDate')}
                  labelBetween=' '
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={employmentSection.positionDescriptionId}
                  inputLabel={employmentSection.positionDescription}
                  numberOfRows={4}
                  register={register('employment.positionDescription')}
                  errorMessage={
                    errors?.employment?.positionDescription?.message
                  }
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={employmentSection.scheduleDescriptionId}
                  inputLabel={employmentSection.scheduleDescription}
                  numberOfRows={4}
                  register={register('employment.scheduleDescription')}
                  errorMessage={
                    errors?.employment?.scheduleDescription?.message
                  }
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={employmentSection.requiredSkillsId}
                  inputLabel={employmentSection.requiredSkills}
                  numberOfRows={4}
                  register={register('employment.requiredSkills')}
                  errorMessage={errors?.employment?.requiredSkills?.message}
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={employmentSection.requiredExperienceId}
                  inputLabel={employmentSection.requiredExperience}
                  numberOfRows={4}
                  register={register('employment.requiredExperience')}
                  errorMessage={errors?.employment?.requiredExperience?.message}
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={employmentSection.specialRequirementsId}
                  inputLabel={employmentSection.specialRequirements}
                  numberOfRows={4}
                  register={register('employment.specialRequirements')}
                  errorMessage={
                    errors?.employment?.specialRequirements?.message
                  }
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ConnectForm>
  );
};

export default Employment;
