import { Grid } from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import SwitchTileListView from 'components/switchTileListView';
import { ApplicantSummaryResponse } from 'hooks/services/hosts/interfaces/applicantSummary.response';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import { Nullable } from 'utils/interfaces';
import ApplicantsTable from './components/applicantsTable';
import { ApplicantsTableProps } from './components/applicantsTable/interfaces/applicantsTableProps';
import { FiltersProps } from './components/filters/interfaces/filtersProps';

const ApplicantsList = ({
  isTileView,
  handleChangeView,
  filterProps,
  tableProps,
  handleSetInterview,
}: {
  isTileView: Nullable<boolean>;
  handleChangeView: () => void;
  filterProps: FiltersProps;
  tableProps: ApplicantsTableProps;
  handleSetInterview: (applicant: ApplicantSummaryResponse) => void;
}) => {
  const translations = useTranslations();

  const [, pageHeaderRef, pageHeaderHeightWithPaddings] = useHeightRef();
  const containerProps = {
    ...ContainerSpacing,
    height: 'unset',
    rowSpacing: 2,
  };

  return (
    <Grid {...containerProps}>
      <Grid
        item
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
        }}
        ref={pageHeaderRef}
      >
        <Grid item>
          <PageHeader>{translations.applicants.title}</PageHeader>
        </Grid>
        <Grid item>
          <SwitchTileListView
            isTileView={isTileView}
            handleChangeView={handleChangeView}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ApplicantsTable
          heightOfTopComponents={pageHeaderHeightWithPaddings}
          filterProps={filterProps}
          isTileView={isTileView}
          tableProps={tableProps}
          handleSetInterview={handleSetInterview}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicantsList;
