import { Action } from 'pages/interviews/interfaces/interviews';
import { useState } from 'react';
import { Nullable } from 'utils/interfaces';

export const useActionsModal = (openDeclineInterviewModal: () => void, openInterviewReportModal: () => void) => {
  const [selectedAction, setSelectedAction] = useState<Nullable<Action>>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (action: Action) => {
    setSelectedAction(action);

    if (action.id === 'interviewReport') {
      openInterviewReportModal();
    } else if (action.id === 'decline') {
      openDeclineInterviewModal();
    } else {
      setModalOpen(true);
    }
  };


  return {
    selectedAction,
    setSelectedAction,
    modalOpen,
    setModalOpen,
    handleOpenModal,
  };
};
