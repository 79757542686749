import DatePickerInput from 'components/datePickerInput';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import DropdownInputMultiselect from 'components/dropdownInputMultiselect';
import DropdownYesNo from 'components/dropdownYesNo/dropdownYesNo';
import NumberInput from 'components/numberInput';
import TextareaInput from 'components/textAreaInput';
import { DynamicFieldType } from 'utils/interfaces/dynamicFieldType';
import { DynamicFieldInputProps } from './interfaces/dynamicFieldInputProps';

const DynamicField = ({
  field,
  register,
  control,
  getValues,
  setValue,
  errors,
}: DynamicFieldInputProps) => {
  const errorMessage = errors ? errors[field.name]?.message?.toString() : '';

  switch (field.type) {
    case DynamicFieldType.String:
    case DynamicFieldType.TextArea:
      return (
        <TextareaInput
          errorMessage={errorMessage}
          inputLabel={field.label}
          register={register(field.name)}
          inputId={field.name}
          control={control}
          singleLine={field.type === DynamicFieldType.String}
          numberOfRows={field.type === DynamicFieldType.String ? 1 : undefined}
        />
      );

    case DynamicFieldType.Date:
      return (
        <DatePickerInput
          label={field.label}
          validationMessage={errorMessage}
          control={control}
          name={field.name}
          getValue={() => getValues(field.name)}
        />
      );

    case DynamicFieldType.Number:
    case DynamicFieldType.Float:
      return (
        <NumberInput
          label={field.label}
          useDecimals={field.type === DynamicFieldType.Float}
          errorMessage={errorMessage}
          control={control}
          name={field.name}
          getValue={() => getValues(field.name)}
        />
      );
    case DynamicFieldType.Dropdown:
      return (
        <DropdownInput
          control={control}
          errorMessage={errorMessage}
          inputId={field.name}
          inputLabel={field.label}
          register={register(field.name)}
          items={field.options ?? []}
        />
      );
    case DynamicFieldType.MultiselectDropdown:
      return (
        <DropdownInputMultiselect
          control={control}
          errorMessage={errorMessage}
          inputId={field.name}
          inputLabel={field.label}
          name={field.name}
          items={field.options ?? []}
        />
      );
    case DynamicFieldType.YesNo:
      return (
        <DropdownYesNo
          control={control}
          errorMessage={errorMessage}
          inputId={field.name}
          inputLabel={field.label}
          register={register(field.name)}
        />
      );

    default:
      throw new Error('field type is not supported');
  }
};

export default DynamicField;
