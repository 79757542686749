import { Button, Grid, useTheme } from '@mui/material';
import ButtonWithProgress from 'components/button';
import DropdownInput from 'components/dropdownInput';
import DropdownMultiselectInput from 'components/dropdownInputMultiselect';
import NumberInput from 'components/numberInput';
import { PageHeader } from 'components/page/header/styledHeader';
import {
  ButtonResponsiveProps,
  ButtonsContainer,
  ContainerSpacing,
  PrimaryButtonResponsiveMargin,
} from 'components/page/header/utils';
import TextareaInput from 'components/textAreaInput/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { CreatePlacementFormInput } from './interfaces/createPlacementFormInput';
import { CreatePlacementProps } from './interfaces/createPlacementProps';

const CreatePlacement = ({
  onSubmit,
  errors,
  availablePrograms,
  control,
  register,
  setValue,
  getValues,
  handleCancelBtn,
  isLoading,
  availableProgramTypes,
}: CreatePlacementProps) => {
  const theme = useTheme();
  const translations = useTranslations();

  const {
    inputStyle,

    inputsGridFullWidth,
    smallInput,
    numberInput,
    inputsSection,
  } = useFormInputsStyle(theme);

  return (
    <form onSubmit={onSubmit}>
      <Grid {...ContainerSpacing}>
        <Grid item xs={12}>
          <PageHeader>{translations.createPlacementPageHeader}</PageHeader>
        </Grid>

        <Grid item xs={12}>
          <Grid {...inputsSection}>
            <Grid {...inputStyle(6, 3)}>
              <DropdownInput<CreatePlacementFormInput>
                inputId='program'
                inputLabel={translations.createPlacement.program}
                items={availablePrograms}
                errorMessage={errors?.program?.message}
                control={control}
                register={register('program')}
              />
            </Grid>

            {availableProgramTypes?.length > 0 && (
              <Grid {...smallInput}>
                <DropdownMultiselectInput<CreatePlacementFormInput>
                  inputId='programType'
                  inputLabel={translations.createPlacement.programType}
                  items={availableProgramTypes}
                  errorMessage={errors?.programTypes?.message}
                  control={control}
                  name='programTypes'
                />
              </Grid>
            )}

            <Grid {...numberInput}>
              <NumberInput
                label={translations.createPlacement.totalPositions}
                useDecimals={false}
                errorMessage={errors?.totalPositions?.message}
                control={control}
                name='totalPositions'
                getValue={() => getValues('totalPositions')}
              />
            </Grid>
          </Grid>

          <Grid {...inputsGridFullWidth}>
            <TextareaInput
              inputId='positionDescription'
              inputLabel={translations.createPlacement.positionDescription}
              register={register('positionDescription')}
              errorMessage={errors?.positionDescription?.message}
              control={control}
              maxLength={500}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid {...ButtonsContainer}>
            <Button
              variant='outlined'
              sx={{ ...ButtonResponsiveProps }}
              onClick={handleCancelBtn}
            >
              {translations.cancelButton}
            </Button>
            <Grid
              sx={{
                ...ButtonResponsiveProps,
                ...PrimaryButtonResponsiveMargin,
              }}
            >
              <ButtonWithProgress
                dataTestid='continue'
                isLoading={isLoading}
                text={translations.continueButton}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreatePlacement;
