import { Grid, useTheme } from '@mui/material';
import DropdownInput from 'components/dropdownInput';
import ScrollableContent from 'components/scrollableContent';
import TextareaInput from 'components/textAreaInput/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { ContactFormProps } from './interfaces/ContactFormProps';

export const ContactForm = ({
  isPrimaryContact = false,
  Buttons,
  pageHeaderTitle,
  contactTypes,
  errors,
  control,
  register,
  onSubmit,
}: ContactFormProps) => {
  const translations = useTranslations();
  const theme = useTheme();

  const { inputStyle, defaultInput, smallInput, inputsSection } =
    useFormInputsStyle(theme);

  const {
    name,
    email,
    fax,
    directPhoneNotRequired,
    directPhoneRequired,
    mobilePhone,
    department,
    title,
    type,
    skype,
  } = translations.contacts.form;

  return (
    <form onSubmit={onSubmit} style={{ height: '100%', overflow: 'hidden' }}>
      <ScrollableContent
        pageHeaderTitle={pageHeaderTitle}
        buttonsComponent={Buttons}
      >
        <Grid item xs={12}>
          <Grid {...inputsSection}>
            <Grid {...smallInput}>
              <DropdownInput
                inputId={'contactTypeId'}
                inputLabel={type}
                items={contactTypes}
                errorMessage={errors?.type?.message}
                register={register('type')}
                control={control}
              />
            </Grid>

            <Grid {...defaultInput}>
              <TextareaInput
                inputId='name'
                inputLabel={name}
                control={control}
                errorMessage={errors.name?.message}
                register={register('name')}
                singleLine
              />
            </Grid>

            <Grid {...defaultInput}>
              <TextareaInput
                inputId='email'
                inputLabel={email}
                control={control}
                errorMessage={errors.email?.message}
                register={register('email')}
                singleLine
              />
            </Grid>

            <Grid {...defaultInput}>
              <TextareaInput
                inputId='titleId'
                inputLabel={title}
                control={control}
                errorMessage={errors.title?.message}
                register={register('title')}
                singleLine
              />
            </Grid>

            <Grid {...defaultInput}>
              <TextareaInput
                inputId='departmentId'
                inputLabel={department}
                control={control}
                errorMessage={errors.department?.message}
                register={register('department')}
                singleLine
              />
            </Grid>

            <Grid {...inputStyle(4.5, 2.5)}>
              <TextareaInput
                inputId='phoneDirectId'
                inputLabel={
                  isPrimaryContact
                    ? directPhoneRequired
                    : directPhoneNotRequired
                }
                control={control}
                errorMessage={errors.directPhone?.message}
                register={register('directPhone')}
                singleLine
              />
            </Grid>
          </Grid>

          <Grid {...inputsSection}>
            <Grid {...inputStyle(4.5, 2.5)}>
              <TextareaInput
                inputId='mobilePhone'
                inputLabel={mobilePhone}
                control={control}
                errorMessage={errors.mobilePhone?.message}
                register={register('mobilePhone')}
                singleLine
              />
            </Grid>

            <Grid {...inputStyle(4.5, 2.5)}>
              <TextareaInput
                inputId='faxId'
                inputLabel={fax}
                control={control}
                errorMessage={errors.fax?.message}
                register={register('fax')}
                singleLine
              />
            </Grid>

            <Grid {...defaultInput}>
              <TextareaInput
                inputId='skype'
                inputLabel={skype}
                control={control}
                errorMessage={errors.skype?.message}
                register={register('skype')}
                singleLine
              />
            </Grid>
          </Grid>
        </Grid>
      </ScrollableContent>
    </form>
  );
};
