import { useCallback, useEffect, useState } from 'react';
import { useHostService } from 'hooks/services/hosts';
import { useParams } from 'react-router-dom';
import { FiltersFormInputs } from './components/filters/interfaces/filtersFormInput';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { FilterParams } from './components/visaArrivalInfoTable/interfaces/filterParams';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import VisaArrivalInfoTable from './components/visaArrivalInfoTable';
import { VisaArrivalInfoResponse } from 'hooks/services/hosts/interfaces/visaArrivalInfo.response';
import { VisaArrivalInfoFilters } from 'hooks/services/hosts/interfaces/visaArrivalInfoFilters.response';
import { VisaArrivalInfoRequest } from 'hooks/services/hosts/interfaces/visaArrivalInfo.request';

const VisaArrivalInfoContainer = ({
  pageHeaderHeight,
}: {
  pageHeaderHeight: number;
}) => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const { getVisaArrivalInfoFilters, getVisaArrivalInfo } = useHostService();
  const [filters, setFilters] = useState<VisaArrivalInfoFilters | null>(null);
  const [rows, setRows] = useState<VisaArrivalInfoResponse[]>([]);
  const [rowsTotal, setRowsTotal] = useState(100);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [filterParams, setFilterParams] = useState<FilterParams>();

  const onApplyFilter = async (data?: FiltersFormInputs) => {
    if (data) {
      const updatedFilter: FilterParams = {
        ...data,
        embassyDate: formatDate(
          data.embassyDate,
          DEFAULT_FORMAT,
          BE_DATE_FORMAT
        ),
        arrivalDate: formatDate(
          data.arrivalDate,
          DEFAULT_FORMAT,
          BE_DATE_FORMAT
        ),
      };

      setFilterParams(updatedFilter);

      await getVisaArrivalInfoHandler({
        ...updatedFilter,
        ...tableParams,
        hostId: parsedHostId,
      });
    } else {
      setFilterParams({});

      await getVisaArrivalInfoHandler({ ...tableParams, hostId: parsedHostId });
    }
  };

  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await getVisaArrivalInfoHandler({
      ...data,
      ...filterParams,
      hostId: parsedHostId,
    });
  };

  const getVisaArrivalInfoHandler = async (
    data: VisaArrivalInfoRequest & { hostId: number }
  ) => {
    const response = await getVisaArrivalInfo(data);
    setRowsTotal(response.total);
    setRows(response.records);
  };

  const fetchVisaArrivalInfo = useCallback(async () => {
    try {
      const data = {
        ...filterParams,
        ...tableParams,
        hostId: parsedHostId,
      };
      await getVisaArrivalInfoHandler(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await getVisaArrivalInfoFilters(parsedHostId);
      setFilters(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchFilterOptions();
    fetchVisaArrivalInfo();
  }, [fetchFilterOptions, fetchVisaArrivalInfo]);

  if (!filters) return null;

  return (
    <VisaArrivalInfoTable
      {...{
        filterProps: {
          filters,
          onApplyFilter,
        },
        tableProps: {
          rows,
          rowsTotal,
          handleTableChanged,
        },
        heightOfTopComponents: pageHeaderHeight,
      }}
    />
  );
};

export default VisaArrivalInfoContainer;
