import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { useHostService } from 'hooks/services/hosts';
import { ArrivalInstructionSummaryResponse } from 'hooks/services/hosts/interfaces/arrivalInstructionSummary.response';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import ArrivalInstructions from './arrivalInstructions';
import ActionsComponent from './components/actionsComponent/actionsComponent';
import { RowProps } from './components/interfaces/rowProps';
import StatusComponent from './components/statusComponent/statusComponent';
import { curPrevValueHolderFactory } from './utils/curPrevValueUtils';

const ArrivalInstructionsContainer = () => {
  const [rows, setRows] = useState<RowProps[]>([]);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [paginationParams, setPaginationParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const { hostId } = useParams();
  const intHostId = Number(hostId);
  const {
    arrivalInformation: { tableHeaders },
  } = useTranslations();
  const { getArrivalInstructionsSummaries, cloneArrivalInstruction } =
    useHostService();
  const [paginationHeight, paginationRef] = useHeightRef();
  const [headerHeight, headerRef] = useHeightRef();
  const [buttonHeight, buttonRef] = useHeightRef();
  const mdSize = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const heightToSkip = paginationHeight + headerHeight + buttonHeight;
  const tableMaxHeight = mdSize
    ? `calc(100vh - ${heightToSkip}px - 110px)`
    : `calc(100vh - ${heightToSkip}px - 70px)`;

  const headers = [
    tableHeaders.status,
    tableHeaders.description,
    tableHeaders.airport,
    tableHeaders.pickUpProvided,
    tableHeaders.shouldContactBeforeArrival,
    tableHeaders.phone,
    tableHeaders.pickUpCost,
    tableHeaders.arrivalDays,
    tableHeaders.arrivalTimes,
    tableHeaders.report,
    tableHeaders.actions,
  ];

  const handleChangePage = async (event: unknown, pageNumber: number) => {
    const newData = {
      pageNumber,
      pageSize: paginationParams.pageSize,
    };

    setPaginationParams(newData);

    await fetchItems(intHostId, newData);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newData = {
      pageNumber: 0,
      pageSize: parseInt(event.target.value, 10),
    };

    setPaginationParams(newData);

    await fetchItems(intHostId, newData);
  };

  const handleActions = async (
    item: ArrivalInstructionSummaryResponse,
    action: 'edit' | 'duplicate'
  ) => {
    switch (action) {
      case 'edit':
        return navigate(
          ApplicationRoutes.arrivalInstructionsForm
            .replace(':hostId', hostId as string)
            .replace(':arrivalInstructionsId', item.id.toString())
        );
      case 'duplicate':
        await cloneArrivalInstruction(intHostId, item.id);

        return fetchItems(intHostId, paginationParams);
    }
  };

  const handleAddNewArrivalInformation = () => {
    navigate(
      ApplicationRoutes.arrivalInstructionsForm
        .replace(':hostId', hostId as string)
        .replace(':arrivalInstructionsId', 'new')
    );
  };

  const fetchItems = useCallback(
    async (hostId: number, tableParams: PagedRequest) => {
      const response = await getArrivalInstructionsSummaries({
        hostId: hostId,
        ...tableParams,
      });

      setRowsTotal(response.total);

      const mapped: RowProps[] = response.records.map((r) => {
        const description = curPrevValueHolderFactory(r.description);
        const contactPhoneNumber = curPrevValueHolderFactory(
          r.contactPhoneNumber
        );
        const nearestInternationalAirport = curPrevValueHolderFactory(
          r.nearestInternationalAirport
        );
        const pickUpProvided = curPrevValueHolderFactory(r.pickUpProvided);
        const preferredArrivalDays = curPrevValueHolderFactory(
          r.preferredArrivalDays
        );
        const preferredArrivalTime = curPrevValueHolderFactory(
          r.preferredArrivalTimes
        );
        const reportingTo = curPrevValueHolderFactory(r.reportingTo);
        const shouldContactHCBeforeArrival = curPrevValueHolderFactory(
          r.shouldContactHCBeforeArrival
        );
        const transportPickUpCost = curPrevValueHolderFactory(
          r.transportPickUpCost
        );
        const hasPrev = r.status === 'ChangeRequested';

        return {
          hasPrev,
          current: [
            { value: <StatusComponent status={r.status} /> },
            { value: description.current },
            { value: nearestInternationalAirport.current },
            { value: pickUpProvided.current },
            { value: shouldContactHCBeforeArrival.current },
            { value: contactPhoneNumber.current },
            { value: transportPickUpCost.current },
            { value: preferredArrivalDays.current },
            { value: preferredArrivalTime.current },
            { value: reportingTo.current },
            hasPrev
              ? undefined
              : {
                  value: (
                    <ActionsComponent item={r} handleAction={handleActions} />
                  ),
                },
          ],
          previous: hasPrev
            ? [
                { value: <StatusComponent status={r.status} isPrev={true} /> },
                { value: description.previous },
                { value: nearestInternationalAirport.previous },
                { value: pickUpProvided.previous },
                { value: shouldContactHCBeforeArrival.previous },
                { value: contactPhoneNumber.previous },
                { value: transportPickUpCost.previous },
                { value: preferredArrivalDays.previous },
                { value: preferredArrivalTime.previous },
                { value: reportingTo.previous },
                {
                  value: (
                    <ActionsComponent item={r} handleAction={handleActions} />
                  ),
                  shouldSpan: true,
                },
              ]
            : undefined,
        } as RowProps;
      });

      setRows(mapped);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchItems(intHostId, paginationParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ArrivalInstructions
      tableProps={{ headers, rows }}
      handleAddNewArrivalInformation={handleAddNewArrivalInformation}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      rowsPerPage={paginationParams.pageSize}
      page={paginationParams.pageNumber}
      rowsTotal={rowsTotal}
      buttonRef={buttonRef}
      headerRef={headerRef}
      paginationRef={paginationRef}
      tableMaxHeight={tableMaxHeight}
    />
  );
};

export default ArrivalInstructionsContainer;
