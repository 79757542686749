export const timezonesRuby = [
  {
    key: 'International Date Line West',
    value: '(GMT-12:00) International Date Line West',
  },
  {
    key: 'American Samoa',
    value: '(GMT-11:00) American Samoa',
  },
  {
    key: 'Midway Island',
    value: '(GMT-11:00) Midway Island',
  },
  {
    key: 'Hawaii',
    value: '(GMT-10:00) Hawaii',
  },
  {
    key: 'Alaska',
    value: '(GMT-09:00) Alaska',
  },
  {
    key: 'Pacific Time (US & Canada)',
    value: '(GMT-08:00) Pacific Time (US & Canada)',
  },
  {
    key: 'Tijuana',
    value: '(GMT-08:00) Tijuana',
  },
  {
    key: 'Arizona',
    value: '(GMT-07:00) Arizona',
  },
  {
    key: 'Chihuahua',
    value: '(GMT-07:00) Chihuahua',
  },
  {
    key: 'Mazatlan',
    value: '(GMT-07:00) Mazatlan',
  },
  {
    key: 'Mountain Time (US & Canada)',
    value: '(GMT-07:00) Mountain Time (US & Canada)',
  },
  {
    key: 'Central America',
    value: '(GMT-06:00) Central America',
  },
  {
    key: 'Central Time (US & Canada)',
    value: '(GMT-06:00) Central Time (US & Canada)',
  },
  {
    key: 'Guadalajara',
    value: '(GMT-06:00) Guadalajara',
  },
  {
    key: 'Mexico City',
    value: '(GMT-06:00) Mexico City',
  },
  {
    key: 'Monterrey',
    value: '(GMT-06:00) Monterrey',
  },
  {
    key: 'Saskatchewan',
    value: '(GMT-06:00) Saskatchewan',
  },
  {
    key: 'Bogota',
    value: '(GMT-05:00) Bogota',
  },
  {
    key: 'Eastern Time (US & Canada)',
    value: '(GMT-05:00) Eastern Time (US & Canada)',
  },
  {
    key: 'Indiana (East)',
    value: '(GMT-05:00) Indiana (East)',
  },
  {
    key: 'Lima',
    value: '(GMT-05:00) Lima',
  },
  {
    key: 'Quito',
    value: '(GMT-05:00) Quito',
  },
  {
    key: 'Atlantic Time (Canada)',
    value: '(GMT-04:00) Atlantic Time (Canada)',
  },
  {
    key: 'Caracas',
    value: '(GMT-04:00) Caracas',
  },
  {
    key: 'Georgetown',
    value: '(GMT-04:00) Georgetown',
  },
  {
    key: 'La Paz',
    value: '(GMT-04:00) La Paz',
  },
  {
    key: 'Puerto Rico',
    value: '(GMT-04:00) Puerto Rico',
  },
  {
    key: 'Santiago',
    value: '(GMT-04:00) Santiago',
  },
  {
    key: 'Newfoundland',
    value: '(GMT-03:30) Newfoundland',
  },
  {
    key: 'Brasilia',
    value: '(GMT-03:00) Brasilia',
  },
  {
    key: 'Buenos Aires',
    value: '(GMT-03:00) Buenos Aires',
  },
  {
    key: 'Greenland',
    value: '(GMT-03:00) Greenland',
  },
  {
    key: 'Montevideo',
    value: '(GMT-03:00) Montevideo',
  },
  {
    key: 'Mid-Atlantic',
    value: '(GMT-02:00) Mid-Atlantic',
  },
  {
    key: 'Azores',
    value: '(GMT-01:00) Azores',
  },
  {
    key: 'Cape Verde Is.',
    value: '(GMT-01:00) Cape Verde Is.',
  },
  {
    key: 'Edinburgh',
    value: '(GMT+00:00) Edinburgh',
  },
  {
    key: 'Lisbon',
    value: '(GMT+00:00) Lisbon',
  },
  {
    key: 'London',
    value: '(GMT+00:00) London',
  },
  {
    key: 'Monrovia',
    value: '(GMT+00:00) Monrovia',
  },
  {
    key: 'UTC',
    value: '(GMT+00:00) UTC',
  },
  {
    key: 'Amsterdam',
    value: '(GMT+01:00) Amsterdam',
  },
  {
    key: 'Belgrade',
    value: '(GMT+01:00) Belgrade',
  },
  {
    key: 'Berlin',
    value: '(GMT+01:00) Berlin',
  },
  {
    key: 'Bern',
    value: '(GMT+01:00) Bern',
  },
  {
    key: 'Bratislava',
    value: '(GMT+01:00) Bratislava',
  },
  {
    key: 'Brussels',
    value: '(GMT+01:00) Brussels',
  },
  {
    key: 'Budapest',
    value: '(GMT+01:00) Budapest',
  },
  {
    key: 'Casablanca',
    value: '(GMT+01:00) Casablanca',
  },
  {
    key: 'Copenhagen',
    value: '(GMT+01:00) Copenhagen',
  },
  {
    key: 'Dublin',
    value: '(GMT+01:00) Dublin',
  },
  {
    key: 'Ljubljana',
    value: '(GMT+01:00) Ljubljana',
  },
  {
    key: 'Madrid',
    value: '(GMT+01:00) Madrid',
  },
  {
    key: 'Paris',
    value: '(GMT+01:00) Paris',
  },
  {
    key: 'Prague',
    value: '(GMT+01:00) Prague',
  },
  {
    key: 'Rome',
    value: '(GMT+01:00) Rome',
  },
  {
    key: 'Sarajevo',
    value: '(GMT+01:00) Sarajevo',
  },
  {
    key: 'Skopje',
    value: '(GMT+01:00) Skopje',
  },
  {
    key: 'Stockholm',
    value: '(GMT+01:00) Stockholm',
  },
  {
    key: 'Vienna',
    value: '(GMT+01:00) Vienna',
  },
  {
    key: 'Warsaw',
    value: '(GMT+01:00) Warsaw',
  },
  {
    key: 'West Central Africa',
    value: '(GMT+01:00) West Central Africa',
  },
  {
    key: 'Zagreb',
    value: '(GMT+01:00) Zagreb',
  },
  {
    key: 'Zurich',
    value: '(GMT+01:00) Zurich',
  },
  {
    key: 'Athens',
    value: '(GMT+02:00) Athens',
  },
  {
    key: 'Bucharest',
    value: '(GMT+02:00) Bucharest',
  },
  {
    key: 'Cairo',
    value: '(GMT+02:00) Cairo',
  },
  {
    key: 'Harare',
    value: '(GMT+02:00) Harare',
  },
  {
    key: 'Helsinki',
    value: '(GMT+02:00) Helsinki',
  },
  {
    key: 'Jerusalem',
    value: '(GMT+02:00) Jerusalem',
  },
  {
    key: 'Kaliningrad',
    value: '(GMT+02:00) Kaliningrad',
  },
  {
    key: 'Kyiv',
    value: '(GMT+02:00) Kyiv',
  },
  {
    key: 'Pretoria',
    value: '(GMT+02:00) Pretoria',
  },
  {
    key: 'Riga',
    value: '(GMT+02:00) Riga',
  },
  {
    key: 'Sofia',
    value: '(GMT+02:00) Sofia',
  },
  {
    key: 'Tallinn',
    value: '(GMT+02:00) Tallinn',
  },
  {
    key: 'Vilnius',
    value: '(GMT+02:00) Vilnius',
  },
  {
    key: 'Baghdad',
    value: '(GMT+03:00) Baghdad',
  },
  {
    key: 'Istanbul',
    value: '(GMT+03:00) Istanbul',
  },
  {
    key: 'Kuwait',
    value: '(GMT+03:00) Kuwait',
  },
  {
    key: 'Minsk',
    value: '(GMT+03:00) Minsk',
  },
  {
    key: 'Moscow',
    value: '(GMT+03:00) Moscow',
  },
  {
    key: 'Nairobi',
    value: '(GMT+03:00) Nairobi',
  },
  {
    key: 'Riyadh',
    value: '(GMT+03:00) Riyadh',
  },
  {
    key: 'St. Petersburg',
    value: '(GMT+03:00) St. Petersburg',
  },
  {
    key: 'Volgograd',
    value: '(GMT+03:00) Volgograd',
  },
  {
    key: 'Tehran',
    value: '(GMT+03:30) Tehran',
  },
  {
    key: 'Abu Dhabi',
    value: '(GMT+04:00) Abu Dhabi',
  },
  {
    key: 'Baku',
    value: '(GMT+04:00) Baku',
  },
  {
    key: 'Muscat',
    value: '(GMT+04:00) Muscat',
  },
  {
    key: 'Samara',
    value: '(GMT+04:00) Samara',
  },
  {
    key: 'Tbilisi',
    value: '(GMT+04:00) Tbilisi',
  },
  {
    key: 'Yerevan',
    value: '(GMT+04:00) Yerevan',
  },
  {
    key: 'Kabul',
    value: '(GMT+04:30) Kabul',
  },
  {
    key: 'Ekaterinburg',
    value: '(GMT+05:00) Ekaterinburg',
  },
  {
    key: 'Islamabad',
    value: '(GMT+05:00) Islamabad',
  },
  {
    key: 'Karachi',
    value: '(GMT+05:00) Karachi',
  },
  {
    key: 'Tashkent',
    value: '(GMT+05:00) Tashkent',
  },
  {
    key: 'Chennai',
    value: '(GMT+05:30) Chennai',
  },
  {
    key: 'Kolkata',
    value: '(GMT+05:30) Kolkata',
  },
  {
    key: 'Mumbai',
    value: '(GMT+05:30) Mumbai',
  },
  {
    key: 'New Delhi',
    value: '(GMT+05:30) New Delhi',
  },
  {
    key: 'Sri Jayawardenepura',
    value: '(GMT+05:30) Sri Jayawardenepura',
  },
  {
    key: 'Kathmandu',
    value: '(GMT+05:45) Kathmandu',
  },
  {
    key: 'Almaty',
    value: '(GMT+06:00) Almaty',
  },
  {
    key: 'Astana',
    value: '(GMT+06:00) Astana',
  },
  {
    key: 'Dhaka',
    value: '(GMT+06:00) Dhaka',
  },
  {
    key: 'Urumqi',
    value: '(GMT+06:00) Urumqi',
  },
  {
    key: 'Rangoon',
    value: '(GMT+06:30) Rangoon',
  },
  {
    key: 'Bangkok',
    value: '(GMT+07:00) Bangkok',
  },
  {
    key: 'Hanoi',
    value: '(GMT+07:00) Hanoi',
  },
  {
    key: 'Jakarta',
    value: '(GMT+07:00) Jakarta',
  },
  {
    key: 'Krasnoyarsk',
    value: '(GMT+07:00) Krasnoyarsk',
  },
  {
    key: 'Novosibirsk',
    value: '(GMT+07:00) Novosibirsk',
  },
  {
    key: 'Beijing',
    value: '(GMT+08:00) Beijing',
  },
  {
    key: 'Chongqing',
    value: '(GMT+08:00) Chongqing',
  },
  {
    key: 'Hong Kong',
    value: '(GMT+08:00) Hong Kong',
  },
  {
    key: 'Irkutsk',
    value: '(GMT+08:00) Irkutsk',
  },
  {
    key: 'Kuala Lumpur',
    value: '(GMT+08:00) Kuala Lumpur',
  },
  {
    key: 'Perth',
    value: '(GMT+08:00) Perth',
  },
  {
    key: 'Singapore',
    value: '(GMT+08:00) Singapore',
  },
  {
    key: 'Taipei',
    value: '(GMT+08:00) Taipei',
  },
  {
    key: 'Ulaanbaatar',
    value: '(GMT+08:00) Ulaanbaatar',
  },
  {
    key: 'Osaka',
    value: '(GMT+09:00) Osaka',
  },
  {
    key: 'Sapporo',
    value: '(GMT+09:00) Sapporo',
  },
  {
    key: 'Seoul',
    value: '(GMT+09:00) Seoul',
  },
  {
    key: 'Tokyo',
    value: '(GMT+09:00) Tokyo',
  },
  {
    key: 'Yakutsk',
    value: '(GMT+09:00) Yakutsk',
  },
  {
    key: 'Adelaide',
    value: '(GMT+09:30) Adelaide',
  },
  {
    key: 'Darwin',
    value: '(GMT+09:30) Darwin',
  },
  {
    key: 'Brisbane',
    value: '(GMT+10:00) Brisbane',
  },
  {
    key: 'Canberra',
    value: '(GMT+10:00) Canberra',
  },
  {
    key: 'Guam',
    value: '(GMT+10:00) Guam',
  },
  {
    key: 'Hobart',
    value: '(GMT+10:00) Hobart',
  },
  {
    key: 'Melbourne',
    value: '(GMT+10:00) Melbourne',
  },
  {
    key: 'Port Moresby',
    value: '(GMT+10:00) Port Moresby',
  },
  {
    key: 'Sydney',
    value: '(GMT+10:00) Sydney',
  },
  {
    key: 'Vladivostok',
    value: '(GMT+10:00) Vladivostok',
  },
  {
    key: 'Magadan',
    value: '(GMT+11:00) Magadan',
  },
  {
    key: 'New Caledonia',
    value: '(GMT+11:00) New Caledonia',
  },
  {
    key: 'Solomon Is.',
    value: '(GMT+11:00) Solomon Is.',
  },
  {
    key: 'Srednekolymsk',
    value: '(GMT+11:00) Srednekolymsk',
  },
  {
    key: 'Auckland',
    value: '(GMT+12:00) Auckland',
  },
  {
    key: 'Fiji',
    value: '(GMT+12:00) Fiji',
  },
  {
    key: 'Kamchatka',
    value: '(GMT+12:00) Kamchatka',
  },
  {
    key: 'Marshall Is.',
    value: '(GMT+12:00) Marshall Is.',
  },
  {
    key: 'Wellington',
    value: '(GMT+12:00) Wellington',
  },
  {
    key: 'Chatham Is.',
    value: '(GMT+12:45) Chatham Is.',
  },
  {
    key: "Nuku'alofa",
    value: "(GMT+13:00) Nuku'alofa",
  },
  {
    key: 'Samoa',
    value: '(GMT+13:00) Samoa',
  },
  {
    key: 'Tokelau Is.',
    value: '(GMT+13:00) Tokelau Is.',
  },
];

export const rubyToWindows = new Map([
  ['Abu Dhabi', 'Arabian Standard Time'],
  ['Adelaide', 'Cen. Australia Standard Time'],
  ['Alaska', 'Alaskan Standard Time'],
  ['Almaty', 'Central Asia Standard Time'],
  ['American Samoa', 'UTC-11'],
  ['Amsterdam', 'W. Europe Standard Time'],
  ['Arizona', 'US Mountain Standard Time'],
  ['Astana', 'Bangladesh Standard Time'],
  ['Athens', 'GTB Standard Time'],
  ['Atlantic Time (Canada)', 'Atlantic Standard Time'],
  ['Auckland', 'New Zealand Standard Time'],
  ['Azores', 'Azores Standard Time'],
  ['Baghdad', 'Arabic Standard Time'],
  ['Baku', 'Azerbaijan Standard Time'],
  ['Bangkok', 'SE Asia Standard Time'],
  ['Beijing', 'China Standard Time'],
  ['Belgrade', 'Central Europe Standard Time'],
  ['Berlin', 'W. Europe Standard Time'],
  ['Bern', 'W. Europe Standard Time'],
  ['Bogota', 'SA Pacific Standard Time'],
  ['Brasilia', 'E. South America Standard Time'],
  ['Bratislava', 'Central Europe Standard Time'],
  ['Brisbane', 'E. Australia Standard Time'],
  ['Brussels', 'Romance Standard Time'],
  ['Bucharest', 'GTB Standard Time'],
  ['Budapest', 'Central Europe Standard Time'],
  ['Buenos Aires', 'Argentina Standard Time'],
  ['Cairo', 'Egypt Standard Time'],
  ['Canberra', 'AUS Eastern Standard Time'],
  ['Cape Verde Is.', 'Cape Verde Standard Time'],
  ['Caracas', 'Venezuela Standard Time'],
  ['Casablanca', 'Morocco Standard Time'],
  ['Central America', 'Central America Standard Time'],
  ['Central Time (US & Canada)', 'Central Standard Time'],
  ['Chennai', 'India Standard Time'],
  ['Chihuahua', 'Mountain Standard Time (Mexico)'],
  ['Chongqing', 'China Standard Time'],
  ['Copenhagen', 'Romance Standard Time'],
  ['Darwin', 'AUS Central Standard Time'],
  ['Dhaka', 'Bangladesh Standard Time'],
  ['Dublin', 'GMT Standard Time'],
  ['Eastern Time (US & Canada)', 'Eastern Standard Time'],
  ['Edinburgh', 'GMT Standard Time'],
  ['Ekaterinburg', 'Ekaterinburg Standard Time'],
  ['Fiji', 'Fiji Standard Time'],
  ['Georgetown', 'SA Western Standard Time'],
  ['Greenland', 'Greenland Standard Time'],
  ['Guadalajara', 'Central Standard Time (Mexico)'],
  ['Guam', 'West Pacific Standard Time'],
  ['Hanoi', 'SE Asia Standard Time'],
  ['Harare', 'South Africa Standard Time'],
  ['Hawaii', 'Hawaiian Standard Time'],
  ['Helsinki', 'FLE Standard Time'],
  ['Hobart', 'Tasmania Standard Time'],
  ['Hong Kong', 'China Standard Time'],
  ['Indiana (East)', 'US Eastern Standard Time'],
  ['International Date Line West', 'UTC-11'],
  ['Irkutsk', 'North Asia East Standard Time'],
  ['Islamabad', 'Pakistan Standard Time'],
  ['Istanbul', 'Turkey Standard Time'],
  ['Jakarta', 'SE Asia Standard Time'],
  ['Jerusalem', 'Israel Standard Time'],
  ['Kabul', 'Afghanistan Standard Time'],
  ['Kaliningrad', 'Kaliningrad Standard Time'],
  ['Kamchatka', 'Russia Time Zone 11'],
  ['Karachi', 'Pakistan Standard Time'],
  ['Kathmandu', 'Nepal Standard Time'],
  ['Kolkata', 'India Standard Time'],
  ['Krasnoyarsk', 'North Asia Standard Time'],
  ['Kuala Lumpur', 'Singapore Standard Time'],
  ['Kuwait', 'Arab Standard Time'],
  ['Kyiv', 'FLE Standard Time'],
  ['La Paz', 'SA Western Standard Time'],
  ['Lima', 'SA Pacific Standard Time'],
  ['Lisbon', 'GMT Standard Time'],
  ['Ljubljana', 'Central Europe Standard Time'],
  ['London', 'GMT Standard Time'],
  ['Madrid', 'Romance Standard Time'],
  ['Magadan', 'Magadan Standard Time'],
  ['Marshall Is.', 'UTC+12'],
  ['Mazatlan', 'Mountain Standard Time (Mexico)'],
  ['Melbourne', 'AUS Eastern Standard Time'],
  ['Mexico City', 'Central Standard Time (Mexico)'],
  ['Mid-Atlantic', 'UTC-02'],
  ['Midway Island', 'UTC-11'],
  ['Minsk', 'Belarus Standard Time'],
  ['Monrovia', 'Greenwich Standard Time'],
  ['Monterrey', 'Central Standard Time (Mexico)'],
  ['Montevideo', 'Montevideo Standard Time'],
  ['Moscow', 'Russian Standard Time'],
  ['Mountain Time (US & Canada)', 'Mountain Standard Time'],
  ['Mumbai', 'India Standard Time'],
  ['Muscat', 'Arabian Standard Time'],
  ['Nairobi', 'E. Africa Standard Time'],
  ['New Caledonia', 'Central Pacific Standard Time'],
  ['New Delhi', 'India Standard Time'],
  ['Newfoundland', 'Newfoundland Standard Time'],
  ['Novosibirsk', 'N. Central Asia Standard Time'],
  ["Nuku'alofa", 'Tonga Standard Time'],
  ['Osaka', 'Tokyo Standard Time'],
  ['Pacific Time (US & Canada)', 'Pacific Standard Time'],
  ['Paris', 'Romance Standard Time'],
  ['Perth', 'W. Australia Standard Time'],
  ['Port Moresby', 'West Pacific Standard Time'],
  ['Prague', 'Central Europe Standard Time'],
  ['Pretoria', 'South Africa Standard Time'],
  ['Quito', 'SA Pacific Standard Time'],
  ['Rangoon', 'Myanmar Standard Time'],
  ['Riga', 'FLE Standard Time'],
  ['Riyadh', 'Arab Standard Time'],
  ['Rome', 'W. Europe Standard Time'],
  ['Samara', 'Russia Time Zone 3'],
  ['Samoa', 'Samoa Standard Time'],
  ['Santiago', 'Pacific SA Standard Time'],
  ['Sapporo', 'Tokyo Standard Time'],
  ['Sarajevo', 'Central European Standard Time'],
  ['Saskatchewan', 'Canada Central Standard Time'],
  ['Seoul', 'Korea Standard Time'],
  ['Singapore', 'Singapore Standard Time'],
  ['Skopje', 'Central European Standard Time'],
  ['Sofia', 'FLE Standard Time'],
  ['Solomon Is.', 'Central Pacific Standard Time'],
  ['Srednekolymsk', 'Russia Time Zone 10'],
  ['Sri Jayawardenepura', 'Sri Lanka Standard Time'],
  ['St. Petersburg', 'Russian Standard Time'],
  ['Stockholm', 'W. Europe Standard Time'],
  ['Sydney', 'AUS Eastern Standard Time'],
  ['Taipei', 'Taipei Standard Time'],
  ['Tallinn', 'FLE Standard Time'],
  ['Tashkent', 'West Asia Standard Time'],
  ['Tbilisi', 'Georgian Standard Time'],
  ['Tehran', 'Iran Standard Time'],
  ['Tijuana', 'Pacific Standard Time'],
  ['Tokelau Is.', 'Tonga Standard Time'],
  ['Tokyo', 'Tokyo Standard Time'],
  ['Ulaanbaatar', 'Ulaanbaatar Standard Time'],
  ['Urumqi', 'Central Asia Standard Time'],
  ['UTC', 'UTC'],
  ['Vienna', 'W. Europe Standard Time'],
  ['Vilnius', 'FLE Standard Time'],
  ['Vladivostok', 'Vladivostok Standard Time'],
  ['Volgograd', 'Russian Standard Time'],
  ['Warsaw', 'Central European Standard Time'],
  ['Wellington', 'New Zealand Standard Time'],
  ['West Central Africa', 'W. Central Africa Standard Time'],
  ['Yakutsk', 'Yakutsk Standard Time'],
  ['Yerevan', 'Caucasus Standard Time'],
  ['Zagreb', 'Central European Standard Time'],
]);

export const rubyToMomentMapping = new Map([
  ['International Date Line West', 'Etc/GMT+12'],
  ['Midway Island', 'Pacific/Midway'],
  ['American Samoa', 'Pacific/Pago_Pago'],
  ['Hawaii', 'Pacific/Honolulu'],
  ['Alaska', 'America/Juneau'],
  ['Pacific Time (US & Canada)', 'America/Los_Angeles'],
  ['Tijuana', 'America/Tijuana'],
  ['Mountain Time (US & Canada)', 'America/Denver'],
  ['Arizona', 'America/Phoenix'],
  ['Chihuahua', 'America/Chihuahua'],
  ['Mazatlan', 'America/Mazatlan'],
  ['Central Time (US & Canada)', 'America/Chicago'],
  ['Saskatchewan', 'America/Regina'],
  ['Guadalajara', 'America/Mexico_City'],
  ['Mexico City', 'America/Mexico_City'],
  ['Monterrey', 'America/Monterrey'],
  ['Central America', 'America/Guatemala'],
  ['Eastern Time (US & Canada)', 'America/New_York'],
  ['Indiana (East)', 'America/Indiana/Indianapolis'],
  ['Bogota', 'America/Bogota'],
  ['Lima', 'America/Lima'],
  ['Quito', 'America/Lima'],
  ['Atlantic Time (Canada)', 'America/Halifax'],
  ['Caracas', 'America/Caracas'],
  ['La Paz', 'America/La_Paz'],
  ['Santiago', 'America/Santiago'],
  ['Newfoundland', 'America/St_Johns'],
  ['Brasilia', 'America/Sao_Paulo'],
  ['Buenos Aires', 'America/Argentina/Buenos_Aires'],
  ['Montevideo', 'America/Montevideo'],
  ['Georgetown', 'America/Guyana'],
  ['Puerto Rico', 'America/Puerto_Rico'],
  ['Greenland', 'America/Godthab'],
  ['Mid-Atlantic', 'Atlantic/South_Georgia'],
  ['Azores', 'Atlantic/Azores'],
  ['Cape Verde Is.', 'Atlantic/Cape_Verde'],
  ['Dublin', 'Europe/Dublin'],
  ['Edinburgh', 'Europe/London'],
  ['Lisbon', 'Europe/Lisbon'],
  ['London', 'Europe/London'],
  ['Casablanca', 'Africa/Casablanca'],
  ['Monrovia', 'Africa/Monrovia'],
  ['UTC', 'Etc/UTC'],
  ['Belgrade', 'Europe/Belgrade'],
  ['Bratislava', 'Europe/Bratislava'],
  ['Budapest', 'Europe/Budapest'],
  ['Ljubljana', 'Europe/Ljubljana'],
  ['Prague', 'Europe/Prague'],
  ['Sarajevo', 'Europe/Sarajevo'],
  ['Skopje', 'Europe/Skopje'],
  ['Warsaw', 'Europe/Warsaw'],
  ['Zagreb', 'Europe/Zagreb'],
  ['Brussels', 'Europe/Brussels'],
  ['Copenhagen', 'Europe/Copenhagen'],
  ['Madrid', 'Europe/Madrid'],
  ['Paris', 'Europe/Paris'],
  ['Amsterdam', 'Europe/Amsterdam'],
  ['Berlin', 'Europe/Berlin'],
  ['Bern', 'Europe/Zurich'],
  ['Zurich', 'Europe/Zurich'],
  ['Rome', 'Europe/Rome'],
  ['Stockholm', 'Europe/Stockholm'],
  ['Vienna', 'Europe/Vienna'],
  ['West Central Africa', 'Africa/Algiers'],
  ['Bucharest', 'Europe/Bucharest'],
  ['Cairo', 'Africa/Cairo'],
  ['Helsinki', 'Europe/Helsinki'],
  ['Kyiv', 'Europe/Kiev'],
  ['Riga', 'Europe/Riga'],
  ['Sofia', 'Europe/Sofia'],
  ['Tallinn', 'Europe/Tallinn'],
  ['Vilnius', 'Europe/Vilnius'],
  ['Athens', 'Europe/Athens'],
  ['Istanbul', 'Europe/Istanbul'],
  ['Minsk', 'Europe/Minsk'],
  ['Jerusalem', 'Asia/Jerusalem'],
  ['Harare', 'Africa/Harare'],
  ['Pretoria', 'Africa/Johannesburg'],
  ['Kaliningrad', 'Europe/Kaliningrad'],
  ['Moscow', 'Europe/Moscow'],
  ['St. Petersburg', 'Europe/Moscow'],
  ['Volgograd', 'Europe/Volgograd'],
  ['Samara', 'Europe/Samara'],
  ['Kuwait', 'Asia/Kuwait'],
  ['Riyadh', 'Asia/Riyadh'],
  ['Nairobi', 'Africa/Nairobi'],
  ['Baghdad', 'Asia/Baghdad'],
  ['Tehran', 'Asia/Tehran'],
  ['Abu Dhabi', 'Asia/Muscat'],
  ['Muscat', 'Asia/Muscat'],
  ['Baku', 'Asia/Baku'],
  ['Tbilisi', 'Asia/Tbilisi'],
  ['Yerevan', 'Asia/Yerevan'],
  ['Kabul', 'Asia/Kabul'],
  ['Ekaterinburg', 'Asia/Yekaterinburg'],
  ['Islamabad', 'Asia/Karachi'],
  ['Karachi', 'Asia/Karachi'],
  ['Tashkent', 'Asia/Tashkent'],
  ['Chennai', 'Asia/Kolkata'],
  ['Kolkata', 'Asia/Kolkata'],
  ['Mumbai', 'Asia/Kolkata'],
  ['New Delhi', 'Asia/Kolkata'],
  ['Kathmandu', 'Asia/Kathmandu'],
  ['Astana', 'Asia/Dhaka'],
  ['Dhaka', 'Asia/Dhaka'],
  ['Sri Jayawardenepura', 'Asia/Colombo'],
  ['Almaty', 'Asia/Almaty'],
  ['Novosibirsk', 'Asia/Novosibirsk'],
  ['Rangoon', 'Asia/Rangoon'],
  ['Bangkok', 'Asia/Bangkok'],
  ['Hanoi', 'Asia/Bangkok'],
  ['Jakarta', 'Asia/Jakarta'],
  ['Krasnoyarsk', 'Asia/Krasnoyarsk'],
  ['Beijing', 'Asia/Shanghai'],
  ['Chongqing', 'Asia/Chongqing'],
  ['Hong Kong', 'Asia/Hong_Kong'],
  ['Urumqi', 'Asia/Urumqi'],
  ['Kuala Lumpur', 'Asia/Kuala_Lumpur'],
  ['Singapore', 'Asia/Singapore'],
  ['Taipei', 'Asia/Taipei'],
  ['Perth', 'Australia/Perth'],
  ['Irkutsk', 'Asia/Irkutsk'],
  ['Ulaanbaatar', 'Asia/Ulaanbaatar'],
  ['Seoul', 'Asia/Seoul'],
  ['Osaka', 'Asia/Tokyo'],
  ['Sapporo', 'Asia/Tokyo'],
  ['Tokyo', 'Asia/Tokyo'],
  ['Yakutsk', 'Asia/Yakutsk'],
  ['Darwin', 'Australia/Darwin'],
  ['Adelaide', 'Australia/Adelaide'],
  ['Canberra', 'Australia/Melbourne'],
  ['Melbourne', 'Australia/Melbourne'],
  ['Sydney', 'Australia/Sydney'],
  ['Brisbane', 'Australia/Brisbane'],
  ['Hobart', 'Australia/Hobart'],
  ['Vladivostok', 'Asia/Vladivostok'],
  ['Guam', 'Pacific/Guam'],
  ['Port Moresby', 'Pacific/Port_Moresby'],
  ['Magadan', 'Asia/Magadan'],
  ['Srednekolymsk', 'Asia/Srednekolymsk'],
  ['Solomon Is.', 'Pacific/Guadalcanal'],
  ['New Caledonia', 'Pacific/Noumea'],
  ['Fiji', 'Pacific/Fiji'],
  ['Kamchatka', 'Asia/Kamchatka'],
  ['Marshall Is.', 'Pacific/Majuro'],
  ['Auckland', 'Pacific/Auckland'],
  ['Wellington', 'Pacific/Auckland'],
  ["Nuku'alofa", 'Pacific/Tongatapu'],
  ['Tokelau Is.', 'Pacific/Fakaofo'],
  ['Chatham Is.', 'Pacific/Chatham'],
  ['Samoa', 'Pacific/Apia'],
]);
