import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DynamicFieldProps } from 'components/form/dynamic/dynamicField/interfaces/dynamicFieldProps';
import useDynamicFormHook from 'components/form/dynamic/hooks/dynamicFormHook';
import { useHostService } from 'hooks/services/hosts';
import { DocumentDetailsResponse } from 'hooks/services/hosts/interfaces/documentDetails.response';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'providers/alertProvider';
import { useDropzone } from 'react-dropzone';
import { blobToUrl, downloadBlob, toBlobUrl } from 'utils/file/fileUtilities';
import { DynamicFieldType } from 'utils/interfaces/dynamicFieldType';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import UploadDocument from './uploadDocument';

const UploadDocumentContainer = () => {
  const translations = useTranslations();
  const alert = useAlert();
  const [documentDetails, setDocumentDetails] =
    useState<DocumentDetailsResponse>();
  const [previewSrc, setPreviewSrc] = useState<string>();
  const [fields, setFields] = useState<DynamicFieldProps[]>([]);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const { getDocumentFile } = useHostService();
  const { getDocumentDetails, uploadDocument } = useHostService();
  const navigate = useNavigate();

  const handledDropRejection = () => {
    alert.error(translations.alerts.uploadDocument.documentSubmittedError);
  };

  const dragDropZoneProps = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.jpeg', '.jpg', '.png'],
    },
    maxSize: 5242880,
    multiple: false,
    onDropRejected: handledDropRejection,
  });

  const { hostId, id } = useParams();
  const intHostId = Number(hostId);
  const intId = Number(id);
  const { acceptedFiles, open } = dragDropZoneProps;

  const onConfirm = () => {
    setIsConfirmed(!isConfirmed);
  };

  const handleChangeDocument = () => {
    setPreviewSrc(undefined);
    setIsConfirmed(false);
    setHasFile(false);
    open();
  };

  const handleCancelButton = () => {
    navigate(
      ApplicationRoutes.documents.replace(':hostId', intHostId.toString())
    );
  };

  const submitHandler = async (data: Record<string, any>) => {
    if (acceptedFiles.length < 1) {
      return;
    }

    await uploadDocument({
      hostId: Number(hostId),
      id: Number(id),
      formFile: acceptedFiles[0],
      extras: data,
    });

    handleCancelButton();
  };

  const { control, errors, getValues, onSubmit, register, setValue } =
    useDynamicFormHook({
      fields,
      submitHandler,
    });

  const fetchDocument = useCallback(async () => {
    const documentDetails = await getDocumentDetails({
      hostId: intHostId,
      id: intId,
    });

    setDocumentDetails(documentDetails);
    setDocumentTitle(documentDetails.type);

    if (documentDetails.status !== 'NotStarted') {
      try {
        const file = await getDocumentFile(
          intHostId,
          documentDetails.id.toString()
        );
        setPreviewSrc(blobToUrl(file));
      } catch (err) {
        console.log(err);
      }
    }

    const mapped: DynamicFieldProps[] = [];
    documentDetails?.extraFields.forEach((f) => {
      const df = {
        label: f.label,
        name: f.name,
        type: f.type,
        validation: { required: true },
        defaultValue: f.defaultValue
          ? f.defaultValue
          : f.type === DynamicFieldType.MultiselectDropdown
          ? []
          : '',
        options: f.options.map((o) => ({
          key: o.key,
          value: o.value,
        })),
      } as DynamicFieldProps;

      setValue(df.name, df.defaultValue);

      mapped.push(df);
    });

    setFields(mapped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intHostId, intId]);

  const convertFileToPreview = async (file: File) => {
    const src = await toBlobUrl(file);
    setPreviewSrc(src);
  };

  const handleDownload = async (document?: DocumentDetailsResponse) => {
    if (document) {
      try {
        const file = await getDocumentFile(intHostId, document.id.toString());

        downloadBlob(file, document.type);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];
    convertFileToPreview(file);
    setHasFile(true);
    setIsConfirmVisible(true);
  }, [acceptedFiles]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  return (
    <UploadDocument
      canUpload={acceptedFiles?.length > 0 && isConfirmed}
      documentDetails={documentDetails}
      documentTitle={documentTitle}
      handleCancelButton={handleCancelButton}
      dynamicFormProps={{ control, errors, getValues, register, setValue }}
      fields={fields}
      onSubmit={onSubmit}
      handleDownload={() => handleDownload(documentDetails)}
      dragDropProps={dragDropZoneProps}
      previewSrc={previewSrc}
      handleChangeDocument={handleChangeDocument}
      isConfirmed={isConfirmed}
      onConfirm={onConfirm}
      isConfirmVisible={isConfirmVisible}
      hasFile={hasFile}
    />
  );
};

export default UploadDocumentContainer;
