import { UseFormSetValue } from 'react-hook-form';
import { ContactResponse } from 'hooks/services/hosts/interfaces/contact.response';
import { ContactInputsProps } from '../interfaces/ContactInputsProps';

export const setValues = (
  {
    directPhone,
    email,
    fax,
    mobilePhone,
    name,
    title,
    department,
    type,
    skype,
  }: ContactResponse,
  setValue: UseFormSetValue<ContactInputsProps>
) => {
  setValue('name', name);
  setValue('type', type);
  setValue('email', email);
  setValue('directPhone', directPhone);
  setValue('mobilePhone', mobilePhone);
  setValue('title', title);
  setValue('department', department);
  setValue('fax', fax);
  setValue('skype', skype);
};
