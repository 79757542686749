import { Paper, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import BlankLayout from './blankLayout ';

const PaperLayout = ({ children }: { children: ReactElement }) => {
  const theme = useTheme();

  return (
    <BlankLayout>
      <Paper
        sx={{
          px: 0,
          mx: 'auto',
          maxWidth: { xs: '100%', md: '1600px' },
          minHeight: '100%',
          height: '100%',
          padding: {
            md: theme.spacing(6, 5, 4),
            sm: theme.spacing(5, 6, 4),
            xs: theme.spacing(3, 2),
          },
        }}
      >
        {children}
      </Paper>
    </BlankLayout>
  );
};

export default PaperLayout;
