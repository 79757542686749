import { useState } from 'react';
import { Nullable } from 'utils/interfaces';

export const useScheduleInterview = <T>() => {
  const [activeRow, setActiveRow] = useState<Nullable<T>>(null);

  const handleInterviewAction = (item: T) => {
    setActiveRow(item);
  };

  return {
    activeRow,
    setActiveRow,
    handleInterviewAction
  };
};
