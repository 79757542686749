import { ReactElement } from "react";
import { useTheme, Grid } from '@mui/material';

const Wrapper = ({ children }: { children: ReactElement }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      data-testid="wrapper"
      columns={{ xs: 4, sm: 4, md: 8, lg: 12, xl: 12 }}
      sx={{
        display: { lg: 'flex', xl: 'flex' },
        flexGrow: 1,
        justifyContent: { lg: 'center', xl: 'center' },
        alignItems: { lg: 'center', xl: 'center' },
        height: { lg: '100%', xl: '100%' },
        padding: {
          xs: theme.spacing(7, 2, 0, 2),
          md: theme.spacing(6, 4, 0, 4)
        },
      }}
    >
      {children}
    </Grid>
  );
};

export default Wrapper;
