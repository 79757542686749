import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import ApplicantsList from 'pages/applicants';
import ChangePassword from 'pages/changePassword';
import AddressForm from 'pages/company/addressForm';
import CompleteInfo from 'pages/company/completeInfo';
import DocumentList from 'pages/company/documentList';
import UploadDocument from 'pages/company/uploadDocument';
import Home from 'pages/home';
import Interviews from 'pages/interviews';
import Notifications from 'pages/notifications';
import ParticipantsVisa from 'pages/participantsVisa';
import ClonePlacement from 'pages/placements/clonePlacement';
import CreatePlacement from 'pages/placements/createPlacement';
import EditPlacement from 'pages/placements/editPlacement';
import PlacementsList from 'pages/placements/placementsList';
import PlacementPreview from 'pages/placements/preview';
import { Profile } from 'pages/profile';
import ProgramResources from 'pages/programResources';
import ArrivalInstructions from 'pages/programs/arrivalInstructions';
import ArrivalInstructionsForm from 'pages/programs/arrivalInstructionsForm';
import Housing from 'pages/programs/housing';
import HousingForm from 'pages/programs/housingForm';
import OfferDetailsForm from 'pages/programs/offerDetailsForm';
import ResetPassword from 'pages/resetPassword';
import SignIn from 'pages/signIn';
import { ProtectedRoutes } from 'routes/protectedRoutes';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import PaperLayout from '../components/layout/paperLayout';
import ContactForm from '../pages/company/contactForm';
import Contacts from '../pages/company/contacts';
import BlankLayout from '../components/layout/blankLayout ';

const AppRoutes = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Routes>
      <Route
        path={ApplicationRoutes.signIn}
        element={
          isLoggedIn ? (
            <Navigate to={ApplicationRoutes.dashboard} replace />
          ) : (
            <SignIn />
          )
        }
      />
      <Route
        path={ApplicationRoutes.dashboard}
        element={
          <ProtectedRoutes>
            <BlankLayout>
              <Home />
            </BlankLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.createPlacement}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <CreatePlacement />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.editPlacement}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <EditPlacement />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.clonePlacement}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ClonePlacement />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.placements}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <PlacementsList />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.placementPreview}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <PlacementPreview />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.applicants}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ApplicantsList />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.participants}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ParticipantsVisa />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.resetPassword}
        element={<ResetPassword />}
      />
      <Route
        path={ApplicationRoutes.changePassword}
        element={<ChangePassword />}
      />
      <Route
        path={ApplicationRoutes.completeCompanyInfo}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <CompleteInfo />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.companyHousing}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <Housing />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.companyHousingForm}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <HousingForm />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.uploadDocument}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <UploadDocument />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.documents}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <DocumentList />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.addressHost}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <AddressForm />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.arrivalInstructions}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ArrivalInstructions />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.arrivalInstructionsForm}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ArrivalInstructionsForm />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.contacts}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <Contacts />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />

      <Route
        path={ApplicationRoutes.offerDetails}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <OfferDetailsForm />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.contactsForm}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ContactForm />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.interviews}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <Interviews />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />
      <Route
        path={ApplicationRoutes.notifications}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <Notifications />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />

      <Route
        path={ApplicationRoutes.profile}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <Profile />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />

      <Route
        path={ApplicationRoutes.programResources}
        element={
          <ProtectedRoutes>
            <PaperLayout>
              <ProgramResources />
            </PaperLayout>
          </ProtectedRoutes>
        }
      />

      <Route
        path={ApplicationRoutes.badRoute}
        element={<p>Something went wrong: 404!</p>}
      />
    </Routes>
  );
};

export default AppRoutes;
