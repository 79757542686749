import {
  CompanyHousingResponse,
  HousingDetailsResponse,
  HousingSecurityResponse,
  HousingUtilitiesResponse,
} from 'hooks/services/hosts/interfaces/companyHousing.response';
import { UseFormSetValue } from 'react-hook-form';
import {
  convertBooleanToString,
  getStringValueOrEmptyString,
} from 'utils/string/stringConverters';
import { HousingFormInput } from '../interfaces/housingForm.formInput';

export const setValues = (
  value: CompanyHousingResponse,
  setValue: UseFormSetValue<HousingFormInput>
) => {
  const { details, utilities, security } = value;

  setDetailsValues(details, setValue);
  setUtilitiesValues(utilities, setValue);
  setSecurityValues(security, setValue);
};

const setDetailsValues = (
  details: HousingDetailsResponse,
  setValue: UseFormSetValue<HousingFormInput>
) => {
  setValue('details.housingModel', details.housingModel);
  setValue('details.housingType', details.housingType);
  setValue('details.propertyName', details.propertyName);
  setValue('details.contactName', details.contactName);
  setValue('details.propertyManagement', details.propertyManagement);
  setValue('details.phoneNumber', details.phoneNumber);
  setValue('details.website', details.website);
  setValue(
    'details.emailForReservationEnquiries',
    details.emailForReservationEnquiries
  );
  setValue('details.description', details.description);
  setValue('details.addressStreet', details.addressStreet);
  setValue('details.addressCity', details.addressCity);
  setValue('details.addressState', details.addressState);
  setValue('details.addressZipCode', details.addressZipCode);
  setValue('details.canBeCoEd', convertBooleanToString(details.canBeCoEd));
  setValue(
    'details.numberOfBedsPerRoom',
    getStringValueOrEmptyString(details.numberOfBedsPerRoom)
  );
  setValue(
    'details.numberOfBedrooms',
    getStringValueOrEmptyString(details.numberOfBedrooms)
  );
  setValue(
    'details.participantsPerProperty',
    getStringValueOrEmptyString(details.participantsPerProperty)
  );
  setValue(
    'details.participantsPerRoom',
    getStringValueOrEmptyString(details.participantsPerRoom)
  );
  setValue(
    'details.bathroomsPerProperty',
    getStringValueOrEmptyString(details.bathroomsPerProperty)
  );
  setValue(
    'details.beddingAndTowels',
    convertBooleanToString(details.beddingAndTowels)
  );
  setValue(
    'details.costOfBeddingAndTowels',
    getStringValueOrEmptyString(details.costOfBeddingAndTowels)
  );
  setValue(
    'details.beddingAndTowelPaymentDue',
    getStringValueOrEmptyString(details.beddingAndTowelPaymentDue)
  );
  setValue(
    'details.housingCost',
    getStringValueOrEmptyString(details.housingCost)
  );
  setValue(
    'details.isWeeklyRentPayrollDeducted',
    convertBooleanToString(details.isWeeklyRentPayrollDeducted)
  );
  setValue(
    'details.administrationFee',
    getStringValueOrEmptyString(details.administrationFee)
  );
  setValue(
    'details.housingDepositAmount',
    getStringValueOrEmptyString(details.housingDepositAmount)
  );
  setValue(
    'details.isHousingDepositPayrollDeducted',
    convertBooleanToString(details.isHousingDepositPayrollDeducted)
  );
  setValue(
    'details.housingDepositRefundable',
    convertBooleanToString(details.isHousingDepositRefundable)
  );
  setValue(
    'details.leaseRequired',
    convertBooleanToString(details.isLeaseRequired)
  );
  setValue('details.depositRefundPolicy', details.depositRefundPolicy);
  setValue(
    'details.isRentersInsuranceRequired',
    convertBooleanToString(details.isRentersInsuranceRequired)
  );
  setValue('details.kitchenFacilities', details.kitchenFacilities);
  setValue(
    'details.additionalItemsMustBring',
    details.additionalItemsMustBring
  );
  setValue('details.additionalComments', details.additionalComments);
  setValue('details.rentPeriodicity', details.rentPeriodicity);
  setValue('details.includedInCost', details.includedInCost);
  setValue(
    'details.additionalItemsIncludedInCost',
    details.additionalItemsIncludedInCost
  );
  setValue('details.administrationFeeDue', details.administrationFeeDue);
  setValue(
    'details.furtherInformationOnHousingRefundPolicy',
    details.furtherInformationOnHousingRefundPolicy
  );
  setValue('details.lengthOfLease', details.lengthOfLease);
  setValue('details.timeUnit', details.timeUnit);
  setValue(
    'details.furtherInformationOnLengthOfLease',
    details.furtherInformationOnLengthOfLease
  );
  setValue('details.feesAdditionalComments', details.feesAdditionalComments);
  setValue('details.housingAmentities', details.housingAmentities);
  setValue(
    'details.distanceFromWorkSiteToHousing',
    details.distanceFromWorkSiteToHousing
  );
  setValue('details.transportationDetails', details.transportationDetails);
};

const setUtilitiesValues = (
  utilities: HousingUtilitiesResponse,
  setValue: UseFormSetValue<HousingFormInput>
) => {
  setValue('utilities.gas', convertBooleanToString(utilities.gas));
  setValue('utilities.electric', convertBooleanToString(utilities.electric));
  setValue('utilities.water', convertBooleanToString(utilities.water));
  setValue('utilities.sewer', convertBooleanToString(utilities.sewer));
  setValue('utilities.trash', convertBooleanToString(utilities.trash));
  setValue('utilities.internet', convertBooleanToString(utilities.internet));
  setValue('utilities.tv', convertBooleanToString(utilities.tv));
  setValue('utilities.telephone', convertBooleanToString(utilities.telephone));
  setValue(
    'utilities.isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants',
    convertBooleanToString(
      utilities.isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants
    )
  );
  setValue(
    'utilities.theAverageMonthlyCostOfAnyUtilitiesNotIncluded',
    getStringValueOrEmptyString(
      utilities.theAverageMonthlyCostOfAnyUtilitiesNotIncluded
    )
  );
};

const setSecurityValues = (
  security: HousingSecurityResponse,
  setValue: UseFormSetValue<HousingFormInput>
) => {
  setValue('security.contactName', security.contactName);
  setValue('security.contactPhone', security.contactPhone);
  setValue('security.contactEmail', security.contactEmail);
  setValue('security.frequencyOfBus', security.frequencyOfBus);
  setValue('security.frequencyOfSubway', security.frequencyOfSubway);
  setValue('security.frequencyOfTrain', security.frequencyOfTrain);
  setValue('security.otherPublicTransport', security.otherPublicTransport);
  setValue(
    'security.isThereAWebsiteForPublicTransportation',
    security.isThereAWebsiteForPublicTransportation
  );
  setValue('security.howFarIsIt', security.howFarIsIt);
  setValue('security.bus', convertBooleanToString(security.bus));
  setValue('security.gated', convertBooleanToString(security.gated));
  setValue(
    'security.areThereActiveSecurityCamerasOnSite',
    convertBooleanToString(security.areThereActiveSecurityCamerasOnSite)
  );
  setValue('security.secured', convertBooleanToString(security.secured));
  setValue(
    'security.isThereARepresentativeOnSite24For7',
    convertBooleanToString(security.isThereARepresentativeOnSite24For7)
  );
  setValue('security.subway', convertBooleanToString(security.subway));
  setValue('security.train', convertBooleanToString(security.train));
  setValue(
    'security.isItConsideredSafeToWalkToTheNearestPublicTransportationStop',
    convertBooleanToString(
      security.isItConsideredSafeToWalkToTheNearestPublicTransportationStop
    )
  );
  setValue(
    'security.doesItRunToTheJobsite',
    convertBooleanToString(security.doesItRunToTheJobsite)
  );
};
