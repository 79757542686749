import { Box, Grid } from '@mui/material';
import { useCallback, useContext } from 'react';
import { Control } from 'react-hook-form';
import ConnectForm from 'components/connectForm';
import { PageSectionSubtitle, SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import CollapsibleTable from 'components/tables/collapsibleTable';
import { RowProps } from 'components/tables/collapsibleTable/interfaces/rowProps';
import { useTranslations } from 'hooks/useTranslations';
import { convertBooleanToYesOrNo } from 'utils/string/stringConverters';
import SubContent from './components/subContent';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const ArrivalInstructions = () => {
  const { arrivalInstructionsSection } = useTranslations();

  const {
    state: { arrivalInstructions, isReadOnly },
  } = useContext(EditPlacementContext);

  const rows = useCallback(
    (control: Control<EditPlacementFormInput, any>): RowProps<any>[] => {
      return arrivalInstructions.map((detail) => ({
        values: [
          detail.portOfEntryAirPort,
          convertBooleanToYesOrNo(detail.shouldContactHCBeforeArrival),
          convertBooleanToYesOrNo(detail.isPickUpProvided),
          detail.preferredArrivalDays,
          detail.preferredArrivalTimes,
          detail.reportingTo,
        ],
        subContent: <SubContent {...detail} />,
        checkBoxId: 'arrivalInstructions',
        control,
        item: {
          key: detail.id.toString(),
          value: detail.id.toString(),
        },
        name: 'arrivalInstructions',
        disabled: isReadOnly,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arrivalInstructions]
  );

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ control }) => (
        <Box>
          <SectionHeader>{arrivalInstructionsSection.title}</SectionHeader>
          <PageSectionSubtitle>
            {arrivalInstructionsSection.description}
          </PageSectionSubtitle>

          <Grid {...ContainerSpacing}>
            <Grid item xs={12}>
              <CollapsibleTable
                headers={arrivalInstructionsSection.tableHeader.map(
                  (title) => ({ title })
                )}
                rows={rows(control)}
                align='left'
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};

export default ArrivalInstructions;
