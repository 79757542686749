import { useParams } from 'react-router-dom';

import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ContainerSpacing } from 'components/page/header/utils';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import ParticipantsList from './participants';
import VisaArrivaInfo from './visaArrivalInfo';
import { useGetDefaultView } from 'hooks/useGetDefaultView';
import SwitchTileListView from 'components/switchTileListView';
import TabSwitcher from 'components/tabs/tabsSwitcher';
import TabPanel from 'components/tabs/tabPanel';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { TabRoute } from 'components/tabs/interfaces/tabRoute';

const ParticipantsVisaSwitcher = () => {
  const { tabRoute } = useParams();
  const { participants, visaArrivalInfo } = useTranslations();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const [, pageHeaderRef, pageHeaderHeightWithPaddings] = useHeightRef();
  const containerProps = {
    ...ContainerSpacing,
    height: 'unset',
    rowSpacing: 2,
  };

  const [isTileView, setIsTileView] = useGetDefaultView();
  const handleChangeView = () => {
    setIsTileView(!isTileView);
  };

  const tabs: TabRoute[] = [
    {
      label: participants.tabName,
      path: 'participants',
      component: (
        <ParticipantsList
          tileViewSwitcherProps={{
            isTileView,
            handleChangeView,
          }}
          pageHeaderHeight={pageHeaderHeightWithPaddings}
        />
      ),
    },
    {
      label: visaArrivalInfo.tabName,
      path: 'visaArrivalInfo',
      component: (
        <VisaArrivaInfo pageHeaderHeight={pageHeaderHeightWithPaddings} />
      ),
    },
  ];

  return (
    <Grid {...containerProps}>
      <Grid
        item
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
        }}
        ref={pageHeaderRef}
      >
        <TabSwitcher
          routes={tabs}
          currentRoute={ApplicationRoutes.participants}
        >
          {!isMobile && tabRoute === 'participants' ? (
            <Grid item xs={12} md={'auto'} mt={{ xs: 2, md: 0 }}>
              <SwitchTileListView
                isTileView={isTileView}
                handleChangeView={handleChangeView}
              />
            </Grid>
          ) : null}
        </TabSwitcher>
      </Grid>

      <TabPanel routes={tabs} />
    </Grid>
  );
};

export default ParticipantsVisaSwitcher;
