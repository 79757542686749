import { Button, Typography, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonProps } from './interfaces/buttonProps';

export const ButtonWithProgress = ({
  dataTestid,
  isLoading,
  text,
  onClick,
  isDisabled,
  type,
  color = 'primary',
  variant = 'contained',
}: ButtonProps) => {
  const theme = useTheme();
  const disabled = isDisabled || isLoading;
  const buttonType = type ?? 'submit';

  return (
    <Button
      data-testid={dataTestid}
      variant={variant}
      color={color}
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
      fullWidth
    >
      {isLoading ? (
        <CircularProgress
          size={25}
          sx={{
            color:
              variant === 'contained'
                ? theme.palette.common.white
                : theme.palette.primary.main,
          }}
        />
      ) : (
        <Typography>{text}</Typography>
      )}
    </Button>
  );
};

export default ButtonWithProgress;
