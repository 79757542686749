import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { CommonResponseList } from './interfaces/commons.response';
import { HostResponse } from './interfaces/host.response';
import { useFetch } from '../../useFetch';
import { TimeZoneRequest } from './interfaces/timeZone.request';

export const useCommonService = () => {
  const { get } = useFetch();
  const controllerName = 'commons';

  const getPlatformTypesOptions =
    async (): Promise<CommonResponseList> => {
      return await get<CommonResponseList>(
        `${controllerName}/platform-types-options`
      );
    };

  const getEnglishProficiencyOptions =
    async (): Promise<CommonResponseList> => {
      return await get<CommonResponseList>(
        `${controllerName}/english-proficiencies-options`
      );
    };

  const getSalaryWagePerOptions = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(
      `${controllerName}/salary-wage-per-options`
    );
  };

  const getPaymentSchedulesOptions = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(
      `${controllerName}/payment-schedules-options`
    );
  };

  const getHousingOptions = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(`${controllerName}/housing-options`);
  };

  const getGenderRequirementsOptions =
    async (): Promise<CommonResponseList> => {
      return await get<CommonResponseList>(
        `${controllerName}/gender-requirements-options`
      );
    };

  const getSectors = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(`${controllerName}/sectors-options`);
  };

  const getTimeUnitsOptions = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(`${controllerName}/time-unit-options`);
  };

  const getRentPeriodicity = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(
      `${controllerName}/rent-periodicity-options`
    );
  };

  const getHousingTypes = async (): Promise<CommonResponseList> => {
    return await get<CommonResponseList>(
      `${controllerName}/housing-types-options`
    );
  };

  const getProgramTypes = async (): Promise<KeyValueItemProps[]> => {
    return await get<KeyValueItemProps[]>(`${controllerName}/program-types`);
  };

  const getAvailableHosts = async (): Promise<HostResponse[]> => {
    return await get<HostResponse[]>(`${controllerName}/available-hosts`);
  };

  const getTimeZones = async ({
    hostId,
  }: TimeZoneRequest): Promise<KeyValueItemProps[]> => {
    return await get<KeyValueItemProps[]>(`hosts/${hostId}/time-zones`);
  };

  return {
    getEnglishProficiencyOptions,
    getSalaryWagePerOptions,
    getPaymentSchedulesOptions,
    getHousingOptions,
    getGenderRequirementsOptions,
    getSectors,
    getTimeUnitsOptions,
    getRentPeriodicity,
    getHousingTypes,
    getProgramTypes,
    getAvailableHosts,
    getTimeZones,
    getPlatformTypesOptions,
  };
};
