import { useParams } from 'react-router-dom';
import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { useHostService } from 'hooks/services/hosts';
import { ApplicantSummaryResponse } from 'hooks/services/hosts/interfaces/applicantSummary.response';
import { useHeightRef } from 'hooks/useHeightRef';
import ScheduleInterview from 'pages/interviews/scheduleInterview';
import { useScheduleInterview } from 'pages/interviews/scheduleInterview/hooks/useScheduleInterview';
import { downloadBlob } from 'utils/file/fileUtilities';
import { Nullable } from 'utils/interfaces';
import ApplicantsTable from './applicantsTable';
import { CellHeaderId } from './interfaces/applicantsCellHeader';
import { ApplicantsTableProps } from './interfaces/applicantsTableProps';
import useFilterHook from '../filters/hooks/useFilterHook';
import { FiltersProps } from '../filters/interfaces/filtersProps';

const ApplicantsTableContainer = ({
  heightOfTopComponents,
  filterProps,
  isTileView,
  tableProps: { rows, rowsTotal, handleTableChanged: handleTablePropsChanged },
  handleSetInterview,
}: {
  heightOfTopComponents: number;
  filterProps: FiltersProps;
  isTileView: Nullable<boolean>;
  tableProps: ApplicantsTableProps;
  handleSetInterview: (applicant: ApplicantSummaryResponse) => void;
}) => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);
  const { getApplicantPdf } = useHostService();

  const [, filtersRef, filtersHeightWithPaddings] = useHeightRef();
  const [paginationHeight, paginationRef] = useHeightRef();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    sortHandler,
    page,
    rowsPerPage,
    order,
    orderBy,
  } = usePaginationHook<CellHeaderId>(handleTablePropsChanged,'desc','id');
  const useFilterHookProps = useFilterHook(filterProps);

  const heightToSkip =
    heightOfTopComponents + filtersHeightWithPaddings + paginationHeight;

  const {
    activeRow,
    setActiveRow,
    handleInterviewAction
  } = useScheduleInterview<ApplicantSummaryResponse>();

  const handleOpenResumeInNewTab = async (applicantId: string) => {
    try {
      const blob = await getApplicantPdf(parsedHostId, applicantId);
      const fileName = `ApplicationResume${applicantId}.pdf`;
      downloadBlob(blob, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenVideoInNewTab = (videoUrl: string) => {
    window.open(videoUrl, '_blank');
  };

  return (
    <>
      <ApplicantsTable
        filterProps={useFilterHookProps}
        filtersRef={filtersRef}
        heightToSkip={heightToSkip}
        isTileView={isTileView}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        paginationRef={paginationRef}
        rowsPerPage={rowsPerPage}
        tableProps={{
          rows,
          rowsTotal,
          handleTableChanged: handleTablePropsChanged,
        }}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
        handleOpenResumeInNewTab={handleOpenResumeInNewTab}
        handleOpenVideoInNewTab={handleOpenVideoInNewTab}
        handleInterviewAction={handleInterviewAction}
      />
      {activeRow && (
        <ScheduleInterview
          open={true}
          onClose={() => {
            setActiveRow(null);
          }}
          applicantId={activeRow.id}
          applicantName={activeRow.name}
          applicantTimeZone={activeRow.applicantTimeZone}
          applicantTimeZoneOffset={activeRow.applicantTimeZoneOffset}
          countryOfResidence={activeRow.countryOfResidence}
          fetchData={() => handleSetInterview(activeRow)}
          isJobsFieldShow
          actionType='book'
        />
      )}
    </>
  );
};

export default ApplicantsTableContainer;
