import { Grid } from '@mui/material';
import { ArrivalInstructionResponse } from 'hooks/services/hosts/interfaces/arrivalInstruction.response';
import { useTranslations } from 'hooks/useTranslations';
import { StyledBoldedParagraph } from '../styledBoldedParagraph';

const SubContent = (detail: ArrivalInstructionResponse) => {
  const { arrivalInstructionsSection } = useTranslations();

  return (
    <Grid container>
      <Grid item xs={12}>
        <p
          style={{
            textDecoration: 'underline',
            fontSize: '14px',
          }}
        >
          {arrivalInstructionsSection.title}
        </p>
      </Grid>
      <Grid item xs={8} display={'flex'} flexDirection={'column'}>
        <Grid item xs={12} display={'flex'} flexDirection={'row'}>
          <Grid item xs={6}>
            <StyledBoldedParagraph
              text={arrivalInstructionsSection.subContentTitles.nearestAirport}
            />
            <p>{detail.nearestInternationalAirport}</p>
          </Grid>

          <Grid item xs={6}>
            <StyledBoldedParagraph
              text={
                arrivalInstructionsSection.subContentTitles.returnUponArrival
              }
            />
            <p>{detail.returnUponArrival}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'row'}>
          <Grid item xs={6}>
            <StyledBoldedParagraph
              text={
                arrivalInstructionsSection.subContentTitles.transportPickUpCost
              }
            />
            <p>{detail.transportPickUpCost}</p>
          </Grid>

          <Grid item xs={6}>
            <StyledBoldedParagraph
              text={arrivalInstructionsSection.subContentTitles.contactPhone}
            />
            <p>{detail.contactPhoneNumber}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <p
          style={{
            fontWeight: 'bold',
          }}
        >
          {arrivalInstructionsSection.subContentTitles.generalInstructions}
        </p>
        <p>{detail.generalArrivalInstructions}</p>
      </Grid>
    </Grid>
  );
};

export default SubContent;
