import { CurPrevValueHolder } from 'hooks/services/commons/interfaces/curPrevValueHolder';
import { formatDate } from 'utils/date/convertToDate';
import DisplayValue from '../components/displayValue/displayValue';

export function convertDataToString(
  data: string | boolean | number | Date,
  yes: string,
  no: string
) {
  if (typeof data === 'boolean') {
    return data ? yes : no;
  }

  if (data instanceof Date) {
    return formatDate(data);
  }

  return data.toString();
}

export function curPrevValueHolderFactory(
  data: CurPrevValueHolder<string | boolean | number | Date>
) {
  return {
    current: <DisplayValue data={data?.currValue} />,
    previous: <DisplayValue data={data?.prevValue} isPrev={true} />,
  };
}
