import { Box, Typography } from '@mui/material';
import BigSearch from 'assets/icons/bigSearch.svg';
import { useTranslations } from 'hooks/useTranslations';

export const NoFoundMsg = ({
  customMessageNoDataFound,
}: {
  customMessageNoDataFound?: string;
}) => {
  const translations = useTranslations();
  const { noDataMessageDefault } = translations.tables;
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box component='img' alt='No result' src={BigSearch}></Box>
      <Typography>
        {customMessageNoDataFound
          ? customMessageNoDataFound
          : noDataMessageDefault}
      </Typography>
    </Box>
  );
};
