import { useTheme } from '@mui/material';
import ButtonWithProgress from 'components/button';
import Error from 'components/error/error';
import { Container } from 'components/login/container/styledContainer';
import Header from 'components/login/header';
import { InputWrapper } from 'components/login/inputWrapper/styledInputWrapper';
import LoginLink from 'components/login/loginLink';
import PasswordInput from 'components/login/passwordInput';
import Wrapper from 'components/login/wrapper';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { ChangePasswordProps } from './interfaces/changePasswordProps';


const ChangePassword = ({
  onSubmit,
  register,
  formErrors,
  shouldDisplayForm,
  errors,
  isLoading,
}: ChangePasswordProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const { changePasswordPage } = useTranslations();

  return (
    <Wrapper>
      <Container item xs={4} md={8} lg={4} xl={3}>
        <Header
          title={changePasswordPage.changePasswordTitle}
          subtitle={changePasswordPage.changePasswordSubtitle}
        />

        {errors && <Error errors={errors} />}

        <form
          onSubmit={onSubmit()}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: theme.spacing(20),
          }}
        >
          {shouldDisplayForm && (
            <>
              <InputWrapper sx={{ mb: 3 }}>
                <PasswordInput
                  autoFocus={false}
                  register={register}
                  formErrors={formErrors.newPassword}
                  inputLabel={changePasswordPage.newPassword}
                  inputId={changePasswordPage.newPasswordInputId}
                />

                <PasswordInput
                  autoFocus={false}
                  register={register}
                  formErrors={formErrors.passwordConfirm}
                  inputLabel={changePasswordPage.confirmPassword}
                  inputId={changePasswordPage.passwordConfirmInputId}
                />
              </InputWrapper>

              <ButtonWithProgress
                dataTestid='change-password'
                isLoading={isLoading}
                text={changePasswordPage.changePasswordButton}
              />

              <LoginLink
                link={ApplicationRoutes.signIn}
                text={translations.returnToLogin}
              />
            </>
          )}
        </form>
      </Container>
    </Wrapper>
  );
};

export default ChangePassword;
