import { UploadDocumentBody } from '../interfaces/uploadDocument.request';

export default function convertDocumentToFormData(body: UploadDocumentBody) {
  const formData = new FormData();

  Object.entries(body).forEach(([k, v]) => {
    if (v !== undefined) {
      const value: File | string = typeof v === 'boolean' ? v.toString() : v;
      formData.append(k, value);
    }
  });

  return formData;
}
