import { FieldValues, useFormContext } from 'react-hook-form';
import { ConnectFormProps } from './interfaces/connectFormProps';

const ConnectForm = <TFieldValues extends FieldValues>({
  children,
}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  return children({ ...methods });
};

export default ConnectForm;
