import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { CellHeaderId } from './interfaces/interviewsToBookCellHeader';
import { InterviewsToBookTableProps } from './interfaces/interviewsToBookTableProps';
import { InterviewsToBookListView } from '../interviewsToBookListView';
import { InterviewToBookResponse } from 'hooks/services/hosts/interfaces/interviewsToBook.response';

const InterviewsToBookTable = ({
  heightToSkip,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  paginationRef,
  rowsPerPage,
  tableProps: { rowsTotal, rows },
  order,
  orderBy,
  sortHandler,
  handleClick,
  handleInterviewAction,
  openDeclineInterviewModal
}: {
  heightToSkip: number;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
  page: number;
  paginationRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
  tableProps: InterviewsToBookTableProps;
  order?: SortOrder;
  orderBy?: CellHeaderId;
  sortHandler: (property: CellHeaderId) => void;
  handleClick: (applicantId: number) => void;
  handleInterviewAction: (row: InterviewToBookResponse) => void;
  openDeclineInterviewModal: (row: InterviewToBookResponse) => void;
}) => {
  return (
    <ResponsiveListView
      listView={
        <InterviewsToBookListView
          {...{
            rows,
            order,
            orderBy,
            sortHandler,
            heightToSkip,
            handleClick,
            handleInterviewAction,
            openDeclineInterviewModal
          }}
        />
      }
      csv={{
        filename: 'interviews-to-book-export.csv',
        data: rows
      }}
      paginationProps={{
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        paginationRef,
        rowsPerPage,
        rowsTotal,
      }}
    />
  );
};

export default InterviewsToBookTable;
