import { Switch, useTheme } from '@mui/material';
import { SwitchDisplayProps } from './interfaces/switchDisplay.props';

export const SwitchDisplayMobile = ({
  checked,
  onChange,
  firstButton,
  secondButton,
}: SwitchDisplayProps) => {
  const {
    palette: { primary, grey },
    transitions,
  } = useTheme();

  return (
    <div data-testid='switchDisplayMobile' onClick={onChange}>
      <Switch
        disableRipple
        checked={checked}
        sx={{
          height: 38,
          width: 73,
          padding: 0,
          backgroundColor: grey[700],
          borderRadius: 100,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 0.22,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(35px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: 'transparent',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {},
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            borderRadius: '100',
            height: 32,
            width: 32,
            margin: 0.2,
            boxShadow: '0',
            zIndex: 1,
            '&:before': {
              fontSize: '12px',
              color: primary.main,
              display: 'flex',
              width: '100%',
              height: '100%',
              backgroundRepeat: 'no-repeat',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundPositionY: 'center',
              backgroundPositionX: 'left',
              marginLeft: '8px',
            },
          },
          '& .MuiSwitch-track': {
            zIndex: 1,
            borderRadius: '4px',
            backgroundColor: 'transparent',
            opacity: 1,
            display: 'flex',
            justifyContent: 'space-around',
            transition: transitions.create(['background-color'], {
              duration: 300,
            }),
            '&:before, &:after': {
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: '18px 18px',
              content: '""',
              height: '100%',
              width: '100%',
            },
            '&:before': {
              backgroundImage: `url(${firstButton.icon})`,
            },
            '&:after': {
              backgroundImage: `url(${secondButton.icon})`,
            },
          },
        }}
      />
    </div>
  );
};
