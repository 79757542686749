import { useState } from 'react';
import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { InterviewResponse } from 'hooks/services/hosts/interfaces/interviews.response';
import { useHeightRef } from 'hooks/useHeightRef';
import { useUser } from 'hooks/useUser';
import DeclineInterviewModal from 'pages/interviews/declineInterviewModal';
import { Action } from 'pages/interviews/interfaces/interviews';
import InterviewReportModal from 'pages/interviews/interviewReportModal';
import ScheduleInterview from 'pages/interviews/scheduleInterview';
import { useScheduleInterview } from 'pages/interviews/scheduleInterview/hooks/useScheduleInterview';
import { Nullable } from 'utils/interfaces';
import AllInterviewsTable from './allInterviewsTable';
import ActionsDropdown from './components/actionsDropdown';
import ActionsModal from './components/actionsModal/actionsModal';
import { useActionsModal } from './components/actionsModal/useActionsModal';
import { CellHeaderId } from '../interfaces/allInterviewsCellHeader';

const AllInterviewsTableContainer = ({
  heightOfTopComponents,
  rows,
  rowsTotal,
  handleTableChanged,
  fetchInterviews,
  handleClick,
  fetchData,
}: {
  heightOfTopComponents: number;
  rows: InterviewResponse[];
  rowsTotal: number;
  handleTableChanged: (data: PagedRequest) => Promise<void>;
  fetchInterviews: () => void;
  handleClick: (applicantId: number) => void;
  fetchData: () => void;
}) => {
  const { user } = useUser();
  const timeZone = user?.timeZone;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [interview, setInterview] = useState<Nullable<InterviewResponse>>(null);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [interviewReportOpen, setInterviewReportOpen] = useState(false);

  const {
    activeRow,
    setActiveRow,
    handleInterviewAction
  } = useScheduleInterview<InterviewResponse>();

  const [paginationHeight, paginationRef] = useHeightRef();

  const heightToSkip = heightOfTopComponents + paginationHeight;

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    sortHandler,
    page,
    rowsPerPage,
    order,
    orderBy,
  } = usePaginationHook<CellHeaderId>(handleTableChanged);

  const handleCancelAction = (interview: InterviewResponse, action: Action) => {
    setInterview(interview);
    handleOpenModal(action);
  };

  const handleOpenActionsDropdown = (
    event: React.MouseEvent<HTMLElement>,
    interview: InterviewResponse
  ) => {
    setAnchorEl(event.currentTarget);
    setInterview(interview);
  };

  const handleCloseActionsDropdown = () => {
    setAnchorEl(null);
    setInterview(null);
  };

  const openInterviewReportModal = () => {
    setInterviewReportOpen(true);
  };

  const openDeclineInterviewModal = () => {
    setDeclineOpen(true);
  };

  const {
    selectedAction,
    setSelectedAction,
    modalOpen,
    setModalOpen,
    handleOpenModal,
  } = useActionsModal(openInterviewReportModal, openDeclineInterviewModal);

  return (
    <>
      <AllInterviewsTable
        {...{
          heightToSkip,
          handleChangePage,
          handleChangeRowsPerPage,
          page,
          paginationRef,
          rowsPerPage,
          rows,
          rowsTotal,
          order,
          orderBy,
          sortHandler,
          handleInterviewAction,
          handleOpenActionsDropdown,
          handleClick,
          handleCancelAction,
          timeZone,
        }}
      />
      {open && interview && (
        <ActionsDropdown
          {...{
            interview,
            anchorEl,
            open,
            handleCloseActionsDropdown,
            fetchInterviews,
            openInterviewReportModal,
            openDeclineInterviewModal,
          }}
        />
      )}

      <ActionsModal
        {...{
          interview,
          fetchInterviews,
          handleCloseActionsDropdown,
          selectedAction,
          setSelectedAction,
          modalOpen,
          setModalOpen,
        }}
      />

      {interview && (
        <DeclineInterviewModal
          modalOpen={declineOpen}
          setModalOpen={setDeclineOpen}
          actionAfterClose={() => {
            handleCloseActionsDropdown();
          }}
          entity='interviews'
          participantName={interview.applicantName}
          jobPosition={interview.position}
          interviewId={interview.id}
          fetchData={fetchData}
        />
      )}

      {interview && (
        <InterviewReportModal
          modalOpen={interviewReportOpen}
          setModalOpen={setInterviewReportOpen}
          actionAfterClose={() => {
            handleCloseActionsDropdown();
          }}
          interviewId={interview.id}
        />
      )}

      {activeRow && (
        <ScheduleInterview
          open={true}
          onClose={() => {
            setActiveRow(null);
          }}
          applicantId={activeRow.applicantId}
          applicantTimeZone={activeRow.applicantTimeZone}
          applicantTimeZoneOffset={activeRow.applicantTimeZoneOffset}
          interviewId={activeRow.id}
          applicantName={activeRow.applicantName}
          countryOfResidence={activeRow.countryOfResidence}
          fetchData={fetchData}
          actionType='change'
        />
      )}
    </>
  );
};

export default AllInterviewsTableContainer;
