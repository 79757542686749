import { MenuItem, Select, useTheme } from '@mui/material';
import { Controller, Path, FieldValues } from 'react-hook-form';
import { DropdownInputProps } from './interfaces/dropdownInputProps';
import InputWrapper from '../inputWrapper';

export default function DropdownInput<TFieldValues extends FieldValues>({
  errorMessage,
  inputLabel,
  labelHeight,
  items,
  inputId,
  control,
  disabled,
  register,
  sxSelect,
}: DropdownInputProps<TFieldValues>) {
  const theme = useTheme();
  const { name } = register;

  return (
    <InputWrapper
      inputId={inputId}
      inputLabel={inputLabel}
      labelHeight={labelHeight}
      errorMessage={errorMessage}
    >
      <Controller
        name={name as Path<TFieldValues>}
        control={control}
        render={({ field }) => (
          <Select
            defaultValue={''}
            id={inputId}
            inputProps={{ 'data-testid': inputId }}
            displayEmpty
            fullWidth
            variant='standard'
            disabled={disabled}
            {...field}
            sx={{
              height: 52,
              boxShadow: 3,
              'input': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& .MuiSelect-select': {
                padding: 2,
                fontWeight: 400,
                fontSize: theme.spacing(2),
                height: '100% !important',
                boxSizing: 'border-box',
              },
              '&:before': {
                borderBottom: 'none',
              },
              ...sxSelect,
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: '30%',
                  mt: theme.spacing(0.4),
                  '& .MuiMenuItem-root': {
                    height: 52,
                    padding: 2,
                    fontWeight: 400,
                    fontSize: theme.spacing(2),
                    lineHeight: theme.spacing(2),
                    ':hover': {
                      backgroundColor: theme.box.borderColor,
                    },
                  },
                },
              },
            }}
            {...register}
            error={!!errorMessage}
          >
            {items &&
              items.map((i) => (
                <MenuItem
                  key={`${inputId}${i.key}`}
                  value={i.key}
                  disabled={i.disabled}
                >
                  {i.value}
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </InputWrapper>
  );
}
