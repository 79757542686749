import ResponsiveFiltersBar from 'components/responsiveListView/components/responsiveFiltersBar';
import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import ResponsiveTable from 'components/responsiveListView/components/responsiveTable';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import DesktopTable from './components/desktopTable';
import MobileTable from './components/mobileTable';
import { CellHeaderId } from './interfaces/placementsCellHeader';
import { PlacementsTableProps } from './interfaces/placementsTableProps';
import { FiltersFormProps } from '../filters/interfaces/filtersFormProps';
import DesktopFilters from '../filters/placementsDesktopFilters';
import PlacementsMobileFilters from '../filters/placementsMobileFilters';

const PlacementsTable = ({
  heightToSkip,
  filtersRef,
  paginationRef,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  orderBy,
  order,
  sortHandler,
  tableProps: { handlePlacementAction, rows, rowsTotal },
  filterProps,
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  heightToSkip: number;
  filtersRef: (node: HTMLDivElement) => void;
  tableProps: PlacementsTableProps;
  filterProps: FiltersFormProps;
  paginationRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
  page: number;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
}) => {
  return (
    <ResponsiveListView
      filters={
        <ResponsiveFiltersBar
          filtersBarRef={filtersRef}
          desktopView={<DesktopFilters {...filterProps} />}
          mobileView={<PlacementsMobileFilters filterProps={filterProps} />}
        />
      }
      listView={
        <ResponsiveTable
          heightToSkip={heightToSkip}
          desktopTable={
            <DesktopTable
              rows={rows}
              order={order}
              orderBy={orderBy}
              sortHandler={sortHandler}
              handlePlacementAction={handlePlacementAction}
            />
          }
          mobileTable={
            <MobileTable
              rows={rows}
              handlePlacementAction={handlePlacementAction}
            />
          }
        />
      }
      paginationProps={{
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        paginationRef,
        rowsPerPage,
        rowsTotal,
      }}
    />
  );
};

export default PlacementsTable;
