import { useCallback, useEffect, useState } from 'react';
import { useCommonService } from 'hooks/services/commons';
import { useHostService } from 'hooks/services/hosts';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';

const useProgramWithTypeHook = ({
  program,
  resetProgramTypes,
  hostId,
}: {
  program: string;
  resetProgramTypes: () => void;
  hostId: number;
}) => {
  const { getPrograms } = useHostService();
  const { getProgramTypes } = useCommonService();
  const [availablePrograms, setAvailablePrograms] = useState(
    [] as KeyValueItemProps[]
  );
  const [programCategoryMap, setProgramCategoryMap] =
    useState<Map<string, string>>();
  const [availableProgramTypes, setAvailableProgramTypes] = useState(
    [] as KeyValueItemProps[]
  );
  const [allProgramTypes, setAllProgramTypes] = useState(
    [] as KeyValueItemProps[]
  );

  const validateSelectedProgram = (value: string) =>
    programCategoryMap?.get(value) === 'cat_itp'; // check that program category is intern || trainee

  const fetchPrograms = useCallback(async () => {
    const programsResponse = await getPrograms({ hostId });
    const progIdToCategoryMap = new Map<string, string>();

    const mapped: KeyValueItemProps[] = [];

    programsResponse.forEach((p) => {
      const item = {
        key: p.id.toString(),
        value: p.name,
      };

      mapped.push(item);
      progIdToCategoryMap.set(item.key, p.categoryId);
    });

    setAvailablePrograms(mapped);
    setProgramCategoryMap(progIdToCategoryMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProgramTypes = useCallback(async () => {
    const programTypesResponse = await getProgramTypes();

    setAllProgramTypes(programTypesResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newProgramTypes = validateSelectedProgram(program)
      ? allProgramTypes
      : [];
    setAvailableProgramTypes(newProgramTypes);
    resetProgramTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  useEffect(() => {
    Promise.all([fetchPrograms(), fetchProgramTypes()]).catch((error) =>
      console.error(error)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { availablePrograms, availableProgramTypes, validateSelectedProgram };
};

export default useProgramWithTypeHook;
