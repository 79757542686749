import { useState } from 'react';

export const useCollapse = () => {
  const [isOpenCollapse, setIsOpenCollapse] = useState<string | null>(null);

  const handleClick = (text: string | null) => {
    if (isOpenCollapse === text) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(text);
    }
  };

  return {
    handleClick,
    isOpenCollapse,
  };
};
