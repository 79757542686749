import { Button, Grid, useTheme } from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import { ButtonsContainer, ContainerSpacing, PrimaryButtonResponsiveMargin } from 'components/page/header/utils';
import Stepper from 'components/stepper';
import { useTranslations } from 'hooks/useTranslations';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import SectionContainerStyle from 'utils/styles/sectionContainerStyle/sectionContainerStyle';
import { EditPlacementProps } from './interfaces/editPlacementProps';

const ButtonResponsiveProps = {
  minWidth: { md: 150, xs: 'calc(30% - 8px)' },
};

const EditPlacement = ({
  onSubmit,
  handleCancelBtn,
  pageHeaderRef,
  buttonsRef,
  stepperRef,
  matchDownMd,
  general,
  sections,
  sectionComponents,
  pageTitle,
  primaryButton,
  secondaryButton,
}: EditPlacementProps) => {
  const theme = useTheme();
  const translations = useTranslations();

  return (
    <form
      onSubmit={(e) => onSubmit(e)}
      style={{ height: '100%', overflow: 'hidden' }}
    >
      <Grid {...ContainerSpacing}>
        <Grid item xs={12} lg={4} order={1} pb={{ lg: 4 }} ref={pageHeaderRef}>
          <PageHeader>{pageTitle}</PageHeader>
        </Grid>

        <Grid
          item
          xs={12}
          lg={8}
          order={{ xs: 3, lg: 2 }}
          pt={{ xs: 0 }}
          ref={buttonsRef}
          pb={4}
        >
          <Grid {...ButtonsContainer}>
            <Button
              variant='outlined'
              sx={{ ...ButtonResponsiveProps }}
              onClick={handleCancelBtn}
            >
              {translations.cancelButton}
            </Button>

            {secondaryButton && (
              <Grid
                sx={{
                  ...ButtonResponsiveProps,
                  ...PrimaryButtonResponsiveMargin,
                }}
              >
                {secondaryButton}
              </Grid>
            )}

            <Grid
              sx={{
                ...ButtonResponsiveProps,
                ...PrimaryButtonResponsiveMargin,
              }}
            >
              {primaryButton}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          lg={2}
          order={{ xs: 2, lg: 3 }}
          ref={stepperRef}
          sx={{
            height: { lg: `calc(100% - ${theme.spacing(4)} - 15px)` },
            overflowY: { lg: 'auto' },
            paddingRight: theme.spacing(2),
            '&.MuiGrid-item': {
              paddingTop: 0,
            },
            ...NoScrollBar,
          }}
        >
          <Stepper steps={sections} breakPoint='lg' />
        </Grid>

        <Grid
          item
          order={4}
          xs={12}
          md={10}
          px={0.3}
          sx={{
            overflowY: 'hidden',
            height: matchDownMd
              ? `calc(100% - ${general}px)`
              : `calc(100% - ${theme.spacing(9)})`,
            '&.MuiGrid-item': {
              paddingTop: '0 ',
            },
          }}
        >
          <Grid id='containerElement' sx={{ ...SectionContainerStyle }}>
            {sectionComponents}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditPlacement;
