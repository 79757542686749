import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { OfferDetailsFormInput } from '../../interfaces/offerDetailsForm.formInput';

export const SocialSecurityOfferDetails = ({
  isDisabled,
  matchMdAboveForSecurity,
}: {
  isDisabled: boolean;
  matchMdAboveForSecurity: boolean;
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  const {
    inputStyle,
    halfToFullPageInput,
    inputsGridFullWidth,
    inputsSection,
  } = useFormInputsStyle(theme);

  const {
    title,
    nearest_social_security_office,
    distance_of_sso_from_soa,
    host_entity_provide_transportation,
    host_entity_help,
    other_social_security_assistance,
  } = translations.offerDetailsForm.sections.socialSecurity;

  return (
    <ConnectForm<OfferDetailsFormInput>>
      {({ register, control, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'addressOfNearestSocialSecurityOffice'}
                  inputLabel={nearest_social_security_office}
                  register={register(
                    'socialSecurity.addressOfNearestSocialSecurityOffice'
                  )}
                  errorMessage={
                    errors?.socialSecurity?.addressOfNearestSocialSecurityOffice
                      ?.message
                  }
                  maxLength={250}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'distanceOfSSOFromSOA'}
                  inputLabel={distance_of_sso_from_soa}
                  numberOfRows={1}
                  register={register('socialSecurity.distanceOfSSOFromSOA')}
                  errorMessage={
                    errors?.socialSecurity?.distanceOfSSOFromSOA?.message
                  }
                  singleLine
                  maxLength={100}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(6, 3.5)}>
                <DropdownYesNo
                  inputId={
                    'willHostEntityProvideTransportationToTheNearestSocialSecurityOffice'
                  }
                  inputLabel={host_entity_provide_transportation}
                  labelHeight={matchMdAboveForSecurity ? 46 : 70}
                  errorMessage={
                    errors?.socialSecurity
                      ?.willHostEntityProvideTransportationToTheNearestSocialSecurityOffice
                      ?.message
                  }
                  control={control}
                  register={register(
                    'socialSecurity.willHostEntityProvideTransportationToTheNearestSocialSecurityOffice'
                  )}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid {...inputStyle(6, 3.5)}>
                <DropdownYesNo
                  inputId={'willHostEntityHelpEVMakeCopiesOfNecessaryDocuments'}
                  inputLabel={host_entity_help}
                  labelHeight={matchMdAboveForSecurity ? 46 : 70}
                  errorMessage={
                    errors?.socialSecurity
                      ?.willHostEntityHelpEVMakeCopiesOfNecessaryDocuments
                      ?.message
                  }
                  control={control}
                  register={register(
                    'socialSecurity.willHostEntityHelpEVMakeCopiesOfNecessaryDocuments'
                  )}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextAreaInput
                  inputId={'otherSocialSecurityAssistanceProvided'}
                  inputLabel={other_social_security_assistance}
                  register={register(
                    'socialSecurity.otherSocialSecurityAssistanceProvided'
                  )}
                  errorMessage={
                    errors?.socialSecurity
                      ?.otherSocialSecurityAssistanceProvided?.message
                  }
                  maxLength={500}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
