import { useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import DropdownMultiselectInput, {
  selectAllValue,
} from './dropdownMultiselectInput';
import { DropdownInputProps } from './interfaces/dropdownMultipleInputProps';

export default function DropdownMultiselectInputContainer<
  TFieldValues extends FieldValues
>(props: DropdownInputProps<TFieldValues>) {
  const defaultValue = useController({
    control: props.control,
    name: props.name,
  })?.field?.value;
  const [selected, setSelected] = useState<string[]>(defaultValue ?? []);
  const itemsLength = props.items.length;
  const isAllSelected = itemsLength > 0 && selected.length === itemsLength;
  const keysArray = props.items.map((i) => i.key);
  const handleChange = (e: { target: { value: string[] } }) => {
    const value = e.target.value;

    if (value && Array.isArray(value)) {
      if (value[value.length - 1] === selectAllValue) {
        const newValues = selected.length === itemsLength ? [] : keysArray;
        setSelected(newValues);
        e.target.value = newValues;

        return;
      }
      setSelected(value);
    }
  };

  return (
    <DropdownMultiselectInput
      {...props}
      onChange={handleChange}
      isAllSelected={isAllSelected}
      selected={selected}
      disabled={props.disabled}
    />
  );
}
