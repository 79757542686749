import * as yup from 'yup';
import {
  transformStringToNumber,
} from 'utils/extensions/numberInputTransformer';
import validateMinNumber from 'utils/extensions/validateMinNumber';
import { convertStringToBoolean } from 'utils/string/stringConverters';
import { WagePerEnum } from '../interfaces/wagePer';

const wagePerOptions: string[] = Object.values(WagePerEnum);

export const schema = yup.object().shape(
  {
    grossWage: yup
      .number()
      .required()
      .transform(transformStringToNumber)
      .positive()
      .min(0.1)
      .label('Guaranteed salary/wage before deduction'),
    wagePer: yup
      .string()
      .notRequired()
      .oneOf(wagePerOptions)
      .label('Guaranteed salary/wage per'),
    paymentSchedule: yup.string().required().label('Payment schedule'),
    allowancesBonusesIncentives: yup
      .string()
      .when('allowancesBonusesIncentives', {
        is: (value?: string) => !!value,
        then: (rule) => rule.min(3).max(1000),
      })
      .label('Allowances, bonuses, incentives'),
    additionalInformation: yup
      .string()
      .when('additionalInformation', {
        is: (value?: string) => !!value,
        then: (rule) => rule.min(3).max(1000),
      })
      .label('Additional information'),
    isOvertimeAvailable: yup
      .boolean()
      .transform(convertStringToBoolean)
      .label('Is overtime available'),
    overtimeWage: yup
      .string()
      .test('is-number-less-than-0', 'Value must be more than 0', (value) =>
        validateMinNumber(value, 0.01)
      )
      .label('Overtime wage'),
  },
  [
    ['allowancesBonusesIncentives', 'allowancesBonusesIncentives'],
    ['additionalInformation', 'additionalInformation'],
  ]
);
