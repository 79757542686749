import { convertToNullForValidation } from 'utils/string/stringConverters';
import * as yup from 'yup';

const types = ['Primary', 'Secondary'];

export const schema = yup.object().shape({
  name: yup.string().required().max(150).label('Name'),
  type: yup.string().required().oneOf(types).label('Type'),
  email: yup.string().email().max(50).required().label('Email'),
  directPhone: yup
    .string()
    .label('Direct Phone')
    .when('type', (type: string, schema: yup.StringSchema) => {
      if (type === 'Primary') {
        return schema.required();
      }
      return schema.nullable();
    }),
  mobilePhone: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .label('Mobile Phone'),
  department: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(100)
    .label('Department'),
  title: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(50)
    .label('Title'),
  fax: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .label('Fax'),
  skype: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .label('Skype'),
});
