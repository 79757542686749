import { Box, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import ConnectForm from 'components/connectForm/connectForm';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import SingleCheckbox from 'components/tables/collapsibleTable/components/singleCheckBox';
import { ColumnsProps } from 'components/tables/components/header/interfaces/headerProps';
import SimpleTable from 'components/tables/simpleTable';
import { CellType } from 'components/tables/simpleTable/interfaces/cellType';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import translations from 'utils/translations';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const Housing = () => {
  const theme = useTheme();

  const { defaultInput, smallInput, inputsSection } = useFormInputsStyle(theme);

  const { title, housingLabel, genderLabel, housingTable } =
    translations.housing;

  const {
    state: { housingTypes, genders, housings, isReadOnly },
  } = useContext(EditPlacementContext);

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ register, control, formState: { errors } }) => {
        const headers: ColumnsProps = [
          {
            title: '',
            width: '10px',
          },
          {
            title: housingTable.name,
          },
          {
            title: housingTable.address,
          },
          {
            title: housingTable.city,
          },
          {
            title: housingTable.zip,
          },
        ];

        const rows: { values: CellType[] }[] = housings.map((house, index) => {
          const name = house.name,
            address = house.address,
            city = house.city,
            zip = house.city,
            checkbox = (
              <SingleCheckbox
                control={control}
                item={{
                  key: house.id.toString(),
                  value: house.id.toString(),
                }}
                name='housing.selectedHousingsIds'
                hideLabel={true}
                disabled={isReadOnly}
              />
            );

          return { values: [checkbox, name, address, city, zip] };
        });

        return (
          <Box>
            <SectionHeader>{title}</SectionHeader>

            <Grid {...ContainerSpacing}>
              <Grid {...inputsSection}>
                <Grid {...defaultInput}>
                  <DropdownInput
                    inputId={'housingType'}
                    inputLabel={housingLabel}
                    items={housingTypes}
                    errorMessage={errors?.housing?.housingType?.message}
                    register={register('housing.housingType')}
                    disabled={isReadOnly}
                    control={control}
                  />
                </Grid>

                <Grid {...smallInput}>
                  <DropdownInput
                    inputId={'genderRequirement'}
                    inputLabel={genderLabel}
                    items={genders}
                    errorMessage={errors?.housing?.genderRequirement?.message}
                    register={register('housing.genderRequirement')}
                    disabled={isReadOnly}
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid {...inputsSection}>
                <SimpleTable headers={headers} rows={rows} align='left' />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </ConnectForm>
  );
};

export default Housing;
