import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../context/auth.context';
import useLocalStorage from 'hooks/useLocalStorage';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage('accessToken', '');
  const [, setRefreshToken] = useLocalStorage('refreshToken', '');
  const [userEmail, setUserEmail] = useLocalStorage('email', '');
  const [isRememberMe, setIsRememberMe] = useLocalStorage('rememberMe', '');
  const [, setHostId] = useLocalStorage('hostId', '');
  const [isLoggedIn, setIsLoggedIn] = useState(!!accessToken);
  const navigate = useNavigate();

  const login = (
    accessToken: string,
    refreshToken: string,
    rememberMe?: boolean,
    email?: string
  ) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setIsLoggedIn(true);

    if (rememberMe && email) {
      setUserEmail(email);
      setIsRememberMe('true');
    } else if (!rememberMe && isRememberMe && userEmail) {
      setUserEmail('');
      setIsRememberMe('');
    }
  };

  const logout = () => {
    setAccessToken('');
    setRefreshToken('');
    setHostId('');
    setIsLoggedIn(false);
    navigate(ApplicationRoutes.signIn, { replace: true });
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        login,
        logout,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
