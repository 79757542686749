import { Box, Button, IconButton, Grid } from '@mui/material';
import Cross from 'assets/icons/cross.svg';
import ButtonWithProgress from 'components/button/buttonWithProgress';
import MobileBottomDrawer from 'components/responsiveListView/components/mobileBottomDrawer';
import MobileFiltersBar from 'components/responsiveListView/components/mobileFilterView';
import useMobileFiltersHook from 'components/responsiveListView/hooks/useMobileFiltersHook';
import { useTranslations } from 'hooks/useTranslations';
import {
  LanguageLevel,
  Programs,
  AvailableFrom,
  AvailableTo,
  Skills,
  AvailableAtShortNotice,
  ProgramTypes,
  PlacementCategories,
  ResidenceCountries,
} from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const ApplicantsMobileFilters = ({
  onSubmit,
  control,
  register,
  getValues,
  programs,
  englishProficiencies,
  skills,
  placementCategories,
  programTypes,
  residenceCountries,
  isDirty,
  clearFilters,
  isITPProgram,
}: FiltersFormProps) => {
  const translations = useTranslations();
  const { open, setOpen } = useMobileFiltersHook();

  const handleClose = () => {
    setOpen(false);
  };
  const onClearFilters = () => {
    clearFilters();
    handleClose();
  };

  return (
    <>
      <MobileBottomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        header={
          <Box display='flex' justifyContent='flex-end' m={1}>
            <IconButton onClick={() => handleClose()}>
              <Box component='img' alt='Close' src={Cross} />
            </IconButton>
          </Box>
        }
        footer={
          <Box
            display='flex'
            justifyContent='space-between'
            p={2}
            sx={{ '> *': { flex: '0 0 48%' } }}
          >
            <Button variant='outlined' onClick={() => onClearFilters()}>
              {translations.applicants.actions.clearFilters}
            </Button>
            <ButtonWithProgress
              dataTestid='submit'
              text={translations.applicants.actions.applyFilters}
              type='submit'
              onClick={() => handleClose()}
            />
          </Box>
        }
      >
        <form onSubmit={onSubmit}>
          <Box
            display='flex'
            flexDirection='column'
            p={2}
            sx={{ '> *:not(:last-child)': { mb: 2 } }}
          >
            <Box>
              <Programs control={control} programs={programs} />
            </Box>

            <Box>
              <LanguageLevel
                register={register}
                control={control}
                englishProficiencies={englishProficiencies}
              />
            </Box>

            <Box>
              <Skills control={control} skills={skills} />
            </Box>

            <Grid container gap={1} justifyContent={'space-between'}>
              <Grid item xs={5.5}>
                <AvailableFrom getValues={getValues} control={control} />
              </Grid>
              <Grid item xs={5.5}>
                <AvailableTo getValues={getValues} control={control} />
              </Grid>
            </Grid>

            <Box sx={{ mt: 2.5, flex: '0 1 auto' }}>
              <AvailableAtShortNotice control={control} />
            </Box>

            <Box>
              <ProgramTypes
                control={control}
                programTypes={programTypes}
                disabled={!isITPProgram}
              />
            </Box>
            <Box>
              <PlacementCategories
                control={control}
                placementCategories={placementCategories}
                disabled={!isITPProgram}
              />
            </Box>
            <Box>
              <ResidenceCountries
                control={control}
                residenceCountries={residenceCountries}
                disabled={!isITPProgram}
              />
            </Box>
          </Box>
        </form>
      </MobileBottomDrawer>

      <MobileFiltersBar setOpen={setOpen} />
    </>
  );
};

export default ApplicantsMobileFilters;
