import { Box, Typography } from '@mui/material';

import BigSearch from 'assets/icons/bigSearch.svg';
import { useTranslations } from 'hooks/useTranslations';

const EmptyTable = () => {
  const translations = useTranslations();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box component='img' alt='No result' src={BigSearch}></Box>
      <Typography>
        {translations.placementsList.placementTable.emptyResult}
      </Typography>
    </Box>
  );
};

export default EmptyTable;
