import { Box, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { ReactElement } from 'react';
import PaginationBar from './paginationBar';
import { CSVLink } from 'react-csv';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadPlacement.svg';

const ResponsiveListView = ({
  listView,
  filters = undefined,
  csv = undefined,
  paginationProps = undefined,
}: {
  listView: ReactElement;
  csv?: {
    filename: string;
    data: Array<Object>;
  },
  filters?: ReactElement;
  paginationProps?: {
    paginationRef: (node: HTMLDivElement) => void;
    rowsPerPage: number;
    page: number;
    rowsTotal: number;
    handleChangePage: (newPage: number) => void;
    handleChangeRowsPerPage: (pageSize: number) => void;
  };
}) => {
  const CSVButton = styled(CSVLink)({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '6px',
    padding: '10px',
    position: 'absolute',
    bottom: '5px',
    left: '0',
    'z-index': '10',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '9.6px',
    }
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <Box sx={{ position: 'relative' }}>
      {filters !== undefined && filters}

      {csv !== undefined && csv.data !== undefined && !isMobile && (
        <CSVButton {...csv} target="_blank" title="Download the visible table data as a CSV" aria-label="Download the visible table data as a CSV">
          <DownloadIcon />
          <span>Download as CSV</span>
        </CSVButton>
      )}

      {listView}

      {paginationProps !== undefined &&
        paginationProps?.rowsTotal !== undefined && (
          <PaginationBar
            handleChangePage={paginationProps.handleChangePage}
            handleChangeRowsPerPage={paginationProps.handleChangeRowsPerPage}
            page={paginationProps.page}
            paginationRef={paginationProps.paginationRef}
            rowsPerPage={paginationProps.rowsPerPage}
            rowsTotal={paginationProps.rowsTotal}
          />
        )}
    </Box>
  );
};

export default ResponsiveListView;
