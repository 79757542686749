import { useState } from 'react';
import { Grid } from '@mui/material';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';

import {
  ContainerSpacing,
  PrimaryButtonResponsiveMargin,
} from 'components/page/header/utils';
import ButtonWithProgress from 'components/button/buttonWithProgress';

import AllInterviews from './allInterviews';
import InterviewsToBook from './interviewsToBook';
import SetAvailabilityModal from './setAvailabilityModal';
import TabSwitcher from 'components/tabs/tabsSwitcher';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import TabPanel from 'components/tabs/tabPanel';
import { TabRoute } from 'components/tabs/interfaces/tabRoute';
import ParticipantModal from 'components/participantModal/participantModal';
import useParticipantModal from 'components/participantModal/hooks/useParticipantModal';

const InterviewsSwitcher = () => {
  const { allInterviews, interviewsToBook, availability } = useTranslations();

  const [, pageHeaderRef, pageHeaderHeightWithPaddings] = useHeightRef();
  const margin = 48;
  const heightToSkip = pageHeaderHeightWithPaddings + margin;
  const containerProps = {
    ...ContainerSpacing,
    height: 'unset',
    rowSpacing: 2,
  };

  const { isOpen, handleCloseModal, activeItem, handleOpenModal } =
    useParticipantModal();

  const [showModal, setShowModal] = useState(false);

  const tabs: TabRoute[] = [
    {
      label: interviewsToBook.tabName,
      path: 'toBook',
      component: (
        <InterviewsToBook
          pageHeaderHeight={heightToSkip}
          handleClick={handleOpenModal}
        />
      ),
    },
    {
      label: allInterviews.tabName,
      path: 'all',
      component: (
        <AllInterviews
          pageHeaderHeight={heightToSkip}
          handleClick={handleOpenModal}
        />
      ),
    },
  ];

  return (
    <Grid {...containerProps}>
      <Grid
        item
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
        }}
        ref={pageHeaderRef}
      >
        <TabSwitcher routes={tabs} currentRoute={ApplicationRoutes.interviews}>
          <Grid
            sx={{
              width: { xs: '100%', md: 'unset' },
              ...PrimaryButtonResponsiveMargin,
            }}
          >
            <ButtonWithProgress
              dataTestid='button'
              text={availability.buttonLabel}
              type='button'
              onClick={() => {
                setShowModal(true);
              }}
            />
          </Grid>
        </TabSwitcher>
      </Grid>

      <TabPanel routes={tabs} />

      <SetAvailabilityModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
      <ParticipantModal
        open={isOpen}
        onClose={handleCloseModal}
        item={activeItem}
      />
    </Grid>
  );
};

export default InterviewsSwitcher;
