import { useCallback, useState } from 'react';
import { getBoundingClientRectWithPadding } from 'utils/styles/getBoundingClientRectWithPadding';

export function useHeightRef(): [
  number,
  (node: HTMLDivElement) => void,
  number
] {
  const [height, setHeight] = useState(0);
  const [heightWithPaddings, setHeightWithPaddings] = useState(0);
  const heightRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setHeightWithPaddings(getBoundingClientRectWithPadding(node).height);
    }
  }, []);

  return [height, heightRef, heightWithPaddings];
}
