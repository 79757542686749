import { convertToDate } from '../../utils/date/convertToDate';
import { ValidationRangeResult } from './interfaces/validationRangeResult';

const changeEndDateToAfterStartDate = (
  startDate: Date | null,
  endDate: Date | null
): Date | null => {
  const isNotNull = startDate && endDate;
  if (isNotNull && endDate.getTime() < startDate.getTime()) {
    return startDate;
  }

  return endDate;
};

const validRange = (
  startDate: Date | null | string | undefined,
  endDate: Date | null | string | undefined,
  minDate: Date | null | string | undefined,
  maxDate: Date | null | string | undefined
): ValidationRangeResult => {
  const start = convertToDate(startDate);
  const end = convertToDate(endDate);
  const min = convertToDate(minDate);
  const max = convertToDate(maxDate);

  const startIsValid =
    start && min && max
      ? start.getTime() >= min.getTime() && start.getTime() <= max.getTime()
      : true;

  const endIsValid =
    end && min && max && start
      ? end.getTime() >= min.getTime() &&
        end.getTime() >= start.getTime() &&
        end.getTime() <= max.getTime()
      : true;

  return { startIsValid, endIsValid };
};

const isValidStartAndEndDates = (
  startDate: Date | null | string | undefined,
  endDate: Date | null | string | undefined
): boolean => {
  const start = convertToDate(startDate);
  const end = convertToDate(endDate);

  if (start && end) {
    return end.getTime() >= start.getTime();
  }

  return true;
};

const executeValidation = (
  startDate: Date | null | string | undefined,
  endDate: Date | null | string | undefined,
  minDate: Date | null | string | undefined,
  maxDate: Date | null | string | undefined,
  setValidationMessageStartDate: (message: string) => void,
  setValidationMessageEndDate: (message: string) => void
) => {
  if (!isValidStartAndEndDates(startDate, endDate)) {
    setValidationMessageStartDate('Start date must be before end date');
    setValidationMessageEndDate('End date must be after start date');
    return false;
  }

  const validationRange = validRange(startDate, endDate, minDate, maxDate);

  if (validationRange.startIsValid && validationRange.endIsValid) {
    return true;
  }

  if (!validationRange.startIsValid) {
    setValidationMessageStartDate('Start date is not in the valid range');
  }

  if (!validationRange.endIsValid) {
    setValidationMessageEndDate('End date is not in the valid range');
  }

  return false;
};

export { changeEndDateToAfterStartDate, executeValidation, validRange };
