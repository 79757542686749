import { Fragment } from 'react';

import { TableCell, TableRow } from '@mui/material';
import { NoFoundMsg } from './NoFoundMsg';

export const RowNotFound = ({
  colSpan,
  customMessageNoDataFound,
}: {
  colSpan: number;
  customMessageNoDataFound?: string;
}) => {
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={colSpan} sx={{ textAlign: 'center' }}>
          <NoFoundMsg customMessageNoDataFound={customMessageNoDataFound} />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
