import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import ResponsiveFiltersBar from 'components/responsiveListView/components/responsiveFiltersBar';
import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import ResponsiveTable from 'components/responsiveListView/components/responsiveTable';
import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import SwitchInput from 'components/switchInput';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import EmptyTable from 'pages/placements/placementsList/components/placementsTable/components/emptyTable';
import { ContainerSpacing } from 'utils/formHeader';
import { NotificationsProps } from './interfaces/notificationsProps';
import { CellHeaderId } from './notifications.container';

const Notifications = ({
  order,
  orderBy,
  sortHandler,
  rows,
  handleRowAction,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  rowsTotal,
  control,
  onSubmit,
  onlyArchived,
}: NotificationsProps) => {
  const translations = useTranslations();

  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [filtersHeight, filtersRef] = useHeightRef();
  const [paginationHeight, paginationRef] = useHeightRef();

  const heightOfTopComponents =
    pageHeaderHeight + filtersHeight + paginationHeight + 60;
  const containerProps = { ...ContainerSpacing, height: 'unset' };

  const cells: CellHeader<CellHeaderId>[] = [
    {
      id: 'createdAt',
      label: translations.notifications.columns.dateTime,
      sortable: true,
    },
    {
      id: 'title',
      label: translations.notifications.columns.title,
      sortable: true,
    },
    {
      id: 'content',
      label: translations.notifications.columns.content,
      sortable: true,
    },
    {
      id: 'createdBy',
      label: translations.notifications.columns.createdBy,
      sortable: true,
    },
  ];

  if (!onlyArchived) {
    cells.push({
      id: 'action',
      label: '',
      sortable: false,
    });
  }

  return (
    <Grid {...containerProps}>
      <Grid item xs={12} ref={pageHeaderRef}>
        <PageHeader>{translations.notifications.title}</PageHeader>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveListView
          filters={
            <ResponsiveFiltersBar
              filtersBarRef={filtersRef}
              desktopView={
                <form onSubmit={onSubmit}>
                  <Box p={1}>
                    <SwitchInput
                      control={control}
                      name='showOnlyArchived'
                      label={translations.notifications.showOnlyArchived}
                    />
                  </Box>
                </form>
              }
            />
          }
          listView={
            <ResponsiveTable
              heightToSkip={heightOfTopComponents}
              desktopTable={
                <Table stickyHeader>
                  <SortableTableHeader<CellHeaderId>
                    cells={cells}
                    order={order}
                    orderBy={orderBy}
                    sortHandler={sortHandler}
                  />
                  <TableBody>
                    {rows?.length > 0 ? (
                      rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.createdAt}</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>{row.content}</TableCell>
                          <TableCell>{row.createdBy}</TableCell>
                          {!onlyArchived && (
                            <TableCell>
                              {!row.archived && (
                                <Button
                                  variant='outlined'
                                  color='secondary'
                                  size='small'
                                  onClick={() => handleRowAction(row)}
                                >
                                  {translations.notifications.archive}
                                </Button>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align='center' colSpan={15}>
                          <EmptyTable />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              }
            />
          }
          paginationProps={{
            handleChangePage,
            handleChangeRowsPerPage,
            page,
            paginationRef,
            rowsPerPage,
            rowsTotal,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Notifications;
