import { UseFormSetValue } from 'react-hook-form';
import { ArrivalInstructionResponse } from 'hooks/services/hosts/interfaces/arrivalInstruction.response';
import { BE_DATE_FORMAT, formatDate } from 'utils/date/convertToDate';
import { convertBooleanToString } from 'utils/string/stringConverters';
import { ArrivalInstructionsFormInput } from '../interfaces/arrivalInstructionsForm.formInput';

export const setArrivalInstructionsValues = (
  value: ArrivalInstructionResponse,
  setValue: UseFormSetValue<ArrivalInstructionsFormInput>
) => {
  setValue('program', value.programId?.toString());
  setValue('description', value.description);
  setValue('nearestInternationalAirport', value.nearestInternationalAirport);
  setValue('nearestAirportToJobsite', value.nearestAirportToJobsite);
  setValue('isPickUpProvided', convertBooleanToString(value.isPickUpProvided));
  setValue(
    'latestPreferredArrivalDate',
    formatDate(value.latestPreferredArrivalDate, BE_DATE_FORMAT)
  );
  setValue('generalArrivalInstructions', value.generalArrivalInstructions);
  setValue(
    'shouldContactHCBeforeArrival',
    convertBooleanToString(value.shouldContactHCBeforeArrival)
  );
  setValue('contactPhoneNumber', value.contactPhoneNumber);
  setValue('transportPickUpCost', value.transportPickUpCost.toString());
  setValue('preferredArrivalDays', value.preferredArrivalDays);
  setValue('preferredArrivalTimes', value.preferredArrivalTimes);
  setValue('reportingTo', value.reportingTo);
};
