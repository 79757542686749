import { Grid, Typography, useTheme } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'hooks/useTranslations';
import { Nullable } from 'utils/interfaces';
import TimeSlotItem from './timeSlotItem';
import { SlotsToShow } from '../interfaces/slotsToShow';
import { isLaterThan48HoursBefore } from '../utils';

const TimeSlots = ({ slotsToShow }: { slotsToShow: Nullable<SlotsToShow> }) => {
  const {
    scheduleInterview: { chooseAtime, selectADayFirst },
  } = useTranslations();
  const theme = useTheme();

  const { control } = useFormContext();
  const value = useWatch({
    control,
    name: 'time',
  });

  return (
    <Grid item xs={12}>
      <Typography
        variant='body1'
        color={theme.palette.primary.main}
        mb={theme.spacing(1)}
      >
        {chooseAtime}
      </Typography>
      {!slotsToShow ? (
        <Grid
          container
          item
          px={theme.spacing(5)}
          sx={{
            border: '3px dotted #D9D9D9',
            borderRadius: theme.spacing(1),
            alignItems: 'center',
            textAlign: 'center',
            height: 400,
            mt: theme.spacing(3),
          }}
        >
          <Typography variant='subtitle1' color={theme.palette.grey[600]}>
            {selectADayFirst}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant='subtitle1' color={theme.palette.primary.main}>
            {slotsToShow.date}
          </Typography>
          <Grid
            container
            sx={{
              maxHeight: 400,
              overflowY: 'auto',
              p: { xs: theme.spacing(1), md: theme.spacing(1, 2, 1, 0) },
            }}
            gap={2}
          >
            {slotsToShow.slots.map((item) => (
              <TimeSlotItem
                key={item.value}
                value={item.value}
                isSelected={item.value === value}
                disabled={
                  item.disabled ||
                  isLaterThan48HoursBefore(`${slotsToShow.date} ${item.value}`)
                }
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeSlots;
