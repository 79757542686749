import { useHeightRef } from 'hooks/useHeightRef';
import { StatusProps } from 'pages/placements/placementsList/components/placementsTable/components/interfaces/statusProps';
import { HousingRow } from 'pages/programs/housing/interfaces/housingRow';
import HousingMobileDetails from './housingMobileDetails';

const HousingMobileDetailsContainer = ({
  housingDetails,
  handleClose,
}: {
  housingDetails: HousingRow;
  handleClose: () => void;
}) => {
  const [top, topRef] = useHeightRef();
  const [bottom, bottomRef] = useHeightRef();
  const height = top + bottom;

  return (
    <HousingMobileDetails
      bottomRef={bottomRef}
      handleClose={handleClose}
      height={height}
      housingDetails={housingDetails}
      topRef={topRef}
    />
  );
};

export default HousingMobileDetailsContainer;
