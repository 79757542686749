import { UseFormSetValue } from 'react-hook-form';

import { Grooming } from 'hooks/services/placements/interfaces/placement.response';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';
import {
  convertBooleanToString,
  getStringValueOrEmptyString,
} from 'utils/string/stringConverters';

export const setGroomingValues = (
  values: Grooming,
  setValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!values) {
    return;
  }

  setValue('grooming.dressCode', getStringValueOrEmptyString(values.dressCode));
  setValue(
    'grooming.uniformCost',
    getStringValueOrEmptyString(values.uniformCost)
  );
  setValue(
    'grooming.uniformCostDue',
    getStringValueOrEmptyString(values.uniformCostDue)
  );
  setValue(
    'grooming.uniformProvided',
    convertBooleanToString(values.isUniformProvided)
  );
};
