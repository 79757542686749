import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import PrimarySubmitButton from 'components/button';
import DynamicField from 'components/form/dynamic/dynamicField/dynamicField';
import { DynamicFieldProps } from 'components/form/dynamic/dynamicField/interfaces/dynamicFieldProps';
import { DynamicFormProps } from 'components/form/dynamic/dynamicField/interfaces/dynamicFormProps';
import IFrame from 'components/iFrame';
import NotAllowed from 'components/notAllowed/notAllowed';
import { DocumentDetailsResponse } from 'hooks/services/hosts/interfaces/documentDetails.response';
import { useTranslations } from 'hooks/useTranslations';
import {
  ButtonResponsiveProps,
  PrimaryButtonResponsiveMargin,
} from 'utils/formHeader';
import DragDropUploadZone from './components/dragDropUploadZone';
import { DragDropUploadZoneProps } from './components/dragDropUploadZone/dragDropUploadZoneProps';
import WithoutPreview from './components/withoutPreview';
import ScrollableContent from 'components/scrollableContent';

const UploadDocument = ({
  canUpload,
  handleCancelButton,
  documentDetails,
  documentTitle,
  dynamicFormProps,
  fields,
  onSubmit,
  handleDownload,
  dragDropProps,
  previewSrc,
  handleChangeDocument,
  isConfirmed,
  onConfirm,
  isConfirmVisible,
  hasFile,
}: {
  canUpload: boolean;
  handleDownload: () => void;
  handleCancelButton: () => void;
  documentDetails?: DocumentDetailsResponse;
  documentTitle?: string;
  dynamicFormProps: DynamicFormProps;
  fields: DynamicFieldProps[];
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  dragDropProps: DragDropUploadZoneProps;
  previewSrc?: string;
  handleChangeDocument: () => void;
  isConfirmed: boolean;
  onConfirm: () => void;
  isConfirmVisible: boolean;
  hasFile: boolean;
}) => {
  const translations = useTranslations();
  const {
    title,
    cancelBtn,
    uploadBtn,
    previousDocument,
    download,
    confirmation,
    changeDocument,
    notAllowedPage,
    notAllowed,
  } = translations.uploadDocument;

  const Buttons = (
    <>
      <Button
        variant='outlined'
        sx={{ ...ButtonResponsiveProps }}
        onClick={() => handleCancelButton()}
      >
        {cancelBtn}
      </Button>

      <Grid
        sx={{
          ...ButtonResponsiveProps,
          ...PrimaryButtonResponsiveMargin,
        }}
      >
        <PrimarySubmitButton
          dataTestid='upload'
          isLoading={false}
          text={uploadBtn}
          isDisabled={!canUpload}
        />
      </Grid>
    </>
  );

  return (
    <form onSubmit={onSubmit} style={{ height: '100%', overflow: 'hidden' }}>
      <ScrollableContent
        pageHeaderTitle={`${title} ${documentTitle}`}
        buttonsComponent={Buttons}
      >
        <Grid container item xs={12}>
          {documentDetails?.status !== 'PendingApproval' ? (
            <Grid
              item
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={{ xs: 'column', md: 'row' }}
              xs={12}
            >
              {fields?.length !== 0 && (
                <Grid item xs={12} md={4} mr={{ xs: 0, md: 3 }}>
                  <Grid container>
                    {fields.map((field) => (
                      <Grid item key={field.name} xs={12} px={0.3} mb={2}>
                        <DynamicField field={field} {...dynamicFormProps} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} md={fields.length !== 0 ? 8 : 12}>
                {hasFile ? (
                  <Box>
                    <Box sx={{ width: '100%', height: { xs: 250, md: 400 } }}>
                      {previewSrc ? (
                        <IFrame src={previewSrc} title='Document preview' />
                      ) : (
                        <WithoutPreview />
                      )}
                    </Box>
                    {isConfirmVisible && (
                      <FormControlLabel
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          mt: 3,
                        }}
                        control={
                          <Checkbox
                            sx={{ mt: -1 }}
                            checked={isConfirmed}
                            onChange={onConfirm}
                          />
                        }
                        label={confirmation}
                      />
                    )}
                    <Box display='flex' justifyContent='right' mt={3}>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => handleChangeDocument()}
                      >
                        {changeDocument}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <DragDropUploadZone {...dragDropProps} />
                )}

                {documentDetails?.status === 'Rejected' ? (
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: {
                        xs: 'column',
                        md: 'row',
                      },
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        marginBottom: {
                          xs: 3,
                          md: 0,
                        },
                      }}
                    >
                      {previousDocument}
                    </Typography>
                    <Link
                      component='button'
                      variant='body1'
                      sx={{
                        cursor: 'pointer',

                        marginBottom: {
                          xs: 3,
                          md: 0,
                        },
                        marginLeft: {
                          xs: 0,
                          md: 2,
                        },
                      }}
                      onClick={() => handleDownload()}
                    >
                      {download}
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <NotAllowed page={notAllowedPage} text={notAllowed} />
          )}
        </Grid>
      </ScrollableContent>
    </form>
  );
};

export default UploadDocument;
