import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import Cross from 'assets/icons/cross.svg';
import InputWrapper from 'components/inputWrapper/inputWrapper';
import { OptionalTooltip } from 'components/tooltip/tooltip';
import { useTranslations } from 'hooks/useTranslations';
import { FileUploadProps } from './interfaces/fileUploadProps';

const FileUpload = ({
  handleReset,
  inputKey,
  fileName,
  inputId,
  inputLabel,
  labelHeight,
  errorMessage,
  register,
}: FileUploadProps) => {
  const theme = useTheme();
  const { fileUpload } = useTranslations();

  return (
    <InputWrapper
      inputId={inputId}
      inputLabel={inputLabel}
      labelHeight={labelHeight}
      errorMessage={errorMessage}
    >
      <Box
        boxShadow={3}
        height={52}
        borderRadius='unset'
        display='flex'
        alignItems='center'
      >
        <Button
          variant='contained'
          color='secondary'
          size='small'
          sx={{
            m: theme.spacing(1, 2),

            flexShrink: 0,
          }}
          component='label'
        >
          <input
            id={register.name}
            hidden
            accept='*'
            type='file'
            key={inputKey || ''}
            {...register}
          />
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: 400,
              lineHeight: '15px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }}
          >
            {fileUpload.chooseFile}
          </Typography>
        </Button>
        <OptionalTooltip
          show={!!fileName}
          text={fileName ?? ''}
          placement='top'
        >
          <Typography
            sx={{
              ml: theme.spacing(1),
              mr: 'auto',
              fontSize: '15px',
              fontWeight: 400,
              lineHeight: '15px',
              textTransform: 'none',
              overflow: ' hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              wordWrap: '',
            }}
          >
            {fileName}
          </Typography>
        </OptionalTooltip>

        <IconButton sx={{ mr: 2 }} onClick={handleReset}>
          <Box component='img' alt='' src={Cross} />
        </IconButton>
      </Box>
    </InputWrapper>
  );
};

export default FileUpload;
