import { EmploymentFormInputs } from '../../sections/employment/interfaces/employmentFormInputs';

export function getEmptyEmploymentValues(): EmploymentFormInputs {
  return {
    program: '',
    programTypes: [],
    totalPositions: '',
    selfArranged: '',
    positionTitle: '',
    supervisor: '',
    department: '',
    englishLevel: '',
    startDate: null,
    latestStartDate: null,
    endDate: null,
    latestEndDate: null,
    positionDescription: '',
    scheduleDescription: '',
    requiredSkills: '',
    requiredExperience: '',
    specialRequirements: '',
  };
}
