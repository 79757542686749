import React, { JSXElementConstructor, ReactElement } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Tab, Tabs } from '@mui/material';
import { TabRoute } from './interfaces/tabRoute';

interface TabSwitcherProps {
  routes: TabRoute[];
  currentRoute: string;
  children?:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | null
    | undefined;
  onChange?: (event: React.SyntheticEvent, newPath: string) => void;
}

const TabSwitcher: React.FC<TabSwitcherProps> = ({
  routes,
  currentRoute,
  children,
  onChange,
}) => {
  const { hostId, tabRoute } = useParams<{
    hostId: string;
    tabRoute: string;
  }>();

  const handleChange = (event: React.SyntheticEvent, newPath: string) => {
    onChange && onChange(event, newPath);
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
    >
      <Tabs value={tabRoute} onChange={handleChange}>
        {routes.map((route) => {
          const path = currentRoute
            .replace(':hostId', hostId || '')
            .replace(':tabRoute', route.path);

          return (
            <Tab
              key={route.path}
              value={route.path}
              label={route.label}
              component={Link}
              to={path}
              sx={{
                '&.MuiButtonBase-root': {
                  width: { xs: '50%', md: 'unset' },
                },
              }}
            />
          );
        })}
      </Tabs>
      {children && children}
    </Grid>
  );
};

export default TabSwitcher;
