import { Box, Grid, IconButton } from '@mui/material';
import ClearFilters from 'assets/icons/clearFilters.svg';
import ClearFiltersDisabled from 'assets/icons/clearFiltersDisabled.svg';
import {
  Programs,
  Host,
  Position,
  Country,
  StartDate,
  EndDate,
  Statuses,
  ApplicantIdSearch,
  FirstNameSearch,
  LastNameSearch,
  EmailSearch,
} from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const ParticipantsDesktopFilters = ({
  onSubmit,
  control,
  getValues,
  programs,
  hosts,
  positions,
  statuses,
  countries,
  clearFilters,
  register,
  isDirty,
}: FiltersFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid
        container
        alignItems='center'
        flexWrap={'nowrap'}
        sx={{
          width: '100%',
          mx: 2.5,
          my: 2.5,
          '> * ': {
            maxWidth: '250px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box>
          <Host control={control} hosts={hosts} register={register} />
        </Box>

        <Box>
          <ApplicantIdSearch control={control} register={register} />
        </Box>

        <Box>
          <FirstNameSearch control={control} register={register} />
        </Box>

        <Box>
          <LastNameSearch control={control} register={register} />
        </Box>

        <Box>
          <EmailSearch control={control} register={register} />
        </Box>

        <Box>
          <Programs control={control} programs={programs} />
        </Box>

        <Box sx={{ mt: 2.5 }}>
          <IconButton onClick={clearFilters}>
            <Box
              component='img'
              alt='Clear Filters'
              src={isDirty ? ClearFilters : ClearFiltersDisabled}
            />
          </IconButton>
        </Box>
      </Grid>

      <Grid
        container
        alignItems='center'
        sx={{
          width: '100%',
          mx: 2.5,
          my: 2,
          '> * ': {
            maxWidth: '250px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box>
          <Position
            control={control}
            positions={positions}
            register={register}
          />
        </Box>

        <Box>
          <Country
            control={control}
            countries={countries}
            register={register}
          />
        </Box>

        <Box>
          <StartDate getValues={getValues} control={control} />
        </Box>

        <Box>
          <EndDate getValues={getValues} control={control} />
        </Box>

        <Box>
          <Statuses control={control} statuses={statuses} />
        </Box>
      </Grid>
    </form>
  );
};

export default ParticipantsDesktopFilters;
