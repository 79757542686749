import { Box, IconButton, Link, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Clone from 'assets/icons/clonePlacement.svg';
import Download from 'assets/icons/downloadPlacement.svg';
import Edit from 'assets/icons/editPlacement.svg';
import EditDisabled from 'assets/icons/editPlacementDisabled.svg';
import { OptionalTooltip } from 'components/tooltip/tooltip';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { useTranslations } from 'hooks/useTranslations';
import { isEditAvailable } from 'pages/placements/placementsList/helpers';
import { PlacementAction } from 'pages/placements/placementsList/interfaces/placementAction';
import { BE_DATE_FORMAT, DEFAULT_FORMAT, formatDate } from 'utils/date/convertToDate';
import { ExtendedTableHeader } from './tableHeader';
import { CellHeaderId } from '../../interfaces/placementsCellHeader';
import ActionButton from '../actionButton';
import EmptyTable from '../emptyTable';
import Status from '../status';

const DesktopTable = ({
  order,
  orderBy,
  sortHandler,
  rows,
  handlePlacementAction,
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  rows: PlacementSummaryResponse[];
  handlePlacementAction: (
    placement: PlacementSummaryResponse,
    action: PlacementAction
  ) => void;
}) => {
  const {
    placementsList: {
      actions: { clone, edit, download },
    },
  } = useTranslations();

  return (
    <>
      <Table stickyHeader>
        <ExtendedTableHeader
          order={order}
          orderBy={orderBy}
          sortHandler={sortHandler}
        />
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.program}</TableCell>
                <TableCell>
                  <Status placement={row} />
                </TableCell>

                <TableCell>
                  <Link
                    component='button'
                    variant='caption'
                    underline='hover'
                    onClick={() =>
                      handlePlacementAction(row, PlacementAction.Open)
                    }
                  >
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    component='button'
                    variant='caption'
                    underline='hover'
                    onClick={() =>
                      handlePlacementAction(row, PlacementAction.Preview)
                    }
                    sx={{
                      textAlign: 'left'
                    }}
                  >
                    {row.position}
                  </Link>
                </TableCell>
                <TableCell>{row.department}</TableCell>
                <TableCell>
                  {formatDate(
                    row.earliestStartDate,
                    BE_DATE_FORMAT,
                    DEFAULT_FORMAT
                  )}
                </TableCell>
                <TableCell>
                  {formatDate(
                    row.latestStartDate,
                    BE_DATE_FORMAT,
                    DEFAULT_FORMAT
                  )}
                </TableCell>
                <TableCell>
                  {formatDate(
                    row.earliestEndDate,
                    BE_DATE_FORMAT,
                    DEFAULT_FORMAT
                  )}
                </TableCell>
                <TableCell>
                  {formatDate(
                    row.latestEndDate,
                    BE_DATE_FORMAT,
                    DEFAULT_FORMAT
                  )}
                </TableCell>
                <TableCell>{row.total}</TableCell>
                <TableCell>{row.open}</TableCell>
                <TableCell>{row.matched}</TableCell>
                <TableCell>{row.approved}</TableCell>
                <TableCell>
                  <Box display='flex'>
                    <OptionalTooltip show text={edit} placement='top'>
                      <IconButton
                        onClick={() =>
                          handlePlacementAction(row, PlacementAction.Edit)
                        }
                        disabled={!isEditAvailable(row)}
                      >
                        <Box
                          component='img'
                          alt='Edit'
                          src={isEditAvailable(row) ? Edit : EditDisabled}
                        />
                      </IconButton>
                    </OptionalTooltip>
                    <OptionalTooltip show text={download} placement='top'>
                      <IconButton
                        onClick={() =>
                          handlePlacementAction(row, PlacementAction.Download)
                        }
                      >
                        <Box component='img' alt='Download' src={Download} />
                      </IconButton>
                    </OptionalTooltip>
                    <OptionalTooltip show text={clone} placement='top'>
                      <IconButton
                        onClick={() =>
                          handlePlacementAction(row, PlacementAction.Clone)
                        }
                      >
                        <Box component='img' alt='Clone' src={Clone} />
                      </IconButton>
                    </OptionalTooltip>
                    <ActionButton
                      placement={row}
                      handlePlacementAction={handlePlacementAction}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align='center' colSpan={15}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default DesktopTable;
