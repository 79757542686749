import { Box, Typography, useTheme } from '@mui/material';

const MobileDisplayProp = ({
  label,
  value,
}: {
  label: string;
  value?: string | JSX.Element;
}) => {
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='column'>
      <Typography
        sx={{
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        {label}
      </Typography>
      {value ?? typeof value === 'string' ? (
        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

export default MobileDisplayProp;
