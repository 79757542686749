import { createContext, Dispatch } from 'react';
import { ActionType } from 'hooks/useCreateReducer';
import { EditPlacementInitialState } from './editPlacement.state';

export interface EditPlacementContextProps {
  state: EditPlacementInitialState;
  dispatch: Dispatch<ActionType<EditPlacementInitialState>>;
}

const EditPlacementContext = createContext<EditPlacementContextProps>(
  undefined!
);

export default EditPlacementContext;
