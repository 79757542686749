import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DynamicFieldType } from 'utils/interfaces/dynamicFieldType';
import { DynamicFieldProps } from '../dynamicField/interfaces/dynamicFieldProps';

const useDynamicFormHook = ({
  fields,
  submitHandler,
}: {
  fields: DynamicFieldProps[];
  submitHandler: (data: Record<string, any>) => void;
}) => {
  const schema = yup.object().shape(
    fields.reduce((acc, el) => {
      if (el.validation) {
        if (el.validation.required)
          acc[el.name] = yup.string().required().label(el.label);
      }
      return acc;
    }, {} as Record<string, any>)
  );

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, any>>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: fields.reduce((acc, el) => {
      acc[el.name] = el.type === DynamicFieldType.MultiselectDropdown ? [] : '';
      return acc;
    }, {} as Record<string, any>),
  });

  const onSubmit = handleSubmit(submitHandler);

  return {
    control,
    register,
    getValues,
    setValue,
    onSubmit,
    errors,
  };
};

export default useDynamicFormHook;
