import { PlacementResponse } from 'hooks/services/placements/interfaces/placement.response';
import { UseFormSetValue } from 'react-hook-form';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';
import { setArrivalInstructionsValues } from './setArrivalInstructionsValues';
import { setEmploymentValues } from './setEmploymentValues';
import { setGroomingValues } from './setGroomingValues';
import { setHousingValues } from './setHousingValues';
import { setInterviewValues } from './setInterviewValues';
import { setPaymentValues } from './setPaymentValues';
import { setTrainingOrientationValues } from './setTrainingOrientationValues';

export const setValues = (
  response: PlacementResponse,
  formSetValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!response) {
    return;
  }

  const {
    trainingOrientation,
    grooming,
    payment,
    employmentPosition,
    interviewRequirements,
    housing,
    arrivalInstructions,
  } = response;

  setTrainingOrientationValues(trainingOrientation, formSetValue);
  setGroomingValues(grooming, formSetValue);
  setEmploymentValues(employmentPosition, formSetValue);
  setPaymentValues(payment, formSetValue);
  setInterviewValues(interviewRequirements, formSetValue);
  setHousingValues(housing, formSetValue);
  setArrivalInstructionsValues(arrivalInstructions, formSetValue);
};
