import { DeclineInterviewForm } from './interfaces/declineInterviewForm';
import DeclineInterviewModal from './declineInterviewModal';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validation/schema.validation';
import { useEffect, useState } from 'react';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { useHostService } from 'hooks/services/hosts';
import { useParams } from 'react-router-dom';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const DeclineInterviewModalContainer = ({
  modalOpen,
  setModalOpen,
  actionAfterClose,
  entity,
  participantName,
  jobPosition,
  interviewId,
  fetchData,
}: {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  actionAfterClose?: () => void;
  entity: string;
  participantName: string;
  jobPosition: string;
  interviewId: number;
  fetchData: () => void;
}) => {
  const alert = useAlert();
  const translations = useTranslations();
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const { getDeclineReasons, updateInterview } = useHostService();
  const [reasons, setReasons] = useState<KeyValueItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<DeclineInterviewForm>({
    defaultValues: {
      interview: {
        reason: '',
      },
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;

  const handleCloseModal = () => {
    setModalOpen(false);
    if (actionAfterClose) {
      actionAfterClose();
    }
  };

  const fetchDeclineReasons = async () => {
    try {
      const response = await getDeclineReasons(parsedHostId, entity, interviewId);
      setReasons(response);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async (data: DeclineInterviewForm) => {
    setIsLoading(true);
    try {
      await updateInterview(
        parsedHostId.toString(),
        interviewId,
        entity,
        'decline',
        data
      );

      fetchData();

      alert.success(translations.alerts.interviews.interviewDeclined);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };
  const onSave = handleSubmit(submitHandler);

  useEffect(() => {
    fetchDeclineReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <DeclineInterviewModal
        {...{
          modalOpen,
          handleCloseModal,
          onSave,
          entity,
          participantName,
          jobPosition,
          reasons,
          isLoading,
        }}
      />
    </FormProvider>
  );
};

export default DeclineInterviewModalContainer;
