export default function validateMinNumber(value: any, minNumber: number) {
  if (!value) {
    return true;
  }
  const parsedValue = Number(value);

  if (!isNaN(parsedValue) && parsedValue < minNumber) {
    return false;
  }

  return true;
}
