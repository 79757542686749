import * as yup from 'yup';

import { transformStringToNumber } from 'utils/extensions/numberInputTransformer';
import { convertStringToBoolean } from 'utils/string/stringConverters';

export const schema = yup.object().shape({
  gas: yup.boolean().transform(convertStringToBoolean).nullable(),
  electric: yup.boolean().transform(convertStringToBoolean).nullable(),
  water: yup.boolean().transform(convertStringToBoolean).nullable(),
  sewer: yup.boolean().transform(convertStringToBoolean).nullable(),
  trash: yup.boolean().transform(convertStringToBoolean).nullable(),
  internet: yup.boolean().transform(convertStringToBoolean).nullable(),
  tv: yup.boolean().transform(convertStringToBoolean).nullable(),
  telephone: yup.boolean().transform(convertStringToBoolean).nullable(),
  isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants: yup
    .boolean()
    .transform(convertStringToBoolean)
    .nullable(),
  theAverageMonthlyCostOfAnyUtilitiesNotIncluded: yup
    .number()
    .min(0)
    .transform(transformStringToNumber)
    .nullable()
    .label('The average monthly cost of any utilities'),
});
