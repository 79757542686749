import ResponsiveFiltersBar from 'components/responsiveListView/components/responsiveFiltersBar';
import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { CellHeaderId } from './interfaces/visaArrivalInfoCellHeader';
import { VisaArrivalInfoTableProps } from './interfaces/visaArrivalInfoTableProps';
import { FiltersFormProps } from '../filters/interfaces/filtersFormProps';
import VisaArrivalInfoDesktopFilters from '../filters/visaArrivalInfoDesktopFilters';
import VisaArrivalInfoMobileFilters from '../filters/visaArrivalInfoMobileFilters';
import { VisaArrivalInfoListView } from '../visaArrivalInfoListView';

const VisaArrivalInfoTable = ({
  filterProps,
  filtersRef,
  heightToSkip,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  paginationRef,
  rowsPerPage,
  tableProps: { rowsTotal, rows },
  order,
  orderBy,
  sortHandler,
  timeZone,
}: {
  heightToSkip: number;
  filtersRef: (node: HTMLDivElement) => void;
  filterProps: FiltersFormProps | null;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
  page: number;
  paginationRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
  tableProps: VisaArrivalInfoTableProps;
  order?: SortOrder;
  orderBy?: CellHeaderId;
  sortHandler: (property: CellHeaderId) => void;
  timeZone?: string;
}) => {
  return (
    <ResponsiveListView
      filters={
        filterProps ? (
          <ResponsiveFiltersBar
            filtersBarRef={filtersRef}
            desktopView={<VisaArrivalInfoDesktopFilters {...filterProps} />}
            mobileView={<VisaArrivalInfoMobileFilters {...filterProps} />}
          />
        ) : undefined
      }
      csv={{
        filename: 'visa-arrival-information-export.csv',
        data: rows
      }}
      listView={
        <VisaArrivalInfoListView
          {...{
            rows,
            order,
            orderBy,
            sortHandler,
            heightToSkip,
            timeZone,
          }}
        />
      }
      paginationProps={{
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        paginationRef,
        rowsPerPage,
        rowsTotal,
      }}
    />
  );
};

export default VisaArrivalInfoTable;
