import { DocumentInfo } from 'components/documentsTable/interfaces/documentInfo';
import { ArrivalInstructionsResponse } from 'hooks/services/hosts/interfaces/arrivalInstruction.response';
import { HousingsResponse } from 'hooks/services/hosts/interfaces/housing.response';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';

export interface EditPlacementInitialState {
  isLoading: boolean;
  isLoadingDocument: boolean;
  availablePrograms: KeyValueItemProps[];
  availableProgramTypes: KeyValueItemProps[];
  availablePositionTitles: KeyValueItemProps[];
  availableContacts: KeyValueItemProps[];
  englishLevels: KeyValueItemProps[];
  wageOptions: KeyValueItemProps[];
  paymentScheduleOptions: KeyValueItemProps[];
  housingTypes: KeyValueItemProps[];
  housings: HousingsResponse;
  genders: KeyValueItemProps[];
  arrivalInstructions: ArrivalInstructionsResponse;
  allArrivalInstructions: Map<string, ArrivalInstructionsResponse>;
  documents: DocumentInfo[];
  id: number;
  intHostId: number;
  programId: number;
  isReadOnly: boolean;
}

export const initialState: EditPlacementInitialState = {
  isLoading: false,
  isLoadingDocument: false,
  availablePrograms: [],
  availableProgramTypes: [],
  availablePositionTitles: [],
  availableContacts: [],
  englishLevels: [],
  wageOptions: [],
  paymentScheduleOptions: [],
  housingTypes: [],
  housings: [],
  genders: [],
  arrivalInstructions: [],
  allArrivalInstructions: new Map(),
  documents: [],
  id: 0,
  intHostId: 0,
  programId: 0,
  isReadOnly: false,
};
