import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { CellHeaderId } from '../interfaces/allInterviewsCellHeader';

export const getCells = ({
  hostEntity,
  program,
  applicantId,
  applicantName,
  email,
  applicationStatus,
  skype,
  countryOfResidence,
  jobId,
  position,
  placementStartsOn,
  placementEndsOn,
  interviewDate,
  interviewStatus,
  interviewer,
  interviewerSkypeId,
  actions,
  changeTime,
  cancelInterview,
}: {
  program: string;
  applicantId: string;
  applicantName: string;
  applicationStatus: string;
  skype: string;
  countryOfResidence: string;
  jobId: string;
  position: string;
  placementStartsOn: string;
  placementEndsOn: string;
  interviewDate: string;
  interviewStatus: string;
  interviewer: string;
  interviewerSkypeId: string;
  email: string;
  hostEntity: string;
  actions: string;
  changeTime: string;
  cancelInterview: string;
}): CellHeader<CellHeaderId>[] => {
  return [
    {
      id: 'hostEntity',
      label: hostEntity,
      sortable: true,
    },
    {
      id: 'program',
      label: program,
      sortable: true,
    },
    {
      id: 'applicantId',
      label: applicantId,
      sortable: false,
    },
    {
      id: 'applicantName',
      label: applicantName,
      sortable: true,
    },
    {
      id: 'email',
      label: email,
      sortable: true,
    },
    {
      id: 'applicationStatus',
      label: applicationStatus,
      sortable: true,
    },
    {
      id: 'skype',
      label: skype,
      sortable: true,
    },
    {
      id: 'countryOfResidence',
      label: countryOfResidence,
      sortable: true,
    },
    {
      id: 'jobId',
      label: jobId,
      sortable: false,
    },
    {
      id: 'position',
      label: position,
      sortable: true,
    },
    {
      id: 'placementStartsOn',
      label: placementStartsOn,
      sortable: true,
    },
    {
      id: 'placementEndsOn',
      label: placementEndsOn,
      sortable: true,
    },
    {
      id: 'interviewDate',
      label: interviewDate,
      sortable: true,
    },
    {
      id: 'interviewStatus',
      label: interviewStatus,
      sortable: true,
    },
    {
      id: 'interviewer',
      label: interviewer,
      sortable: true,
    },
    {
      id: 'interviewerSkypeId',
      label: interviewerSkypeId,
      sortable: true,
    },
    {
      id: 'changeTime',
      label: changeTime,
      sortable: true,
    },
    {
      id: 'cancelInterview',
      label: cancelInterview,
      sortable: true,
    },
    {
      id: 'actions',
      label: actions,
      sortable: false,
    },
  ];
};
