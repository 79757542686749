import { OfferDetailsFormInput } from "../interfaces/offerDetailsForm.formInput";

export const initWithEmptyValues: OfferDetailsFormInput = {
  screening: {
    willEVIncurCostForScreening: '',
    willCompanyRequireDrugTest: '',
    drugScreeningPolicy: '',
    isDrugTestProvidedByHostCompany: '',
    drugScreeningCostToStudent: '',
    drugScreeningCostDue: '',
  },
  grooming: {
    groomingStandards: '',

  },
  housing: {
    doesHostEntityProvideTemporaryHousing: '',
    durationOfTemporaryHousing: '',
    costOfTemporaryHousing: '',
  },
  socialSecurity: {
    addressOfNearestSocialSecurityOffice: '',
    distanceOfSSOFromSOA: '',
    willHostEntityProvideTransportationToTheNearestSocialSecurityOffice: '',
    willHostEntityHelpEVMakeCopiesOfNecessaryDocuments: '',
    otherSocialSecurityAssistanceProvided: '',
  }
};
