import { useEffect, useState } from 'react';

import { Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import PrimarySubmitButton from 'components/button';

import { useAccountsService } from 'hooks/services/accounts';
import { useCommonService } from 'hooks/services/commons';
import { useTranslations } from 'hooks/useTranslations';

import {
  ButtonResponsiveProps,
  PrimaryButtonResponsiveMargin,
} from 'utils/formHeader';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';

import { ProfileFormInputs } from './interfaces/profileFormInputs';
import { ProfileUI } from './profile';
import { emptyValue } from './utils/empty.value';
import { setValues } from './utils/setValue';
import { makeRequest } from './utils/makeRequest';
import { useAlert } from 'providers/alertProvider';

export const ProfileContainer = () => {
  const [timeZones, setTimeZones] = useState<KeyValueItemProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { hostId } = useParams<{ hostId: string; }>();
  const { getTimeZones } = useCommonService();
  const { getUserInfo, patchUpdateProfile } = useAccountsService();
  const formMethods = useForm<ProfileFormInputs>({
    defaultValues: emptyValue,
  });
  const navigate = useNavigate();
  const translations = useTranslations();
  const alert = useAlert();

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = formMethods;

  const handleGetTimeZones = async () => {
    const timeZones = await getTimeZones({ hostId: Number(hostId) });
    setTimeZones(timeZones);
  };

  const handleGetProfile = async () => {
    const profile = await getUserInfo(Number(hostId));
    setValues(profile, setValue);
  };

  const handleCancelButton = () => {
    navigate(
      ApplicationRoutes.dashboard.replace(':hostId', hostId!.toString())
    );
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([handleGetTimeZones(), handleGetProfile()]).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const submitHandler = async (data: ProfileFormInputs) => {
    const body = makeRequest(data);
    await patchUpdateProfile({ body, hostId: Number(hostId) });
    alert.success(translations.alerts.profile.updated);
  };

  const Buttons = (
    <>
      <Button
        variant='outlined'
        sx={{ ...ButtonResponsiveProps }}
        onClick={handleCancelButton}
      >
        {translations.cancelButton}
      </Button>

      <Grid
        sx={{
          ...ButtonResponsiveProps,
          ...PrimaryButtonResponsiveMargin,
        }}
      >
        <PrimarySubmitButton
          dataTestid='save'
          isLoading={false}
          text={translations.saveButton}
        />
      </Grid>
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <ProfileUI
        {...{
          timeZones,
          loading,
          control,
          register,
          errors,
          onSubmit: handleSubmit(submitHandler),
          buttons: Buttons,
          translations: translations.profile,
        }}
      />
    </FormProvider>
  );
};
