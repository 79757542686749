import { Box, Grid, IconButton } from '@mui/material';
import ClearFilters from 'assets/icons/clearFilters.svg';
import ClearFiltersDisabled from 'assets/icons/clearFiltersDisabled.svg';
import {
  AvailableFrom,
  AvailableTo,
  LanguageLevel,
  Programs,
  AvailableAtShortNotice,
  Skills,
  ProgramTypes,
  PlacementCategories,
  ResidenceCountries,
} from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const ApplicantsDesktopFilters = ({
  register,
  onSubmit,
  control,
  getValues,
  programs,
  englishProficiencies,
  skills,
  placementCategories,
  programTypes,
  residenceCountries,
  isDirty,
  clearFilters,
  isITPProgram,
}: FiltersFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid
        container
        alignItems='center'
        flexWrap={'nowrap'}
        sx={{
          width: '100%',
          mx: 2.5,
          my: 5,
          '> * ': {
            maxWidth: '250px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box>
          <Programs control={control} programs={programs} />
        </Box>

        <Box>
          <LanguageLevel
            register={register}
            control={control}
            englishProficiencies={englishProficiencies}
          />
        </Box>

        <Box>
          <Skills control={control} skills={skills} />
        </Box>

        <Box>
          <AvailableFrom getValues={getValues} control={control} />
        </Box>

        <Box>
          <AvailableTo getValues={getValues} control={control} />
        </Box>

        <Box sx={{ mt: 2.5, flex: '0 1 auto', width: 150 }}>
          <AvailableAtShortNotice control={control} />
        </Box>

        <Box sx={{ mt: 2.5 }}>
          <IconButton onClick={clearFilters}>
            <Box
              component='img'
              alt='Clear Filters'
              src={isDirty ? ClearFilters : ClearFiltersDisabled}
            />
          </IconButton>
        </Box>
      </Grid>

      <Grid
        container
        alignItems='center'
        sx={{
          width: '100%',
          mx: 2.5,
          my: 2,
          '> * ': {
            maxWidth: '250px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box>
          <ProgramTypes
            control={control}
            programTypes={programTypes}
            disabled={!isITPProgram}
          />
        </Box>
        <Box>
          <PlacementCategories
            control={control}
            placementCategories={placementCategories}
            disabled={!isITPProgram}
          />
        </Box>
        <Box>
          <ResidenceCountries
            control={control}
            residenceCountries={residenceCountries}
            disabled={!isITPProgram}
          />
        </Box>
      </Grid>
    </form>
  );
};

export default ApplicantsDesktopFilters;
