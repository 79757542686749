import { useCallback, useEffect, useState } from 'react';
import { useHostService } from 'hooks/services/hosts';
import { useParams } from 'react-router-dom';
import { FiltersFormInputs } from './components/filters/interfaces/filtersFormInput';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { FilterParams } from './components/participantsTable/interfaces/filterParams';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import { ParticipantsFilters } from 'hooks/services/hosts/interfaces/participantsFilters.response';
import { ParticipantsSummaryRequest } from 'hooks/services/hosts/interfaces/participantsSummary.request';
import { ParticipantSummaryResponse } from 'hooks/services/hosts/interfaces/participantSummary.response';
import ParticipantsTable from './components/participantsTable';
import { TileViewSwitcherProps } from 'components/switchTileListView/interfaces/TileViewSwitcherProps';

const ParticipantsListContainer = ({
  tileViewSwitcherProps,
  pageHeaderHeight,
}: {
  tileViewSwitcherProps: TileViewSwitcherProps;
  pageHeaderHeight: number;
}) => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const { getParticipantsFilters, getParticipants } = useHostService();
  const [filters, setFilters] = useState<ParticipantsFilters | null>(null);
  const [rows, setRows] = useState<ParticipantSummaryResponse[]>([]);
  const [rowsTotal, setRowsTotal] = useState(100);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [filterParams, setFilterParams] = useState<FilterParams>();

  const onApplyFilter = async (data?: FiltersFormInputs) => {
    if (data) {
      const updatedFilter: FilterParams = {
        ...data,
        startDate: formatDate(data.startDate, DEFAULT_FORMAT, BE_DATE_FORMAT),
        endDate: formatDate(data.endDate, DEFAULT_FORMAT, BE_DATE_FORMAT),
      };

      setFilterParams(updatedFilter);

      await getParticipantsHandler({
        ...updatedFilter,
        ...tableParams,
        hostId: parsedHostId,
      });
    } else {
      setFilterParams({});

      await getParticipantsHandler({ ...tableParams, hostId: parsedHostId });
    }
  };

  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await getParticipantsHandler({
      ...data,
      ...filterParams,
      hostId: parsedHostId,
    });
  };

  const getParticipantsHandler = async (
    data: ParticipantsSummaryRequest & { hostId: number }
  ) => {
    const response = await getParticipants(data);
    setRowsTotal(response.total);
    setRows(response.records);
  };

  const fetchParticipants = useCallback(async () => {
    try {
      const data = {
        ...filterParams,
        ...tableParams,
        hostId: parsedHostId,
      };
      await getParticipantsHandler(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await getParticipantsFilters(parsedHostId);
      setFilters(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchParticipants();
    fetchFilterOptions();
  }, [fetchFilterOptions, fetchParticipants]);

  if (!filters) return null;

  return (
    <ParticipantsTable
      {...{
        tileViewSwitcherProps,
        filterProps: {
          filters,
          onApplyFilter,
        },
        tableProps: {
          rows,
          rowsTotal,
          handleTableChanged,
        },
        heightOfTopComponents: pageHeaderHeight,
      }}
    />
  );
};

export default ParticipantsListContainer;
