import { UserRequestBody } from '../../../hooks/services/accounts/interfaces/user.request';
import { ProfileFormInputs } from '../interfaces/profileFormInputs';

export const makeRequest = ({
  firstName,
  email,
  lastName,
  skypeAddress,
  timeZone,
}: ProfileFormInputs): UserRequestBody => {
  return {
    firstName,
    lastName,
    skypeAddress,
    email,
    timeZone,
  };
};
