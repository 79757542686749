import { Box, Grid, IconButton } from '@mui/material';
import ClearFilters from 'assets/icons/clearFilters.svg';
import ClearFiltersDisabled from 'assets/icons/clearFiltersDisabled.svg';
import {
  Programs,
  Host,
  PositionSearch,
  EmbassyDate,
  ArrivalDate,
  ApplicantIdSearch,
  EmailSearch,
  PlacementIdSearch,
  ArrivalAirportSearch,
  AirlineSearch,
  FlightNumberSearch,
} from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const VisaArrivalInfoDesktopFilters = ({
  onSubmit,
  control,
  getValues,
  programs,
  hostEntities,
  clearFilters,
  register,
  isDirty,
}: FiltersFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid
        container
        alignItems='center'
        flexWrap={'nowrap'}
        sx={{
          width: '100%',
          mx: 2.5,
          my: 5,
          '> * ': {
            maxWidth: '200px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box sx={{ minWidth: 250 }}>
          <Host
            control={control}
            hostEntities={hostEntities}
            register={register}
          />
        </Box>

        <Box sx={{ minWidth: 250 }}>
          <Programs control={control} programs={programs} />
        </Box>

        <Box>
          <ApplicantIdSearch control={control} register={register} />
        </Box>

        <Box>
          <EmailSearch control={control} register={register} />
        </Box>

        <Box sx={{ maxWidth: 120 }}>
          <PlacementIdSearch control={control} register={register} />
        </Box>

        <Box sx={{ minWidth: 250 }}>
          <PositionSearch control={control} register={register} />
        </Box>

        <Box sx={{ mt: 2.5 }}>
          <IconButton onClick={clearFilters}>
            <Box
              component='img'
              alt='Clear Filters'
              src={isDirty ? ClearFilters : ClearFiltersDisabled}
            />
          </IconButton>
        </Box>
      </Grid>

      <Grid
        container
        alignItems='center'
        sx={{
          width: '100%',
          mx: 2.5,
          my: 2,
          '> * ': {
            maxWidth: '250px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box>
          <ArrivalAirportSearch control={control} register={register} />
        </Box>

        <Box>
          <AirlineSearch control={control} register={register} />
        </Box>

        <Box sx={{ maxWidth: 120 }}>
          <FlightNumberSearch control={control} register={register} />
        </Box>

        <Box>
          <EmbassyDate getValues={getValues} control={control} />
        </Box>

        <Box>
          <ArrivalDate getValues={getValues} control={control} />
        </Box>
      </Grid>
    </form>
  );
};

export default VisaArrivalInfoDesktopFilters;
