import ConfigurationContext from 'context/configuration.context';
import { ConfigurationProps } from 'context/types/configurationProps';
import { ReactElement, useEffect, useState } from 'react';

const ConfigurationProvider = ({ children }: { children: ReactElement }) => {
  const [configuration, setConfiguration] = useState<ConfigurationProps>(
    undefined!
  );
  const getConfig = async () => {
    const res = await fetch('/env.json');
    const configuration: ConfigurationProps = await res.json();
    setConfiguration(configuration);
  };

  useEffect(() => {
    getConfig();
  }, []);

  if (configuration)
    return (
      <ConfigurationContext.Provider value={configuration}>
        {children}
      </ConfigurationContext.Provider>
    );

  return <></>;
};

export default ConfigurationProvider;
