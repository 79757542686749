import bigDecimal from 'js-big-decimal';

const handleStep = (
  isSum: boolean,
  currentValue: string,
  useDecimals: boolean,
  step: number | undefined
): string => {
  let valueStep = useDecimals ? new bigDecimal(0.1) : new bigDecimal(1);

  if (step) {
    valueStep = new bigDecimal(step);
  }

  const oldValue = new bigDecimal(currentValue ? currentValue : 0);

  const newValue = isSum
    ? oldValue.add(valueStep)
    : oldValue.subtract(valueStep);

  return newValue.round(useDecimals ? 2 : 0).getValue();
};

const removeNumber = (currentValue: string, useDecimals: boolean): string => {
  const precision = useDecimals ? 2 : 0;
  const ten = new bigDecimal(10);
  const newValue = new bigDecimal(currentValue!).divide(ten, precision);

  return newValue.round(precision).getValue();
};

const addDecimal = (currentValue: string, decimal: string): string => {
  const ten = new bigDecimal(10);
  const hundred = new bigDecimal(100);
  const oldValue = new bigDecimal(currentValue ? currentValue : 0);
  const decimalValue = new bigDecimal(decimal).divide(hundred, 2);
  const integerAndDecimalValue = oldValue.getValue().split('.');

  if (
    (integerAndDecimalValue.length > 1 &&
      integerAndDecimalValue[1].length === 1) ||
    integerAndDecimalValue.length === 1
  ) {
    const newValue = oldValue.add(decimalValue);
    return newValue.round(2).getValue();
  }

  const newValue = oldValue.multiply(ten).add(decimalValue);
  return newValue.round(2).getValue();
};

const addInteger = (currentValue: string, integer: string) => {
  const newValueString = (currentValue ? currentValue : '0') + integer;
  const newValue = new bigDecimal(BigInt(newValueString)).round(0);
  return newValue.getValue();
};

export { handleStep, removeNumber, addDecimal, addInteger };
