import { useTranslations } from 'hooks/useTranslations';
import HousingTableHeader from './housingTableHeader';
import { HousingCellHeader } from '../../interfaces/housingCellHeader';

const HousingTableHeaderContainer = () => {
  const translations = useTranslations();
  const { name, status, address, city, zip, actions } = translations.companyHousing.companyHousingTable.columns;

  const cells: readonly HousingCellHeader[] = [
    {
      id: 'name',
      label: name,
      collapsible: false,
      sortable: true,
    },
    {
      id: 'status',
      label: status,
      collapsible: false,
      sortable: true,
    },
    {
      id: 'address',
      label: address,
      collapsible: false,
      sortable: false,
    },
    {
      id: 'city',
      label: city,
      collapsible: false,
      sortable: true,
    },
    {
      id: 'zip',
      label: zip,
      collapsible: false,
      sortable: true,
    },
    {
      id: 'actions',
      label: actions,
      collapsible: false,
      sortable: true,
    },
  ];

  return (
    <HousingTableHeader cells={cells} />
  );
};

export default HousingTableHeaderContainer;
