import NoScrollBar from 'utils/interfaces/noScrollBar';

export const ResponsiveContainerProps = (heightToSkip: number) => ({
  overflow: 'auto',
  width: '100%',
  maxHeight: {
    md: `calc(100vh - ${heightToSkip}px - 80px)`,
    xs: `calc(100vh - ${heightToSkip}px - 120px)`,
  },
  ...NoScrollBar,
});
