import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Grid
} from '@mui/material';
import Edit from 'assets/icons/editPlacement.svg';
import EditDisabled from 'assets/icons/editPlacementDisabled.svg';
import TablePaginationActions from './components/tablePaginationActions';
import { HousingRow } from '../../interfaces/housingRow';
import HousingStatus from '../status';
import HousingTableHeader from '../tableHeader';
import { RowNotFound } from 'components/tables/components/rowNotFound';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';

const HousingDesktopTable = ({
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  heightToSkip
}: {
  rows: HousingRow[];
  rowsPerPage: number;
  page: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  heightToSkip: number;
}) => {
  return (
    <Grid item xs={12}>
      <Box
        pb={1}
        px={'1px'}
        sx={{ ...ResponsiveContainerProps(heightToSkip) }}
      >
        <Table stickyHeader>
          <HousingTableHeader />

          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell sx={{ width: 180 }}>
                  <HousingStatus status={row.status} />
                </TableCell>
                <TableCell sx={{ maxWidth: 400 }}>{row.address}</TableCell>
                <TableCell sx={{ maxWidth: 250 }}>{row.city}</TableCell>
                <TableCell sx={{ maxWidth: 150 }}>{row.zip}</TableCell>
                <TableCell sx={{ width: 400 }}>
                  <IconButton
                    onClick={() => row.edit()}
                    disabled={!row.isEditable}
                  >
                    <Box
                      component='img'
                      alt={row.isEditable ? 'Edit' : 'EditDisabled'}
                      src={row.isEditable ? Edit : EditDisabled}
                    />
                  </IconButton>
                  <Button
                    onClick={() => row.action.onClick()}
                    variant='outlined'
                    color='primary'
                    sx={{ marginLeft: 1 }}
                  >
                    {row.action.title}
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            {rows.length === 0 && <RowNotFound colSpan={6} />}

          </TableBody>
        </Table>

      </Box>

      <TablePagination
        component='div'
        size='small'
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        showFirstButton
        showLastButton
      />
    </Grid>

  );
};

export default HousingDesktopTable;
