import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { InterviewResponse } from 'hooks/services/hosts/interfaces/interviews.response';
import { Action } from 'pages/interviews/interfaces/interviews';
import AllInterviewTableList from './components/tableList/allInterviewsTableList';
import { CellHeaderId } from '../interfaces/allInterviewsCellHeader';

const AllInterviewsTable = ({
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  paginationRef,
  rowsPerPage,
  rows,
  rowsTotal,
  order,
  orderBy,
  sortHandler,
  handleOpenActionsDropdown,
  heightToSkip,
  handleInterviewAction,
  handleClick,
  handleCancelAction,
  timeZone,
}: {
  handleChangePage: (pageNumber: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
  page: number;
  paginationRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
  rows: InterviewResponse[];
  rowsTotal: number;
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  heightToSkip: number;
  handleInterviewAction: (row: InterviewResponse) => void;
  handleOpenActionsDropdown: (
    event: React.MouseEvent<HTMLElement>,
    interview: InterviewResponse
  ) => void;
  handleClick: (applicantId: number) => void;
  handleCancelAction: (interview: InterviewResponse, action: Action) => void;
  timeZone?: string;
}) => {
  return (
    <ResponsiveListView
      listView={
        <AllInterviewTableList
          {...{
            rows,
            order,
            orderBy,
            sortHandler,
            handleOpenActionsDropdown,
            heightToSkip,
            handleInterviewAction,
            handleClick,
            handleCancelAction,
            timeZone,
          }}
        />
      }
      csv={{
        filename: 'all-interviews-export.csv',
        data: rows
      }}
      paginationProps={{
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        paginationRef,
        rowsPerPage,
        rowsTotal,
      }}
    />
  );
};

export default AllInterviewsTable;
