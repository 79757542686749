import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SignIn from './signIn';
import LoginRequest from './interfaces/loginRequest';
import LoginResponse from './interfaces/loginResponse';
import { schema } from './validation/schemaValidation';
import { useAuth } from 'hooks/useAuth';
import { useFetch } from 'hooks/useFetch';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const SignInContainer = () => {
  const translations = useTranslations();
  const alert = useAlert();
  const [, setSubmitErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxRememberMe, setCheckboxRememberMe] = useState(false);
  const [email] = useLocalStorage('email', '');
  const [isRememberMe] = useLocalStorage('rememberMe', '');
  const { post } = useFetch();
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<LoginRequest>({
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (body: LoginRequest) => {
    setIsLoading(true);

    try {
      const response = await post<LoginResponse>('accounts/login', { body });

      login(
        response.accessToken,
        response.refreshToken,
        checkboxRememberMe,
        body.email
      );

      alert.success(translations.alerts.authentication.signedIn);

      setSubmitErrors([]);
    } catch (error: any) {
      setSubmitErrors(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRememberMe = () => setCheckboxRememberMe((set) => !set);

  const onSubmit = () => handleSubmit(handleOnSubmit);

  return (
    <SignIn
      onSubmit={onSubmit}
      register={register}
      formErrors={formErrors}
      handleRememberMe={handleRememberMe}
      autoFocus={isRememberMe && email ? true : false}
      defaultValue={isRememberMe && email ? email : null}
      isLoading={isLoading}
    />
  );
};

export default SignInContainer;
