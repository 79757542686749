import { EmploymentPosition } from 'hooks/services/placements/interfaces/patchParams.request';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import {
  convertToBooleanOrUndefined,
  convertToNumberOrUndefined,
} from 'utils/string/stringConverters';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export function makeEmployment(
  data: EditPlacementFormInput
): EmploymentPosition {
  return {
    programId: convertToNumberOrUndefined(data.employment.program),
    programTypes: data.employment.programTypes,
    totalPositions: convertToNumberOrUndefined(data.employment.totalPositions),
    supervisorId: convertToNumberOrUndefined(data.employment.supervisor),
    department: data.employment.department,
    englishProficiencyLevel: data.employment.englishLevel,
    position: data.employment.positionTitle,
    isSelfArranged: convertToBooleanOrUndefined(data.employment.selfArranged),
    positionDescription: data.employment.positionDescription,
    scheduleDescription: data.employment.scheduleDescription,
    requiredSkills: data.employment.requiredSkills,
    requiredExperience: data.employment.requiredExperience,
    specialSkills: data.employment.specialRequirements,
    earliestStartDate: formatDate(
      data.employment.startDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    latestStartDate: formatDate(
      data.employment.latestStartDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    earliestEndDate: formatDate(
      data.employment.endDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    latestEndDate: formatDate(
      data.employment.latestEndDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
  };
}
