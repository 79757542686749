import { addMinutes, format, isAfter, parse, sub } from 'date-fns';

export function getTimeSlots(
  periods: { startHour: string; endHour: string }[]
): string[] {
  const timeSlotStep = 15; // in minutes

  const timeSlots = periods.flatMap((period) => {
    const startTime = parse(period.startHour, 'hh:mm a', new Date());
    const endTime = parse(period.endHour, 'hh:mm a', new Date());
    const slots = [];

    let currentTime = startTime;
    while (currentTime < endTime) {
      slots.push(format(currentTime, 'hh:mm a').toLowerCase());
      currentTime = addMinutes(currentTime, timeSlotStep);
    }

    return slots;
  });

  return timeSlots;
}

export function isLaterThan48HoursBefore(
  dateString: string,
  currentDateVal?: Date
): boolean {
  const givenDate = parse(dateString, 'EEEE, MMMM dd hh:mm a', new Date());
  const currentDate = currentDateVal || new Date();
  const fortyEightBeforeGivenDate = sub(givenDate, { hours: 48 });
  return isAfter(currentDate, fortyEightBeforeGivenDate);
}

export function getGMTOffset(timeZoneOffset: string): string {
  if (timeZoneOffset === '+00:00' || timeZoneOffset === '-00:00') {
    return 'GMT';
  }

  const sign = timeZoneOffset.startsWith('-') ? '-' : '+';
  const [hours, minutes] = timeZoneOffset.substr(1).split(':');

  let formattedOffset = `${sign}${parseInt(hours, 10)}`;
  if (minutes !== '00') {
    formattedOffset += `:${minutes}`;
  }

  return `GMT${formattedOffset}`;
}
