import { TrainingOrientation } from "hooks/services/placements/interfaces/patchParams.request";
import { EditPlacementFormInput } from "../../interfaces/editPlacementFormInput";
import { convertToBooleanOrUndefined } from "utils/string/stringConverters";

export function makeTrainingOperationPatchRequest(data: EditPlacementFormInput): TrainingOrientation {
  return {
    costDue: data.trainingOrientation.costDue,
    description: data.trainingOrientation.description,
    isEVPaidDuring: convertToBooleanOrUndefined(data.trainingOrientation.isEVPaidDuring),
    length: data.trainingOrientation.length,
    payRate: data.trainingOrientation.payRate,
    providedCost: data.trainingOrientation.providedCost,
    provided: data.trainingOrientation.provided,
    willParticipantIncurCost: convertToBooleanOrUndefined(data.trainingOrientation.willParticipantIncurCost)
  };
};
