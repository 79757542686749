import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { createKeyValueItems } from '../../utils';
import AvailableDateItem from './availableDateItem';

const AvailableDateItemContainer = ({
  dateIndex,
  isLastItem
}: {
  dateIndex: number;
  isLastItem: boolean;
}) => {
  const { control } = useFormContext();

  const [showPeriods, setShowPeriods] = useState(true);

  const weekDateLabel = useWatch({
    name: `dates.${dateIndex}.weekDayLabel`,
    control,
  });

  const disabled = useWatch({
    name: `dates.${dateIndex}.disabled`,
    control,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: `dates.${dateIndex}.periods`,
  });

  const startTimeArray: KeyValueItemProps[] = createKeyValueItems(fields, true);
  const endTimeArray: KeyValueItemProps[] = createKeyValueItems(fields, false);

  const addTimeSlot = () => {
    append({
      startHour: '',
      endHour: '',
    });
  };

  return (
    <AvailableDateItem
      {...{
        dateIndex,
        isLastItem,
        showPeriods,
        setShowPeriods,
        weekDateLabel,
        addTimeSlot,
        fields,
        startTimeArray,
        endTimeArray,
        remove,
        append,
        disabled
      }}
    />
  );
};

export default AvailableDateItemContainer;
