import {
  CompanyDetailsRequest,
  CompanyProgramRequest,
  CompanyRequestBody,
} from 'hooks/services/hosts/interfaces/companyPatch.request';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import {
  convertToBooleanOrUndefined,
  convertToNumberOrUndefined,
} from 'utils/string/stringConverters';
import {
  CompleteDetailsFormInput,
  CompleteInfoFormInput,
  CompleteProgramsFormInput,
} from '../interfaces/completeInfo.formInput';

const makeRequestCompanyDetails = (
  details: CompleteDetailsFormInput
): CompanyDetailsRequest => {
  return {
    approximateAnnualRevenueOfPreviousYear: details.annualRevenue,
    businessLicenseOrHostSchoolAccreditationExpirationDate: formatDate(
      details.businessLicense,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    comments: details.comments,
    companyDescription: details.description,
    companyUrl: details.url,
    embedVideo: details.videoEmbed,
    haveYouHadAnyLayoffsInThePast120Days: convertToBooleanOrUndefined(
      details.layoffs
    ),
    howLongHaveYouBeenHiringParticipantsForTheJ1Programs:
      details.howLongHiringParticipants,
    industry: details.industry,
    internAndTraineePrograms: details.internProgram,
    isOrientationRequired: convertToBooleanOrUndefined(
      details.orientationRequired
    ),
    isSiteVisitRequirementSatisfied: convertToBooleanOrUndefined(
      details.siteRequirement
    ),
    isTheHostEntityIncorporatedInTheUSA: convertToBooleanOrUndefined(
      details.hostIncorporateInUsa
    ),
    irsTaxNumber: details.irsTaxNumber,
    policyExpiration: formatDate(
      details.expirationDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    hostEntityHeadQuartersAddressIncludingCounty: details.hostAddress,
    insuranceCompany: details.insuranceCompany,
    seasonalityReportExpirationDate: formatDate(
      details.seasonalityReportExpiration,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    sector: details.sector,
    summerWorkTravelProgram: details.summerWork,
    teacherProgram: details.teacherProgram,
    termsConditionsExpirationDate: formatDate(
      details.termsConditionsExpiration,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    totalNumberOfExchangeVisitors: convertToNumberOrUndefined(
      details.totalExchangeVisitors
    ),
    totalNumberOfFullTimeEmployees: convertToNumberOrUndefined(
      details.totalCurrentEmployees
    ),
    workmansCompPolicyID: details.workmanCompId,
    workmansCompPolicyProviderPhone: details.workmanCompPhone,
    whereIsHostEntityIncorporated: details.hostIncorporateNotUsa,
    yearEstablished: convertToNumberOrUndefined(details.yearEstablished),
    whichOtherSponsorsAreYouCurrentlyWorkingWith: details.otherSponsors,
  };
};

const makeRequestCompanyProgram = (
  program: CompleteProgramsFormInput
): CompanyProgramRequest => {
  return {
    address: program.address,
    aproximateCostOfHousingPerMonthPerExchangeVisitor:
      convertToNumberOrUndefined(
        program.aproximateCostOfHousingPerMonthPerExchangeVisitor
      ),
    existsAnyAffordableHousingOptionsNearby: convertToBooleanOrUndefined(
      program.existsAnyAffordableHousingOptionsNearby
    ),
    hasAnyOtherVisaPrograms: convertToBooleanOrUndefined(
      program.hasAnyOtherVisaPrograms
    ),
    hasHelpWithCulturalActivities: convertToBooleanOrUndefined(
      program.hasHelpWithCulturalActivities
    ),
    hasSupervisor: convertToBooleanOrUndefined(program.hasSupervisor),
    isEnglishPrimaryLanguage: convertToBooleanOrUndefined(
      program.isEnglishPrimaryLanguage
    ),
    isHousingProvided: convertToBooleanOrUndefined(program.isHousingProvided),
    isProvided32HoursPerWeek: convertToBooleanOrUndefined(
      program.isProvided32HoursPerWeek
    ),
    isTrainingOrWorkOverNightRequired: convertToBooleanOrUndefined(
      program.isTrainingOrWorkOverNightRequired
    ),
    otherVisaProgramsDescription: program.otherVisaProgramsDescription,
  };
};

export const makeRequest = ({
  details,
  program,
}: CompleteInfoFormInput): CompanyRequestBody => {
  return {
    details: makeRequestCompanyDetails(details),
    program: makeRequestCompanyProgram(program),
  };
};
