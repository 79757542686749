import { KeyValueItemProps } from '../interfaces/keyItemProps';

export interface BackEndKeyItem {
  key: string;
  value: string;
}

export const convertBackEndKeyItemToKeyItem = (
  keyItem: BackEndKeyItem
): KeyValueItemProps => {
  return {
    key: keyItem.key,
    value: keyItem.value,
  };
};

export const convertBackEndKeyItemsToKeyItems = (
  items: BackEndKeyItem[]
): KeyValueItemProps[] => {
  return items.map(convertBackEndKeyItemToKeyItem);
};
