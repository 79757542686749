import { Grooming } from 'hooks/services/placements/interfaces/patchParams.request';
import { convertToBooleanOrUndefined } from 'utils/string/stringConverters';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const makeGroomingPatchRequest = (
  data: EditPlacementFormInput
): Grooming => {
  const { dressCode, uniformCost, uniformCostDue, uniformProvided } =
    data.grooming;
  return {
    dressCode,
    isUniformProvided: convertToBooleanOrUndefined(uniformProvided),
    uniformCost: uniformCost,
    uniformCostDue: uniformCostDue,
  };
};
