import { matchRoutes, useLocation } from "react-router-dom"
import ApplicationRoutes from "utils/navigation/applicationRoutes";

export const useCurrentPath = () => {
  const location = useLocation();

  const routes = Object.entries(ApplicationRoutes).map(([key, value]) => ({
    path: value,
  }));
  const match = matchRoutes(routes, location);
  const [{ route }] = match || [];

  return route.path
}
