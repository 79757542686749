import { FormControlLabel, Switch } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { SwitchInputProps } from './interfaces/switchInputProps';

const SwitchInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
}: SwitchInputProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControlLabel
            label={label}
            control={
              <Switch
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
              />
            }
          />
        );
      }}
    ></Controller>
  );
};

export default SwitchInput;
