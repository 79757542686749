const toBlobUrl = async (file: File): Promise<string> => {
  const arrayBuffer = await file.arrayBuffer();
  const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};

const blobToUrl = (blob: Blob): string => {
  return URL.createObjectURL(blob);
};

const downloadBlob = (blob: Blob, name: string) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

const openBlob = (blob: Blob) => {
  const url = URL.createObjectURL(blob);

  window.open(url, '_blank', 'noreferrer');
};

export { toBlobUrl, downloadBlob, openBlob, blobToUrl };
