import { Box, Grid } from '@mui/material';
import DocumentsTable from 'components/documentsTable';
import { DocumentInfo } from 'components/documentsTable/interfaces/documentInfo';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'utils/formHeader';

export default function ReadOnlyDocuments({
  title,
  rows,
  handleDownload,
  isReadOnly = true,
}: {
  title: string;
  rows: DocumentInfo[];
  handleDownload: (docInfo: DocumentInfo) => Promise<void>;
  isReadOnly?: boolean;
}) {
  const handleDelete = async (_: any) => {};

  return (
    <Box>
      <SectionHeader>{title}</SectionHeader>

      <Grid {...ContainerSpacing}>
        <Grid item xs={12}>
          <DocumentsTable
            handleDelete={handleDelete}
            isReadOnly={isReadOnly}
            rows={rows}
            handleDownload={handleDownload}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
