import { Grid, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const TimeSlotItem = ({
  value,
  isSelected,
  disabled,
}: {
  value: string;
  isSelected: boolean;
  disabled: boolean;
}) => {
  const theme = useTheme();

  const { setValue } = useFormContext();

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.spacing(0.5),
        p: theme.spacing(1, 2),
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: isSelected ? theme.palette.secondary.main : 'unset',
        opacity: disabled ? 0.6 : 1,
      }}
      onClick={() => {
        if (disabled) return;
        setValue('time', value);
      }}
    >
      <Typography
        variant='body2'
        color={isSelected ? 'white' : theme.palette.secondary.main}
        fontWeight={700}
      >
        {value}
      </Typography>
    </Grid>
  );
};

export default TimeSlotItem;
