import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { CellHeaderId } from '../components/interviewsToBookTable/interfaces/interviewsToBookCellHeader';

export const getCells = ({
  applicantName,
  program,
  position,
  hostEntity,
  applicantId,
  email,
  countryOfResidence,
  jobId,
  status,
  skype,
  startsOn,
  endsOn,
  actions,
}: {
  applicantId: string;
  applicantName: string;
  program: string;
  position: string;
  jobId: string;
  hostEntity: string;
  email: string;
  countryOfResidence: string;
  status: string;
  skype: string;
  startsOn: string;
  endsOn: string;
  actions: string;
}): CellHeader<CellHeaderId>[] => {
  return [
    {
      id: 'hostEntity',
      label: hostEntity,
      sortable: true,
    },
    {
      id: 'program',
      label: program,
      sortable: true,
    },
    {
      id: 'applicantId',
      label: applicantId,
      sortable: true,
    },
    {
      id: 'applicantName',
      label: applicantName,
      sortable: true,
    },
    {
      id: 'email',
      label: email,
      sortable: true,
    },
    {
      id: 'status',
      label: status,
      sortable: true,
    },
    {
      id: 'skype',
      label: skype,
      sortable: true,
    },

    {
      id: 'countryOfResidence',
      label: countryOfResidence,
      sortable: true,
    },
    {
      id: 'jobId',
      label: jobId,
      sortable: true,
    },
    {
      id: 'position',
      label: position,
      sortable: true,
    },
    {
      id: 'startsOn',
      label: startsOn,
      sortable: true,
    },
    {
      id: 'endsOn',
      label: endsOn,
      sortable: true,
    },

    {
      id: 'actions',
      label: actions,
      sortable: false,
    },
  ];
};
