import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';

import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { useTranslations } from 'hooks/useTranslations';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';
import { RowNotFound } from 'components/tables/components/rowNotFound';

import { getCells } from '../../utils/table.cells';
import { CellHeaderId } from '../participantsTable/interfaces/participantsCellHeader';
import { ParticipantSummaryResponse } from 'hooks/services/hosts/interfaces/participantSummary.response';
import { useHorizontalScrollBar } from 'hooks/useHorizontalScrollBar';
import {
  BE_DATE_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';

export const ParticipantsListView = ({
  order,
  orderBy,
  sortHandler,
  rows,
  heightToSkip,
  handleClick
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  rows: ParticipantSummaryResponse[];
  heightToSkip: number;
  handleClick: (applicantId: number) => void;
}) => {
  const horizontalScrollBar = useHorizontalScrollBar();
  const {
    participants: {
      participantsTable: { columns },
    },
  } = useTranslations();

  const cells: CellHeader<CellHeaderId>[] = getCells(columns);

  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const margins = isSmallScreen ? 0 : 16 + 16 + 8;

  return (
    <Box
      mt={2}
      pb={1}
      px={'1px'}
      sx={{
        ...ResponsiveContainerProps(heightToSkip + margins),
        ...horizontalScrollBar,
      }}
    >
      <Table stickyHeader>
        <SortableTableHeader<CellHeaderId>
          {...{ order, orderBy, sortHandler, cells }}
        />
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ minWidth: 200 }}>{row.hostEntity}</TableCell>
                <TableCell sx={{ minWidth: 300 }}>{row.program}</TableCell>
                <TableCell
                  sx={{ minWidth: 100, cursor: 'pointer' }}
                  onClick={() => handleClick(row.id)}
                >
                  {row.id}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.ds2019}</TableCell>
                <TableCell sx={{ minWidth: 250 }}>{row.applicantFirstName}</TableCell>
                <TableCell sx={{ minWidth: 250 }}>{row.applicantLastName}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.email}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(row.dateOfBirth, BE_DATE_FORMAT, 'MMM d, yyyy')}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.gender}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.statusCode}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.skypeId}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {row.countryOfResidence}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.jobId}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.position}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(
                    row.programStartDate,
                    BE_DATE_FORMAT,
                    'MMM d, yyyy'
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(
                    row.programEndDate,
                    BE_DATE_FORMAT,
                    'MMM d, yyyy'
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <RowNotFound colSpan={15} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
