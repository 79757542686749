import * as yup from 'yup';
import createProgramTypesValidation from 'pages/placements/common/validations/createProgramTypesValidation';
import { transformDate } from 'utils/date/convertToDate';
import { transformStringToNumber } from 'utils/extensions/numberInputTransformer';
import { convertStringToBoolean } from 'utils/string/stringConverters';

export default function createSchema(
  checkSelectedProgram: (value: string) => boolean
) {
  return yup.object().shape(
    {
      program: yup.string().required().label('Program'),
      programTypes: createProgramTypesValidation(checkSelectedProgram),
      totalPositions: yup
        .number()
        .required()
        .transform(transformStringToNumber)
        .positive()
        .min(1)
        .label('Total Positions'),
      selfArranged: yup
        .boolean()
        .required()
        .transform(convertStringToBoolean)
        .label('Self Arranged'),
      positionTitle: yup.string().required().label('Position Title'),
      supervisor: yup.string().required().label('Supervisor'),
      department: yup
        .string()
        .label('Department')
        .when('department', {
          is: (value?: string) => !!value,
          then: (rule) => rule.min(3).max(1000),
        }),
      englishLevel: yup.string().label('English Level'),
      startDate: yup
        .date()
        .nullable()
        .required()
        .transform(transformDate)
        .label('Start Date'),
      latestStartDate: yup
        .date()
        .nullable()
        .required()
        .transform(transformDate)
        .label('Latest Start Date'),
      endDate: yup
        .date()
        .nullable()
        .required()
        .transform(transformDate)
        .label('End Date'),
      latestEndDate: yup
        .date()
        .nullable()
        .required()
        .transform(transformDate)
        .label('Latest End Date'),
      positionDescription: yup
        .string()
        .required()
        .min(3)
        .max(500)
        .label('Position Description'),
      scheduleDescription: yup
        .string()
        .when('scheduleDescription', {
          is: (value?: string) => !!value,
          then: (rule) => rule.min(3).max(1000),
        })
        .label('Schedule Description'),
      requiredSkills: yup
        .string()
        .when('requiredSkills', {
          is: (value?: string) => !!value,
          then: (rule) => rule.min(3).max(1000),
        })
        .label('Required Skills'),
      requiredExperience: yup
        .string()
        .when('requiredExperience', {
          is: (value?: string) => !!value,
          then: (rule) => rule.min(3).max(1000),
        })
        .label('Required Experience'),
      specialRequirements: yup
        .string()
        .when('specialRequirements', {
          is: (value?: string) => !!value,
          then: (rule) => rule.min(3).max(1000),
        })
        .label('Special Requirements'),
    },
    [
      ['department', 'department'],
      ['scheduleDescription', 'scheduleDescription'],
      ['requiredSkills', 'requiredSkills'],
      ['requiredExperience', 'requiredExperience'],
      ['specialRequirements', 'specialRequirements'],
    ]
  );
}
