import { PaymentFormInputs } from '../../sections/payment/interfaces/paymentFormInputs';

export const getEmptyPaymentValues = (): PaymentFormInputs => {
  return {
    additionalInformation: '',
    allowancesBonusesIncentives: '',
    averageHoursPerWeek: '',
    estimatedTip: '',
    grossWage: '',
    isOvertimeAvailable: '',
    overtimeWage: '',
    paymentSchedule: '',
    wagePer: '',
  };
};
