import {
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import ButtonWithProgress from 'components/button';
import DateRange from 'components/dateRange';
import DropdownInput from 'components/dropdownInput';
import NumberInput from 'components/numberInput';
import {
  PageHeader,
  PageHeaderSubtitle,
} from 'components/page/header/styledHeader';
import {
  ButtonResponsiveProps,
  ButtonsContainer,
  ContainerSpacing,
  PrimaryButtonResponsiveMargin,
} from 'components/page/header/utils';
import { useTranslations } from 'hooks/useTranslations';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import { ClonePlacementProps } from './interfaces/clonePlacementProps';

const ClonePlacement = ({
  wageOptions,
  supervisors,
  control,
  register,
  errors,
  setValue,
  getValues,
  isLoading,
  isCheckboxChecked,
  handleReviewedCheckbox,
  onSubmit,
  handleCancelBtn,
  placementId,
  positionTitle,
}: ClonePlacementProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const { clonePage, paymentSection, employmentSection } = useTranslations();

  return (
    <form
      onSubmit={(e) => onSubmit(e)}
      style={{ height: '100%', overflow: 'hidden' }}
    >
      <Grid {...ContainerSpacing}>
        <Grid item xs={12} md={4} order={1} pb={{ md: 4 }}>
          <PageHeader>
            {clonePage.clonePlacementPageHeader}
            {placementId}
          </PageHeader>
          <PageHeaderSubtitle>{positionTitle}</PageHeaderSubtitle>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 3, md: 2 }}
          pt={{ xs: 0 }}
          pb={4}
        >
          <Grid {...ButtonsContainer}>
            <Button
              variant='outlined'
              sx={{ ...ButtonResponsiveProps }}
              onClick={handleCancelBtn}
            >
              {translations.cancelButton}
            </Button>

            <Grid
              sx={{
                ...ButtonResponsiveProps,
                ...PrimaryButtonResponsiveMargin,
              }}
            >
              <ButtonWithProgress
                dataTestid='continue'
                isLoading={isLoading}
                isDisabled={!isCheckboxChecked}
                text={translations.continueButton}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          rowGap={3}
          order={4}
          xs={12}
          px={0.3}
          ml={0.2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: `calc(100% - ${theme.spacing(15)})`,
            '&.MuiGrid-item': {
              paddingTop: '0 ',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              overflow: 'auto',
              ...NoScrollBar,
              msOverflowX: 'none',
              scrollbarWidth: 'none',
              padding: {
                xs: theme.spacing(1, 0.5, 7, 0.5),
                md: theme.spacing(1, 0.5, 5, 0.5),
              },
            }}
          >
            <Grid item xs={12} lg={6} mb={2}>
              <NumberInput
                label={paymentSection.grossWage}
                useDecimals={true}
                errorMessage={
                  errors?.guaranteedSalaryWageBeforeDeduction?.message
                }
                control={control}
                name='guaranteedSalaryWageBeforeDeduction'
                getValue={() =>
                  getValues('guaranteedSalaryWageBeforeDeduction')
                }
              />
            </Grid>

            <Grid item xs={12} lg={6} mb={2}>
              <DropdownInput
                inputId={paymentSection.wagePerId}
                inputLabel={paymentSection.wagePer}
                items={wageOptions}
                errorMessage={errors?.guaranteedSalaryWagePeriod?.message}
                control={control}
                register={register('guaranteedSalaryWagePeriod')}
                key={'guaranteedSalaryWagePeriod'}
              />
            </Grid>

            <Grid item xs={12} lg={6} mb={2}>
              <DropdownInput
                inputId={employmentSection.supervisorId}
                inputLabel={employmentSection.supervisor}
                items={supervisors}
                errorMessage={errors?.supervisor?.message}
                register={register('supervisor')}
                control={control}
                key={'supervisor'}
              />
            </Grid>

            <Grid item xs={12} lg={6} mb={2}>
              <Grid item sx={{ display: { md: 'flex' } }} columnGap={3}>
                <Grid item md={6}>
                  <DateRange
                    control={control}
                    nameStartDate='earliestStartDate'
                    nameEndDate='earliestEndDate'
                    labelStartDate={employmentSection.startDate}
                    labelEndDate={employmentSection.endDate}
                    startDateValidationMessage={
                      errors?.earliestStartDate?.message
                    }
                    endDateValidationMessage={errors?.earliestEndDate?.message}
                    getStartDate={() => getValues('earliestStartDate')}
                    getEndDate={() => getValues('earliestEndDate')}
                    labelBetween=' '
                  />
                </Grid>

                <Grid item md={6} sx={{ marginTop: { xs: 1, md: 0 } }}>
                  <DateRange
                    control={control}
                    nameStartDate='latestStartDate'
                    nameEndDate='latestEndDate'
                    labelStartDate={employmentSection.latestStartDate}
                    labelEndDate={employmentSection.latestEndDate}
                    startDateValidationMessage={
                      errors?.latestStartDate?.message
                    }
                    endDateValidationMessage={errors?.latestEndDate?.message}
                    getStartDate={() => getValues('latestStartDate')}
                    getEndDate={() => getValues('latestEndDate')}
                    labelBetween=' '
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6} mb={2}>
              <Grid item sx={{ display: { md: 'flex' } }} columnGap={3}>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    label={employmentSection.totalPositions}
                    useDecimals={false}
                    errorMessage={errors?.totalPositions?.message}
                    control={control}
                    name='totalPositions'
                    getValue={() => getValues('totalPositions')}
                  />
                </Grid>
                <Grid item xs={0} md={6} />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
              mb={2}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <Checkbox
                checked={isCheckboxChecked}
                onChange={handleReviewedCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  padding: 0,
                  marginRight: theme.spacing(1),
                  marginTop: theme.spacing(0.2),
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              />

              <Typography>{clonePage.checkbox}</Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ClonePlacement;
