import { Box, InputAdornment, TextField, useTheme } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import DownArrow from 'assets/icons/downArrow.svg';
import UpArrow from 'assets/icons/upArrow.svg';
import InputWrapper from 'components/inputWrapper/inputWrapper';
import ArrowButton from './components/arrowButton';
import { NumberInputProps } from './interfaces/NumberInputProps';

const NumberInput = <TFieldValues extends FieldValues>({
  label,
  labelHeight,
  errorMessage,
  control,
  name,
  onKeyDown,
  handleStep,
  disabled,
}: NumberInputProps<TFieldValues>) => {
  const theme = useTheme();

  return (
    <InputWrapper
      inputId={label}
      inputLabel={label}
      labelHeight={labelHeight}
      errorMessage={errorMessage}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            id={label}
            inputProps={{ 'data-testid': name  }}
            fullWidth
            variant='standard'
            type='text'
            sx={{
              boxShadow: 3,
              '& .MuiInput-input': {
                padding: theme.spacing(2),
                fontSize: theme.input.control.fontSize,
                lineHeight: theme.input.control.lineHeight,
                fontWeight: theme.input.control.fontWeight,
                height: '100%',
                boxSizing: 'border-box',
              },
              '& .MuiInputBase-root:before': {
                borderBottom: 'none',
              },

              '& .MuiInput-underline': {
                border: 'none',
                height: 52,
              },
            }}
            {...field}
            error={!!errorMessage}
            InputProps={{
              onKeyDown: onKeyDown,
              endAdornment: (
                <InputAdornment
                  position='end'
                  style={{
                    margin: 0,
                    padding: 0,
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <ArrowButton
                    name={`button-up`}
                    isUp={true}
                    icon={<Box component='img' src={UpArrow} />}
                    handleStep={handleStep}
                  />
                  <ArrowButton
                    name={`button-down`}
                    isUp={false}
                    icon={<Box component='img' src={DownArrow} />}
                    handleStep={handleStep}
                  />
                </InputAdornment>
              ),
            }}
            autoComplete='off'
            disabled={disabled}
          />
        )}
      />
    </InputWrapper>
  );
};

export default NumberInput;
