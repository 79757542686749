import { Grid, Typography, useTheme } from '@mui/material';

const ModalHeader = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <Grid item mb={theme.spacing(1)}>
      <Typography
        variant='subtitle1'
        fontWeight={theme.typography.fontWeightBold}
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default ModalHeader;
