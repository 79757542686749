import { Box, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';

const NotAllowed = ({
  page,
  text,
  notAllowedText,
}: {
  page: string;
  text?: string;
  notAllowedText?: string;
}) => {
  const translations = useTranslations();

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100%'
      my={10}
    >
      <Typography variant='h6'>
        {page}
        {notAllowedText ?? translations.notAllowed}
        {text}
      </Typography>
    </Box>
  );
};

export default NotAllowed;
