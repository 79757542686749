import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import NoPreview from 'assets/icons/noPreview.svg';
import { useTranslations } from 'hooks/useTranslations';

const WithoutPreview = () => {
  const theme = useTheme();
  const {
    uploadDocument: { withoutPreview },
  } = useTranslations();

  return (
    <Box width='100%' height='100%' display='flex' flexDirection='column'>
      <Box
        width='100%'
        height='100%'
        sx={{ backgroundColor: theme.palette.grey[400] }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        mb={1}
      >
        <Box component='img' alt='No preview' src={NoPreview} />
      </Box>
      <Typography variant='body2' sx={{ color: theme.palette.text.secondary }}>
        {withoutPreview}
      </Typography>
    </Box>
  );
};

export default WithoutPreview;
