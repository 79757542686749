import { ArrivalInstructionsFormInput } from '../interfaces/arrivalInstructionsForm.formInput';

export const arrivalInstructionsInitEmptyValues: ArrivalInstructionsFormInput = {
  program: '',
  description: '',
  nearestInternationalAirport: '',
  nearestAirportToJobsite: '',
  isPickUpProvided: '',
  latestPreferredArrivalDate: '',
  generalArrivalInstructions: '',
  shouldContactHCBeforeArrival: '',
  contactPhoneNumber: '',
  transportPickUpCost: '',
  preferredArrivalDays: '',
  preferredArrivalTimes: '',
  reportingTo: '',
};
