import { useTheme, Drawer } from '@mui/material';

import { AppDrawerProps } from './interfaces/appDrawerProps';
import DrawerContent from './components/drawerContent';
import DesktopOpenCloseDrawer from './components/desktopOpenCloseDrawer/styledDesktopOpenCloseDrawer';
import { useHostService } from 'hooks/services/hosts';
import {useCallback, useEffect, useRef, useState} from 'react';
import { MenuItemsResponse } from 'hooks/services/hosts/interfaces/menu-item.response';
import { useCurrentPath } from 'hooks/useCurrentPath';
import { useUser } from 'hooks/useUser';

const AppDrawer = ({ menuOpen, handleMenuOpen }: AppDrawerProps) => {
  const { host } = useUser();
  const theme = useTheme();
  const path = useCurrentPath();
  const { getMenu } = useHostService();

  const [menuItems, setMenuItems] = useState<MenuItemsResponse>([]);

  const isFetching = useRef(false);

  const fetchMenu = useCallback(async () => {
    if (host && !isFetching.current) {
      isFetching.current = true;
      try {
        const menuItems = await getMenu({ hostId: host.id });
        setMenuItems(menuItems);
      } catch (error) {
        console.log(error);
      } finally {
        isFetching.current = false;
      }
    }
  }, [getMenu, host]);

  useEffect(() => {
    fetchMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);


  if (!host) return null;
  
  console.log('logging the host menu')
  console.log(host);

  return (
    <>
      {/* desktop drawer */}
      <DesktopOpenCloseDrawer
        variant='permanent'
        open={menuOpen}
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
      >
        <DrawerContent
          open={menuOpen}
          menuItems={menuItems}
          activeHostId={host.id.toString()}
          path={path}
          accessApplicants={host.accessapplicants === 'true'}
        />
      </DesktopOpenCloseDrawer>

      {/* mobile drawer */}
      <Drawer
        variant='temporary'
        open={menuOpen}
        onClose={handleMenuOpen}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: theme.appDrawer.openWidth,
          },
        }}
      >
        <DrawerContent
          open={menuOpen}
          menuItems={menuItems}
          activeHostId={host.id.toString()}
          path={path}
          accessApplicants={host.accessapplicants === 'true'}
        />
      </Drawer>
    </>
  );
};

export default AppDrawer;
