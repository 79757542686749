import {
  Box,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Expand from 'assets/icons/expandPlacement.svg';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { PlacementAction } from 'pages/placements/placementsList/interfaces/placementAction';
import EmptyTable from '../emptyTable';
import MobileDetails from '../mobileDetails';
import Status from '../status';

const StyledTableCellTypography = styled(Typography)(() => ({
  fontSize: 'inherit',
}));

const MobileTable = ({
  rows,
  handlePlacementAction,
  placementDetails,
  showDetails,
}: {
  rows: PlacementSummaryResponse[];
  handlePlacementAction: (
    placement: PlacementSummaryResponse,
    action: PlacementAction
  ) => void;
  showDetails: (value: PlacementSummaryResponse | undefined) => void;
  placementDetails: PlacementSummaryResponse | undefined;
}) => {
  const theme = useTheme();

  return (
    <>
      <MobileDetails
        placementDetails={placementDetails!}
        handleClose={() => showDetails(undefined)}
        handlePlacementAction={handlePlacementAction}
      />

      <Table>
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Box display='flex' flexDirection='column'>
                    <StyledTableCellTypography>
                      {row.program}
                    </StyledTableCellTypography>
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{
                        mt: 1,
                        '> *:last-child': {
                          ml: 'auto',
                        },
                      }}
                    >
                      <StyledTableCellTypography>
                        <Link
                          component='button'
                          variant='caption'
                          underline='hover'
                          onClick={() =>
                            handlePlacementAction(row, PlacementAction.Open)
                          }
                        >
                          {row.id}
                        </Link>
                      </StyledTableCellTypography>
                      <Divider
                        orientation='vertical'
                        sx={{ mr: 1.5, ml: 1.5, height: theme.spacing(2.5) }}
                      />
                      <StyledTableCellTypography>
                        {row.department}
                      </StyledTableCellTypography>
                      <Status placement={row} />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell width={theme.spacing(7)}>
                  <IconButton onClick={() => showDetails(row)}>
                    <Box component='img' alt='Show Details' src={Expand} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align='center' colSpan={2}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default MobileTable;
