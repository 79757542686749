import { useState } from 'react';
import { MobileViewItemProps } from '../../interfaces/mobileViewItemProps';
import MobileViewItem from './mobileViewItem';

const MobileViewItemContainer = ({
  icon,
  status,
  type,
  button,
  remarks,
}: MobileViewItemProps) => {
  const [displayAllRemarks, setDisplayAllRemarks] = useState(false);

  const toggleAllRemarks = () => {
    setDisplayAllRemarks(!displayAllRemarks);
  };

  return (
    <MobileViewItem
      icon={icon}
      status={status}
      type={type}
      button={button}
      remarks={remarks}
      enableAllRemarks={displayAllRemarks}
      toggleAllRemarks={toggleAllRemarks}
    />
  );
};

export default MobileViewItemContainer;
