import { FieldValues, useController } from 'react-hook-form';
import { addDecimal, addInteger, handleStep, removeNumber } from './helpers';
import { NumberInputContainerProps } from './interfaces/NumberInputContainerProps';
import NumberInput from './numberInput';

const NumberInputContainer = <TFieldValues extends FieldValues>({
  label,
  labelHeight,
  errorMessage,
  useDecimals,
  step,
  getValue,
  control,
  name,
  disabled = false,
}: NumberInputContainerProps<TFieldValues>) => {
  const {
    field: { onChange },
  } = useController({ control, name });
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const isNumericKey = new RegExp('^[0-9]$').test(event.key);
    const oldValue = getValue();
    let newValue = '';

    if (isNumericKey) {
      newValue = useDecimals
        ? addDecimal(oldValue, event.key)
        : addInteger(oldValue, event.key);
      onChange(newValue);
    }

    switch (event.key) {
      case 'Backspace':
        if (useDecimals && Number(oldValue) > 0) {
          newValue = removeNumber(oldValue, useDecimals);
        }

        if (!useDecimals && oldValue.length > 1) {
          newValue = removeNumber(oldValue, useDecimals);
        }
        onChange(newValue);
        break;

      case 'ArrowUp':
      case 'ArrowRight':
        newValue = handleStep(true, oldValue, useDecimals, step);
        onChange(newValue);
        break;

      case 'ArrowDown':
      case 'ArrowLeft':
        newValue = handleStep(false, oldValue, useDecimals, step);
        onChange(newValue);
        break;
    }
  };

  return (
    <NumberInput
      label={label}
      labelHeight={labelHeight}
      errorMessage={errorMessage}
      onKeyDown={onKeyDown}
      control={control}
      name={name}
      handleStep={(isSum: boolean) => {
        const value = handleStep(isSum, getValue(), useDecimals, step);
        onChange(value);
      }}
      disabled={disabled}
    />
  );
};

export default NumberInputContainer;
