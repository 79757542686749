import { Box, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import ButtonWithProgress from 'components/button';
import DocumentsTable from 'components/documentsTable';
import DropdownYesNo from 'components/dropdownYesNo';
import FileUpload from 'components/fileUpload';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import TextareaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { DocumentsSectionProps } from './interfaces/documentsSectionProps';
import EditPlacementContext from '../../editPlacement.context';

const Documents = ({
  onSubmit,
  control,
  register,
  files,
  setFile,
  errors,
  tableProps,
}: DocumentsSectionProps) => {
  const theme = useTheme();

  const { inputStyle, yesOrNoInput, inputsGridFullWidth, inputsSection } =
    useFormInputsStyle(theme);

  const { documentsSection } = useTranslations();

  const {
    state: { isLoading, isReadOnly },
  } = useContext(EditPlacementContext);

  return (
    <Box>
      <SectionHeader>{documentsSection.documents}</SectionHeader>

      <Grid {...ContainerSpacing}>
        <Grid item xs={12} sx={{ display: isReadOnly ? 'none' : 'unset' }}>
          <Grid {...inputsSection}>
            <Grid {...inputStyle(8, 6)}>
              <FileUpload
                inputId={documentsSection.uploadFile}
                inputLabel={documentsSection.uploadFile}
                files={files}
                setFile={setFile}
                register={register('files')}
                errorMessage={errors.files?.message}
              />
            </Grid>

            <Grid {...yesOrNoInput}>
              <DropdownYesNo
                inputId={documentsSection.documentsSignatureRequired}
                inputLabel={documentsSection.documentsSignatureRequired}
                control={control}
                register={register('requiredSignature')}
                errorMessage={errors?.requiredSignature?.message}
              />
            </Grid>
          </Grid>

          <Grid {...inputsSection}>
            <Grid {...inputsGridFullWidth}>
              <TextareaInput
                inputId={documentsSection.documentsDescription}
                inputLabel={documentsSection.documentsDescription}
                register={register('description')}
                errorMessage={errors?.description?.message}
                control={control}
                maxLength={1000}
              />
            </Grid>
          </Grid>

          <Grid item xs={0} md={8} />

          <Grid item xs={12} md={4}>
            <ButtonWithProgress
              dataTestid='upload'
              type='button'
              color='secondary'
              onClick={onSubmit}
              text={documentsSection.uploadButton}
              isDisabled={!files || files.length === 0}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <DocumentsTable {...tableProps} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Documents;
