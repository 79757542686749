import { Box, Divider, Drawer } from '@mui/material';
import { ReactElement } from 'react';
import { useHeightRef } from 'hooks/useHeightRef';
import NoScrollBar from 'utils/interfaces/noScrollBar';

const MobileBottomDrawer = ({
  open,
  handleClose,
  children,
  header = undefined,
  footer = undefined,
}: {
  open: boolean;
  handleClose: () => void;
  children: ReactElement;
  header?: ReactElement;
  footer?: ReactElement;
}) => {
  const [top, topRef] = useHeightRef();
  const [bottom, bottomRef] = useHeightRef();
  const contentMaxHeight = top + bottom;

  return (
    <Drawer anchor='bottom' open={open} onClose={() => handleClose()}>
      <Box>
        {header !== undefined && (
          <>
            <Box ref={topRef}>{header}</Box>
            <Divider />
          </>
        )}

        <Box
          sx={{
            maxHeight: `calc(90vh - ${contentMaxHeight}px - 34px)`,
            overflow: 'auto',
            ...NoScrollBar,
          }}
        >
          {children}
        </Box>
        {footer !== undefined && (
          <>
            <Divider />
            <Box ref={bottomRef}>{footer}</Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default MobileBottomDrawer;
