import { GroomingFormInputs } from '../../sections/grooming/interfaces/groomingFormInputs';

export const getEmptyGroomingValues = (): GroomingFormInputs => {
  return {
    dressCode: '',
    uniformCost: '',
    uniformProvided: '',
    uniformCostDue: '',
  };
};
