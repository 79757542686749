import { TrainingOrientationFormInputs } from "../../sections/trainingOrientation/interfaces/trainingOrientationFormInputs";

export function getEmptyTrainingValues(): TrainingOrientationFormInputs {
  return {
    costDue: '',
    description: '',
    isEVPaidDuring: '',
    length: '',
    payRate: '',
    provided: '',
    providedCost: '',
    willParticipantIncurCost: ''
  };
}
