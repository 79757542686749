export enum PlacementAction {
  Edit,
  Clone,
  Download,
  Submit,
  Confirm,
  Review,
  Preview,
  Open,
}
