import { ReactElement, useState } from 'react';
import LayoutContext from 'context/layout.context';

export const LayoutProvider = ({ children }: { children: ReactElement }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <LayoutContext.Provider value={{ menuOpen, setMenuOpen }}>
      {children}
    </LayoutContext.Provider>
  );
};
