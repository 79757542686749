import { Box, Grid, InputAdornment, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import Email from 'assets/icons/email.svg';
import { useTranslations } from 'hooks/useTranslations';

const EmailInput = ({
  defaultValue,
  register,
  formErrors,
}: {
  defaultValue: string | null;
  register: UseFormRegister<any>;
  formErrors: {
    email?: {
      message?: string;
    };
  };
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  return (
    <Grid item sx={{ height: theme.spacing(13) }}>
      <InputLabel htmlFor='email'>
        {translations.email}
      </InputLabel>

      <TextField
        id='email'
        inputProps={{ 'data-testid': 'email' }}
        defaultValue={defaultValue}
        fullWidth
        variant='standard'
        sx={{
          marginTop: 0.5,
          boxShadow: 3,
          'input': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        {...register('email')}
        error={formErrors.email?.message ? true : false}
        InputProps={{
          style: {
            height: 52,
            padding: theme.spacing(0, 1),
          },
          startAdornment: (
            <InputAdornment position='start'>
              <Box
                component='img'
                alt='email-icon'
                data-testid='email-icon'
                src={Email}
              />
            </InputAdornment>
          ),
        }}
      />
      {formErrors.email && (
        <Typography role='alert' color={theme.palette.error.main}>
          {formErrors.email?.message}
        </Typography>
      )}
    </Grid>
  );
};

export default EmailInput;
