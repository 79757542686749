import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from 'assets/icons/editPlacement.svg';
import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { CellType } from 'components/tables/simpleTable/interfaces/cellType';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { useHostService } from 'hooks/services/hosts';
import { ContactResponse } from 'hooks/services/hosts/interfaces/contact.response';
import { useHeightRef } from 'hooks/useHeightRef';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { ButtonWithToolltip } from './components/ButtonWithToolltip';
import { Contacts } from './contacts';

const ContactsContainer = () => {
  const [contacts, setContacts] = useState<ContactResponse[]>([]);
  const [, paginationRef] = useHeightRef();
  const [rowsTotal, setRowsTotal] = useState(0);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });

  const { host } = useUser();

  const { hostId } = useParams<{ hostId: string; }>();
  const navigate = useNavigate();
  const { getPaginatedContacts: getContacts } = useHostService();
  const translations = useTranslations();

  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);
    const response = await getContacts({
      hostId: Number(hostId),
      ...data,
    });
    setContacts(response.records);
    setRowsTotal(response.total);
  };

  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } =
    usePaginationHook(handleTableChanged);

  const { title, editButtonTooltip, table, addButtonLabel } =
    translations.contacts;

  const { header } = table;

  const fetchContacts = async () => {
    const response = await getContacts({
      hostId: Number(hostId),
      ...tableParams,
    });
    setContacts(response.records);
    setRowsTotal(response.total);
  };

  const handleOnEdit = (id: string) => {
    if (!hostId) {
      return;
    }

    navigate(
      ApplicationRoutes.contactsForm
        .replace(':hostId', hostId)
        .replace(':contactId', id)
    );
  };

  const rows: { values: CellType[]; }[] = useMemo(() => {
    return contacts.map(
      ({ id, type, name, email, directPhone, mobilePhone, title, fax }) => ({
        values: [
          type,
          name,
          email,
          directPhone,
          mobilePhone,
          title,
          fax,
          <ButtonWithToolltip
            key={id}
            title={editButtonTooltip}
            icon={EditIcon}
            onClick={() => handleOnEdit(id.toString())}
          />,
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Contacts
      hostName={host?.name}
      title={title}
      newContactButton={{
        label: addButtonLabel,
        onClick: () => handleOnEdit('new'),
      }}
      tableProps={{
        headers: [
          { title: header.typeLabel, width: '50px' },
          { title: header.nameLabel, width: '250px' },
          { title: header.emailLabel, width: '150px' },
          { title: header.directPhoneLabel, width: '200px' },
          { title: header.mobilePhoneLabel, width: '150px' },
          { title: header.positionLabel, width: '200px' },
          { title: header.faxLabel, width: '70px' },
          { title: header.actionLabel, width: '70px' },
        ],
        rows,
      }}
      paginationProps={{
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        paginationRef,
        rowsTotal,
      }}
    />
  );
};

export default ContactsContainer;
