import { Box, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Locker from 'assets/icons/locker.svg';
import { PasswordInputProps } from './interfaces/passwordInputProps';

const PasswordInput = ({
  autoFocus,
  register,
  formErrors,
  showPassword,
  handleShowPassword,
  handleMouseDownPassword,
  inputLabel,
  inputId,
}: PasswordInputProps) => {
  const theme = useTheme();

  return (
    <Grid item data-testid='passwordInput' sx={{ height: theme.spacing(13) }}>
      <InputLabel htmlFor={inputId} data-testid={inputLabel}>
        {inputLabel}
      </InputLabel>

      <TextField
        id={inputId}
        autoFocus={autoFocus}
        type={showPassword ? 'text' : 'password'}
        fullWidth
        variant='standard'
        sx={{ marginTop: 0.5, boxShadow: 3 }}
        {...register(inputId)}
        error={formErrors && formErrors.message ? true : false}
        inputProps={{ 'data-testid': inputId }}
        InputProps={{
          style: {
            height: 52,
            padding: theme.spacing(0, 1.6),
          },
          startAdornment: (
            <InputAdornment position='start'>
              <Box component='img' alt='password-locker' src={Locker} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {formErrors && formErrors.message && (
        <Typography role='alert' color={theme.palette.error.main}>
          {formErrors.message}
        </Typography>
      )}
    </Grid>
  );
};

export default PasswordInput;
