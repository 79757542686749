import { Grid, Typography } from '@mui/material';
import PrimarySubmitButton from 'components/button';
import { PageHeader } from 'components/page/header/styledHeader';
import { useTranslations } from 'hooks/useTranslations';
import {
  ButtonsContainer,
  ContainerSpacing,
  PrimaryButtonResponsiveMargin,
} from 'utils/formHeader';
import HousingDesktopTable from './components/desktopTable';
import HousingMobileTable from './components/mobileTable';
import { HousingRow } from './interfaces/housingRow';
import { useHeightRef } from 'hooks/useHeightRef';

const Housing = ({
  rows,
  isMobileView,
  navigateToCreateForm,
}: {
  rows: HousingRow[];
  isMobileView: boolean;
  navigateToCreateForm: () => void;
}) => {
  const [, topRef, topHeightWithPaddings] = useHeightRef();

  const translations = useTranslations();
  const { title, subtitle, addNewHousing } = translations.companyHousing;
  const containerProps = { ...ContainerSpacing, height: 'unset' };

  const heightToSkip = topHeightWithPaddings + 70;

  return (
    <Grid {...containerProps}>
      <Grid container item xs={12} ref={topRef}>
        <Grid item md={4} mt={2} mb={2} order={1}>
          <PageHeader>{title}</PageHeader>
        </Grid>

        <Grid item mb={2} order={3}>
          <Typography
            sx={{
              textAlign: 'justify',
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            {subtitle}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} mb={2} order={2}>
          <Grid {...ButtonsContainer}>
            <Grid
              sx={{
                width: { xs: '100%', md: 'unset' },
                ...PrimaryButtonResponsiveMargin,
              }}
            >
              <PrimarySubmitButton
                dataTestid='create'
                onClick={() => {
                  navigateToCreateForm();
                }}
                isLoading={false}
                text={addNewHousing}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {isMobileView ? (
          <HousingMobileTable rows={rows} top={heightToSkip} />
        ) : (
          <HousingDesktopTable rows={rows} heightToSkip={heightToSkip} />
        )}
      </Grid>
    </Grid>
  );
};

export default Housing;
