import { TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { HeaderProps } from './interfaces/headerProps';

export const Header = ({
  columns,
  align,
  isFirstColumnEmpty = false,
}: HeaderProps) => {
  const theme = useTheme();
  const { backgroundColor, fontweight } = theme.tables.collapsibleTable;

  return (
    <TableHead
      sx={{
        backgroundColor,
      }}
    >
      <TableRow>
        {isFirstColumnEmpty && <TableCell />}
        {columns &&
          columns.map(({ title, width }, index) => (
            <TableCell
              key={title}
              align={align}
              sx={{ fontWeight: fontweight, minWidth: width }}
            >
              {title}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
