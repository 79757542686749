import { Box, Grid, Table, TableBody, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { ButtonTable } from 'components/button/buttonTable';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';
import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { RowNotFound } from 'components/tables/components/rowNotFound';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { ApplicantSummaryResponse } from 'hooks/services/hosts/interfaces/applicantSummary.response';
import { useTranslations } from 'hooks/useTranslations';
import { BE_DATE_FORMAT, formatDate } from '../../../../utils/date/convertToDate';
import { getCells } from '../../utils/table.cells';
import { CellHeaderId } from '../applicantsTable/interfaces/applicantsCellHeader';

export const ApplicantsTableView = ({
  order,
  orderBy,
  sortHandler,
  rows,
  heightToSkip,
  handleOpenResumeInNewTab,
  handleOpenVideoInNewTab,
  handleInterviewAction,
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  rows: ApplicantSummaryResponse[];
  heightToSkip: number;
  handleOpenResumeInNewTab: (applicantId: string) => void;
  handleOpenVideoInNewTab: (videoUrl: string) => void;
  handleInterviewAction: (row: ApplicantSummaryResponse) => void;
}) => {
  const {
    applicants: {
      applicantsTable: { columns, buttonsActions },
    },
  } = useTranslations();

  const cells: CellHeader<CellHeaderId>[] = getCells(columns);
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));

  const margins = isSmallScreen ? 0 : 16 + 16 + 8;
  return (
    <Box
      mt={2}
      pb={1}
      px={'1px'}
      sx={{ ...ResponsiveContainerProps(heightToSkip + margins) }}
    >
      <Table stickyHeader>
        <SortableTableHeader<CellHeaderId>
          {...{ order, orderBy, sortHandler, cells }}
        />
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ minWidth: 100 }}>{row.id}</TableCell>
                <TableCell sx={{ minWidth: { xs: 300 } }}>{row.name}</TableCell>
                <TableCell sx={{ minWidth: { xs: 150 } }}>
                  {formatDate(row.dateOfBirth, BE_DATE_FORMAT, 'MMM d, yyyy')}
                </TableCell>
                <TableCell sx={{ textTransform: 'uppercase' }}>
                  {row.gender}
                </TableCell>
                <TableCell sx={{ minWidth: { xs: 200 } }}>
                  {row.countryOfResidence}
                </TableCell>
                <TableCell sx={{ minWidth: { xs: 500 } }} align='center'>
                  <Grid
                    container
                    justifyContent={'space-around'}
                    flexDirection={'row'}
                  >
                    <ButtonTable
                      label={buttonsActions.resume}
                      onClick={() => {
                        handleOpenResumeInNewTab(row.id.toString());
                      }}
                      icon={<SimCardDownloadOutlinedIcon />}
                    />
                    <ButtonTable
                      label={buttonsActions.interview}
                      onClick={() => {
                        handleInterviewAction(row);
                      }}
                      icon={<CalendarMonthOutlinedIcon />}
                    />
                    <ButtonTable
                      label={buttonsActions.video}
                      disabled={!row.video || row.video === 'Not set'}
                      onClick={() => {
                        handleOpenVideoInNewTab(row.video!);
                      }}
                      icon={<PlayCircleFilledWhiteOutlinedIcon />}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <RowNotFound colSpan={15} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
