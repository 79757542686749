import { UseFormSetValue } from 'react-hook-form';

import { InterviewRequirements } from 'hooks/services/placements/interfaces/placement.response';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';
import { convertBooleanToString } from 'utils/string/stringConverters';

export const setInterviewValues = (
  values: InterviewRequirements,
  setValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!values) {
    return;
  }

  setValue(
    'interviews.isApplicationVideoRequired',
    convertBooleanToString(values.isApplicationVideoRequired)
  );

  setValue(
    'interviews.isSponsorInterviewRequired',
    convertBooleanToString(values.isSponsorInterviewRequired)
  );

  setValue(
    'interviews.isHostCompanyInterviewRequired',
    convertBooleanToString(values.isHostCompanyInterviewRequired)
  );
};
