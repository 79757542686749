import { Grid, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import ApplicationAppBar from '../appBar';
import AppDrawer from '../appDrawer';
import { useLayout } from 'hooks/useLayout';

const BlankLayout = ({ children }: { children: ReactElement }) => {
  const theme = useTheme();
  const { menuOpen } = useLayout();

  return (
    <>
      <ApplicationAppBar />
      <AppDrawer />
      <Grid
        container
        sx={{
          overflow: 'auto',
          background: theme.box.boxBackground,
          width: '100%',
          height: '100%',
          pt: `calc(${theme.appBar.height} + 2px)`,
          pl: {
            md: menuOpen
              ? theme.appDrawer.openWidth
              : theme.appDrawer.closeWidth,
          },
          transition: theme.transitions.create('padding-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            overflow: 'hidden',
            height: `calc(100vh - (${theme.appBar.height} + 2px))`,
          }}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default BlankLayout;
