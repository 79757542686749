import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useUser } from './useUser';
import { HostTypeName } from './services/commons/interfaces/host.response';
import { Nullable } from 'utils/interfaces';

export const useGetDefaultView = (): [
  Nullable<boolean>,
  Dispatch<SetStateAction<Nullable<boolean>>>
] => {
  const { host } = useUser();

  const [isTileView, setIsTileView] = useState<Nullable<boolean>>(null);

  const getIsTileView = (hostype: HostTypeName): boolean => {
    if (hostype === 'Camp') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (host) {
      setIsTileView(getIsTileView(host.type.name));
    }
  }, [host]);

  return [isTileView, setIsTileView];
};
