import { useState } from 'react';
import HousingDesktopTable from './housingDesktopTable';
import { HousingRow } from '../../interfaces/housingRow';

const HousingDesktopTableContainer = ({
  rows,
  heightToSkip,
}: {
  rows: HousingRow[];
  heightToSkip: number;
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <HousingDesktopTable
      rows={rows}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      heightToSkip={heightToSkip}
    />
  );
};

export default HousingDesktopTableContainer;
