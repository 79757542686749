import { ReactElement, useState } from 'react';
import LoadingContext from 'context/loading.context';

export const LoadingProvider = ({ children }: { children: ReactElement; }) => {
  const [isLoading, setIsLoading] = useState(false);
  let count = 0;

  const handleIsLoading = (value: boolean) => {
    count = count + (value ? 1 : -1);

    const shouldBeLoading = count > 0;

    setIsLoading(shouldBeLoading);
  };

  return (
    <LoadingContext.Provider value={{ isLoading, handleIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
