import { HousingRequest } from 'hooks/services/placements/interfaces/patchParams.request';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const makeHousing = (data: EditPlacementFormInput): HousingRequest => {
  const { housing } = data;
  const { genderRequirement, selectedHousingsIds, housingType } = housing;
  return {
    housingType,
    genderRequirement,
    selectedHousingsIds: selectedHousingsIds.map((i) => i.id),
  };
};
