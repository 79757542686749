import { Box, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import ConnectForm from 'components/connectForm/connectForm';
import DropdownYesNo from 'components/dropdownYesNo/dropdownYesNo';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import TextareaInput from 'components/textAreaInput/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const Grooming = () => {
  const theme = useTheme();

  const { numberInput, yesOrNoInput, inputsGridFullWidth, inputsSection } =
    useFormInputsStyle(theme);

  const { groomingSection } = useTranslations();

  const {
    state: { isReadOnly },
  } = useContext(EditPlacementContext);

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ register, control, setValue, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>Grooming</SectionHeader>
          <Grid {...ContainerSpacing}>
            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={groomingSection.dressCodeId}
                  inputLabel={groomingSection.dressCode}
                  register={register('grooming.dressCode')}
                  errorMessage={errors?.grooming?.dressCode?.message}
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={groomingSection.uniformProvidedId}
                  inputLabel={groomingSection.uniformProvided}
                  errorMessage={errors.grooming?.uniformProvided?.message}
                  control={control}
                  register={register('grooming.uniformProvided')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...numberInput}>
                <TextareaInput
                  inputId={groomingSection.uniformCost}
                  inputLabel={groomingSection.uniformCost}
                  singleLine={true}
                  numberOfRows={1}
                  errorMessage={errors?.grooming?.uniformCost?.message}
                  control={control}
                  register={register('grooming.uniformCost')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...numberInput}>
                <TextareaInput
                  inputId={groomingSection.uniformCostDue}
                  inputLabel={groomingSection.uniformCostDue}
                  singleLine={true}
                  numberOfRows={1}
                  errorMessage={errors?.grooming?.uniformCostDue?.message}
                  control={control}
                  register={register('grooming.uniformCostDue')}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};

export default Grooming;
