import * as yup from 'yup';
import { transformStringToNumber } from 'utils/extensions/numberInputTransformer';
import createProgramTypesValidation from '../../common/validations/createProgramTypesValidation';

export default function createSchema(
  checkSelectedProgram: (value: string) => boolean
) {
  return yup.object({
    program: yup
      .string()
      .required()
      .label('Program')
    ,
    totalPositions: yup
      .number()
      .transform(transformStringToNumber)
      .positive()
      .min(1)
      .max(9999)
      .required()
      .label('Total Positions')
    ,
    positionDescription: yup
      .string()
      .min(3)
      .max(500)
      .required()
      .label('Position Description'),
    programTypes: createProgramTypesValidation(checkSelectedProgram),
  });
}
