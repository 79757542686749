import { styled, Typography } from '@mui/material';

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

export const PageHeader = styled(StyledHeader)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  lineHeight: theme.header.pageHeader.lineHeight,
  marginBottom: theme.spacing(1),
}));

export const PageHeaderSubtitle = styled(StyledHeader)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.header.pageHeaderSubtitle.lineHeight,
}));

export const PageSectionSubtitle = styled(StyledHeader)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.header.pageHeaderSubtitle.lineHeight,
  marginBottom: theme.spacing(1.5),
}));

export const SectionHeader = styled(StyledHeader)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: theme.header.sectionHeader.lineHeight,
  marginBottom: theme.spacing(7),

  [theme.breakpoints.only('xs')]: {
    marginBottom: theme.spacing(5),
  },
}));
