import DatePickerInput from 'components/datePickerInput';
import DropdownMultiselectInput from 'components/dropdownInputMultiselect';
import SwitchInput from 'components/switchInput';
import { useTranslations } from 'hooks/useTranslations';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const Programs = ({
  control,
  programs,
}: Pick<FiltersFormProps, 'control' | 'programs'>) => {
  const translations = useTranslations();
  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.placementsList.placementsFilters.programs}
      inputLabel={translations.placementsList.placementsFilters.programs}
      items={programs}
      name='programs'
    />
  );
};

const Positions = ({
  control,
  titles,
}: Pick<FiltersFormProps, 'control' | 'titles'>) => {
  const translations = useTranslations();

  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.placementsList.placementsFilters.positions}
      inputLabel={translations.placementsList.placementsFilters.positions}
      items={titles}
      name='positions'
    />
  );
};

const Statuses = ({
  control,
  statuses,
}: Pick<FiltersFormProps, 'control' | 'statuses'>) => {
  const translations = useTranslations();

  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.placementsList.placementsFilters.statuses}
      inputLabel={translations.placementsList.placementsFilters.statuses}
      items={statuses}
      name='statuses'
    />
  );
};

const ApprovedDate = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.placementsList.placementsFilters.approvedDate}
      control={control}
      name='approvedDate'
      getValue={() => getValues('approvedDate')}
    />
  );
};

const ShowOnlyMatched = ({ control }: Pick<FiltersFormProps, 'control'>) => {
  const translations = useTranslations();

  return (
    <SwitchInput
      control={control}
      name='onlyMatched'
      label={translations.placementsList.placementsFilters.showOnlyMatched}
    />
  );
};

const ShowOnlyNotConfirmed = ({
  control,
}: Pick<FiltersFormProps, 'control'>) => {
  const translations = useTranslations();

  return (
    <SwitchInput
      control={control}
      name='onlyNotConfirmed'
      label={translations.placementsList.placementsFilters.showOnlyNotConfirmed}
    />
  );
};

export {
  ApprovedDate,
  Positions,
  Programs,
  ShowOnlyMatched,
  ShowOnlyNotConfirmed,
  Statuses,
};
