import { useFetch } from '../../useFetch';
import { ChangePasswordRequest } from './interfaces/changePassword.request';
import { ResetPasswordRequest } from './interfaces/resetPassword.request';
import { LoginRequest } from './interfaces/login.request';
import { ChangePasswordResponse } from './interfaces/changePassword.response';
import { LoginResponse } from './interfaces/login.response';
import { UserResponse } from './interfaces/user.response';
import { UserRequest } from './interfaces/user.request';

export const useAccountsService = () => {
  const { get, post, patch } = useFetch();

  const controllerName = 'accounts';

  const getUserInfo = async (hostId: number): Promise<UserResponse> => {
    const resource = `host/${hostId}/user`;
    return await get<UserResponse>(resource);
  };

  const postChangePassword = async (
    body: ChangePasswordRequest
  ): Promise<ChangePasswordResponse> => {
    return await post<ChangePasswordResponse>(
      `${controllerName}/change-password`,
      {
        body,
      }
    );
  };

  const postResetPassword = async (
    body: ResetPasswordRequest
  ): Promise<void> => {
    await post(`${controllerName}/reset-password`, { body });
  };

  const postLogin = async (body: LoginRequest): Promise<LoginResponse> => {
    return await post<LoginResponse>(`${controllerName}/login`, { body });
  };

  const patchUpdateProfile = async ({
    body,
    hostId,
  }: UserRequest): Promise<void> => {
    await patch(`host/${hostId}/user`, { body });
  };

  return {
    getUserInfo,
    postChangePassword,
    postResetPassword,
    postLogin,
    patchUpdateProfile,
  };
};
