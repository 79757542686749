export function makeQueryParamsFromObject(obj: Record<string, any>) {
  const queryParams = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === null || value === '') {
      return;
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        queryParams.append(key, value.join(','));
      }
    } else {
      queryParams.append(key, value.toString());
    }
  });

  const query = queryParams.toString();

  return query.length > 0 ? `?${query}` : '';
}

export function makeDefaultPagedRequestQuery() {
  const pagedRequest = {
    pageSize: 1000,
    pageNumber: 0,
  };

  return makeQueryParamsFromObject(pagedRequest);
}
