import TileComponent from 'components/tile';
import { ApplicantSummaryResponse } from 'hooks/services/hosts/interfaces/applicantSummary.response';
import TileViewWrapper from 'components/responsiveListView/components/tileViewWrapper';

const ApplicantsTileView = ({
  items,
  heightToSkip,

  handleInterviewAction
}: {
  items?: ApplicantSummaryResponse[];
  heightToSkip: number;
  handleInterviewAction: (row: ApplicantSummaryResponse) => void;

}) => {
  return (
    <TileViewWrapper<ApplicantSummaryResponse>
      items={items}
      heightToSkip={heightToSkip}
    >
      <>
        {items?.map((item) => {
          const { id, name, avatarUrl, program, availableFrom, availableTo, video, isInterviewBooked } = item
          return (
            <TileComponent
              key={id}
              id={id}
              header={name}
              title={program}
              availableFrom={availableFrom}
              availableTo={availableTo}
              imgSrc={avatarUrl}
              isInterviewLink
              isInterviewBooked={isInterviewBooked}
              videoLink={video}
              handleInterviewAction={() => { handleInterviewAction(item) }}
            />
          )
        })}
      </>
    </TileViewWrapper>
  );
};

export default ApplicantsTileView;
