import { Box, Chip } from '@mui/material';
import { ColoredChipProps } from './interfaces/coloredChipProps';

const ColoredChip = ({
  color,
  text,
  img,
  size = 'small',
}: ColoredChipProps) => {
  return (
    <Chip
      size={size}
      label={text}
      color={color}
      icon={img ? <Box component='img' src={img} /> : undefined}
    />
  );
};

export default ColoredChip;
