import { ParticipantSummaryResponse } from 'hooks/services/hosts/interfaces/participantSummary.response';
import TileViewWrapper from 'components/responsiveListView/components/tileViewWrapper';
import TileComponent from 'components/tile';
import { Box } from '@mui/material';

const ParticipantsTileView = ({
  items,
  heightToSkip,
  handleClick, 
}: {
  items?: ParticipantSummaryResponse[];
  heightToSkip: number;
  handleClick: (applicantId: number) => void;
}) => {
  return (
    <TileViewWrapper<ParticipantSummaryResponse>
      items={items}
      heightToSkip={heightToSkip}
    >
      <>
        {items?.map((item) => (
          <Box key={item.id} onClick={() => handleClick(item.id)}>
            <TileComponent
              id={item.id}
              header={`${item.applicantFirstName} ${item.applicantLastName}`}
              title={item.program}
              position={item.position}
              availableFrom={item.programStartDate}
              availableTo={item.programEndDate}
              imgSrc={item.avatarUrl}
              isInterviewBooked={item.isInterviewBooked}
            />
          </Box>
        ))}
      </>
    </TileViewWrapper>
  );
};

export default ParticipantsTileView;
