import { Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { FieldValues } from 'react-hook-form';
import CalendarIcon from 'assets/icons/calendar.svg';
import { RenderInput } from './components/renderInput';
import { DatePickerInputProps } from './interfaces/datePickerInputProps';
import InputWrapper from '../inputWrapper';

export const DatePickerInput = <TFieldValues extends FieldValues>({
  format,
  label,
  labelHeight,
  validationMessage,
  open,
  maxDate,
  minDate,
  value,
  control,
  name,
  handleChange,
  handleOpen,
  disabled,
}: DatePickerInputProps<TFieldValues>) => {
  return (
    <InputWrapper
      inputId={label}
      inputLabel={label}
      labelHeight={labelHeight}
      errorMessage={validationMessage}
    >
      <DesktopDatePicker
        open={open}
        onOpen={() => handleOpen(true)}
        onClose={() => handleOpen(false)}
        inputFormat={format}
        value={value}
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        renderInput={(textFieldProps) => (
          <RenderInput
            textFieldProps={{
              ...textFieldProps,
              autoComplete: 'off',
              disabled: disabled,
            }}
            openModal={() => (disabled ? null : handleOpen(true))}
            control={control}
            name={name}
            label={label}
            validationMessage={validationMessage}
          />
        )}
        components={{
          OpenPickerIcon: () => <Box component='img' src={CalendarIcon} />,
        }}
      />
    </InputWrapper>
  );
};
