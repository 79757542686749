import { Box, Button, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslations } from 'hooks/useTranslations';

const MobileFiltersBar = ({
  setOpen,
  additionalChildren = undefined,
}: {
  setOpen: (value: React.SetStateAction<boolean>) => void;
  additionalChildren?: ReactElement[];
}) => {
  const translations = useTranslations();
  const theme = useTheme();

  return (
    <Box
      display='flex'
      alignItems='flex-end'
      justifyContent='space-between'
      m={additionalChildren ? theme.spacing(2, 1) : 0}
    >
      <Button
        sx={!additionalChildren ? { width: '100%' } : null}
        variant='outlined'
        startIcon={<FilterListIcon color='primary' />}
        onClick={() => setOpen(true)}
      >
        {translations.responsiveListView.mobileFiltersBar.filterButton}
      </Button>

      {additionalChildren !== undefined &&
        additionalChildren.map((child) => child)}
    </Box>
  );
};

export default MobileFiltersBar;
