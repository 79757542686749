import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotAllowed from 'components/notAllowed/notAllowed';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { useHostService } from 'hooks/services/hosts';
import { ApplicantsSummaryRequest } from 'hooks/services/hosts/interfaces/applicantsSummary.request';
import { ApplicantSummaryResponse } from 'hooks/services/hosts/interfaces/applicantSummary.response';
import { ProgramsResponse } from 'hooks/services/hosts/interfaces/program.response';
import { useGetDefaultView } from 'hooks/useGetDefaultView';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { BE_DATE_FORMAT, DEFAULT_FORMAT, formatDate } from 'utils/date/convertToDate';
import ApplicantsList from './applicantsList';
import { FilterParams } from './components/applicantsTable/interfaces/filterParams';
import { FiltersFormInputs } from './components/filters/interfaces/filtersFormInput';
import { FiltersApplicantsProps } from './components/filters/interfaces/filtersProps';

const ApplicantsListContainer = () => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const { getApplicantsFilters, getApplicants } = useHostService();
  const [isTileView, setIsTileView] = useGetDefaultView();
  const [filters, setFilters] = useState<FiltersApplicantsProps | null>(null);
  const [allPrograms, setAllPrograms] = useState<ProgramsResponse>([]);
  const [rows, setRows] = useState<ApplicantSummaryResponse[]>([]);
  const [rowsTotal, setRowsTotal] = useState(100);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [filterParams, setFilterParams] = useState<FilterParams>();
  const [isNotAllowed, setIsNotAllowed] = useState<boolean>(false);
  const { host } = useUser();
  const translations = useTranslations();

  const onApplyFilter = async (data?: FiltersFormInputs) => {
    if (data) {
      const updatedFilter = {
        ...data,
        availableFrom: formatDate(
          data.availableFrom,
          DEFAULT_FORMAT,
          BE_DATE_FORMAT
        ),
        availableTo: formatDate(
          data.availableTo,
          DEFAULT_FORMAT,
          BE_DATE_FORMAT
        ),
      } as FilterParams;

      setFilterParams(updatedFilter);

      await getApplicantsHandler({
        ...updatedFilter,
        ...tableParams,
        hostId: parsedHostId,
      });
    } else {
      setFilterParams({});

      await getApplicantsHandler({ ...tableParams, hostId: parsedHostId });
    }
  };

  const handleChangeView = () => {
    setIsTileView(!isTileView);
  };
  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await getApplicantsHandler({
      ...data,
      ...filterParams,
      hostId: parsedHostId,
    });
  };

  const getApplicantsHandler = async (
    data: ApplicantsSummaryRequest & { hostId: number }
  ) => {
    const response = await getApplicants(data);

    setRowsTotal(response.total);
    setRows(response.records);
  };

  const handleSetInterview = (applicant: ApplicantSummaryResponse) => {
    setRowsTotal(rowsTotal - 1);
    setRows(rows.filter((item) => item.id !== applicant.id));
  };

  const fetchApplicants = useCallback(async () => {
    try {
      const data = {
        ...filterParams,
        ...tableParams,
        hostId: parsedHostId,
      };
      await getApplicantsHandler(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await getApplicantsFilters(parsedHostId);
      setFilters({
        ...response,
        programs: response.programs.map((item) => ({
          key: item.id.toString(),
          value: item.name,
        })),
      });
      setAllPrograms(response.programs);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (host) {
        fetchApplicants();
        fetchFilterOptions();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);

  if (isNotAllowed) {
    return (
      <NotAllowed
        page={translations.applicants.title}
        notAllowedText={translations.applicants.notAllowed}
      />
    );
  }

  if (!filters) return null;

  return (
    <ApplicantsList
      {...{
        isTileView,
        handleChangeView,
        filterProps: {
          allPrograms,
          filters,
          onApplyFilter,
        },
        tableProps: {
          rows,
          rowsTotal,
          handleTableChanged,
        },
        handleSetInterview,
      }}
    />
  );
};

export default ApplicantsListContainer;
