import { useFormContext, useWatch } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import Trash from 'assets/icons/trashDarkBlue.svg';
import { AvailableDateContext } from '../../utils/AvailableDateContext';
import {
  adjustTimeBy15Minutes,
  getTimeInMillis,
  isStartTimeGreater,
} from '../../utils';

const TimeSlot = ({
  index,
  startName,
  endName,
  isLastItem,
  startTimeArray,
  endTimeArray,
  disabled
}: {
  index: number;
  startName: string;
  endName: string;
  isLastItem: boolean;
  startTimeArray: KeyValueItemProps[];
  endTimeArray: KeyValueItemProps[];
  disabled: boolean;
}) => {
  const { control, setValue } = useFormContext();
  const dateCtx = useContext(AvailableDateContext);
  const theme = useTheme();
  const { resetField, register } = useFormContext();

  const startNameValue = useWatch({ control, name: startName });
  const endNameValue = useWatch({ control, name: endName });

  const removePeriod = () => dateCtx.remove(index);

  useEffect(() => {
    if (isStartTimeGreater(startNameValue, endNameValue)) {
      resetField(endName, { defaultValue: '' });
    } else if (startNameValue && endNameValue) {
      const startMillis = getTimeInMillis(startNameValue);
      const endMillis = getTimeInMillis(endNameValue);
      if (startMillis && endMillis && startMillis === endMillis) {
        const newEndValue = adjustTimeBy15Minutes(startNameValue, true);
        setValue(endName, newEndValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startNameValue]);

  useEffect(() => {
    if (isStartTimeGreater(startNameValue, endNameValue)) {
      resetField(startName, { defaultValue: '' });
    } else if (startNameValue && endNameValue) {
      const startMillis = getTimeInMillis(startNameValue);
      const endMillis = getTimeInMillis(endNameValue);
      if (startMillis && endMillis && startMillis === endMillis) {
        const newStartValue = adjustTimeBy15Minutes(endNameValue, false);
        setValue(startName, newStartValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endNameValue]);

  return (
    <Grid
      container
      gap={1}
      alignItems={'center'}
      mb={!isLastItem ? theme.spacing(2) : 0}
    >
      <Grid item minWidth={100}>
        <DropdownInput
          control={control}
          inputId={startName}
          items={startTimeArray}
          register={register(startName)}
          sxSelect={{
            height: 30,
            '& .MuiSelect-select': {
              padding: 1,
            },
          }}
          disabled={disabled}
        />
      </Grid>
      -
      <Grid item minWidth={100} mr={theme.spacing(1)}>
        <DropdownInput
          control={control}
          inputId={endName}
          items={endTimeArray}
          register={register(endName)}
          sxSelect={{
            height: 30,
            '& .MuiSelect-select': {
              padding: 1,
            },
          }}
          disabled={disabled}
        />
      </Grid>
      {!disabled && (
        <Grid
          item
          sx={{ cursor: 'pointer', mt: theme.spacing(1) }}
          onClick={removePeriod}
        >
          <Box component='img' alt='' src={Trash} />
        </Grid>
      )}
    </Grid>
  );
};
export default TimeSlot;
