import { TextField, TextFieldProps } from '@mui/material';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CustomStaticDatePickerProps } from './interfaces/customStaticDatePickerProps';

export const CustomStaticDatePicker = ({
  value,
  onChange,
  renderDayCustom,
  values,
  extraSelectedDates,
  minDate,
  disablePast,
}: CustomStaticDatePickerProps) => {
  return (
    <StaticDatePicker
      data-testid='date-input'
      displayStaticWrapperAs='desktop'
      value={value}
      onChange={onChange}
      disablePast={disablePast}
      minDate={minDate}
      renderDay={(day: Date, _, pickersDayProps: PickersDayProps<Date>) => {
        if (values) {
          return renderDayCustom(day, pickersDayProps, values);
        } else {
          return renderDayCustom(
            day,
            pickersDayProps,
            value,
            extraSelectedDates
          );
        }
      }}
      renderInput={(params: TextFieldProps) => <TextField {...params} />}
    />
  );
};
