import { UseFormSetValue } from 'react-hook-form';

import { UserResponse } from 'hooks/services/accounts/interfaces/user.response';

import { ProfileFormInputs } from 'pages/profile/interfaces/profileFormInputs';

export const setValues = (
  { email, firstName, lastName, skypeAddress, timeZone }: UserResponse,
  setValue: UseFormSetValue<ProfileFormInputs>
) => {
  setValue('email', email);
  setValue('firstName', firstName);
  setValue('lastName', lastName);
  setValue('skypeAddress', skypeAddress);
  setValue('timeZone', timeZone);
};
