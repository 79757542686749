import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import UploadIcon from 'assets/icons/upload.svg';
import { useTranslations } from 'hooks/useTranslations';
import { DragDropUploadZoneProps } from './dragDropUploadZoneProps';

const DragDropUploadZone = ({
  getRootProps,
  getInputProps,
  open,
  acceptedFiles,
  fileRejections,
}: DragDropUploadZoneProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const { dragAndDropMessage, or, browse } = translations.uploadDocument;

  const files = acceptedFiles.map((file) => {
    return (
      <Typography key={file.name}>
        {file.name} - {file.size} bytes
      </Typography>
    );
  });

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: {
          xs: 'auto',
          md: 400
        },
        padding: theme.spacing(2),
        border: `${theme.spacing(0.3)} dashed ${theme.palette.grey[400]}`,
        borderRadius: theme.spacing(2),
      }}
    >
      <Grid
        item
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <input {...getInputProps()} />

        <Box
          component='img'
          alt='upload'
          src={UploadIcon}
          sx={{
            height: 90,
            width: 90,
          }}
        />

        <Typography>{dragAndDropMessage}</Typography>

        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 1,
          }}
        >
          <Typography sx={{ marginRight: theme.spacing(0.8) }}>{or}</Typography>
          <Link
            onClick={open}
            sx={{
              cursor: 'pointer',
              color: theme.palette.secondary.main,
            }}
          >
            {browse}
          </Link>
        </Grid>
      </Grid>

      {files
        ? files.length > 0 && (
          <Typography
            sx={{
              marginTop: { xs: 'none', md: theme.spacing(2) }
            }}
          >
            {files}
          </Typography>
        )
        : null}

      {fileRejections
        ? fileRejections.length > 0 && (
          <Typography
            sx={{
              marginTop: { xs: 'none', md: theme.spacing(2) }
            }}
          >
            {fileRejections[0].errors[0].message}
          </Typography>
        )
        : null}
    </Grid>
  );
};

export default DragDropUploadZone;
