import * as yup from 'yup';
import { transformStringToNumberOrUndefined } from 'utils/extensions/numberInputTransformer';
import {
  convertToBoolean,
  convertToBooleanOrUndefined,
  convertToNullForValidation,
} from 'utils/string/stringConverters';

const fieldExistsAnyAffordableHousingOptionsNearby = yup
  .boolean()
  .transform(convertToBoolean)
  .label(
    'Is there safe, affordable housing close to the training/worksite(s)?'
  );

const fieldAproximateCostOfHousingPerMonthPerExchangeVisitor = yup
  .number()
  .transform(transformStringToNumberOrUndefined)
  .label('The approximate cost of the housing per month per Exchange Visitor');

const fielOtherVisaProgramsDescription = yup
  .string()
  .max(250)
  .nullable()
  .label('Please describe the other visa programs');

export const schema = yup.object().shape({
  address: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(150)
    .label(
      'Which address will the Exchange Visitor(s) be working/training at? Is the site based out of a home/residence?'
    ),
  hasSupervisor: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label('Will there always be a supervisor on-site?'),
  isEnglishPrimaryLanguage: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label('Will English be the primary language used during the program(s)?'),
  hasHelpWithCulturalActivities: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label(
      'Will Host Entity assist with organizing cultural activities for the Exchange Visitor(s)?'
    ),
  isProvided32HoursPerWeek: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label(
      'Will Host Entity provide at least 32 hours a week (not an average of)?'
    ),
  isTrainingOrWorkOverNightRequired: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label(
      'Will Host Entity require Exchange Visitor(s) to train/work overnight?'
    ),
  isHousingProvided: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label('Will Host Entity provide housing?'),
  aproximateCostOfHousingPerMonthPerExchangeVisitor:
    fieldAproximateCostOfHousingPerMonthPerExchangeVisitor.when(
      'isHousingProvided',
      {
        is: true,
        then: fieldAproximateCostOfHousingPerMonthPerExchangeVisitor.required(),
        otherwise:
          fieldAproximateCostOfHousingPerMonthPerExchangeVisitor.nullable(),
      }
    ),
  existsAnyAffordableHousingOptionsNearby:
    fieldExistsAnyAffordableHousingOptionsNearby.when('isHousingProvided', {
      is: false,
      then: fieldExistsAnyAffordableHousingOptionsNearby.required(),
      otherwise: fieldExistsAnyAffordableHousingOptionsNearby.nullable(),
    }),
  hasAnyOtherVisaPrograms: yup
    .boolean()
    .transform(convertToBooleanOrUndefined)
    .label('Is the Host Entity utilizing any other visa programs currently?'),
  otherVisaProgramsDescription: fielOtherVisaProgramsDescription.when(
    'hasAnyOtherVisaPrograms',
    {
      is: true,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }
  ),
});
