import { Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MuiButton from 'components/button';
import { Container } from 'components/login/container/styledContainer';
import Header from 'components/login/header';
import LoginLink from 'components/login/loginLink';
import Wrapper from 'components/login/wrapper';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';

const EmailHasBeenSent = ({
  handleSetIsEmailHasBeenSent,
}: {
  handleSetIsEmailHasBeenSent: () => void;
}) => {
  const { emailHasBeenSentPage } = useTranslations();

  return (
    <Wrapper>
      <Container item xs={4} md={8} lg={4} xl={3}>
        <Header
          title={emailHasBeenSentPage.emailHasBeenSentTitle}
          subtitle={emailHasBeenSentPage.emailHasBeenSentSubtitle}
        />

        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Link
            component={RouterLink}
            to={ApplicationRoutes.signIn}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              textDecoration: 'unset',
            }}
          >
            <MuiButton
              dataTestid="login"
              onClick={handleSetIsEmailHasBeenSent}
              text={emailHasBeenSentPage.goToLogin}
            />
          </Link>

          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant='subtitle2' mr={1}>
              {emailHasBeenSentPage.didNotReceiveTheLink}
            </Typography>
            <LoginLink
              link={ApplicationRoutes.resetPassword}
              text={emailHasBeenSentPage.resend}
              onClick={handleSetIsEmailHasBeenSent}
            />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default EmailHasBeenSent;
