import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { usePlacementService } from 'hooks/services/placements';
import { PlacementsSummaryRequest } from 'hooks/services/placements/interfaces/placementsSummary.request';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { BE_DATE_FORMAT, DEFAULT_FORMAT, formatDate } from 'utils/date/convertToDate';
import { downloadBlob, openBlob } from 'utils/file/fileUtilities';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { FiltersFormInputs } from './components/filters/interfaces/filtersFormInput';
import { FilterParams } from './components/placementsTable/interfaces/filterParams';
import { PlacementAction } from './interfaces/placementAction';
import PlacementsList from './placementsList';

const PlacementsListContainer = () => {
  const { hostId } = useParams();
  const navigate = useNavigate();
  const parsedHostId = parseInt(hostId as string);
  const [filterParams, setFilterParams] = useState<FilterParams>();
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [rows, setRows] = useState<PlacementSummaryResponse[]>([]);
  const [allPrograms, setAllPrograms] = useState<KeyValueItemProps[]>([]);
  const [allPositions, setAllPositions] = useState<KeyValueItemProps[]>([]);
  const [statuses, setStatuses] = useState<KeyValueItemProps[]>([]);
  const [rowsTotal, setRowsTotal] = useState(100);

  const {
    getPlacements,
    confirmPlacement,
    getPlacementsFilters,
    getPlacementPdf,
  } = usePlacementService();

  const getPlacementsHandler = async (
    data: PlacementsSummaryRequest & { hostId: number }
  ) => {
    const response = await getPlacements(data);

    setRowsTotal(response.total);
    setRows(response.records);
  };

  const onApplyFilter = async (data?: FiltersFormInputs) => {
    if (data) {
      const updatedFilter = {
        approvedDate: formatDate(
          data.approvedDate,
          DEFAULT_FORMAT,
          BE_DATE_FORMAT
        ),
        positions: data.positions?.map((i) => Number(i)),
        statuses: data.statuses,
        programs: data.programs?.map((i) => Number(i)),
        search: data.search,
        showOnlyMatched: data.onlyMatched,
        showOnlyNotConfirmed: data.onlyNotConfirmed,
      } as FilterParams;

      setFilterParams(updatedFilter);

      await getPlacementsHandler({
        ...updatedFilter,
        ...tableParams,
        hostId: parsedHostId,
      });
    } else {
      setFilterParams({});

      await getPlacementsHandler({ ...tableParams, hostId: parsedHostId });
    }
  };

  const handleTableChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await getPlacementsHandler({
      ...data,
      ...filterParams,
      hostId: parsedHostId,
    });
  };

  const handleConfirmPlacement = async (
    placement: PlacementSummaryResponse
  ) => {
    const response = await confirmPlacement(parsedHostId, Number(placement.id));

    const newRows = rows.map((r) => {
      if (r.id === placement.id) {
        r.status = response.status;
        r.confirmed = response.confirmed;
      }

      return r;
    });

    setRows(newRows);
  };

  const handleEditPlacement = (placement: PlacementSummaryResponse) => {
    navigate(
      ApplicationRoutes.editPlacement
        .replace(':hostId', hostId as string)
        .replace(':placementId', placement.id)
    );
  };
  const handleClonePlacement = (placement: PlacementSummaryResponse) => {
    navigate(
      ApplicationRoutes.clonePlacement
        .replace(':hostId', hostId as string)
        .replace(':placementId', placement.id)
    );
  };

  const handleDownloadPlacement = async (
    placement: PlacementSummaryResponse
  ) => {
    const blob = await getPlacementPdf(parsedHostId, placement.id);
    const fileName = `${placement.id}.pdf`;

    downloadBlob(blob, fileName);
  };

  const handleOpenPlacement = async (placement: PlacementSummaryResponse) => {
    const blob = await getPlacementPdf(parsedHostId, placement.id);

    openBlob(blob);
  };

  const handleCreatePlacement = () => {
    navigate(
      ApplicationRoutes.createPlacement.replace(':hostId', hostId as string)
    );
  };
  const handlePreviewPlacement = (placement: PlacementSummaryResponse) => {
    navigate(
      ApplicationRoutes.placementPreview
        .replace(':hostId', hostId as string)
        .replace(':placementId', placement.id)
    );
  };

  const handlePlacementAction = (
    placement: PlacementSummaryResponse,
    action: PlacementAction
  ) => {
    switch (action) {
      case PlacementAction.Edit:
      case PlacementAction.Submit:
      case PlacementAction.Review:
        return handleEditPlacement(placement);
      case PlacementAction.Clone:
        return handleClonePlacement(placement);
      case PlacementAction.Confirm:
        return handleConfirmPlacement(placement);
      case PlacementAction.Download:
        return handleDownloadPlacement(placement);
      case PlacementAction.Open:
        return handleOpenPlacement(placement);
      case PlacementAction.Preview:
        return handlePreviewPlacement(placement);
    }
  };

  const fetchPlacements = useCallback(async () => {
    try {
      const data = {
        ...filterParams,
        ...tableParams,
        hostId: parsedHostId,
      };
      await getPlacementsHandler(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const { programs, positions, statuses } = await getPlacementsFilters(
        parsedHostId
      );

      setAllPrograms(programs);

      setAllPositions(positions);

      setStatuses(statuses);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPlacements();
    fetchFilterOptions();
  }, [fetchPlacements, fetchFilterOptions]);

  return (
    <PlacementsList
      onCreatePlacement={handleCreatePlacement}
      tableProps={{
        handlePlacementAction,
        rows,
        rowsTotal,
        handleTableChanged,
      }}
      filterProps={{
        programs: allPrograms,
        titles: allPositions,
        statuses,
        onApplyFilter,
      }}
    />
  );
};

export default PlacementsListContainer;
