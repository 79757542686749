import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonWithProgress from 'components/button';
import HeaderFooterModal from 'components/modal/headerFooterModal';
import ModalHeader from 'components/modal/modalHeader';
import StepperStep from 'components/stepper/interfaces/stepperStep';
import { useCommonService } from 'hooks/services/commons';
import { useHostService } from 'hooks/services/hosts';
import { ArrivalInstructionsResponse } from 'hooks/services/hosts/interfaces/arrivalInstruction.response';
import { HousingResponse } from 'hooks/services/hosts/interfaces/housing.response';
import { usePlacementService } from 'hooks/services/placements';
import { PatchPlacementBody } from 'hooks/services/placements/interfaces/patchParams.request';
import { useCreateReducer } from 'hooks/useCreateReducer';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'providers/alertProvider';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import EditPlacement from './editPlacement';
import EditPlacementContext from './editPlacement.context';
import { EditPlacementInitialState, initialState } from './editPlacement.state';
import { EditPlacementFormInput } from './interfaces/editPlacementFormInput';
import ArrivalInstructions from './sections/arrivalInstructions/arrivalInstructions';
import Documents from './sections/documents';
import Employment from './sections/employment/employment';
import Grooming from './sections/grooming/grooming';
import Housing from './sections/housing/housing';
import Interviews from './sections/interviews/interviews';
import Payment from './sections/payment/payment';
import TrainingOperation from './sections/trainingOrientation/trainingOrientation';
import makePatchRequestFromFormInput from './utils/makePatchRequest';
import { getEmptyEmploymentValues, getEmptyHousingValues, getEmptyTrainingValues } from './utils/setFormValues';
import { getEmptyGroomingValues } from './utils/setFormValues/getEmptyGroomingValues';
import { getEmptyInterviewsValues } from './utils/setFormValues/getEmptyInterviewsValues';
import { getEmptyPaymentValues } from './utils/setFormValues/getEmptyPaymentValues';
import { setValues } from './utils/setFormValues/setValues';
import createSchema from './validation/validation.schema';
import useProgramWithTypeHook from '../common/hooks/useProgramWithTypeHook';

const sections: StepperStep[] = [
  {
    id: 'Employment',
    title: 'Employment Position Information',
  },
  {
    id: 'Payment',
    title: 'Payments',
  },
  {
    id: 'Training',
    title: 'Training/Orientation',
  },
  {
    id: 'Grooming',
    title: 'Grooming',
  },
  {
    id: 'Documents',
    title: 'Documents',
  },
  {
    id: 'Arrival',
    title: 'Arrival Instruction',
  },
  {
    id: 'Housing',
    title: 'Housing',
  },
  {
    id: 'Interviews',
    title: 'Interviews',
  },
];

const components: { [key: string]: ReactElement } = {
  Employment: <Employment />,
  Payment: <Payment />,
  Training: <TrainingOperation />,
  Grooming: <Grooming />,
  Documents: <Documents />,
  Arrival: <ArrivalInstructions />,
  Housing: <Housing />,
  Interviews: <Interviews />,
};

const sectionComponents = sections.map((section: any) => (
  <Element key={section.id} name={section.id}>
    {components[section.id]}
  </Element>
));

const EditPlacementContainer = () => {
  const alert = useAlert();
  const { hostId, placementId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const translations = useTranslations();
  const [modalOpen, setModalOpen] = useState(false);

  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();
  const [stepperHeight, stepperRef] = useHeightRef();

  const contextValue = useCreateReducer<EditPlacementInitialState>({
    initialState,
  });

  const {
    state: { id, intHostId, programId, allArrivalInstructions, isLoading },
    dispatch,
  } = contextValue;

  const { getPositions, getContacts, getArrivalInstructions, getHousings } =
    useHostService();

  const {
    getEnglishProficiencyOptions,
    getPaymentSchedulesOptions,
    getSalaryWagePerOptions,
    getGenderRequirementsOptions,
    getHousingOptions,
  } = useCommonService();

  const { getPlacement, patchPlacement, submitPlacement } =
    usePlacementService();

  const general = pageHeaderHeight + buttonsHeight + stepperHeight;

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const formMethods = useForm<EditPlacementFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(
      createSchema((value) => validateSelectedProgram(value))
    ),
    defaultValues: {
      employment: getEmptyEmploymentValues(),
      trainingOrientation: getEmptyTrainingValues(),
      payment: getEmptyPaymentValues(),
      grooming: getEmptyGroomingValues(),
      interviews: getEmptyInterviewsValues(),
      housing: getEmptyHousingValues(),
    },
  });

  const { handleSubmit, reset, resetField, control, setValue, getValues } =
    formMethods;

  const EmploymentProgramWatch = useWatch({
    control,
    name: 'employment.program',
  });

  const { availableProgramTypes, availablePrograms, validateSelectedProgram } =
    useProgramWithTypeHook({
      program: EmploymentProgramWatch,
      hostId: Number(hostId),
      resetProgramTypes: () =>
        resetField('employment.programTypes', { defaultValue: [] }),
    });

  const fetchHousings = async (hostId: number) => {
    if (hostId === 0) return;

    const housings: HousingResponse[] = await getHousings({ hostId });

    dispatch({
      type: 'change',
      field: 'housings',
      value: housings,
    });
  };

  const getAvailablePositionTitles = async (programId: number) => {
    if (programId === 0) return;

    const positions = await getPositions({
      hostId: intHostId,
      programId,
    });
console.log(positions)
    dispatch({
      type: 'change',
      field: 'availablePositionTitles',
      value: positions,
    });

    if (
      !positions.find((i) => i.key === getValues('employment.positionTitle'))
    ) {
      resetField('employment.positionTitle', { defaultValue: '' });
    }
  };

  const getAvailableContacts = async (hostId: number) => {
    if (hostId === 0) return;

    const response = await getContacts({ hostId });

    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.id.toString(),
      value: option.name,
    }));

    dispatch({
      type: 'change',
      field: 'availableContacts',
      value: mapped,
    });
  };

  const getEnglishLevels = async () => {
    const response = await getEnglishProficiencyOptions();
    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.key.toString(),
      value: option.value,
    }));

    dispatch({
      type: 'change',
      field: 'englishLevels',
      value: mapped,
    });
  };

  const getWageOptions = async () => {
    const response = await getSalaryWagePerOptions();
    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.key.toString(),
      value: option.value,
    }));

    dispatch({
      type: 'change',
      field: 'wageOptions',
      value: mapped,
    });
  };

  const getScheduleOptions = async () => {
    const response = await getPaymentSchedulesOptions();
    const mapped: KeyValueItemProps[] = response.map((option) => ({
      key: option.key.toString(),
      value: option.value,
    }));

    dispatch({
      type: 'change',
      field: 'paymentScheduleOptions',
      value: mapped,
    });
  };

  const fetchPlacement = async (hostId: number, id: number) => {
    if (id === 0 || intHostId === 0) return;

    const response = await getPlacement({ hostId, id });

    if (!response) {
      return;
    }

    setValues(response, setValue);

    if (response.documents) {
      dispatch({
        type: 'change',
        field: 'documents',
        value: response.documents,
      });
    }
  };

  const fetchAllArrivalInstructions = async () => {
    if (intHostId === 0) return;

    const response = await getArrivalInstructions({
      hostId: intHostId,
    });

    const arrivalInstructionsMap = response.reduce((all, el) => {
      const strProgId = el.programId.toString();

      if (all.has(strProgId)) {
        all.get(strProgId)?.push(el);
      } else {
        all.set(strProgId, [el]);
      }

      return all;
    }, new Map<string, ArrivalInstructionsResponse>());

    dispatch({
      type: 'change',
      field: 'allArrivalInstructions',
      value: arrivalInstructionsMap,
    });

    return arrivalInstructionsMap;
  };

  const getAvailableArrivalInstructions = async (programId: number) => {
    if (programId === 0) return;

    const response = allArrivalInstructions.get(programId.toString()) ?? [];

    dispatch({
      type: 'change',
      field: 'arrivalInstructions',
      value: response,
    });
  };

  const getGenderOptionsAndMap = async () => {
    const genderResponse = await getGenderRequirementsOptions();
    dispatch({
      type: 'change',
      field: 'genders',
      value: genderResponse.map((g) => ({
        key: g.key,
        value: g.value,
      })),
    });
  };

  const getHousingsOptionsAndMap = async () => {
    const housingOptions = await getHousingOptions();

    dispatch({
      type: 'change',
      field: 'housingTypes',
      value: housingOptions.map((h) => ({
        key: h.key,
        value: h.value,
      })),
    });
  };

  const handleCancelBtn = () => {
    reset();
    navigate(ApplicationRoutes.placements.replace(':hostId', hostId as string));
  };

  const saveHandler = async (data: EditPlacementFormInput, event: any) => {
    event.preventDefault();

    dispatch({
      type: 'change',
      field: 'isLoading',
      value: true,
    });

    const body: PatchPlacementBody = makePatchRequestFromFormInput(data);

    try {
      await patchPlacement({ body, hostId: intHostId, id });

      alert.success(translations.alerts.placements.placementUpdated);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: 'change',
        field: 'isLoading',
        value: false,
      });

      navigate(
        ApplicationRoutes.placements.replace(':hostId', hostId as string)
      );
    }
  };

  const submitHandler = async (data: EditPlacementFormInput) => {
    dispatch({
      type: 'change',
      field: 'isLoading',
      value: true,
    });

    const body: PatchPlacementBody = makePatchRequestFromFormInput(data);

    try {
      await patchPlacement({ body, hostId: intHostId, id });

      alert.success(translations.alerts.placements.placementUpdated);

      await submitPlacement(intHostId, id);

      alert.success(translations.alerts.placements.placementUpdated);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: 'change',
        field: 'isLoading',
        value: false,
      });

      navigate(
        ApplicationRoutes.placements.replace(':hostId', hostId as string)
      );
    }
  };

  const onSubmit = handleSubmit(saveHandler);

  const handleSubmitPlacement = () => {
    handleSubmit(submitHandler)();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getEnglishLevels();
    getWageOptions();
    getScheduleOptions();
    getGenderOptionsAndMap();
    getHousingsOptionsAndMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programId) {
      getAvailablePositionTitles(programId);
      getAvailableArrivalInstructions(programId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  useEffect(() => {
    fetchAllArrivalInstructions();
    getAvailableContacts(intHostId);
    fetchHousings(intHostId);
    fetchPlacement(intHostId, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, intHostId, placementId]);

  useEffect(() => {
    if (EmploymentProgramWatch) {
      dispatch({
        type: 'change',
        field: 'programId',
        value: parseInt(EmploymentProgramWatch),
      });
    }
  }, [EmploymentProgramWatch, dispatch]);

  useEffect(() => {
    if (hostId)
      dispatch({
        type: 'change',
        field: 'intHostId',
        value: parseInt(hostId),
      });

    if (placementId)
      dispatch({
        type: 'change',
        field: 'id',
        value: parseInt(placementId),
      });
  }, [hostId, placementId, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'change',
      field: 'availableProgramTypes',
      value: availableProgramTypes,
    });
  }, [availableProgramTypes, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'change',
      field: 'availablePrograms',
      value: availablePrograms,
    });
  }, [availablePrograms, dispatch]);

  return (
    <>
      <HeaderFooterModal
        open={modalOpen}
        onClose={handleCloseModal}
        saveBtnText={translations.modal.buttons.yes}
        cancelBtnText={translations.modal.buttons.no}
        onSave={handleSubmitPlacement}
        sx={{ width: 500 }}
        isLoading={isLoading}
      >
        <Grid container sx={{ p: theme.spacing(2) }}>
          <Grid item xs={12}>
            <ModalHeader title={translations.createPlacement.submitPlacement} />
          </Grid>
          <Grid item xs={12} sx={{ py: theme.spacing(2) }}>
            <Typography variant='body1'>
              {translations.allInterviews.modal.areYouSure}
            </Typography>
          </Grid>
        </Grid>
      </HeaderFooterModal>
      <EditPlacementContext.Provider value={contextValue}>
        <FormProvider {...formMethods}>
          <EditPlacement
            onSubmit={onSubmit}
            handleCancelBtn={handleCancelBtn}
            pageHeaderRef={pageHeaderRef}
            buttonsRef={buttonsRef}
            stepperRef={stepperRef}
            matchDownMd={matchDownMd}
            general={general}
            sections={sections}
            sectionComponents={sectionComponents}
            primaryButton={
              <Button
                variant='contained'
                color='primary'
                onClick={() => setModalOpen(true)}
              >
                {translations.submitButton}
              </Button>
            }
            secondaryButton={
              <ButtonWithProgress
                dataTestid='continue'
                text={translations.saveButton}
                color='secondary'
              />
            }
            pageTitle={translations.createPlacementPageHeader}
          />
        </FormProvider>
      </EditPlacementContext.Provider>
    </>
  );
};

export default EditPlacementContainer;
