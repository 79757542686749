import { List } from '@mui/material';

import { MenuItem } from '../drawerContent/menuItem';
import SingleLevelMenuItem from './singleLevelMenuItem';
import NestedMenu from './nestedMenu';
import { useCollapse } from '../../../../hooks/useCollapse';
import { useEffect } from 'react';

const Menu = ({ items, open }: { items: MenuItem[]; open: boolean }) => {
  const { isOpenCollapse, handleClick } = useCollapse();

  useEffect(() => {
    handleClick(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <List>
      {items.map(({ text, icon, route, subItems }) => (
        <div key={text}>
          {subItems !== undefined ? (
            <NestedMenu
              text={text}
              icon={icon}
              subItems={subItems}
              open={open}
              isOpenCollapse={isOpenCollapse}
              handleClick={handleClick}
            />
          ) : (
            <SingleLevelMenuItem
              text={text}
              icon={icon}
              route={route}
              open={open}
            />
          )}
        </div>
      ))}
    </List>
  );
};

export default Menu;
