import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { useTranslations } from 'hooks/useTranslations';
import { CellHeaderId } from '../../interfaces/placementsCellHeader';

const ExtendedTableHeader = ({
  order,
  orderBy,
  sortHandler,
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
}) => {
  const translations = useTranslations();

  const cells: CellHeader<CellHeaderId>[] = [
    {
      id: 'program',
      label: translations.placementsList.placementTable.columns.program,
      sortable: true,
    },
    {
      id: 'status',
      label: translations.placementsList.placementTable.columns.status,
      sortable: true,
    },
    {
      id: 'id',
      label: translations.placementsList.placementTable.columns.jobId,
      sortable: true,
    },
    {
      id: 'position',
      label: translations.placementsList.placementTable.columns.position,
      sortable: true,
    },
    {
      id: 'department',
      label: translations.placementsList.placementTable.columns.department,
      sortable: true,
    },
    {
      id: 'earliestStartDate',
      label: translations.placementsList.placementTable.columns.starts,
      sortable: false,
    },
    {
      id: 'latestStartDate',
      label: translations.placementsList.placementTable.columns.latestStarts,
      sortable: false,
    },
    {
      id: 'earliestEndDate',
      label: translations.placementsList.placementTable.columns.ends,
      sortable: false,
    },
    {
      id: 'latestEndDate',
      label: translations.placementsList.placementTable.columns.latestEnds,
      sortable: false,
    },
    {
      id: 'total',
      label: translations.placementsList.placementTable.columns.total,
      sortable: false,
    },
    {
      id: 'open',
      label: translations.placementsList.placementTable.columns.open,
      sortable: false,
    },
    {
      id: 'matched',
      label: translations.placementsList.placementTable.columns.matched,
      sortable: false,
    },
    {
      id: 'approved',
      label: translations.placementsList.placementTable.columns.approved,
      sortable: false,
    },
    {
      id: 'actions',
      label: translations.placementsList.placementTable.columns.actions,
      sortable: false,
    },
  ];

  return (
    <SortableTableHeader<CellHeaderId>
      cells={cells}
      order={order}
      orderBy={orderBy}
      sortHandler={sortHandler}
    />
  );
};

export { ExtendedTableHeader };
