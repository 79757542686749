import * as yup from 'yup';
import { transformDate } from 'utils/date/convertToDate';

export const schema = yup.object().shape({
  overallEnglishLevelInpersonInterview: yup.string().required().label('Overall English Level '),
  furtherCommentsInpersonInterview: yup.string().max(500).required().label('Further comments'),
  interviewerSignatureInpersonInterview: yup.string().max(50).required().label('Interviewer Signature'),
  interviewDateInpersonInterviewReportData: yup
    .date()
    .required()
    .transform(transformDate)
    .label('Interview Date'),
});
