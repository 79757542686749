import { useState } from 'react';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { PlacementAction } from 'pages/placements/placementsList/interfaces/placementAction';
import MobileTable from './mobileTable';

const MobileTableContainer = ({
  rows,
  handlePlacementAction,
}: {
  rows: PlacementSummaryResponse[];
  handlePlacementAction: (
    placement: PlacementSummaryResponse,
    action: PlacementAction
  ) => void;
}) => {
  const [placementDetails, setPlacementDetails] =
    useState<PlacementSummaryResponse>();

  return (
    <MobileTable
      rows={rows}
      handlePlacementAction={handlePlacementAction}
      placementDetails={placementDetails}
      showDetails={(details) => setPlacementDetails(details)}
    />
  );
};

export default MobileTableContainer;
