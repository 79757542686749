import { BaseSyntheticEvent, ReactNode } from 'react';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import DropdownInput from 'components/dropdownInput';
import ScrollableContent from 'components/scrollableContent';
import TextareaInput from 'components/textAreaInput/textAreaInput';

import { useFormInputsStyle } from 'hooks/useFormInputsStyle';

import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';

import { Grid, useTheme } from '@mui/material';
import { ProfileFormInputs } from './interfaces/profileFormInputs';

export const ProfileUI = ({
  timeZones,
  loading,
  onSubmit,
  errors,
  control,
  register,
  buttons,
  translations,
}: {
  timeZones: KeyValueItemProps[];
  loading: boolean;
  errors: Partial<FieldErrorsImpl<ProfileFormInputs>>;
  control: Control<ProfileFormInputs, any>;
  register: UseFormRegister<ProfileFormInputs>;
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  buttons: ReactNode;
  translations: any;
}) => {
  const theme = useTheme();

  const { defaultInput, inputsSection } =
    useFormInputsStyle(theme);

  return (
    <form onSubmit={onSubmit} style={{ height: '100%', overflow: 'hidden' }}>
      <ScrollableContent
        pageHeaderTitle={translations.pageHeaderTitle}
        buttonsComponent={buttons}
      >
        <Grid item xs={12}>
          <Grid {...inputsSection}>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId='firstNameId'
                  inputLabel={translations.firstName}
                  control={control}
                  errorMessage={errors.firstName?.message}
                  register={register('firstName')}
                  singleLine
                  disabled
                />
              </Grid>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId='lastNameId'
                  inputLabel={translations.lastName}
                  control={control}
                  errorMessage={errors.lastName?.message}
                  register={register('lastName')}
                  singleLine
                  disabled
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId='emailId'
                  inputLabel={translations.email}
                  control={control}
                  errorMessage={errors.email?.message}
                  register={register('email')}
                  singleLine
                  disabled
                />
              </Grid>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId='skypeId'
                  inputLabel={translations.skype}
                  control={control}
                  errorMessage={errors.skypeAddress?.message}
                  register={register('skypeAddress')}
                  singleLine
                />
              </Grid>
            </Grid>
            <Grid {...defaultInput}>
              <DropdownInput<ProfileFormInputs>
                inputId={'timeZoneId'}
                inputLabel={translations.timeZone}
                items={timeZones}
                errorMessage={errors?.timeZone?.message}
                control={control}
                register={register('timeZone')}
              />
            </Grid>
          </Grid>
        </Grid>
      </ScrollableContent>
    </form>
  );
};
