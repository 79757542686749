import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ApplicantInfoResponse } from 'hooks/services/hosts/interfaces/participantSummary.response';
import { useTranslations } from 'hooks/useTranslations';
import { formDateDDMonYYYY } from 'utils/date/convertToDate';
import { Nullable } from 'utils/interfaces';
import { MainDetailsItem, SecondaryDetailsItem } from './modalContentItem';
import Resources from './resources';
import { useParams } from 'react-router-dom';
import {
  BE_DATE_FORMAT,
  formatDate
} from 'utils/date/convertToDate';

const ModalContent = ({
  item,
  isMobile,
}: {
  item: Nullable<ApplicantInfoResponse>;
  isMobile?: boolean;
}) => {
  const {
    applicantID,
    genderLabel,
    ageLabel,
    title,
    dateOfBirthLabel,
    countryLabel,
    jobOrderIDLabel,
    programStartDateLabel,
    programEndDateLabel,
    emergencyContactLabel,
    emailLabel,
    skypeLabel,
    positionLabel,
  } = useTranslations().participants.modal;

  const theme = useTheme();
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  if (!item) return null;

  const {
    avatarUrl,
    applicantFirstName,
    applicantLastName,
    applicantId,
    gender,
    dateOfBirth,
    age,
    countryOfResidence,
    jobId,
    programStartDate,
    programEndDate,
    email,
    skypeId,
    position,
    emergencyContact,
    resume,
    applicationResume,
    signedJobOffer,
    signedPlanExists,
    applicationVideo
  } = item;

  return (
    <Grid container>
      {/* BLUE PART */}
      <Grid
        container
        item
        xs={12}
        md={4}
        sx={{ bgcolor: theme.palette.secondary.main }}
        p={{
          xs: theme.spacing(2, 0, 2, 1),
          sm: theme.spacing(2),
          md: theme.spacing(3, 3, 3, 0.5),
        }}
        textAlign={isMobile ? 'left' : 'right'}
      >
        <Grid
          container
          item
          md={12}
          justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          alignContent={'center'}
          mb={{ xs: 0, md: theme.spacing(2) }}
          width={'auto'}
        >
          <Box
            sx={{
              mr: isMobile ? 0 : '-20%',
              zIndex: 2,
              backgroundImage: `url(${avatarUrl})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: isMobile ? 130 : 197,
              height: isMobile ? 130 : 197,
              borderRadius: '50%',
              border: '6px solid #fff',
              boxShadow: '0 10px 29px 15px rgb(9 40 224 / 16%)',
            }}
          />
        </Grid>
        <Grid
          item
          xs={7.5}
          sm={8}
          md={12}
          container
          gap={isMobile ? 1 : 2}
          p={{
            xs: theme.spacing(1, 0, 0, 2),
            sm: theme.spacing(1, 3, 0),
            md: 0,
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant='h3'
              color='#fff'
              fontSize={
                isMobile
                  ? theme.typography.subtitle1.fontSize
                  : theme.typography.h3.fontSize
              }
            >
              {applicantFirstName} {applicantLastName}
            </Typography>
          </Grid>
          <MainDetailsItem
            label={applicantID}
            value={applicantId.toString()}
            isMobile={isMobile}
          />
          <MainDetailsItem
            label={genderLabel}
            value={gender}
            isMobile={isMobile}
          />
          <MainDetailsItem label={ageLabel} value={age} isMobile={isMobile} />
        </Grid>
      </Grid>

      {/* WHITE PART */}

      <Grid
        item
        xs={12}
        md={8}
        sx={{ bgcolor: 'background.paper' }}
        p={{ xs: theme.spacing(3), md: theme.spacing(6, 4, 3, 7) }}
      >
        <Grid container>
          <Typography
            variant='subtitle1'
            color={'primary'}
            fontWeight={700}
            mb={theme.spacing(2)}
            fontSize={
              isMobile
                ? theme.typography.body2.fontSize
                : theme.typography.subtitle1.fontSize
            }
          >
            {title}
          </Typography>
          <Grid container item xs={12} gap={1}>
            <SecondaryDetailsItem
              label={dateOfBirthLabel}
              value={formDateDDMonYYYY(dateOfBirth)}
              xs={5}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={emailLabel}
              value={email}
              xs={6}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={countryLabel}
              value={countryOfResidence}
              xs={5}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={skypeLabel}
              value={skypeId}
              xs={6}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={jobOrderIDLabel}
              value={`#${jobId}`}
              xs={5}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={positionLabel}
              value={position}
              xs={6}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={programStartDateLabel}
              value={formatDate(
                programStartDate,
                BE_DATE_FORMAT,
                'MMM d, yyyy'
              )}
              xs={5}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={programEndDateLabel}
              value={formatDate(
                programEndDate,
                BE_DATE_FORMAT,
                'MMM d, yyyy'
              )}
              xs={6}
              isMobile={isMobile}
            />
            <SecondaryDetailsItem
              label={emergencyContactLabel}
              value={emergencyContact}
              xs={12}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>

        <Resources
          id={applicantId}
          hostId={parsedHostId}
          isMobile={isMobile}
          isResume={!!resume}
          isApplicationResume={!!applicationResume}
          isJobOffer={!!signedJobOffer}
          isSignedPlacementPlan={signedPlanExists}
          applicationVideo={applicationVideo}
        />
      </Grid>
    </Grid>
  );
};

export default ModalContent;
