import { Grid, Typography, useTheme } from '@mui/material';

export const MainDetailsItem = ({
  label,
  value,
  isMobile,
}: {
  label: string;
  value?: string | number;
  isMobile?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Grid item xs={5.5} md={12}>
      <Typography
        variant='body2'
        color='#fff'
        fontSize={
          isMobile
            ? theme.typography.table.fontSize
            : theme.typography.body2.fontSize
        }
      >
        {label}
      </Typography>
      {value && (
        <Typography
          variant='h4'
          color='#fff'
          fontWeight={700}
          fontSize={
            isMobile
              ? theme.typography.body1.fontSize
              : theme.typography.h4.fontSize
          }
        >
          {value}
        </Typography>
      )}
    </Grid>
  );
};

export const SecondaryDetailsItem = ({
  label,
  value,
  xs,
  isMobile,
}: {
  label: string;
  value?: string;
  xs: number;
  isMobile?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Grid item xs={xs}>
      <Typography
        variant='table'
        color={theme.palette.grey[600]}
        fontSize={
          isMobile
            ? theme.typography.caption.fontSize
            : theme.typography.table.fontSize
        }
      >
        {label}
      </Typography>
      {value && (
        <Typography
          variant='body1'
          color={'primary'}
          fontSize={
            isMobile
              ? theme.typography.body2.fontSize
              : theme.typography.body1.fontSize
          }
        >
          {value}
        </Typography>
      )}
    </Grid>
  );
};
