import { IconButton, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ArrowButtonProps } from '../../interfaces/ArrowButtonProps';

export const ArrowButton = ({ open, setOpen }: ArrowButtonProps) => {
  const theme = useTheme();
  const { arrowUp, arrowDown } = theme.tables;

  const style = {
    ...{
      height: '25px',
      width: '25px',
    },
    ...(open ? arrowUp : arrowDown),
  };

  return (
    <IconButton
      name='arrow-button'
      id='arrow-button'
      disableRipple
      sx={style}
      aria-label='expand row'
      size='small'
      onClick={() => setOpen(!open)}
    >
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};
