import { Box, Button, IconButton, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChangeTime from 'assets/icons/changeTime.svg';
import DeclinedIcon from 'assets/icons/declined.svg';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';
import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { RowNotFound } from 'components/tables/components/rowNotFound';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { InterviewResponse } from 'hooks/services/hosts/interfaces/interviews.response';
import { useHorizontalScrollBar } from 'hooks/useHorizontalScrollBar';
import { useTranslations } from 'hooks/useTranslations';
import { Action } from 'pages/interviews/interfaces/interviews';
import { BE_DATE_FORMAT, convertUTCDateToOffset, formatDate, formDateDDMonthYYYYTime, isDateAfterCurrentDate } from 'utils/date/convertToDate';
import { CellHeaderId } from '../../../interfaces/allInterviewsCellHeader';
import { getCells } from '../../../utils/table.cells';

const AllInterviewTableList = ({
  rows,
  order,
  orderBy,
  sortHandler,
  handleOpenActionsDropdown,
  heightToSkip,
  handleInterviewAction,
  handleClick,
  handleCancelAction,
  timeZone,
}: {
  rows: InterviewResponse[];
  order: SortOrder | undefined;
  orderBy: any;
  sortHandler: (property: CellHeaderId) => void;
  heightToSkip: number;
  handleOpenActionsDropdown: (
    event: React.MouseEvent<HTMLElement>,
    interview: InterviewResponse
  ) => void;
  handleInterviewAction: (row: InterviewResponse) => void;
  handleClick: (applicantId: number) => void;
  handleCancelAction: (interview: InterviewResponse, action: Action) => void;
  timeZone?: string;
}) => {
  const theme = useTheme();
  const {
    allInterviews: {
      table: { columns },
    },
  } = useTranslations();

  const cells: CellHeader<CellHeaderId>[] = getCells(columns);

  const horizontalScrollBar = useHorizontalScrollBar();

  return (
    <Box
      mt={2}
      pb={1}
      px={'1px'}
      sx={{
        ...ResponsiveContainerProps(heightToSkip),
        ...horizontalScrollBar,
      }}
    >
      <Table stickyHeader>
        <SortableTableHeader {...{ order, orderBy, sortHandler, cells }} />

        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row: InterviewResponse) => {
              const isInterviewDateAfterCurrentDate = isDateAfterCurrentDate(
                row.interviewDate,
                timeZone
              );

              const handleCancelInterviewClick = isInterviewDateAfterCurrentDate
                ? () => {
                    handleCancelAction(row, {
                      id: 'cancel',
                      label: columns.cancelInterview,
                    });
                  }
                : undefined;


              return (
                <TableRow key={row.id}>
                  <TableCell sx={{ minWidth: 250 }}>{row.hostEntity}</TableCell>
                  <TableCell sx={{ minWidth: 250 }}>{row.program}</TableCell>
                  <TableCell
                    sx={{ minWidth: 100, cursor: 'pointer' }}
                    onClick={() => handleClick(row.applicantId)}
                  >
                    {row.applicantId}
                  </TableCell>
                  <TableCell sx={{ minWidth: 150 }}>
                    {row.applicantName}
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>{row.email}</TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    {row.applicationStatus}
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>{row.skype}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>
                    {row.countryOfResidence}
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>{row.jobId}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{row.position}</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>
                    {formatDate(
                      row.placementStartsOn,
                      BE_DATE_FORMAT,
                      'MMM d, yyyy'
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }}>
                    {formatDate(
                      row.placementEndsOn,
                      BE_DATE_FORMAT,
                      'MMM d, yyyy'
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: 170 }}>
                    {formDateDDMonthYYYYTime(
                      convertUTCDateToOffset(row.interviewDate, timeZone)
                    )}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 150 }}>
                    {row.interviewStatus}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 150 }}>
                    {row.interviewer}
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    {/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(row.interviewerSkypeId) ? (
                      <a href={row.interviewerSkypeId} target="_blank" rel="noopener noreferrer">
                        Link
                      </a>
                    ) : (
                      row.interviewerSkypeId
                    )}
                  </TableCell>

                  <TableCell sx={{ width: 150 }}>
                    <IconButton
                      onClick={() => {
                        handleInterviewAction(row);
                      }}
                    >
                      <Box component='img' alt='change-time' src={ChangeTime} />
                    </IconButton>
                  </TableCell>

                  <TableCell
                    sx={{ width: 150 }}
                    onClick={handleCancelInterviewClick}
                  >
                    <IconButton
                      sx={{
                        cursor: isInterviewDateAfterCurrentDate
                          ? 'pointer'
                          : 'not-allowed',
                        '&:hover': {
                          backgroundColor: isInterviewDateAfterCurrentDate
                            ? 'none'
                            : 'unset',
                        },
                      }}
                    >
                      <Box
                        component='img'
                        alt={'cancel interview'}
                        src={DeclinedIcon}
                        sx={{
                          opacity: isInterviewDateAfterCurrentDate
                            ? undefined
                            : 0.6,
                        }}
                      />
                    </IconButton>
                  </TableCell>

                  <TableCell sx={{ width: 200 }}>
                    <Button
                      endIcon={<KeyboardArrowDownIcon />}
                      variant='contained'
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFF',
                        borderRadius: '5px',
                        height: '30px',
                        width: '100px',
                      }}
                      onClick={(e) => {
                        handleOpenActionsDropdown(e, row);
                      }}
                    >
                      Actions
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <RowNotFound colSpan={19} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default AllInterviewTableList;
