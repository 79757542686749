import { Button, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CorrectCircularIcon from 'assets/icons/correctCircularIcon.svg';
import MobileCorrectCircularIcon from 'assets/icons/correctCircularIconMobile.svg';
import EditCircularIcon from 'assets/icons/editCircularIcon.svg';
import MobileEditCircularIcon from 'assets/icons/editCircularIconMobile.svg';
import UserCircularIcon from 'assets/icons/userCircularIcon.svg';
import MobileUserCircularIcon from 'assets/icons/userCircularIconMobile.svg';
import WarningCircularIcon from 'assets/icons/warningCircularIcon.svg';
import MobileWarningCircularIcon from 'assets/icons/warningCircularIconMobile.svg';
import ColoredChip from 'components/coloredChip';
import { ChipColor } from 'components/coloredChip/interfaces/chipColor';
import { CellType } from 'components/tables/simpleTable/interfaces/cellType';
import { useHostService } from 'hooks/services/hosts';
import { DocumentListResponse } from 'hooks/services/hosts/interfaces/documentList.response';
import { DocumentStatus } from 'hooks/services/hosts/interfaces/documentStatus.enum';
import { useTranslations } from 'hooks/useTranslations';
import { downloadBlob } from 'utils/file/fileUtilities';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { convertBooleanToYesOrNo } from 'utils/string/stringConverters';
import { MobileIcon } from './components/mobileIcon';
import DocumentList from './documentList';
import { MobileViewItemContainerProps } from './interfaces/mobileViewItemContainerProps';

const DocumentListContainer = () => {
  const [documents, setDocuments] = useState<DocumentListResponse>([]);

  const theme = useTheme();
  const translations = useTranslations();
  const { title, columns, buttonDownloadLabel, buttonUploadLabel } =
    translations.documentList;
  const {
    actionsLabel,
    documentTypeLabel,
    isRequiredLabel,
    remarksLabel,
    statusLabel,
  } = columns;
  const navigate = useNavigate();
  const { hostId } = useParams<{ hostId: string; }>();
  const { getDocuments, getDocumentFile } = useHostService();

  const fetchDocuments = async () => {
    const response = await getDocuments({ hostId: Number(hostId) });
    setDocuments(response);
  };

  const { success, warning, grey, error } = theme.palette;

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotToUploadForm = (documentId: string) => {
    navigate(
      ApplicationRoutes.uploadDocument
        .replace(':hostId', hostId as string)
        .replace(':id', documentId as string)
    );
  };

  const handleDownload = async (documentId: string) => {
    const doc = documents.find((i) => i.id === Number(documentId));

    if (doc) {
      try {
        const file = await getDocumentFile(Number(hostId), documentId);

        downloadBlob(file, doc.type);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const statusColorAndImg = {
    [DocumentStatus.PendingApproval]: {
      color: 'statusOrange',
      img: UserCircularIcon,
      buttonLabel: '',
      mobileProps: { color: warning.light, icon: MobileUserCircularIcon },
    },
    [DocumentStatus.Approved]: {
      color: 'statusGreen',
      img: CorrectCircularIcon,
      buttonLabel: buttonDownloadLabel,
      buttonAction: handleDownload,
      mobileProps: { color: success.light, icon: MobileCorrectCircularIcon },
    },
    [DocumentStatus.Rejected]: {
      color: 'statusRed',
      img: WarningCircularIcon,
      buttonLabel: buttonUploadLabel,
      buttonAction: gotToUploadForm,
      mobileProps: { color: error.light, icon: MobileWarningCircularIcon },
    },
    [DocumentStatus.NotStarted]: {
      color: 'statusGrayedWithWhiteLabel',
      img: EditCircularIcon,
      buttonLabel: buttonUploadLabel,
      buttonAction: gotToUploadForm,
      mobileProps: { color: grey[300], icon: MobileEditCircularIcon },
    },
  };

  const convertResponseToDesktopTableData = (): { values: CellType[]; }[] => {
    const convertedValue = documents.map((document) => {
      const type: string = document.type;
      const text: DocumentStatus = DocumentStatus[document.status];
      const { color, img, buttonLabel, buttonAction } = statusColorAndImg[
        text
      ] as {
        color: ChipColor;
        img: string;
        buttonLabel: string;
        buttonAction?: (documentId: string) => void;
      };

      return {
        values: [
          type,
          <ColoredChip {...{ text, color, img }} />,
          document.remarks,
          convertBooleanToYesOrNo(document.isRequired),
          !!buttonLabel ? (
            <Button
              variant='outlined'
              color='secondary'
              sx={{ borderRadius: '5px', height: '30px' }}
              onClick={() => buttonAction?.(document.id.toString())}
            >
              {buttonLabel}
            </Button>
          ) : (
            <></>
          ),
        ],
      };
    });

    return convertedValue;
  };

  const convertResponseToMobileTableData =
    (): MobileViewItemContainerProps[] => {
      const convertedValue = documents.map((document) => {
        const type: string = document.type;
        const status: DocumentStatus = DocumentStatus[document.status];
        const { mobileProps, buttonLabel, buttonAction } = statusColorAndImg[
          status
        ] as {
          mobileProps: { color: string; icon: string; };
          buttonLabel: string;
          buttonAction?: (documentId: string) => void;
        };

        return {
          type,
          status,
          remarks: document.remarks,
          icon: (
            <MobileIcon
              icon={mobileProps.icon}
              backgroundColor={mobileProps.color}
            />
          ),
          button: !!buttonLabel ? (
            <Button
              variant='outlined'
              color='secondary'
              sx={{ borderRadius: '5px', height: '30px' }}
              onClick={() => buttonAction?.(document.id.toString())}
            >
              {buttonLabel}
            </Button>
          ) : (
            <></>
          ),
        };
      });

      return convertedValue;
    };

  return (
    <DocumentList
      title={title}
      rows={convertResponseToDesktopTableData()}
      mobileRows={convertResponseToMobileTableData()}
      headers={[
        { title: documentTypeLabel, width: '100px' },
        { title: statusLabel, width: '150px' },
        { title: remarksLabel, width: '500px' },
        { title: isRequiredLabel, width: '40px' },
        { title: actionsLabel, width: '50px' },
      ]}
    />
  );
};

export default DocumentListContainer;
