import { Box, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import ConnectForm from 'components/connectForm';
import DropdownInput from 'components/dropdownInput';
import DropdownYesNo from 'components/dropdownYesNo';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import TextareaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const Payment = () => {
  const theme = useTheme();

  const {
    inputStyle,
    yesOrNoInput,
    inputsGridFullWidth,
    inputsSection,
    defaultInput,
  } = useFormInputsStyle(theme);

  const { paymentSection } = useTranslations();

  const {
    state: { paymentScheduleOptions, wageOptions, isReadOnly },
  } = useContext(EditPlacementContext);

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>{paymentSection.paymentSectionTitle}</SectionHeader>

          <Grid {...ContainerSpacing}>
            <Grid {...inputsSection}>
              <Grid {...inputStyle(5, 2)}>
                <NumberInput
                  label={paymentSection.grossWage}
                  labelHeight={46}
                  useDecimals={true}
                  errorMessage={errors?.payment?.grossWage?.message}
                  control={control}
                  name='payment.grossWage'
                  getValue={() => getValues('payment.grossWage')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...inputStyle(5, 2)}>
                <DropdownInput
                  inputId={paymentSection.wagePerId}
                  inputLabel={paymentSection.wagePer}
                  labelHeight={46}
                  items={wageOptions}
                  errorMessage={errors?.payment?.wagePer?.message}
                  control={control}
                  register={register('payment.wagePer')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...inputStyle(5, 2)}>
                <TextareaInput
                  inputId={paymentSection.averageHoursPerWeek}
                  inputLabel={paymentSection.averageHoursPerWeek}
                  errorMessage={errors?.payment?.averageHoursPerWeek?.message}
                  register={register('payment.averageHoursPerWeek')}
                  control={control}
                  numberOfRows={1}
                  singleLine={true}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...inputStyle(5, 2)}>
                <TextareaInput
                  errorMessage={errors?.payment?.estimatedTip?.message}
                  control={control}
                  inputId={paymentSection.estimatedTip}
                  inputLabel={paymentSection.estimatedTip}
                  register={register('payment.estimatedTip')}
                  numberOfRows={1}
                  singleLine={true}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...inputStyle(5, 2)}>
                <DropdownInput
                  inputId={paymentSection.paymentScheduleId}
                  inputLabel={paymentSection.paymentSchedule}
                  items={paymentScheduleOptions}
                  errorMessage={errors?.payment?.paymentSchedule?.message}
                  control={control}
                  register={register('payment.paymentSchedule')}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={paymentSection.allowancesBonusesIncentivesId}
                  inputLabel={paymentSection.allowancesBonusesIncentives}
                  register={register('payment.allowancesBonusesIncentives')}
                  errorMessage={
                    errors?.payment?.allowancesBonusesIncentives?.message
                  }
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  inputId={paymentSection.additionalInformationId}
                  inputLabel={paymentSection.additionalInformation}
                  register={register('payment.additionalInformation')}
                  errorMessage={errors?.payment?.additionalInformation?.message}
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={paymentSection.overtimeWageId}
                  inputLabel={paymentSection.isOvertimeAvailable}
                  errorMessage={errors.payment?.isOvertimeAvailable?.message}
                  control={control}
                  register={register('payment.isOvertimeAvailable')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={paymentSection.overtimeWage}
                  inputLabel={paymentSection.overtimeWage}
                  errorMessage={errors?.payment?.overtimeWage?.message}
                  register={register('payment.overtimeWage')}
                  control={control}
                  singleLine={true}
                  numberOfRows={1}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};

export default Payment;
