import { useMediaQuery, useTheme } from '@mui/material';
import ResponsiveFiltersBar from 'components/responsiveListView/components/responsiveFiltersBar';
import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import { Nullable } from 'utils/interfaces';
import { SortOrder } from '../../../../hooks/services/commons/interfaces/sortOrder';
import ApplicantsTileView from '../applicantsTileView/applicantsTileView';
import ApplicantsDesktopFilters from '../filters/applicantsDesktopFilters';
import ApplicantsMobileFilters from '../filters/applicantsMobileFilters';
import { FiltersFormProps } from '../filters/interfaces/filtersFormProps';
import { CellHeaderId } from './interfaces/applicantsCellHeader';
import { ApplicantsTableProps } from './interfaces/applicantsTableProps';
import { ApplicantsTableView } from '../applicantsTableView';
import { ApplicantSummaryResponse } from 'hooks/services/hosts/interfaces/applicantSummary.response';

const ApplicantsTable = ({
  filterProps,
  filtersRef,
  heightToSkip,
  isTileView,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  paginationRef,
  rowsPerPage,
  tableProps: { rowsTotal, rows },
  order,
  orderBy,
  sortHandler,
  handleOpenResumeInNewTab,
  handleOpenVideoInNewTab,
  handleInterviewAction
}: {
  heightToSkip: number;
  filtersRef: (node: HTMLDivElement) => void;
  filterProps: FiltersFormProps | null;
  isTileView: Nullable<boolean>;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
  page: number;
  paginationRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
  tableProps: ApplicantsTableProps;
  order?: SortOrder;
  orderBy?: CellHeaderId;
  sortHandler: (property: CellHeaderId) => void;
  handleOpenResumeInNewTab: (applicantId: string) => void;
  handleOpenVideoInNewTab: (videoUrl: string) => void;
  handleInterviewAction: (row: ApplicantSummaryResponse) => void;
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <>
      <ResponsiveListView
        filters={
          filterProps ? (
            <ResponsiveFiltersBar
              filtersBarRef={filtersRef}
              desktopView={<ApplicantsDesktopFilters {...filterProps} />}
              mobileView={<ApplicantsMobileFilters {...filterProps} />}
              sx={isMobile ? { boxShadow: 'none', overflow: 'visible' } : null}
            />
          ) : undefined
        }
        listView={
          isTileView !== null ? (
            isTileView ? (
              <ApplicantsTileView
                items={rows}
                heightToSkip={heightToSkip}
                handleInterviewAction={handleInterviewAction}
              />
            ) : (
              <ApplicantsTableView
                {...{
                  rows,
                  order,
                  orderBy,
                  sortHandler,
                  heightToSkip,
                  handleOpenResumeInNewTab,
                  handleOpenVideoInNewTab,
                  handleInterviewAction
                }}
              />
            )
          ) : (
            <div>Loading</div>
          )
        }
        paginationProps={{
          handleChangePage,
          handleChangeRowsPerPage,
          page,
          paginationRef,
          rowsPerPage,
          rowsTotal,
        }}
      />
    </>
  );
};

export default ApplicantsTable;
