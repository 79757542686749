import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  useTheme,
} from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslations } from 'hooks/useTranslations';
import { DropdownInputProps } from './interfaces/dropdownMultipleInputProps';
import InputWrapper from '../inputWrapper';

export const selectAllValue = 'all';

export default function DropdownMultiselectInput<
  TFieldValues extends FieldValues
>({
  name,
  errorMessage,
  inputLabel,
  items,
  inputId,
  control,
  onChange,
  isAllSelected,
  selected,
  disabled,
  labelHeight,
}: DropdownInputProps<TFieldValues> & {
  onChange: (e: any) => void;
  isAllSelected: boolean;
  selected: string[];
}) {
  const theme = useTheme();
  const translations = useTranslations();

  return (
    <InputWrapper
      inputId={inputId}
      inputLabel={inputLabel}
      errorMessage={errorMessage}
      labelHeight={labelHeight}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            id={inputId}
            inputProps={{ 'data-testid': inputId }}
            displayEmpty
            fullWidth
            variant='standard'
            multiple
            {...field}
            disabled={disabled}
            onChange={(e) => {
              if (onChange) {
                onChange(e);

                field.onChange(e);
              }
            }}
            renderValue={(selected) =>
              items
                .filter((x) => selected.includes(x.key))
                .map((i) => i.value)
                .join(', ')
            }
            sx={{
              height: 52,
              boxShadow: 3,
              'input': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& .MuiSelect-select': {
                padding: 2,
                fontWeight: 400,
                fontSize: theme.spacing(2),
                height: '100% !important',
                boxSizing: 'border-box',
              },
              '&:before': {
                borderBottom: 'none',
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: '50%',
                  mt: theme.spacing(0.4),
                  '& .MuiMenuItem-root': {
                    height: 52,
                    padding: 2,
                    fontWeight: 400,
                    fontSize: theme.spacing(2),
                    lineHeight: theme.spacing(2),
                    ':hover': {
                      backgroundColor: theme.box.borderColor,
                    },
                  },
                },
              },
            }}
            error={!!errorMessage}
          >
            <MenuItem value={selectAllValue}>
              <ListItemIcon>
                <Checkbox checked={isAllSelected} />
              </ListItemIcon>
              <ListItemText primary={translations.dropdownSelectAll} />
            </MenuItem>
            {items &&
              items.map((i) => (
                <MenuItem key={`${inputId}${i.key}`} value={i.key}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        selected.indexOf(i.key) > -1 && field.value.length > 0
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={i.value} />
                </MenuItem>
              ))}
          </Select>
        )}
      ></Controller>
    </InputWrapper>
  );
}
