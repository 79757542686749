import { UseFormSetValue } from 'react-hook-form';
import {
convertBooleanToString, getStringValueOrEmptyString,
} from 'utils/string/stringConverters';
import { Grooming, Housing, OfferDetails, Screening, SocialSecurity } from 'hooks/services/hosts/interfaces/offerDetails';
import { OfferDetailsFormInput } from '../interfaces/offerDetailsForm.formInput';

export const setValues = (
  value: OfferDetails,
  setValue: UseFormSetValue<OfferDetailsFormInput>
) => {
  const { screening, grooming, housing, socialSecurity } = value;

  setScreeningValues(screening, setValue);
  setGroomingValues(grooming, setValue);
  setHousingValues(housing, setValue);
  setSocialSecurityValues(socialSecurity, setValue);
};

const setScreeningValues = (
  screening: Screening,
  setValue: UseFormSetValue<OfferDetailsFormInput>
) => {
  setValue('screening.willEVIncurCostForScreening', convertBooleanToString(screening.willEVIncurCostForScreening));
  setValue('screening.willCompanyRequireDrugTest', convertBooleanToString(screening.willCompanyRequireDrugTest));
  setValue('screening.drugScreeningPolicy', screening.drugScreeningPolicy);
  setValue('screening.isDrugTestProvidedByHostCompany', convertBooleanToString(screening.isDrugTestProvidedByHostCompany));
  setValue('screening.drugScreeningCostToStudent', getStringValueOrEmptyString(screening.drugScreeningCostToStudent));
  setValue('screening.drugScreeningCostDue', getStringValueOrEmptyString(
    screening.drugScreeningCostDue
  ));
};

const setGroomingValues = (
  grooming: Grooming,
  setValue: UseFormSetValue<OfferDetailsFormInput>
) => {
  setValue('grooming.groomingStandards', grooming.groomingStandards);
};

const setHousingValues = (
  housing: Housing,
  setValue: UseFormSetValue<OfferDetailsFormInput>
) => {
  setValue('housing.doesHostEntityProvideTemporaryHousing', convertBooleanToString(housing.doesHostEntityProvideTemporaryHousing));
  setValue('housing.durationOfTemporaryHousing', housing.durationOfTemporaryHousing);
  setValue('housing.costOfTemporaryHousing', getStringValueOrEmptyString(
    housing.costOfTemporaryHousing));
};

const setSocialSecurityValues = (
  socialSecurity: SocialSecurity,
  setValue: UseFormSetValue<OfferDetailsFormInput>
) => {
  setValue('socialSecurity.addressOfNearestSocialSecurityOffice', socialSecurity.addressOfNearestSocialSecurityOffice);
  setValue('socialSecurity.distanceOfSSOFromSOA', socialSecurity.distanceOfSSOFromSOA);
  setValue('socialSecurity.willHostEntityProvideTransportationToTheNearestSocialSecurityOffice', convertBooleanToString(socialSecurity.willHostEntityProvideTransportationToTheNearestSocialSecurityOffice));
  setValue('socialSecurity.willHostEntityHelpEVMakeCopiesOfNecessaryDocuments', convertBooleanToString(socialSecurity.willHostEntityHelpEVMakeCopiesOfNecessaryDocuments));
  setValue('socialSecurity.otherSocialSecurityAssistanceProvided', socialSecurity.otherSocialSecurityAssistanceProvided);
};


