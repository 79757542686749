import { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Collapse,
  useTheme,
  Menu,
  ButtonBase,
  ListItemText,
} from '@mui/material';
import { popoverClasses } from '@mui/material/Popover';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { MenuItem } from '../drawerContent/menuItem';
import { OptionalTooltip } from 'components/tooltip/tooltip';
import SingleLevelMenuItem from './singleLevelMenuItem';

const NestedMenu = ({
  text,
  icon,
  subItems,
  open,
  handleClick,
  isOpenCollapse,
}: {
  text: string;
  icon: string;
  open: boolean;
  subItems: MenuItem[];
  handleClick: (text: string) => void;
  isOpenCollapse: string | null;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const theme = useTheme();

  const handleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (open) {
    return (
      <>
        <ListItem
          data-testid='list-item'
          key={text}
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => {
            handleClick(text);
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: 'initial',
              px: 2,
              borderRadius: theme.spacing(1.2),
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 1.5,
                justifyContent: 'center',
              }}
            >
              <Box component='img' alt='' src={icon} />
            </ListItemIcon>
            {open && <ListItemText primary={text} />}
            {isOpenCollapse === text ? (
              <ExpandLess color='primary' />
            ) : (
              <ExpandMore color='primary' />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse
          in={isOpenCollapse === text}
          timeout='auto'
          orientation='vertical'
          unmountOnExit
        >
          <List
            component='div'
            disablePadding
            sx={{
              pl: '1rem',
            }}
          >
            {subItems?.map((item) => (
              <SingleLevelMenuItem key={item.text} open={true} {...item} />
            ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <>
        <OptionalTooltip show={true} text={text} key={text}>
          <ListItem
            component={ButtonBase}
            id='basic-button'
            data-testid='list-item'
            key={text}
            disablePadding
            sx={{ display: 'block' }}
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClickEvent}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2,
                borderRadius: theme.spacing(1.2),
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  justifyContent: 'center',
                }}
              >
                <Box component='img' alt='' src={icon} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </OptionalTooltip>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            mt: theme.spacing(-1),
            ml: theme.spacing(1.5),
            [`& .${popoverClasses.paper}`]: {
              borderRadius: 0,
            },
          }}
        >
          {subItems?.map((item) => (
            <SingleLevelMenuItem key={item.text} open={true} {...item} />
          ))}
        </Menu>
      </>
    );
  }
};

export default NestedMenu;
