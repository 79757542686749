import { parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { DatePickerInput } from './datePickerInput';
import { DatePickerInputContainerProps } from './interfaces/datePickerInputContainerProps';
import { DEFAULT_FORMAT } from '../../utils/date/convertToDate';

const DatePickerInputContainer = <TFieldValues extends FieldValues>({
  format,
  label,
  labelHeight,
  validationMessage,
  defaultValue,
  control,
  name,
  getValue,
}: DatePickerInputContainerProps<TFieldValues>) => {
  const [open, setOpen] = useState(false);

  const {
    field: { onChange },
  } = useController({ control, name });

  const handleChange = (date: Date | null) => onChange(date);

  const dateFormat = format ? format : DEFAULT_FORMAT;

  const setDefaultDate = () => {
    if (!defaultValue) {
      return;
    }

    if (defaultValue instanceof Date) {
      onChange(defaultValue);
      return;
    }

    onChange(parse(defaultValue, dateFormat, new Date()));
  };

  useEffect(() => {
    setDefaultDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatePickerInput
      value={getValue()}
      handleChange={handleChange}
      format={dateFormat}
      label={label}
      labelHeight={labelHeight}
      validationMessage={validationMessage}
      control={control}
      name={name}
      handleOpen={setOpen}
      open={open}
    />
  );
};

export default DatePickerInputContainer;
