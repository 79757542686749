import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.typography.subtitle1.color,
  fontWeight: 400,
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));
