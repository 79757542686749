import * as yup from 'yup';
import { transformDate } from 'utils/date/convertToDate';
import { transformStringToNumberOrUndefined } from 'utils/extensions/numberInputTransformer';
import { convertStringToBoolean } from 'utils/string/stringConverters';

export const schema = yup.object().shape({
  program: yup.string().required().label('Program'),
  description: yup.string().max(1445),
  nearestInternationalAirport: yup
    .string()
    .label('Nearest international airport')
    .max(500)
  ,
  nearestAirportToJobsite: yup
    .string()
    .label('Nearest airport to job site')
    .max(500)
  ,
  isPickUpProvided: yup
    .boolean()
    .transform(convertStringToBoolean)
    .label('Is pick-up provided?')
  ,
  latestPreferredArrivalDate: yup
    .date()
    .nullable()
    .transform(transformDate)
    .label('Start Date')
  ,
  generalArrivalInstructions: yup
    .string()
    .nullable()
    .label('General arrival instructions')
    .max(5000)
  ,
  shouldContactHCBeforeArrival: yup
    .boolean()
    .transform(convertStringToBoolean)
    .label('Should Contact HC Before Arrival?')
  ,
  contactPhoneNumber: yup
    .string()
    .label('Contact Phone Number')
    .max(25)
  ,
  transportPickUpCost: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .nullable()
    .max(999999)
    .min(0)
    .label('Transport pick-up cost')
  ,
  preferredArrivalDays: yup
    .string()
    .label('Preferred arrival days')
    .max(50)
  ,
  preferredArrivalTimes: yup
    .string()
    .label('Preferred arrival times')
    .max(150)
  ,
  reportingTo: yup
    .string()
    .label('Report to')
    .max(150)
});
