import { Chip, Grid, Typography, useTheme } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import HeaderFooterModal from 'components/modal/headerFooterModal';
import ModalHeader from 'components/modal/modalHeader';
import { CustomStaticDatePicker } from 'components/staticDatePicker';
import { renderWeekPickerDay } from 'components/staticDatePicker/utils/renderWeekPickerDay';
import { useTranslations } from 'hooks/useTranslations';
import { useVerticalScrollBar } from 'hooks/useVerticalScrollBar';
import { Nullable } from 'utils/interfaces';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import AvailableDateItemContainer from './components/availableDates/availableDateItem.container';
import { DateWeekDayPeriod } from './interfaces/DateWeekDay';
import { SetAvailabilityProps } from './interfaces/SetAvailabilityProps';

interface Props extends SetAvailabilityProps {
  onSave: () => void;
  calendarValue: Nullable<Date>;
  onChangeCalendar: (newValue: Nullable<Date>) => void;
  dateSlots: DateWeekDayPeriod[];
  copyWeeksArray: KeyValueItemProps[];
  availableDates: Date[];
  isLoading?: boolean;
}

const SetAvailability = ({
  onSave,
  calendarValue,
  onChangeCalendar,
  dateSlots,
  copyWeeksArray,
  availableDates,
  isLoading,
  ...props
}: Props) => {
  const theme = useTheme();
  const { availability, modal } = useTranslations();
  const { title, stepOne, stepTwo, setNewHours, selectWeek, copyAvailability } =
    availability.setAvailability;
  const { control, register } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'dates',
  });
  const scrollbar = useVerticalScrollBar();

  return (
    <HeaderFooterModal
      {...props}
      sx={{
        width: { xs: `calc(100% - ${theme.spacing(4)})`, lg: 900 },
        height: { xs: `calc(100% - ${theme.spacing(4)})`, md: 'auto' },
      }}
      saveBtnText={modal.buttons.save}
      cancelBtnText={modal.buttons.cancel}
      onSave={onSave}
      isLoading={isLoading}
    >
      <Grid
        container
        sx={{
          height: { xs: `calc(100% - ${theme.spacing(9)})`, md: 'auto' },
          overflow: 'auto',
          position: { xs: 'absolute', md: 'relative' },
          ...scrollbar,
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={5}
          p={{ xs: theme.spacing(2), md: theme.spacing(2, 3) }}
          alignContent={'flex-start'}
          gap={1}
        >
          <ModalHeader title={title} />
          <Grid item xs={12}>
            <Chip label={stepOne} color='secondary' />
          </Grid>
          <Grid item xs={12} my={theme.spacing(2)}>
            <Typography variant='body1' color={theme.palette.primary.main}>
              {selectWeek}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomStaticDatePicker
              value={calendarValue}
              onChange={onChangeCalendar}
              renderDayCustom={renderWeekPickerDay}
              extraSelectedDates={availableDates}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          p={theme.spacing(2, 3)}
          sx={{ backgroundColor: theme.palette.grey['200'] }}
        >
          <Chip label={stepTwo} color='secondary' />

          <Grid item xs={12} my={theme.spacing(2)}>
            <Typography variant='body1' color={theme.palette.primary.main}>
              {setNewHours}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                maxHeight: 400,
                overflowY: 'auto',
              }}
            >
              {fields.map((date, index) => (
                <AvailableDateItemContainer
                  dateIndex={index}
                  key={date.id}
                  isLastItem={fields.length === index + 1}
                />
              ))}
            </Grid>
          </Grid>

          <Grid item xs={7} my={theme.spacing(2)}>
            <Typography
              variant='body1'
              color={theme.palette.primary.main}
              mb={theme.spacing(1)}
            >
              {copyAvailability}
            </Typography>
            <Grid item>
              <DropdownInput
                control={control}
                inputId={'copyAvailability'}
                items={copyWeeksArray}
                register={register('copyAvailability')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderFooterModal>
  );
};

export default SetAvailability;
