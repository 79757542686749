import { HousingFormInput } from '../interfaces/housingForm.formInput';

export const initWithEmptyValues: HousingFormInput = {
  details: {
    housingModel: '',
    housingType: '',
    propertyName: '',
    contactName: '',
    propertyManagement: '',
    canBeCoEd: '',
    phoneNumber: '',
    website: '',
    emailForReservationEnquiries: '',
    description: '',
    addressStreet: '',
    addressCity: '',
    addressState: '',
    addressZipCode: '',
    numberOfBedsPerRoom: '',
    numberOfBedrooms: '',
    participantsPerProperty: '',
    participantsPerRoom: '',
    bathroomsPerProperty: '',
    beddingAndTowels: '',
    costOfBeddingAndTowels: '',
    beddingAndTowelPaymentDue: '',
    kitchenFacilities: '',
    additionalItemsMustBring: '',
    additionalComments: '',
    housingCost: '',
    rentPeriodicity: '',
    isWeeklyRentPayrollDeducted: '',
    includedInCost: '',
    additionalItemsIncludedInCost: '',
    administrationFee: '',
    administrationFeeDue: '',
    housingDepositAmount: '',
    isHousingDepositPayrollDeducted: '',
    housingDepositRefundable: '',
    furtherInformationOnHousingRefundPolicy: '',
    leaseRequired: '',
    lengthOfLease: '',
    timeUnit: '',
    furtherInformationOnLengthOfLease: '',
    feesAdditionalComments: '',
    depositRefundPolicy: '',
    isRentersInsuranceRequired: '',
    housingAmentities: '',
    distanceFromWorkSiteToHousing: '',
    transportationDetails: '',
  },
  utilities: {
    gas: '',
    electric: '',
    water: '',
    sewer: '',
    trash: '',
    internet: '',
    tv: '',
    telephone: '',
    isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants: '',
    theAverageMonthlyCostOfAnyUtilitiesNotIncluded: '',
  },
  security: {
    gated: '',
    areThereActiveSecurityCamerasOnSite: '',
    secured: '',
    isThereARepresentativeOnSite24For7: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    bus: '',
    frequencyOfBus: '',
    subway: '',
    frequencyOfSubway: '',
    train: '',
    frequencyOfTrain: '',
    otherPublicTransport: '',
    isThereAWebsiteForPublicTransportation: '',
    isItConsideredSafeToWalkToTheNearestPublicTransportationStop: '',
    howFarIsIt: '',
    doesItRunToTheJobsite: '',
  },
};
