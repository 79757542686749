import { Box, IconButton } from '@mui/material';
import Clone from 'assets/icons/clonePlacement.svg';
import Edit from 'assets/icons/editPlacement.svg';
import EditDisabled from 'assets/icons/editPlacementDisabled.svg';
import { OptionalTooltip } from 'components/tooltip/tooltip';
import { ArrivalInstructionSummaryResponse } from 'hooks/services/hosts/interfaces/arrivalInstructionSummary.response';

const ActionsComponent = ({
  item,
  handleAction,
}: {
  item: ArrivalInstructionSummaryResponse;
  handleAction: (
    item: ArrivalInstructionSummaryResponse,
    action: 'edit' | 'duplicate'
  ) => void;
}) => {
  const disabled = item.status !== 'Unlocked';
  return (
    <Box display='flex'>
      <OptionalTooltip show={true} text='Clone' placement='top'>
        <IconButton
          onClick={() => handleAction(item, 'duplicate')}
          sx={{ mr: 1 }}
        >
          <Box component='img' src={Clone} alt='Clone' />
        </IconButton>
      </OptionalTooltip>
      <OptionalTooltip show={!disabled} text='Edit' placement='top'>
        <IconButton
          onClick={() => handleAction(item, 'edit')}
          disabled={disabled}
        >
          <Box
            component='img'
            src={disabled ? EditDisabled : Edit}
            alt='Edit'
          />
        </IconButton>
      </OptionalTooltip>
    </Box>
  );
};

export default ActionsComponent;
