import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import translations from 'utils/translations';
import { HousingFormInput } from '../../interfaces/housingForm.formInput';

export const HousingFormUtilities = () => {
  const theme = useTheme();

  const { inputStyle, yesOrNoInput, inputsSection } = useFormInputsStyle(theme);

  const {
    title,
    gas,
    electric,
    water,
    sewer,
    trash,
    internet,
    tv,
    telephone,
    isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants,
    whatIsTheAverageMonthlyCostOfAnyUtilitiesNotIncluded,
  } = translations.housingForm.sections.utilities;

  return (
    <ConnectForm<HousingFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'gas'}
                  inputLabel={gas}
                  errorMessage={errors?.utilities?.gas?.message}
                  control={control}
                  register={register('utilities.gas')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'electric'}
                  inputLabel={electric}
                  errorMessage={errors?.utilities?.electric?.message}
                  control={control}
                  register={register('utilities.electric')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'water'}
                  inputLabel={water}
                  errorMessage={errors?.utilities?.water?.message}
                  control={control}
                  register={register('utilities.water')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'sewer'}
                  inputLabel={sewer}
                  errorMessage={errors?.utilities?.sewer?.message}
                  control={control}
                  register={register('utilities.sewer')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'trash'}
                  inputLabel={trash}
                  errorMessage={errors?.utilities?.trash?.message}
                  control={control}
                  register={register('utilities.trash')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'internet'}
                  inputLabel={internet}
                  errorMessage={errors?.utilities?.internet?.message}
                  control={control}
                  register={register('utilities.internet')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'tv'}
                  inputLabel={tv}
                  errorMessage={errors?.utilities?.tv?.message}
                  control={control}
                  register={register('utilities.tv')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'telephone'}
                  inputLabel={telephone}
                  errorMessage={errors?.utilities?.telephone?.message}
                  control={control}
                  register={register('utilities.telephone')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(3, 3)}>
                <DropdownYesNo
                  inputId={
                    'isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants'
                  }
                  inputLabel={
                    isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants
                  }
                  errorMessage={
                    errors?.utilities
                      ?.isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants
                      ?.message
                  }
                  control={control}
                  register={register(
                    'utilities.isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants'
                  )}
                />
              </Grid>

              <Grid {...inputStyle(3, 3)}>
                <NumberInput
                  label={whatIsTheAverageMonthlyCostOfAnyUtilitiesNotIncluded}
                  useDecimals={true}
                  errorMessage={
                    errors?.utilities
                      ?.theAverageMonthlyCostOfAnyUtilitiesNotIncluded?.message
                  }
                  control={control}
                  name='utilities.theAverageMonthlyCostOfAnyUtilitiesNotIncluded'
                  getValue={() =>
                    getValues(
                      'utilities.theAverageMonthlyCostOfAnyUtilitiesNotIncluded'
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
