import * as yup from 'yup';

import { schema as screeningSchema } from '../sections/screening/validation/validation.schema';
import { schema as groomingSchema } from '../sections/grooming/validation/validation.schema';
import { schema as housingSchema } from '../sections/housing/validation/validation.schema';
import { schema as socialSecuritySchema } from '../sections/socialSecurity/validation/validation.schema';

export const schema = yup.object().shape({
  screening: screeningSchema,
  grooming: groomingSchema,
  housing: housingSchema,
  socialSecurity: socialSecuritySchema,
});
