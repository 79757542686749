import { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { DateRange } from './dateRange';
import {
  changeEndDateToAfterStartDate,
  executeValidation,
  validRange,
} from './helper';
import { DateRangeContainerProps } from './interfaces/dateRangeContainerProps';
import { convertToDate, DEFAULT_FORMAT } from '../../utils/date/convertToDate';

const DateRangeContainer = <TFieldValues extends FieldValues>({
  control,
  nameEndDate,
  nameStartDate,
  labelEndDate,
  labelStartDate,
  dateEndDefaultValue,
  dateStartDefaultValue,
  format,
  labelBetween,
  endDateValidationMessage,
  startDateValidationMessage,
  minDate,
  maxDate,
  getEndDate,
  getStartDate,
  disabled,
}: DateRangeContainerProps<TFieldValues>) => {
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [selfStartDateValidationMessage, setSelfStartDateValidationMessage] =
    useState<string | undefined | null>();
  const [selfEndDateValidationMessage, setSelfEndDateValidationMessage] =
    useState<string | undefined | null>();

  const {
    field: { onChange: onChangeEndDate },
  } = useController({ control, name: nameEndDate });

  const {
    field: { onChange: onChangeStartDate },
  } = useController({ control, name: nameStartDate });

  const formatPattern = format ? format : DEFAULT_FORMAT;

  useEffect(() => {
    const validationResult = executeValidation(
      dateStartDefaultValue,
      dateEndDefaultValue,
      minDate,
      maxDate,
      setSelfStartDateValidationMessage,
      setSelfEndDateValidationMessage
    );

    if (!validationResult) {
      return;
    }

    onChangeStartDate(convertToDate(getStartDate()));
    onChangeEndDate(convertToDate(getEndDate()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = (date: Date | null) => {
    const { startIsValid } = validRange(
      date,
      convertToDate(getEndDate(), formatPattern),
      minDate,
      maxDate
    );
    if (startIsValid) {
      setSelfStartDateValidationMessage(null);
      onChangeStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    const { endIsValid } = validRange(getStartDate(), date, minDate, maxDate);
    if (endIsValid) {
      setSelfEndDateValidationMessage(null);

      onChangeEndDate(
        changeEndDateToAfterStartDate(
          convertToDate(getStartDate(), formatPattern),
          date
        )
      );
    }
  };

  return (
    <DateRange
      startDate={getStartDate()}
      endDate={getEndDate()}
      handleChangeEndDate={handleEndDateChange}
      handleChangeStartDate={handleStartDateChange}
      labelBetween={labelBetween}
      handleOpenEndDate={setOpenEndDate}
      handleOpenStartDate={setOpenStartDate}
      openEndDate={openEndDate}
      openStartDate={openStartDate}
      format={formatPattern}
      labelEndDate={labelEndDate}
      labelStartDate={labelStartDate}
      maxDate={maxDate}
      minDate={minDate}
      control={control}
      nameEndDate={nameEndDate}
      nameStartDate={nameStartDate}
      startDateValidationMessage={
        selfStartDateValidationMessage
          ? selfStartDateValidationMessage
          : startDateValidationMessage
      }
      endDateValidationMessage={
        selfEndDateValidationMessage
          ? selfEndDateValidationMessage
          : endDateValidationMessage
      }
      disabled={disabled}
    />
  );
};

export default DateRangeContainer;
