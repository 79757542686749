import { TextField, useTheme } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { RenderInputProps } from '../../interfaces/RenderInputProps';

export const RenderInput = <TFieldValues extends FieldValues>({
  textFieldProps,
  control,
  name,
  label,
  validationMessage,
  openModal,
}: RenderInputProps<TFieldValues>) => {
  const { InputProps } = textFieldProps;

  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...textFieldProps}
          {...field}
          error={!!validationMessage}
          fullWidth
          variant='standard'
          InputProps={{
            ...{ 'data-testid': name },
            ...InputProps,
            ...{
              onKeyDown: (e) => {
                e.preventDefault();
              },
              onClick: () => {
                openModal();
              },
              id: label,
            },
          }}
          sx={{
            boxShadow: 3,
            'input': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            '& .MuiInputBase-root': {
              fontWeight: theme.input.control.fontWeight,
              fontSize: theme.input.control.fontSize,
              height: 52,
              padding: theme.spacing(2),
            },
            '& .MuiInputBase-root:before': {
              borderBottom: 'none',
            },
          }}
        />
      )}
    />
  );
};
