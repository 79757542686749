import { ClonePlacementBody } from 'hooks/services/placements/interfaces/cloneParams.request';
import { BE_DATE_FORMAT, DEFAULT_FORMAT, formatDate } from 'utils/date/convertToDate';
import { ClonePlacementFormInput } from '../interfaces/clonePlacementFormInput';

export const makeRequest = (
  inputsProps: ClonePlacementFormInput
): ClonePlacementBody => {
  return {
    guaranteedSalaryWageBeforeDeduction: Number(
      inputsProps.guaranteedSalaryWageBeforeDeduction
    ),
    guaranteedSalaryWagePeriod: inputsProps.guaranteedSalaryWagePeriod,
    supervisor: Number(inputsProps.supervisor),
    earliestStartDate: formatDate(
      inputsProps.earliestStartDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    earliestEndDate: formatDate(
      inputsProps.earliestEndDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    latestStartDate: formatDate(
      inputsProps.latestStartDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    latestEndDate: formatDate(
      inputsProps.latestEndDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    totalPositions: Number(inputsProps.totalPositions),
  };
};
