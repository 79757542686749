import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import ConnectForm from 'components/connectForm';
import DropdownInput from 'components/dropdownInput';
import DropdownYesNo from 'components/dropdownYesNo';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import DatePickerInput from 'components/datePickerInput';

import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';

import { CompleteInfoFormInput } from 'pages/company/completeInfo/interfaces/completeInfo.formInput';

import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';

export const CompanyDetailsSection = ({
  sectors,
  annualRevenues,
}: {
  sectors: KeyValueItemProps[];
  annualRevenues: KeyValueItemProps[];
}) => {
  const translations = useTranslations();
  const theme = useTheme();
  const {
    defaultInput,
    inputsGridFullWidth,
    smallInput,
    halfToFullPageInput,
    datePickerInput,
    numberInput,
    yesOrNoInput,
    inputsSection,
  } = useFormInputsStyle(theme);
  const matchMd = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });

  const {
    title,
    videoEmbedLabel,
    sectorLabel,
    industryLabel,
    orientationRequiredLabel,
    yearEstablishedLabel,
    fullTimeEmployeeLabel,
    visitorsLabel,
    summerWorkLabel,
    internProgramLabel,
    teacherProgramLabel,
    urlLabel,
    irsTaxNumberLabel,
    siteRequirementLabel,
    annualRevenueLabel,
    expirationDateLabel,
    workmanCompIdLabel,
    workmanCompPhoneLabel,
    insuranceCompany,
    descriptionLabel,
    commentsLabel,
    businessLicenseLabel,
    termsConditionsExpirationLabel,
    seasonalityReportExpirationLabel,
    hostAddressLabel,
    hostIncorporateInUsaLabel,
    hostIncorporateNotUsaLabel,
    layoffsLabel,
    otherSponsorsLabel,
    howLongHiringParticipantsLabel,
  } = translations.companyCompleteInfo.details;

  return (
    <ConnectForm<CompleteInfoFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>
          <Grid {...inputsGridFullWidth}>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextAreaInput
                  inputLabel={videoEmbedLabel}
                  inputId={'videoEmbed'}
                  register={register('details.videoEmbed')}
                  errorMessage={errors.details?.videoEmbed?.message}
                  maxLength={100}
                  control={control}
                  singleLine
                />
              </Grid>
              <Grid {...defaultInput}>
                <DropdownInput
                  inputLabel={sectorLabel}
                  inputId={'sector'}
                  errorMessage={errors.details?.sector?.message}
                  control={control}
                  register={register('details.sector')}
                  items={sectors}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextAreaInput
                  inputLabel={industryLabel}
                  inputId={'industry'}
                  register={register('details.industry')}
                  errorMessage={errors.details?.industry?.message}
                  control={control}
                  maxLength={30}
                  singleLine
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputLabel={orientationRequiredLabel}
                  inputId={'orientationRequired'}
                  errorMessage={errors.details?.orientationRequired?.message}
                  control={control}
                  register={register('details.orientationRequired')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...numberInput}>
                <NumberInput
                  label={yearEstablishedLabel}
                  labelHeight={46}
                  useDecimals={false}
                  errorMessage={errors.details?.yearEstablished?.message}
                  control={control}
                  name='details.yearEstablished'
                  getValue={() => getValues('details.yearEstablished')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={fullTimeEmployeeLabel}
                  labelHeight={46}
                  useDecimals={false}
                  errorMessage={errors.details?.totalCurrentEmployees?.message}
                  control={control}
                  name='details.totalCurrentEmployees'
                  getValue={() => getValues('details.totalCurrentEmployees')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={visitorsLabel}
                  labelHeight={46}
                  useDecimals={false}
                  errorMessage={errors.details?.totalExchangeVisitors?.message}
                  control={control}
                  name='details.totalExchangeVisitors'
                  getValue={() => getValues('details.totalExchangeVisitors')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputLabel={summerWorkLabel}
                  inputId={'summerWork'}
                  register={register('details.summerWork')}
                  errorMessage={errors.details?.summerWork?.message}
                  control={control}
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputLabel={internProgramLabel}
                  inputId={'internProgram'}
                  register={register('details.internProgram')}
                  errorMessage={errors.details?.internProgram?.message}
                  control={control}
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputLabel={teacherProgramLabel}
                  inputId={'teacherProgram'}
                  register={register('details.teacherProgram')}
                  errorMessage={errors.details?.teacherProgram?.message}
                  control={control}
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextAreaInput
                  inputLabel={urlLabel}
                  inputId={'url'}
                  register={register('details.url')}
                  errorMessage={errors.details?.url?.message}
                  control={control}
                  maxLength={100}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputLabel={irsTaxNumberLabel}
                  inputId={'irsTaxNumber'}
                  register={register('details.irsTaxNumber')}
                  errorMessage={errors.details?.irsTaxNumber?.message}
                  control={control}
                  maxLength={50}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputLabel={siteRequirementLabel}
                  labelHeight={46}
                  inputId={'siteRequirement'}
                  errorMessage={errors.details?.siteRequirement?.message}
                  control={control}
                  register={register('details.siteRequirement')}
                />
              </Grid>

              <Grid {...numberInput}>
                <DropdownInput
                  inputLabel={annualRevenueLabel}
                  inputId={'annualRevenue'}
                  errorMessage={errors.details?.annualRevenue?.message}
                  control={control}
                  register={register('details.annualRevenue')}
                  items={annualRevenues}
                />
              </Grid>
              <Grid {...datePickerInput}>
                <DatePickerInput
                  label={expirationDateLabel}
                  labelHeight={46}
                  validationMessage={errors.details?.expirationDate?.message}
                  control={control}
                  name='details.expirationDate'
                  getValue={() => getValues('details.expirationDate')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...smallInput}>
                <TextAreaInput
                  inputLabel={workmanCompIdLabel}
                  labelHeight={46}
                  inputId={'workmanCompId'}
                  register={register('details.workmanCompId')}
                  errorMessage={errors.details?.workmanCompId?.message}
                  control={control}
                  maxLength={50}
                  singleLine
                />
              </Grid>

              <Grid {...smallInput}>
                <TextAreaInput
                  inputLabel={workmanCompPhoneLabel}
                  labelHeight={46}
                  inputId={'workmanCompPhone'}
                  register={register('details.workmanCompPhone')}
                  errorMessage={errors.details?.workmanCompPhone?.message}
                  control={control}
                  maxLength={15}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...halfToFullPageInput}>
              <TextAreaInput
                inputLabel={insuranceCompany}
                inputId={'insuranceCompany'}
                register={register('details.insuranceCompany')}
                errorMessage={errors.details?.insuranceCompany?.message}
                control={control}
                maxLength={250}
              />
            </Grid>

            <Grid {...inputsGridFullWidth}>
              <TextAreaInput
                inputLabel={descriptionLabel}
                inputId={'description'}
                register={register('details.description')}
                errorMessage={errors.details?.description?.message}
                control={control}
                maxLength={1000}
              />
            </Grid>

            <Grid {...inputsGridFullWidth}>
              <TextAreaInput
                inputLabel={commentsLabel}
                inputId={'comments'}
                register={register('details.comments')}
                errorMessage={errors.details?.comments?.message}
                control={control}
                maxLength={1000}
              />
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...datePickerInput}>
                <DatePickerInput
                  label={businessLicenseLabel}
                  labelHeight={46}
                  validationMessage={errors.details?.businessLicense?.message}
                  control={control}
                  name='details.businessLicense'
                  getValue={() => getValues('details.businessLicense')}
                />
              </Grid>

              <Grid {...datePickerInput}>
                <DatePickerInput
                  label={termsConditionsExpirationLabel}
                  labelHeight={46}
                  validationMessage={
                    errors.details?.termsConditionsExpiration?.message
                  }
                  control={control}
                  name='details.termsConditionsExpiration'
                  getValue={() =>
                    getValues('details.termsConditionsExpiration')
                  }
                />
              </Grid>

              <Grid {...datePickerInput}>
                <DatePickerInput
                  label={seasonalityReportExpirationLabel}
                  labelHeight={46}
                  validationMessage={
                    errors.details?.seasonalityReportExpiration?.message
                  }
                  control={control}
                  name='details.seasonalityReportExpiration'
                  getValue={() =>
                    getValues('details.seasonalityReportExpiration')
                  }
                />
              </Grid>
            </Grid>

            <Grid {...halfToFullPageInput}>
              <TextAreaInput
                inputLabel={hostAddressLabel}
                inputId={'hostAddress'}
                register={register('details.hostAddress')}
                errorMessage={errors.details?.hostAddress?.message}
                control={control}
                maxLength={250}
              />
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputLabel={hostIncorporateInUsaLabel}
                  labelHeight={46}
                  inputId={'hostIncorporateInUsa'}
                  errorMessage={errors.details?.hostIncorporateInUsa?.message}
                  control={control}
                  register={register('details.hostIncorporateInUsa')}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputLabel={hostIncorporateNotUsaLabel}
                  labelHeight={46}
                  inputId={'hostIncorporateNotUsa'}
                  register={register('details.hostIncorporateNotUsa')}
                  errorMessage={errors.details?.hostIncorporateNotUsa?.message}
                  control={control}
                  maxLength={50}
                  singleLine
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputLabel={layoffsLabel}
                  inputId={'layoffs'}
                  errorMessage={errors.details?.layoffs?.message}
                  control={control}
                  register={register('details.layoffs')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextAreaInput
                  inputLabel={otherSponsorsLabel}
                  labelHeight={matchMd ? 46 : 0}
                  inputId={'otherSponsors'}
                  register={register('details.otherSponsors')}
                  errorMessage={errors.details?.otherSponsors?.message}
                  control={control}
                  maxLength={500}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextAreaInput
                  inputLabel={howLongHiringParticipantsLabel}
                  labelHeight={matchMd ? 46 : 0}
                  inputId={'howLongHiringParticipants'}
                  register={register('details.howLongHiringParticipants')}
                  errorMessage={
                    errors.details?.howLongHiringParticipants?.message
                  }
                  control={control}
                  maxLength={250}
                  numberOfRows={2}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
