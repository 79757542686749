import { Box, IconButton } from '@mui/material';
import ClearFilters from 'assets/icons/clearFilters.svg';
import ClearFiltersDisabled from 'assets/icons/clearFiltersDisabled.svg';
import { ApprovedDate, Positions, Programs, ShowOnlyMatched, ShowOnlyNotConfirmed, Statuses } from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const PlacementsDesktopFilters = ({
  onSubmit,
  control,
  getValues,
  programs,
  statuses,
  register,
  titles,
  isDirty,
  clearFilters,
}: FiltersFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Box
        display='flex'
        alignItems='center'
        sx={{
          width: '100%',
          mx: 2.5,
          my: 2,
          '> * ': {
            maxWidth: '250px',
            flex: '1 0 auto',
            mr: 2,
          },
        }}
      >
        <Box>
          <Programs control={control} programs={programs} />
        </Box>

        <Box>
          <Positions control={control} titles={titles} />
        </Box>

        <Box>
          <Statuses control={control} statuses={statuses} />
        </Box>

        <Box>
          <ApprovedDate getValues={getValues} control={control} />
        </Box>

        <Box sx={{ mt: 2.5, flex: '0 1 auto' }}>
          <ShowOnlyMatched control={control} />
        </Box>
        <Box sx={{ mt: 2.5, flex: '0 1 auto' }}>
          <ShowOnlyNotConfirmed control={control} />
        </Box>
        <Box sx={{ mt: 2.5 }}>
          <IconButton onClick={clearFilters}>
            <Box
              component='img'
              alt='Clear Filters'
              src={isDirty ? ClearFilters : ClearFiltersDisabled}
            />
          </IconButton>
        </Box>
      </Box>
    </form>
  );
};

export default PlacementsDesktopFilters;
