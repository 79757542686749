import { Box, Table, TableBody, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';
import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { RowNotFound } from 'components/tables/components/rowNotFound';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { VisaArrivalInfoResponse } from 'hooks/services/hosts/interfaces/visaArrivalInfo.response';
import { useHorizontalScrollBar } from 'hooks/useHorizontalScrollBar';
import { useTranslations } from 'hooks/useTranslations';
import { BE_DATE_FORMAT, convertUTCDateToOffset, formatDate } from 'utils/date/convertToDate';
import { getCells } from '../../utils/table.cells';
import { CellHeaderId } from '../visaArrivalInfoTable/interfaces/visaArrivalInfoCellHeader';

export const VisaArrivalInfoListView = ({
  order,
  orderBy,
  sortHandler,
  rows,
  heightToSkip,
  timeZone,
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  rows: VisaArrivalInfoResponse[];
  heightToSkip: number;
  timeZone?: string;
}) => {
  const horizontalScrollBar = useHorizontalScrollBar();
  const {
    visaArrivalInfo: {
      visaArrivalInfoTable: { columns },
    },
  } = useTranslations();

  const cells: CellHeader<CellHeaderId>[] = getCells(columns);

  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const margins = isSmallScreen ? 0 : 16 + 16 + 8;

  return (
    <Box
      mt={2}
      pb={1}
      px={'1px'}
      sx={{
        ...ResponsiveContainerProps(heightToSkip + margins),
        ...horizontalScrollBar,
      }}
    >
      <Table stickyHeader>
        <SortableTableHeader<CellHeaderId>
          {...{ order, orderBy, sortHandler, cells }}
        />
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row: VisaArrivalInfoResponse) => (
              <TableRow key={row.applicantId}>
                <TableCell sx={{ minWidth: 200 }}>{row.hostEntity}</TableCell>
                <TableCell sx={{ minWidth: 250 }}>{row.program}</TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.applicantId}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.applicantFirstName}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.applicantLastName}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.email}</TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.gender}</TableCell>

                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(row.dateOfBirth, BE_DATE_FORMAT, 'MMM d, yyyy')}
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.statusCode}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {row.countryOfResidence}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.jobId}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.position}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(row.startsOn, BE_DATE_FORMAT, 'MMM d, yyyy')}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(row.endsOn, BE_DATE_FORMAT, 'MMM d, yyyy')}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(
                    convertUTCDateToOffset(row.embassyDate, timeZone),
                    BE_DATE_FORMAT,
                    'MMM d, yyyy'
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.visaApproved}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.embassy}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(
                    row.visaExpirationDate,
                    BE_DATE_FORMAT,
                    'MMM d, yyyy'
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {formatDate(row.arrivalDate, BE_DATE_FORMAT, 'MMM d, yyyy')}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.arrivalTime}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  {row.arrivalAirport}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.airline}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.flightNumber}</TableCell>
                <TableCell sx={{ minWidth: 350 }}>
                  {row.additionalInfo}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <RowNotFound colSpan={15} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
