import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { CustomPickersDay } from '../components/customPickersDay';

export const renderSelectedDays = (
  date: Date,
  pickersDayProps: PickersDayProps<Date>,
  values: Date | Date[] | null
) => {
  if (!values || !Array.isArray(values)) {
    return <PickersDay {...pickersDayProps} />;
  }

  const findDate = (values: Date[], date: Date) => {
    const dateTime = date.getTime();
    return values.filter((item) => item.getTime() === dateTime).length > 0;
  };

  const selected = findDate(values, date);

  return <CustomPickersDay {...pickersDayProps} selected={selected} />;
};

export const renderSelectedDay = (
  date: Date,
  selectedDays: Date[],
  pickersDayProps: PickersDayProps<Date>
) => {
  const dateTime = date.getTime();

  const selected = selectedDays.some((i) => i.getTime() === dateTime);

  return <CustomPickersDay {...pickersDayProps} selected={selected} />;
};
