import { Grid, useTheme } from '@mui/material';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import HeaderFooterModal from 'components/modal/headerFooterModal';
import ModalHeader from 'components/modal/modalHeader';
import { useTranslations } from 'hooks/useTranslations';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { useFormContext } from 'react-hook-form';
import TextareaInput from 'components/textAreaInput/textAreaInput';
import DatePickerInput from 'components/datePickerInput';
import { InterviewReportResponse } from 'hooks/services/hosts/interfaces/interviewReport.response';

const InterviewReportModal = ({
  modalOpen,
  handleCloseModal,
  onSave,
  englishLevels,
  isLoading,
}: {
  modalOpen: boolean;
  handleCloseModal: () => void;
  onSave: () => void;
  englishLevels: KeyValueItemProps[];
  isLoading: boolean;
}) => {
  const theme = useTheme();
  const { modal, allInterviews } = useTranslations();
  const {
    modal: { interviewReport },
  } = allInterviews;
  const {
    control,
    formState: { errors },
    register,
    getValues,
  } = useFormContext<InterviewReportResponse>();

  return (
    <HeaderFooterModal
      open={modalOpen}
      onClose={handleCloseModal}
      saveBtnText={modal.buttons.submit}
      cancelBtnText={modal.buttons.cancel}
      onSave={onSave}
      sx={{ width: 600 }}
      isLoading={isLoading}
    >
      <Grid container sx={{ p: theme.spacing(2) }}>
        <Grid item xs={12}>
          <ModalHeader title={allInterviews.actions.interviewReport} />
        </Grid>
        <Grid container gap={2} sx={{ p: theme.spacing(2, 2, 2, 1) }}>
          <Grid item xs={12}>
            <DropdownInput<InterviewReportResponse>
              inputId={'overallEnglishLevelInpersonInterview'}
              labelHeight={20}
              inputLabel={interviewReport.overallEnglishLevel}
              items={englishLevels}
              errorMessage={
                errors?.overallEnglishLevelInpersonInterview?.message
              }
              control={control}
              register={register('overallEnglishLevelInpersonInterview')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaInput
              inputId='furtherCommentsInpersonInterview'
              inputLabel={interviewReport.furtherComments}
              labelHeight={20}
              register={register('furtherCommentsInpersonInterview')}
              errorMessage={errors?.furtherCommentsInpersonInterview?.message}
              control={control}
              maxLength={500}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaInput
              inputId='interviewerSignatureInpersonInterview'
              inputLabel={interviewReport.interviewerSignature}
              labelHeight={20}
              register={register('interviewerSignatureInpersonInterview')}
              errorMessage={
                errors?.interviewerSignatureInpersonInterview?.message
              }
              control={control}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePickerInput
              label={interviewReport.interviewDate}
              labelHeight={20}
              validationMessage={
                errors.interviewDateInpersonInterviewReportData?.message
              }
              control={control}
              name='interviewDateInpersonInterviewReportData'
              getValue={() =>
                getValues('interviewDateInpersonInterviewReportData')
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderFooterModal>
  );
};

export default InterviewReportModal;
