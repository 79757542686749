import {
  Box,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Expand from 'assets/icons/expandPlacement.svg';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import HousingMobileDetails from './components/housingMobileDetails';
import { HousingRow } from '../../interfaces/housingRow';
import HousingStatus from '../status';

const StyledTableCellTypography = styled(Typography)(() => ({
  fontSize: 'inherit',
}));

const MobileTable = ({
  rows,
  housingDetails,
  showDetails,
  top,
}: {
  rows: HousingRow[];
  housingDetails: HousingRow | undefined;
  showDetails: (value: any) => void;
  top: number;
}) => {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        overflowY: 'auto',
        height: `calc(90vh - ${top}px - ${theme.spacing(5.5)})`,
        ...NoScrollBar,
      }}
    >
      <HousingMobileDetails
        housingDetails={housingDetails!}
        handleClose={() => showDetails(undefined)}
      />

      <Table
        sx={{
          position: 'relative',
          height: '100%',
          overflow: 'auto',
          padding: theme.spacing(1),
        }}
      >
        <TableBody>
          {rows
            ? rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Box display='flex' flexDirection='column'>
                      <Box
                        display='flex'
                        alignItems='center'
                        sx={{
                          mt: 1,
                          '> *:last-child': {
                            ml: 'auto',
                          },
                        }}
                      >
                        <StyledTableCellTypography>
                          {row.id}
                        </StyledTableCellTypography>

                        <Divider
                          orientation='vertical'
                          sx={{ mr: 1.5, ml: 1.5, height: theme.spacing(2.5) }}
                        />

                        <StyledTableCellTypography>
                          {row.name}
                        </StyledTableCellTypography>

                        <Grid
                          item
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 60,
                          }}
                        >
                          <HousingStatus status={row.status} />
                        </Grid>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell width={theme.spacing(7)}>
                    <IconButton onClick={() => showDetails(row)}>
                      <Box component='img' alt='Show Details' src={Expand} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default MobileTable;
