export const ContainerSpacing = {
  container: true,
  rowSpacing: { xs: 3, md: 4 },
  columnSpacing: { xs: 0, md: 3 },
  height: '100%',
};

export const ButtonResponsiveProps = {
  minWidth: { md: 180, xs: 'calc(50% - 8px)' },
};

export const PrimaryButtonResponsiveMargin = {
  ml: { md: 3 },
};

export const ButtonsContainer = {
  container: true,
  justifyContent: {
    md: 'flex-end',
    xs: 'space-between',
  },
  marginTop: {
    xs: 1.5
  }
};
