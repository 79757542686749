import { Modal, Box, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomModalProps } from './interfaces/CustomModalProps';

const CustomModal = ({
  children,
  sx,
  onClose,
  isMobile,
  ...modalProps
}: CustomModalProps) => {
  const theme = useTheme();
  return (
    <Modal {...modalProps} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '785px',
          borderRadius: theme.spacing(1),
          border: 'none',
          bgcolor: 'background.paper',
          outline: 'none',
          overflow: 'hidden',
          ...(sx || {}),
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            cursor: 'pointer',
            zIndex: 9,
            background: 'white',
            p: theme.spacing(2.5)
          }}
          onClick={onClose}
        >
          <CloseIcon role='button' />
        </Box>

        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
