import { Button } from '@mui/material';

export const ButtonTable = ({
  label,
  onClick,
  icon,
  disabled = false,
}: {
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
}) => {
  return (
    <Button
      variant='outlined'
      color='secondary'
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
