import * as yup from 'yup';
import translations from 'utils/translations/index';

const validationSchema = yup
  .object({
    newPassword: yup
      .string()
      .min(8, translations.newPasswordMinErrorMsg)
      .max(16, translations.newPasswordMaxErrorMsg)
      .required(),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], translations.confirmPasswordErrorMsg),
  })
  .required();

export default validationSchema;
