import { Button, Grid, useTheme } from '@mui/material';
import PrimarySubmitButton from 'components/button';
import DatePickerInput from 'components/datePickerInput';
import DropdownInput from 'components/dropdownInput';
import DropdownYesNo from 'components/dropdownYesNo';
import NotAllowed from 'components/notAllowed/notAllowed';
import NumberInput from 'components/numberInput';
import {
  PageHeader,
  PageHeaderSubtitle,
} from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import {
  ButtonResponsiveProps,
  ButtonsContainer,
  PrimaryButtonResponsiveMargin,
} from 'utils/formHeader';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import { ArrivalInstructionsFormProps } from './interfaces/arrivalInstructionsFormProps';

const ArrivalInstructionsForm = ({
  matchXs,
  matchMdToLg,
  matchLgAbove,
  isEditMode,
  hostId,
  arrivalInfoStatus,
  availablePrograms,
  handleCancelButton,
  onSubmit,
  errors,
  control,
  register,
  getValues,
}: ArrivalInstructionsFormProps) => {
  const theme = useTheme();
  const translations = useTranslations();

  const {
    inputStyle,
    defaultInput,
    datePickerInput,
    numberInput,
    yesOrNoInput,
    halfToFullPageInput,
    inputsGridFullWidth,
    inputsSection,
  } = useFormInputsStyle(theme);

  const {
    editTitle,
    editSubtitle,
    title,
    createArrivalInfoBtn,
    saveArrivalInfoBtn,
    programId,
    program,
    nearestInternationalAirport,
    nearestInternationalAirportId,
    nearestAirportToJobSite,
    nearestAirportToJobSiteId,
    isPickUpProvided,
    isPickUpProvidedId,
    latestPreferredArrivalDate,
    generalArrivalInstructions,
    generalArrivalInstructionsId,
    shouldContactHCBeforeArrival,
    shouldContactHCBeforeArrivalId,
    contactPhoneNumber,
    contactPhoneNumberId,
    transportPickUpCost,
    preferredArrivalDays,
    preferredArrivalDaysId,
    preferredArrivalTimes,
    preferredArrivalTimesId,
    reportingTo,
    reportingToId,
    description,
    descriptionId,
    notAllowedPage,
    notAllowed,
  } = translations.arrivalInformation.form;

  return (
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={isEditMode ? 6 : 4}
          mb={isEditMode ? 3 : 0}
          order={1}
          pb={{ md: 4 }}
        >
          <PageHeader>{isEditMode ? editTitle : title}</PageHeader>
          <PageHeaderSubtitle>
            {isEditMode ? editSubtitle : null}
          </PageHeaderSubtitle>
        </Grid>

        <Grid
          item
          xs={12}
          md={isEditMode ? 6 : 8}
          order={{ xs: 3, md: 2 }}
          pt={{ xs: 0 }}
          pb={4}
        >
          <Grid {...ButtonsContainer}>
            <Button
              variant='outlined'
              sx={{ ...ButtonResponsiveProps }}
              onClick={() => handleCancelButton(hostId)}
            >
              {translations.cancelButton}
            </Button>

            <Grid
              sx={{
                ...ButtonResponsiveProps,
                ...PrimaryButtonResponsiveMargin,
              }}
            >
              <PrimarySubmitButton
                dataTestid='change-status'
                isDisabled={
                  arrivalInfoStatus === ('Locked' || 'Change Requested')
                    ? true
                    : false
                }
                isLoading={false}
                text={isEditMode ? saveArrivalInfoBtn : createArrivalInfoBtn}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {arrivalInfoStatus !== ('Locked' || 'Change Requested') ? (
        <Grid
          item
          xs={12}
          sx={{
            height: {
              lg: `calc(88vh - ${theme.spacing(1)} - ${theme.spacing(15)})`,
              md: `calc(88vh - ${theme.spacing(1)} - ${theme.spacing(22)})`,
              xs: `calc(82vh - ${theme.spacing(1)} - ${theme.spacing(12)})`,
            },
            overflow: 'auto',
            scrollBehavior: 'smooth',
            ...NoScrollBar,
          }}
        >
          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownInput
                  inputId={programId}
                  inputLabel={program}
                  items={availablePrograms}
                  errorMessage={errors?.program?.message}
                  control={control}
                  register={register('program')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(9, 6)}>
                <TextAreaInput
                  inputLabel={nearestInternationalAirport}
                  inputId={nearestInternationalAirportId}
                  register={register('nearestInternationalAirport')}
                  errorMessage={errors?.nearestInternationalAirport?.message}
                  control={control}
                  maxLength={500}
                  singleLine
                />
              </Grid>

              <Grid {...inputStyle(9, 6)}>
                <TextAreaInput
                  inputLabel={nearestAirportToJobSite}
                  inputId={nearestAirportToJobSiteId}
                  register={register('nearestAirportToJobsite')}
                  errorMessage={errors?.nearestAirportToJobsite?.message}
                  control={control}
                  maxLength={500}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputLabel={isPickUpProvided}
                  inputId={isPickUpProvidedId}
                  errorMessage={errors?.isPickUpProvided?.message}
                  control={control}
                  register={register('isPickUpProvided')}
                />
              </Grid>

              <Grid {...datePickerInput}>
                <DatePickerInput
                  label={latestPreferredArrivalDate}
                  validationMessage={
                    errors?.latestPreferredArrivalDate?.message
                  }
                  control={control}
                  name='latestPreferredArrivalDate'
                  getValue={() => getValues('latestPreferredArrivalDate')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsGridFullWidth}>
              <TextAreaInput
                inputLabel={generalArrivalInstructions}
                inputId={generalArrivalInstructionsId}
                register={register('generalArrivalInstructions')}
                errorMessage={errors?.generalArrivalInstructions?.message}
                control={control}
                maxLength={5000}
              />
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputLabel={shouldContactHCBeforeArrival}
                  labelHeight={46}
                  inputId={shouldContactHCBeforeArrivalId}
                  errorMessage={errors?.shouldContactHCBeforeArrival?.message}
                  control={control}
                  register={register('shouldContactHCBeforeArrival')}
                />
              </Grid>

              <Grid {...inputStyle(5, 3)}>
                <TextAreaInput
                  inputLabel={contactPhoneNumber}
                  labelHeight={46}
                  inputId={contactPhoneNumberId}
                  register={register('contactPhoneNumber')}
                  errorMessage={errors?.contactPhoneNumber?.message}
                  control={control}
                  maxLength={25}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...numberInput}>
                <NumberInput
                  label={transportPickUpCost}
                  useDecimals={true}
                  errorMessage={errors?.transportPickUpCost?.message}
                  control={control}
                  name='transportPickUpCost'
                  getValue={() => getValues('transportPickUpCost')}
                />
              </Grid>

              <Grid {...inputStyle(7.5, 4.5)}>
                <TextAreaInput
                  inputLabel={preferredArrivalDays}
                  inputId={preferredArrivalDaysId}
                  register={register('preferredArrivalDays')}
                  errorMessage={errors?.preferredArrivalDays?.message}
                  control={control}
                  maxLength={50}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputLabel={preferredArrivalTimes}
                  inputId={preferredArrivalTimesId}
                  register={register('preferredArrivalTimes')}
                  errorMessage={errors?.preferredArrivalTimes?.message}
                  control={control}
                  maxLength={150}
                  numberOfRows={matchXs ? 4 : 3}
                />
              </Grid>

              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputLabel={reportingTo}
                  inputId={reportingToId}
                  register={register('reportingTo')}
                  errorMessage={errors?.reportingTo?.message}
                  control={control}
                  maxLength={150}
                  numberOfRows={matchXs ? 4 : 3}
                />
              </Grid>
            </Grid>

            <Grid {...inputsGridFullWidth}>
              <TextAreaInput
                inputLabel={description}
                inputId={descriptionId}
                register={register('description')}
                errorMessage={errors?.description?.message}
                control={control}
                maxLength={1145}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <NotAllowed page={notAllowedPage} text={notAllowed} />
      )}
    </form>
  );
};

export default ArrivalInstructionsForm;
