import { SwitchDisplay } from 'components/switchDisplay';
import { useTranslations } from 'hooks/useTranslations';
import iconWindow from 'assets/icons/windows.svg';
import iconList from 'assets/icons/listIcon.svg';
import { TileViewSwitcherProps } from './interfaces/TileViewSwitcherProps';

const SwitchTileListView = ({
  isTileView,
  handleChangeView,
}: TileViewSwitcherProps) => {
  const translations = useTranslations();

  return (
    <SwitchDisplay
      checked={!isTileView}
      onChange={handleChangeView}
      firstButton={{
        label: translations.applicants.switchButtons.tileView,
        icon: iconWindow,
      }}
      secondButton={{
        label: translations.applicants.switchButtons.listView,
        icon: iconList,
      }}
    />
  );
};

export default SwitchTileListView;
