import ResponsiveFiltersBar from 'components/responsiveListView/components/responsiveFiltersBar';
import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import { FiltersFormProps } from '../filters/interfaces/filtersFormProps';
import ParticipantsDesktopFilters from '../filters/participantsDesktopFilters';
import { ParticipantsTableProps } from './interfaces/participantsTableProps';
import ParticipantsMobileFilters from '../filters/participantsMobileFilters';
import { TileViewSwitcherProps } from 'components/switchTileListView/interfaces/TileViewSwitcherProps';
import ParticipantsTileView from '../participantsTileView/participantsTileView';
import { ParticipantsListView } from '../participantsListView';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { CellHeaderId } from './interfaces/participantsCellHeader';

import ParticipantModal from 'components/participantModal';
import useParticipantModal from 'components/participantModal/hooks/useParticipantModal';

const ParticipantsTable = ({
  filterProps,
  filtersRef,
  heightToSkip,
  tileViewSwitcherProps,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  paginationRef,
  rowsPerPage,
  tableProps: { rowsTotal, rows },
  order,
  orderBy,
  sortHandler,
}: {
  tileViewSwitcherProps: TileViewSwitcherProps;
  heightToSkip: number;
  filtersRef: (node: HTMLDivElement) => void;
  filterProps: FiltersFormProps | null;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
  page: number;
  paginationRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
  tableProps: ParticipantsTableProps;
  order?: SortOrder;
  orderBy?: CellHeaderId;
  sortHandler: (property: CellHeaderId) => void;
}) => {
  const { isTileView } = tileViewSwitcherProps;

  const { isOpen, handleCloseModal, activeItem, handleOpenModal } =
    useParticipantModal();

  return (
    <>
      <ResponsiveListView
        filters={
          filterProps ? (
            <ResponsiveFiltersBar
              filtersBarRef={filtersRef}
              desktopView={<ParticipantsDesktopFilters {...filterProps} />}
              mobileView={
                <ParticipantsMobileFilters
                  {...filterProps}
                  tileViewSwitcherProps={tileViewSwitcherProps}
                />
              }
            />
          ) : undefined
        }
        csv={{
          filename: 'participants-export.csv',
          data: rows
        }}
        listView={
          isTileView !== null ? (
            isTileView ? (
              <ParticipantsTileView
                items={rows}
                heightToSkip={heightToSkip}
                handleClick={handleOpenModal}
              />
            ) : (
              <ParticipantsListView
                {...{
                  rows,
                  order,
                  orderBy,
                  sortHandler,
                  heightToSkip,
                  handleClick: handleOpenModal,

                }}
              />
            )
          ) : (
            <div>Loading</div>
          )
        }
        paginationProps={{
          handleChangePage,
          handleChangeRowsPerPage,
          page,
          paginationRef,
          rowsPerPage,
          rowsTotal,
        }}
      />
      <ParticipantModal
        open={isOpen}
        onClose={handleCloseModal}
        item={activeItem}
      />
    </>
  );
};

export default ParticipantsTable;
