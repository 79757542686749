import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import { OfferDetailsFormInput } from '../../interfaces/offerDetailsForm.formInput';

export const HousingOfferDetails = ({
  isDisabled,
  matchBetweenXsMdForHousing,
  matchBetweenMdLgForHousing,
}: {
  isDisabled: boolean;
  matchBetweenXsMdForHousing: boolean;
  matchBetweenMdLgForHousing: boolean;
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  const { inputStyle, halfToFullPageInput, inputsSection } =
    useFormInputsStyle(theme);

  const {
    title,
    provide_temporary_housing,
    duration_of_temporary_housing,
    cost_of_temporary_housing,
  } = translations.offerDetailsForm.sections.housing;

  return (
    <ConnectForm<OfferDetailsFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...inputStyle(4.5, 3.5)}>
                <DropdownYesNo
                  inputId={'doesHostEntityProvideTemporaryHousing'}
                  inputLabel={provide_temporary_housing}
                  labelHeight={matchBetweenXsMdForHousing ? 67 : 46}
                  errorMessage={
                    errors?.housing?.doesHostEntityProvideTemporaryHousing
                      ?.message
                  }
                  control={control}
                  register={register(
                    'housing.doesHostEntityProvideTemporaryHousing'
                  )}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid {...inputStyle(4.5, 3.5)}>
                <NumberInput
                  label={cost_of_temporary_housing}
                  labelHeight={matchBetweenXsMdForHousing ? 67 : 46}
                  useDecimals={true}
                  errorMessage={
                    errors?.housing?.costOfTemporaryHousing?.message
                  }
                  control={control}
                  name='housing.costOfTemporaryHousing'
                  getValue={() => getValues('housing.costOfTemporaryHousing')}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'durationOfTemporaryHousing'}
                  inputLabel={duration_of_temporary_housing}
                  register={register('housing.durationOfTemporaryHousing')}
                  errorMessage={
                    errors?.housing?.durationOfTemporaryHousing?.message
                  }
                  maxLength={250}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
