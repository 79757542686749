import { Grid, Typography, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MuiButton from 'components/button';
import { MuiCheckBox, MuiCheckedItemBox } from 'components/login/checkbox/styledCheckbox';
import { Container } from 'components/login/container/styledContainer';
import EmailInput from 'components/login/emailInput';
import Header from 'components/login/header';
import { InputWrapper } from 'components/login/inputWrapper/styledInputWrapper';
import LoginLink from 'components/login/loginLink';
import PasswordInput from 'components/login/passwordInput';
import Wrapper from 'components/login/wrapper';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { SignInProps } from './interfaces/signInProps';

const SignIn = ({
  onSubmit,
  register,
  formErrors,
  handleRememberMe,
  autoFocus,
  defaultValue,
  isLoading,
}: SignInProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const { loginPage } = useTranslations();

  return (
    <Wrapper>
      <Container item xs={4} md={8} lg={4} xl={3}>
        <Header
          title={loginPage.loginTitle}
          subtitle={loginPage.loginSubtitle}
        />

        <form
          onSubmit={onSubmit()}
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <InputWrapper>
            <EmailInput
              defaultValue={defaultValue}
              register={register}
              formErrors={formErrors}
            />

            <PasswordInput
              autoFocus={autoFocus}
              register={register}
              formErrors={formErrors.password}
              inputLabel={translations.password}
              inputId={translations.passwordInputId}
            />
          </InputWrapper>

          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: theme.spacing(6.5),
              mb: 3,
            }}
          >
            <Checkbox
              disableRipple
              defaultChecked={defaultValue ? true : false}
              icon={<MuiCheckBox />}
              checkedIcon={<MuiCheckedItemBox />}
              inputProps={{ 'aria-label': 'remember_me_checkbox' }}
              onChange={handleRememberMe}
            />
            <Typography>{loginPage.rememberMe}</Typography>
          </Grid>

          <MuiButton dataTestid='sign-in' isLoading={isLoading} text={loginPage.signInButton} />
        </form>

        <LoginLink
          link={ApplicationRoutes.resetPassword}
          text={loginPage.forgotPassword}
        />
      </Container>
    </Wrapper>
  );
};

export default SignIn;
