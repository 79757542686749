import { startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { CustomPickersDay } from '../components/customPickersDay';

export const renderWeekPickerDay = (
  date: Date,
  pickersDayProps: PickersDayProps<Date>,
  value: Date | Date[] | null,
  extraSelectedDates?: Date[]
) => {
  if (!value || Array.isArray(value)) {
    return <PickersDay {...pickersDayProps} />;
  }

  const start = startOfWeek(value);
  const end = endOfWeek(value);

  const dayIsBetween = isWithinInterval(date, { start, end });
  const isFirstDay = date.toDateString() === start.toDateString();
  const isLastDay = date.toDateString() === end.toDateString();

  const findDate = (values: Date[], date: Date) => {
    const dateTime = date.getTime();
    return values.filter((item) => item.getTime() === dateTime).length > 0;
  };

  const selected = extraSelectedDates ? findDate(extraSelectedDates, date) : false;

  return (
    <CustomPickersDay
      {...pickersDayProps}
      disableMargin
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      selected={selected}
    />
  );
};