import * as yup from 'yup';

export const schema = yup.object().shape({
  provided: yup.string().max(1000).label('Training/orientation provided'),
  description: yup.string().max(1000).label('Training/orientation description'),
  evPaid: yup
    .string()
    .notRequired()
    .label('EV paid during Training/Orientation'),
  length: yup
    .string()
    .notRequired()
    .max(1000)
    .label('Length of training/orientation'),
  willIncurCost: yup
    .string()
    .notRequired()
    .label('Will Participant incur a cost for Training/Orientation'),

});
