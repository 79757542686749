import { WagePerEnum } from 'pages/placements/editPlacement/sections/payment/interfaces/wagePer';
import { transformStringToNumber } from 'utils/extensions/numberInputTransformer';
import * as yup from 'yup';

const wagePerOptions: string[] = Object.values(WagePerEnum);

export const cloneSchema = yup.object().shape({
  guaranteedSalaryWageBeforeDeduction: yup
    .number()
    .required()
    .transform(transformStringToNumber)
    .positive()
    .min(0.1)
    .label('Guaranteed salary/wage before deduction'),
  guaranteedSalaryWagePeriod: yup
    .string()
    .notRequired()
    .oneOf(wagePerOptions)
    .label('Guaranteed salary/wage per'),
});
