import * as yup from 'yup';

import { schema as detailsSchema } from '../sections/details/validation/validation.schema';
import { schema as securitySchema } from '../sections/security/validation/validation.schema';
import { schema as utilitiesSchema } from '../sections/utilities/validation/validation.schema';

export const schema = yup.object().shape({
  details: detailsSchema,
  security: securitySchema,
  utilities: utilitiesSchema,
});
