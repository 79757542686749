import DatePickerInput from 'components/datePickerInput';
import DropdownMultiselectInput from 'components/dropdownInputMultiselect';
import { useTranslations } from 'hooks/useTranslations';
import { FiltersFormProps } from './interfaces/filtersFormProps';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import TextareaInput from 'components/textAreaInput/textAreaInput';

const Host = ({
  control,
  hosts,
  register,
}: Pick<FiltersFormProps, 'control' | 'hosts' | 'register'>) => {
  const translations = useTranslations();

  return (
    <DropdownInput
      control={control}
      register={register('host')}
      inputId={translations.participants.filters.host}
      inputLabel={translations.participants.filters.host}
      items={hosts}
      labelHeight={23}
      disabled={hosts.length === 0}
    />
  );
};

const Programs = ({
  control,
  programs,
}: Pick<FiltersFormProps, 'control' | 'programs'>) => {
  const translations = useTranslations();
  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.participants.filters.programs}
      inputLabel={translations.participants.filters.programs}
      items={programs}
      name='programs'
      labelHeight={23}
      disabled={programs.length === 0}
    />
  );
};

const Position = ({
  control,
  positions,
  register,
}: Pick<FiltersFormProps, 'control' | 'positions' | 'register'>) => {
  const translations = useTranslations();

  return (
    <DropdownInput
      control={control}
      register={register('position')}
      inputId={translations.participants.filters.position}
      inputLabel={translations.participants.filters.position}
      items={positions}
      labelHeight={23}
      disabled={positions.length === 0}
    />
  );
};

const Country = ({
  control,
  countries,
  register,
}: Pick<FiltersFormProps, 'control' | 'countries' | 'register'>) => {
  const translations = useTranslations();

  return (
    <DropdownInput
      control={control}
      register={register('country')}
      inputId={translations.participants.filters.country}
      inputLabel={translations.participants.filters.country}
      items={countries}
      labelHeight={23}
      disabled={countries.length === 0}
    />
  );
};

const StartDate = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.participants.filters.startDate}
      control={control}
      name='startDate'
      getValue={() => getValues('startDate')}
      labelHeight={23}
    />
  );
};

const EndDate = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.participants.filters.endDate}
      control={control}
      name='endDate'
      getValue={() => getValues('endDate')}
      labelHeight={23}
    />
  );
};

const Statuses = ({
  control,
  statuses,
}: Pick<FiltersFormProps, 'control' | 'statuses'>) => {
  const translations = useTranslations();
  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.participants.filters.statuses}
      inputLabel={translations.participants.filters.statuses}
      items={statuses}
      name='statuses'
      labelHeight={23}
      disabled={statuses.length === 0}
    />
  );
};

const ApplicantIdSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.participants.filters.applicantId}
      inputLabel={translations.participants.filters.applicantId}
      register={register('applicantId')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const FirstNameSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.participants.filters.firstName}
      inputLabel={translations.participants.filters.firstName}
      register={register('firstName')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const LastNameSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.participants.filters.lastName}
      inputLabel={translations.participants.filters.lastName}
      register={register('lastName')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

const EmailSearch = ({
  control,
  register,
}: Pick<FiltersFormProps, 'control' | 'register'>) => {
  const translations = useTranslations();

  return (
    <TextareaInput
      singleLine
      inputId={translations.participants.filters.email}
      inputLabel={translations.participants.filters.email}
      register={register('email')}
      control={control}
      numberOfRows={1}
      labelHeight={23}
    />
  );
};

export {
  Programs,
  Host,
  Position,
  Country,
  StartDate,
  EndDate,
  Statuses,
  ApplicantIdSearch,
  FirstNameSearch,
  LastNameSearch,
  EmailSearch,
};
