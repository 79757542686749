import { UseFormSetValue } from 'react-hook-form';
import { PlacementResponse } from 'hooks/services/placements/interfaces/placement.response';
import { BE_DATE_FORMAT, convertToDate } from 'utils/date/convertToDate';
import { getStringValueOrEmptyString } from 'utils/string/stringConverters';
import { ClonePlacementFormInput } from '../interfaces/clonePlacementFormInput';

export const setValues = (
  response: PlacementResponse,
  formSetValue: UseFormSetValue<ClonePlacementFormInput>
) => {
  if (!response) {
    return;
  }

  const { payment, employmentPosition } = response;

  formSetValue(
    'supervisor',
    getStringValueOrEmptyString(employmentPosition?.supervisorId)
  );

  formSetValue(
    'guaranteedSalaryWageBeforeDeduction',
    getStringValueOrEmptyString(payment?.grossWage)
  );

  formSetValue(
    'guaranteedSalaryWagePeriod',
    getStringValueOrEmptyString(payment.wagePer)
  );

  formSetValue(
    'earliestStartDate',
    convertToDate(employmentPosition.earliestStartDate, BE_DATE_FORMAT)
  );
  formSetValue(
    'earliestEndDate',
    convertToDate(employmentPosition.earliestEndDate, BE_DATE_FORMAT)
  );
  formSetValue(
    'latestEndDate',
    convertToDate(employmentPosition.latestEndDate, BE_DATE_FORMAT)
  );
  formSetValue(
    'latestStartDate',
    convertToDate(employmentPosition.latestStartDate, BE_DATE_FORMAT)
  );

  formSetValue(
    'totalPositions',
    getStringValueOrEmptyString(employmentPosition.totalPositions)
  );
};
