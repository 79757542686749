import { CompleteInfoFormInput } from '../interfaces/completeInfo.formInput';

export const initWithEmptyValues: CompleteInfoFormInput = {
  details: {
    videoEmbed: '',
    annualRevenue: '',
    expirationDate: new Date(),
    workmanCompId: '',
    workmanCompPhone: '',
    businessLicense: new Date(),
    termsConditionsExpiration: new Date(),
    seasonalityReportExpiration: new Date(),
    hostAddress: '',
    hostIncorporateInUsa: 'false',
    hostIncorporateNotUsa: '',
    layoffs: 'false',
    otherSponsors: '',
    howLongHiringParticipants: '',
    comments: '',
    description: '',
    industry: '',
    sector: '',
    orientationRequired: 'false',
    yearEstablished: '',
    siteRequirement: 'false',
    insuranceCompany: '',
    totalCurrentEmployees: '',
    totalExchangeVisitors: '',
    internProgram: 'false',
    summerWork: '',
    teacherProgram: '',
    irsTaxNumber: '',
    url: '',
  },
  program: {
    address: '',
    hasSupervisor: '',
    isEnglishPrimaryLanguage: '',
    hasHelpWithCulturalActivities: '',
    isProvided32HoursPerWeek: '',
    isTrainingOrWorkOverNightRequired: '',
    isHousingProvided: '',
    aproximateCostOfHousingPerMonthPerExchangeVisitor: '',
    existsAnyAffordableHousingOptionsNearby: '',
    hasAnyOtherVisaPrograms: '',
    otherVisaProgramsDescription: '',
  },
};
