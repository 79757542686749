import { PlacementStatus } from 'hooks/services/placements/interfaces/placementStatus';
import { PlacementSummaryResponse } from '../../../../hooks/services/placements/interfaces/placementSummary.response';

export function isEditAvailable(placement: PlacementSummaryResponse) {
  return (
    !placement.confirmed &&
    (placement.status === PlacementStatus.Draft ||
      placement.status === PlacementStatus.NeedsReview)
  );
}
