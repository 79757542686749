import React, {
  useState,
  useCallback,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid'; // used for unique ids
import { blue, green, red, yellow } from '@mui/material/colors';

type AlertContextType = {
  [key: string]: (message: string) => void;
  error: (msg: string) => void;
  warning: (msg: string) => void;
  info: (msg: string) => void;
  success: (msg: string) => void;
};

type AlertProviderProps = {
  children: ReactNode;
};

interface AlertInfo {
  id: string;
  message: string;
  severity: 'error' | 'success' | 'info' | 'warning';
}

const AlertContext = createContext<AlertContextType>({
  error: () => {},
  warning: () => {},
  info: () => {},
  success: () => {},
});

export const useAlert = () => useContext(AlertContext);

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<AlertInfo[]>([]);

  const showAlert = useCallback(
    (severity: 'error' | 'success' | 'info' | 'warning', message: string) => {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        { id: uuidv4(), severity, message },
      ]);
    },
    []
  );

  const closeAlert = useCallback((id: string) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  }, []);

  const value = useMemo(
    () => ({
      error: (msg: string) => showAlert('error', msg),
      warning: (msg: string) => showAlert('warning', msg),
      info: (msg: string) => showAlert('info', msg),
      success: (msg: string) => showAlert('success', msg),
    }),
    [showAlert]
  );

  return (
    <AlertContext.Provider value={value}>
      {children}

      {alerts.map((alert) => (
        <Snackbar
          key={alert.id}
          open
          autoHideDuration={5000}
          onClose={() => closeAlert(alert.id)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert
            elevation={6}
            variant='outlined'
            onClose={() => closeAlert(alert.id)}
            severity={alert.severity}
            sx={{
              '& .MuiAlert-message': {
                color:
                  alert.severity === 'error'
                    ? red[900]
                    : alert.severity === 'warning'
                    ? yellow[900]
                    : alert.severity === 'info'
                    ? blue[900]
                    : green[900],
              },

              bgcolor:
                alert.severity === 'error'
                  ? red[100]
                  : alert.severity === 'warning'
                  ? yellow[100]
                  : alert.severity === 'info'
                  ? blue[100]
                  : green[100],
            }}
          >
            {alert.message}
          </MuiAlert>
        </Snackbar>
      ))}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
