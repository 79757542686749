import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { useNotificationsService } from 'hooks/services/notifications';
import { NotificationResponse } from 'hooks/services/notifications/interfaces/notificationResponse';
import { NotificationsRequest } from 'hooks/services/notifications/interfaces/notificationsRequest';
import { useUser } from 'hooks/useUser';
import { convertUTCDateToOffset, formDateDDMonthYYYYTime } from 'utils/date/convertToDate';
import { NotificationsFormInputProps } from './interfaces/notificationsFormInputProps';
import Notifications from './notifications';

export type CellHeaderId = keyof NotificationResponse | 'action';

const NotificationsContainer = () => {
  const { hostId } = useParams();
  const { user } = useUser();
  const [rows, setRows] = useState<NotificationResponse[]>([]);
  const [rowsTotal, setRowsTotal] = useState<number>(0);
  const [filterParams, setFilterParams] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<PagedRequest>({
    pageNumber: 0,
    pageSize: 10,
    sortDirection: 'desc',
    sortField: 'createdAt',
  });
  const { archive, getNotifications } = useNotificationsService();
  const parsedHostId = parseInt(hostId as string);

  const handleTablePropsChanged = async (data: PagedRequest) => {
    setTableParams(data);

    await fetchNotifications({
      ...data,
      showOnlyArchived: filterParams,
      hostId: parsedHostId,
    });
  };

  const { control, handleSubmit, watch } = useForm<NotificationsFormInputProps>(
    {
      defaultValues: {
        showOnlyArchived: false,
      },
    }
  );

  const onlyArchived = watch('showOnlyArchived');

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    sortHandler,
    order,
    orderBy,
    page,
    rowsPerPage,
  } = usePaginationHook<CellHeaderId>(
    handleTablePropsChanged,
    'desc',
    'createdAt'
  );

  const handleRowAction = async (row: NotificationResponse) => {
    await archive(parsedHostId, row.id);

    await fetchNotifications({
      ...tableParams,
      hostId: parsedHostId,
      showOnlyArchived: filterParams,
    });
  };

  const fetchNotifications = useCallback(
    async (
      request: NotificationsRequest & { hostId: number },
      userTimeZone?: string
    ) => {
      const response = await getNotifications(request);
      const converted = response.records.map((r) => ({
        ...r,
        createdAt: formDateDDMonthYYYYTime(
          convertUTCDateToOffset(r.createdAt, userTimeZone)
        )!,
      }));

      setRowsTotal(response.total);
      setRows(converted);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const applyFilter = useCallback(async (data: NotificationsFormInputProps) => {
    setFilterParams(data.showOnlyArchived);

    await fetchNotifications({
      ...tableParams,
      hostId: parsedHostId,
      showOnlyArchived: data.showOnlyArchived,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(applyFilter);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const subscription = watch(() => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        handleSubmit(applyFilter)();
      }, 500);
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, applyFilter]);

  useEffect(() => {
    fetchNotifications(
      {
        hostId: parsedHostId,
        ...tableParams,
        showOnlyArchived: filterParams,
      },
      user?.timeZone
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Notifications
      {...{
        sortHandler,
        order,
        orderBy,
        rows,
        handleRowAction,
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        rowsTotal,
        control,
        onSubmit,
        onlyArchived,
      }}
    />
  );
};

export default NotificationsContainer;
