import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useHostService } from 'hooks/services/hosts';
import { formDateDDMonYYYY } from 'utils/date/convertToDate';
import { downloadBlob } from 'utils/file/fileUtilities';
import { TileComponentContainerProps } from './interfaces/tileComponentContainerProps';
import TileComponent from './tileComponent';

const TileComponentContainer = (props: TileComponentContainerProps) => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);
  const {
    id,
    isInterviewLink,
    videoLink,
    availableFrom,
    availableTo,
    position,
    handleInterviewAction,
  } = props;
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const { getParticipantDocument, getApplicantPdf } = useHostService();

  const isApplicant = !!(isInterviewLink && videoLink);

  const downloadDocument = async () => {
    if (isApplicant) {
      try {
        const blob = await getApplicantPdf(parsedHostId, id);
        const fileName = `ApplicationResume${id}.pdf`;
        downloadBlob(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const blob = await getParticipantDocument(
          parsedHostId,
          id,
          'ApplicationResume'
        );
        const fileName = `ApplicationResume${id}.pdf`;
        downloadBlob(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const avatarHeight = matchDownMd ? 95 : 120;
  const offset = avatarHeight / 2;

  const subTitle =
    (availableFrom &&
      availableTo &&
      !position &&
      `${formDateDDMonYYYY(availableFrom)} to ${formDateDDMonYYYY(
        availableTo
      )}`) ||
    position ||
    '';

  return (
    <Box>
      <TileComponent
        {...props}
        avatarHeight={avatarHeight}
        offset={offset}
        isApplicant={isApplicant}
        downloadDocument={downloadDocument}
        subTitle={subTitle}
        videoLink={
          props.videoLink?.toLowerCase() === 'not set'
            ? undefined
            : props.videoLink
        }
        handleInterviewAction={handleInterviewAction}
      />
    </Box>
  );
};

export default TileComponentContainer;
