import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiltersFormInputs } from '../interfaces/filtersFormInput';
import { FiltersFormProps } from '../interfaces/filtersFormProps';
import { FiltersProps } from '../interfaces/filtersProps';

const useFilterHook = ({
  onApplyFilter,
  programs,
  titles,
  statuses,
}: FiltersProps): FiltersFormProps => {
  const [, setIsLoading] = useState(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FiltersFormInputs>({
    defaultValues: {
      approvedDate: null,
      positions: [],
      programs: [],
      statuses: [],
      onlyMatched: false,
      onlyNotConfirmed: false,
    },
  });

  const applyFilter = useCallback(
    async (data?: FiltersFormInputs) => {
      setIsLoading(true);

      await onApplyFilter(data);

      setIsLoading(false);
    },
    [onApplyFilter]
  );

  const submitHandler = handleSubmit(applyFilter);

  const handleReset = async () => {
    reset();
    await applyFilter();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const subscription = watch(() => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        handleSubmit(applyFilter)();
      }, 500);
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, applyFilter]);

  return {
    control,
    getValues,
    onSubmit: submitHandler,
    register,
    isDirty,
    clearFilters: handleReset,
    programs,
    titles,
    statuses,
  };
};

export default useFilterHook;
