import AppDrawer from './appDrawer';
import { useLayout } from 'hooks/useLayout';

const AppDrawerContainer = () => {
  const { menuOpen, setMenuOpen } = useLayout();
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  return <AppDrawer menuOpen={menuOpen} handleMenuOpen={handleMenuOpen} />;
};

export default AppDrawerContainer;
