import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import ResponsiveListView from 'components/responsiveListView/components/responsiveListView';
import ResponsiveTable from 'components/responsiveListView/components/responsiveTable';
import { ProgramResource } from 'hooks/services/hosts/interfaces/programResource.response';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import EmptyTable from 'pages/placements/placementsList/components/placementsTable/components/emptyTable';
import { ContainerSpacing } from 'utils/formHeader';

const ProgramResources = ({
  rows,
  clickLink,
}: {
  rows: ProgramResource[];
  clickLink: (row: ProgramResource) => void;
}) => {
  const translations = useTranslations();

  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();

  const heightToSkip = pageHeaderHeight + 50;
  const containerProps = { ...ContainerSpacing, height: 'unset' };

  return (
    <Grid {...containerProps}>
      <Grid item xs={12} ref={pageHeaderRef}>
        <PageHeader>{translations.programResources.title}</PageHeader>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveListView
          listView={
            <ResponsiveTable
              heightToSkip={heightToSkip}
              desktopTable={
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {translations.programResources.cells.link}
                      </TableCell>
                      <TableCell>
                        {translations.programResources.cells.program}
                      </TableCell>
                      <TableCell>
                        {translations.programResources.cells.description}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length > 0 ? (
                      rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Link
                              component='button'
                              underline='hover'
                              onClick={() => clickLink(row)}
                            >
                              {row.type === 'download'
                                ? translations.programResources.download
                                : translations.programResources.link}
                            </Link>
                          </TableCell>
                          <TableCell>{row.programNames}</TableCell>
                          <TableCell>{row.description}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align='center' colSpan={3}>
                          <EmptyTable />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              }
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default ProgramResources;
