import { Card, Grid, Link, useTheme } from '@mui/material';

import { MobileViewItemProps } from '../../interfaces/mobileViewItemProps';
import { useTranslations } from 'hooks/useTranslations';

const MobileViewItem = ({
  icon,
  status,
  type,
  button,
  remarks,
  enableAllRemarks,
  toggleAllRemarks,
}: MobileViewItemProps) => {
  const remarksLimit = 150;

  const theme = useTheme();

  const { text } = theme.palette;
  const { body1, body2, fontWeightMedium, fontWeightBold, fontSize } =
    theme.typography;

  const translations = useTranslations();
  const { less, more } = translations.documentList.linksRemarks;
  const fontSizeRemarks = fontSize - 2;

  return (
    <Card
      variant='outlined'
      sx={{
        padding: theme.spacing(2.5, 0),
      }}
    >
      <Grid
        item
        xs={12}
        container
        flexDirection='row'
        justifyContent='space-arround'
        mb={remarks ? 1 : 0}
      >
        <Grid item xs={2} container justifyContent='center' alignItems='center'>
          {icon}
        </Grid>

        <Grid
          item
          xs={6.5}
          container
          flexDirection={'column'}
          justifyContent='center'
          alignItems='left'
        >
          <Grid
            item
            sx={{
              fontSize: body1.fontSize,
              fontWeight: fontWeightBold,
              color: text.primary,
            }}
          >
            {type}
          </Grid>
          <Grid
            item
            sx={{
              fontSize: body2.fontSize,
              fontWeight: fontWeightMedium,
              color: text.secondary,
            }}
          >
            {status}
          </Grid>
        </Grid>

        <Grid item xs={3} container justifyContent='center' alignItems='center'>
          {button}
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent='center' alignItems='center'>
        <Grid
          item
          xs={11}
          sx={{
            fontSize: fontSizeRemarks,
            color: text.secondary,
          }}
        >
          {enableAllRemarks || remarks.length <= remarksLimit ? (
            <>
              {remarks.length > remarksLimit ? (
                <>
                  {remarks.concat(' ')}
                  <Link onClick={toggleAllRemarks}>{less}</Link>
                </>
              ) : (
                <>{remarks}</>
              )}
            </>
          ) : (
            <>
              {remarks.substring(0, remarksLimit).concat('... ')}
              <Link onClick={toggleAllRemarks}>{more}</Link>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default MobileViewItem;
