import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { CellHeaderId } from '../components/applicantsTable/interfaces/applicantsCellHeader';

export const getCells = ({
  id,
  name,
  dateOfBirth,
  gender,
  country,
  actions,
}: {
  id: string;
  name: string;
  dateOfBirth: string;
  gender: string;
  country: string;
  actions: string;
}): CellHeader<CellHeaderId>[] => {
  return [
    {
      id: 'id',
      label: id,
      sortable: true,
    },
    {
      id: 'name',
      label: name,
      sortable: true,
    },
    {
      id: 'dateOfBirth',
      label: dateOfBirth,
      sortable: true,
    },
    {
      id: 'gender',
      label: gender,
      sortable: true,
    },
    {
      id: 'countryOfResidence',
      label: country,
      sortable: true,
      align: 'center',
    },

    {
      id: 'actions',
      label: actions,
      sortable: false,
    },
  ];
};
