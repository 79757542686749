import DatePickerInput from 'components/datePickerInput';
import DropdownMultiselectInput from 'components/dropdownInputMultiselect';
import SwitchInput from 'components/switchInput';
import { useTranslations } from 'hooks/useTranslations';
import { FiltersFormProps } from './interfaces/filtersFormProps';
import DropdownInput from 'components/dropdownInput/dropdownInput';

const Programs = ({
  control,
  programs,
}: Pick<FiltersFormProps, 'control' | 'programs'>) => {
  const translations = useTranslations();
  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.applicants.applicantsFilters.programs}
      inputLabel={translations.applicants.applicantsFilters.programs}
      items={programs}
      name='programs'
      labelHeight={23}
      disabled={programs.length === 0}
    />
  );
};

const LanguageLevel = ({
  control,
  englishProficiencies,
  register,
}: Pick<FiltersFormProps, 'control' | 'englishProficiencies' | 'register'>) => {
  const translations = useTranslations();

  return (
    <DropdownInput
      control={control}
      register={register('englishProficiencies')}
      inputId={translations.applicants.applicantsFilters.languageLevel}
      inputLabel={translations.applicants.applicantsFilters.languageLevel}
      items={englishProficiencies}
      labelHeight={23}
      disabled={englishProficiencies.length === 0}
    />
  );
};

const Skills = ({
  control,
  skills,
}: Pick<FiltersFormProps, 'control' | 'skills'>) => {
  const translations = useTranslations();

  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.applicants.applicantsFilters.skills}
      inputLabel={translations.applicants.applicantsFilters.skills}
      items={skills}
      name='skills'
      labelHeight={23}
      disabled={skills.length === 0}
    />
  );
};

const ProgramTypes = ({
  disabled,
  control,
  programTypes,
}: Pick<FiltersFormProps, 'control' | 'programTypes'> & {
  disabled: boolean;
}) => {
  const translations = useTranslations();

  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.applicants.applicantsFilters.programType}
      inputLabel={translations.applicants.applicantsFilters.programType}
      items={programTypes}
      name='programTypes'
      disabled={disabled || programTypes.length === 0}
      labelHeight={23}
    />
  );
};

const PlacementCategories = ({
  disabled,
  control,
  placementCategories,
}: Pick<FiltersFormProps, 'control' | 'placementCategories'> & {
  disabled: boolean;
}) => {
  const translations = useTranslations();

  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.applicants.applicantsFilters.placementCategory}
      inputLabel={translations.applicants.applicantsFilters.placementCategory}
      items={placementCategories}
      name='placementCategories'
      disabled={disabled || placementCategories.length === 0}
      labelHeight={23}
    />
  );
};

const ResidenceCountries = ({
  disabled,
  control,
  residenceCountries,
}: Pick<FiltersFormProps, 'control' | 'residenceCountries'> & {
  disabled: boolean;
}) => {
  const translations = useTranslations();
  return (
    <DropdownMultiselectInput
      control={control}
      inputId={translations.applicants.applicantsFilters.residenceCountry}
      inputLabel={translations.applicants.applicantsFilters.residenceCountry}
      items={residenceCountries}
      name='residenceCountries'
      labelHeight={23}
    />
  );
};

const AvailableFrom = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.applicants.applicantsFilters.availableFrom}
      control={control}
      name='availableFrom'
      getValue={() => getValues('availableFrom')}
      labelHeight={23}
    />
  );
};

const AvailableTo = ({
  getValues,
  control,
}: Pick<FiltersFormProps, 'getValues' | 'control'>) => {
  const translations = useTranslations();

  return (
    <DatePickerInput
      label={translations.applicants.applicantsFilters.availableTo}
      control={control}
      name='availableTo'
      getValue={() => getValues('availableTo')}
      labelHeight={23}
    />
  );
};

const AvailableAtShortNotice = ({
  control,
}: Pick<FiltersFormProps, 'control'>) => {
  const translations = useTranslations();

  return (
    <SwitchInput
      control={control}
      name='availableAtShortNotice'
      label={translations.applicants.applicantsFilters.availableAtShortNotice}
    />
  );
};

export {
  AvailableFrom,
  AvailableTo,
  LanguageLevel,
  Programs,
  Skills,
  AvailableAtShortNotice,
  ProgramTypes,
  PlacementCategories,
  ResidenceCountries,
};
