import {
  Box,
  Button,
  IconButton,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import Clone from 'assets/icons/clonePlacement.svg';
import Cross from 'assets/icons/cross.svg';
import Download from 'assets/icons/downloadPlacement.svg';
import Edit from 'assets/icons/editPlacement.svg';
import EditDisabled from 'assets/icons/editPlacementDisabled.svg';
import MobileBottomDrawer from 'components/responsiveListView/components/mobileBottomDrawer';
import MobileDisplayProp from 'components/responsiveListView/components/mobileDisplayProp';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { useTranslations } from 'hooks/useTranslations';
import { isEditAvailable } from 'pages/placements/placementsList/helpers';
import { PlacementAction } from 'pages/placements/placementsList/interfaces/placementAction';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import ActionButton from '../actionButton';
import Status from '../status';

const MobileDetails = ({
  placementDetails,
  handleClose,
  handlePlacementAction,
}: {
  placementDetails: PlacementSummaryResponse;
  handleClose: () => void;
  handlePlacementAction: (
    placement: PlacementSummaryResponse,
    action: PlacementAction
  ) => void;
}) => {
  const theme = useTheme();
  const translations = useTranslations();

  if (!placementDetails) {
    return <></>;
  }

  return (
    <MobileBottomDrawer
      open={!!placementDetails}
      handleClose={handleClose}
      header={
        <Box display='flex' p={2} alignItems='center'>
          <Typography
            mr='auto'
            variant='body2'
            sx={{
              fontWeight: 700,
            }}
          >
            Job ID: {placementDetails.id}
          </Typography>
          <Button
            sx={{ flex: '0 0 auto', mr: 2 }}
            variant='outlined'
            disabled={!isEditAvailable(placementDetails)}
            startIcon={
              <Box
                component='img'
                alt='Edit Placement'
                src={isEditAvailable(placementDetails) ? Edit : EditDisabled}
              />
            }
            onClick={() =>
              handlePlacementAction(placementDetails, PlacementAction.Edit)
            }
          >
            {translations.placementsList.actions.edit}
          </Button>
          <IconButton onClick={() => handleClose()}>
            <Box component='img' alt='Close' src={Cross} />
          </IconButton>
        </Box>
      }
      footer={
        <Box
          display='flex'
          justifyContent='space-around'
          p={2}
          sx={{
            '> *': {
              flex: '1 1 auto',
            },
            '> *:not(:last-child)': {
              mr: theme.spacing(1),
            },
          }}
        >
          <Button
            variant='outlined'
            startIcon={<Box component='img' alt='Clone' src={Clone} />}
            onClick={() =>
              handlePlacementAction(placementDetails, PlacementAction.Clone)
            }
          >
            {translations.placementsList.actions.clone}
          </Button>
          <Button
            variant='outlined'
            startIcon={<Box component='img' alt='Download' src={Download} />}
            onClick={() =>
              handlePlacementAction(placementDetails, PlacementAction.Download)
            }
          >
            {translations.placementsList.actions.download}
          </Button>
          <ActionButton
            handlePlacementAction={handlePlacementAction}
            placement={placementDetails}
          />
        </Box>
      }
    >
      <Box display='grid' rowGap={3} gridTemplateColumns='repeat(2, 1fr)' p={2}>
        <Box gridColumn='span 2'>
          <MobileDisplayProp
            label={translations.placementsList.placementTable.columns.program}
            value={placementDetails.program}
          />
        </Box>
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.position}
          value={
            <Link
              onClick={() =>
                handlePlacementAction(placementDetails, PlacementAction.Preview)
              }
            >
              {placementDetails.position}
            </Link>
          }
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.department}
          value={placementDetails.department}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.starts}
          value={formatDate(
            placementDetails.earliestStartDate,
            BE_DATE_FORMAT,
            DEFAULT_FORMAT
          )}
        />
        <MobileDisplayProp
          label={
            translations.placementsList.placementTable.columns.latestStarts
          }
          value={formatDate(
            placementDetails.latestStartDate,
            BE_DATE_FORMAT,
            DEFAULT_FORMAT
          )}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.ends}
          value={formatDate(
            placementDetails.earliestEndDate,
            BE_DATE_FORMAT,
            DEFAULT_FORMAT
          )}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.latestEnds}
          value={formatDate(
            placementDetails.latestEndDate,
            BE_DATE_FORMAT,
            DEFAULT_FORMAT
          )}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.total}
          value={placementDetails.total?.toString()}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.open}
          value={placementDetails.open?.toString()}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.matched}
          value={placementDetails.matched?.toString()}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.approved}
          value={placementDetails.approved?.toString()}
        />
        <MobileDisplayProp
          label={translations.placementsList.placementTable.columns.status}
        />
        <Status placement={placementDetails} />
      </Box>
    </MobileBottomDrawer>
  );
};

export default MobileDetails;
