import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DocumentInfo } from 'components/documentsTable/interfaces/documentInfo';
import { DocumentsTableProps } from 'components/documentsTable/interfaces/documentsTableProps';
import { useHostService } from 'hooks/services/hosts';
import { usePlacementService } from 'hooks/services/placements';
import { downloadBlob } from 'utils/file/fileUtilities';
import { convertStringToBoolean } from 'utils/string/stringConverters';
import Documents from './documents';
import EditPlacementContext from '../../editPlacement.context';
import { DocumentFormInput } from '../../sections/documents/interfaces/documentFormInput';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const DocumentContainer = () => {
  const translations = useTranslations();
  const alert = useAlert();
  const { uploadDocument, deleteDocument } = usePlacementService();
  const { getDocumentFile } = useHostService();

  const {
    dispatch,
    state: { documents, intHostId, id },
  } = useContext(EditPlacementContext);

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    register,
    watch,
    formState: { errors: documentErrors },
  } = useForm<DocumentFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      files: undefined,
      description: '',
      requiredSignature: '',
    },
  });

  const handleDelete = async (row: DocumentInfo) => {
    await deleteDocument({ hostId: intHostId, placementId: id, id: row.id });

    dispatch({
      type: 'change',
      field: 'documents',
      value: [...documents.filter((d) => d.id !== row.id)],
    });
  };

  const handleDownload = async (row: DocumentInfo) => {
    const blob = await getDocumentFile(intHostId, row.id.toString());

    downloadBlob(blob, row.title);
  };

  const tableProps: DocumentsTableProps = {
    rows: documents,
    handleDelete,
    handleDownload,
  };

  const submitDocument = async (data: DocumentFormInput) => {
    if (!data?.files) {
      return;
    }

    const file = data.files[0];
    const title = file.name;
    const signatureRequired = convertStringToBoolean(
      null,
      data.requiredSignature
    );

    try {
      dispatch({
        type: 'change',
        field: 'isLoadingDocument',
        value: true,
      });

      const response = await uploadDocument({
        hostId: intHostId,
        placementId: id,
        body: {
          description: data.description,
          formFile: file,
          signatureRequired,
        },
      });

      alert.success(translations.alerts.uploadDocument.documentSubmitted);

      dispatch({
        type: 'change',
        field: 'documents',
        value: [
          ...documents,
          {
            id: response.id,
            title,
            description: data.description,
            signatureRequired: signatureRequired ?? false,
          },
        ],
      });
    } catch (error) {
      alert.error(translations.alerts.uploadDocument.documentSubmittedError);
    } finally {
      dispatch({
        type: 'change',
        field: 'isLoadingDocument',
        value: false,
      });
    }

    reset({
      description: '',
      files: undefined,
      requiredSignature: '',
    });
  };

  const handleDocumentSubmit = handleSubmit(submitDocument);
  const selectedDocuments = watch('files');

  return (
    <Documents
      onSubmit={handleDocumentSubmit}
      control={control}
      register={register}
      errors={documentErrors}
      files={selectedDocuments}
      setFile={(files: FileList | undefined) => setValue('files', files)}
      tableProps={tableProps}
    />
  );
};

export default DocumentContainer;
