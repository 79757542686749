import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextareaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import translations from 'utils/translations';
import { HousingFormInput } from '../../interfaces/housingForm.formInput';

export const HousingFormSecurity = () => {
  const theme = useTheme();

  const { inputStyle, defaultInput, yesOrNoInput, inputsSection } =
    useFormInputsStyle(theme);

  const {
    title,
    gated,
    areThereActiveSecurityCamerasOnSite,
    secured,
    isThereARepresentativeOnSite24For7,
    contactName,
    contactPhone,
    contactEmail,
    bus,
    frequencyOfBus,
    subway,
    frequencyOfSubway,
    train,
    frequencyOfTrain,
    otherPublicTransport,
    isThereAWebsiteForPublicTransportation,
    isItConsideredSafeToWalkToTheNearestPublicTransportationStop,
    howFarIsIt,
    doesItRunToTheJobsite,
  } = translations.housingForm.sections.security;

  const matchXs = useMediaQuery('(min-width: 750px) and (max-width: 937px)', {
    noSsr: true,
  });

  return (
    <ConnectForm<HousingFormInput>>
      {({ register, control, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'gated'}
                  inputLabel={gated}
                  labelHeight={46}
                  errorMessage={errors?.security?.gated?.message}
                  control={control}
                  register={register('security.gated')}
                />
              </Grid>

              <Grid {...inputStyle(3, 3)}>
                <DropdownYesNo
                  inputId={'areThereActiveSecurityCamerasOnSite'}
                  inputLabel={areThereActiveSecurityCamerasOnSite}
                  labelHeight={46}
                  errorMessage={
                    errors?.security?.areThereActiveSecurityCamerasOnSite
                      ?.message
                  }
                  register={register(
                    'security.areThereActiveSecurityCamerasOnSite'
                  )}
                  control={control}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'secured'}
                  inputLabel={secured}
                  labelHeight={46}
                  errorMessage={errors?.security?.secured?.message}
                  register={register('security.secured')}
                  control={control}
                />
              </Grid>

              <Grid {...inputStyle(3, 3)}>
                <DropdownYesNo
                  inputId={'isThereARepresentativeOnSite24For7'}
                  inputLabel={isThereARepresentativeOnSite24For7}
                  labelHeight={46}
                  errorMessage={
                    errors?.security?.isThereARepresentativeOnSite24For7
                      ?.message
                  }
                  register={register(
                    'security.isThereARepresentativeOnSite24For7'
                  )}
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'contactPhone'}
                  inputLabel={contactPhone}
                  numberOfRows={1}
                  register={register('security.contactPhone')}
                  errorMessage={errors?.security?.contactPhone?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'contactName'}
                  inputLabel={contactName}
                  numberOfRows={1}
                  register={register('security.contactName')}
                  errorMessage={errors?.security?.contactName?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'contactEmail'}
                  inputLabel={contactEmail}
                  numberOfRows={1}
                  register={register('security.contactEmail')}
                  errorMessage={errors?.security?.contactEmail?.message}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'bus'}
                  inputLabel={bus}
                  errorMessage={errors?.security?.bus?.message}
                  control={control}
                  register={register('security.bus')}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'frequencyOfBus'}
                  inputLabel={frequencyOfBus}
                  numberOfRows={1}
                  register={register('security.frequencyOfBus')}
                  errorMessage={errors?.security?.frequencyOfBus?.message}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'subway'}
                  inputLabel={subway}
                  errorMessage={errors?.security?.subway?.message}
                  control={control}
                  register={register('security.subway')}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'frequencyOfSubway'}
                  inputLabel={frequencyOfSubway}
                  numberOfRows={1}
                  register={register('security.frequencyOfSubway')}
                  errorMessage={errors?.security?.frequencyOfSubway?.message}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'train'}
                  inputLabel={train}
                  errorMessage={errors?.security?.train?.message}
                  control={control}
                  register={register('security.train')}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'frequencyOfTrain'}
                  inputLabel={frequencyOfTrain}
                  numberOfRows={1}
                  register={register('security.frequencyOfTrain')}
                  errorMessage={errors?.security?.frequencyOfTrain?.message}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'otherPublicTransport'}
                  inputLabel={otherPublicTransport}
                  numberOfRows={1}
                  register={register('security.otherPublicTransport')}
                  errorMessage={errors?.security?.otherPublicTransport?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'isThereAWebsiteForPublicTransportation'}
                  inputLabel={isThereAWebsiteForPublicTransportation}
                  numberOfRows={1}
                  register={register(
                    'security.isThereAWebsiteForPublicTransportation'
                  )}
                  errorMessage={errors?.security?.isThereAWebsiteForPublicTransportation?.message}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(5, 4)}>
                <DropdownYesNo
                  inputId={
                    'isItConsideredSafeToWalkToTheNearestPublicTransportationStop'
                  }
                  inputLabel={
                    isItConsideredSafeToWalkToTheNearestPublicTransportationStop
                  }
                  labelHeight={matchXs ? 68 : 46}
                  errorMessage={
                    errors?.security
                      ?.isItConsideredSafeToWalkToTheNearestPublicTransportationStop
                      ?.message
                  }
                  control={control}
                  register={register(
                    'security.isItConsideredSafeToWalkToTheNearestPublicTransportationStop'
                  )}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={'howFarIsIt'}
                  inputLabel={howFarIsIt}
                  labelHeight={matchXs ? 68 : 46}
                  numberOfRows={1}
                  register={register('security.howFarIsIt')}
                  errorMessage={errors?.security?.howFarIsIt?.message}
                  singleLine
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'doesItRunToTheJobsite'}
                  inputLabel={doesItRunToTheJobsite}
                  errorMessage={
                    errors?.security?.doesItRunToTheJobsite?.message
                  }
                  control={control}
                  register={register('security.doesItRunToTheJobsite')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
