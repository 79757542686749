import { Grid, useTheme } from '@mui/material';
import CustomModal from '.';
import { HeaderFooterModalProps } from './interfaces/HeaderFooterModalProps';
import { Button } from '@mui/material';
import ButtonWithProgress from 'components/button/buttonWithProgress';

const HeaderFooterModal = ({
  saveBtnText,
  cancelBtnText,
  onSave,
  children,
  isLoading,
  disabledSubmitBtn,
  scrollableContent = true,
  ...props
}: HeaderFooterModalProps) => {
  const { onClose } = props;
  const theme = useTheme();
  return (
    <CustomModal {...props}>
      {children}

      <Grid
        container
        item
        xs={12}
        p={theme.spacing(2, 3)}
        borderTop={`1px solid ${theme.palette.grey['700']}`}
        m={0}
        justifyContent={'flex-end'}
        gap={2}
        sx={{
          position: { xs: scrollableContent ? 'absolute' : 'unset', md: 'unset' },
          bottom: 0
        }}
      >
        <Grid item>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            {cancelBtnText}
          </Button>
        </Grid>
        <Grid item>
          <ButtonWithProgress
            dataTestid='save'
            isLoading={isLoading}
            variant='contained'
            color='secondary'
            text={saveBtnText}
            onClick={onSave}
            isDisabled={disabledSubmitBtn}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default HeaderFooterModal;
