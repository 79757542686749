import { styled } from '@mui/material';

import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

interface CustomPickerDayProps extends PickersDayProps<Date> {
  dayIsBetween?: boolean;
  isFirstDay?: boolean;
  isLastDay?: boolean;
  currentValue?: boolean;
}

export const CustomPickersDay: React.ComponentType<CustomPickerDayProps> =
  styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
  })<CustomPickerDayProps>(
    ({ theme, dayIsBetween, isFirstDay, isLastDay, selected }) => ({
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.primary.main,
      padding: theme.spacing(0, 2.5),
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: `${theme.palette.secondary.main}15`,
        color: theme.palette.secondary.main,
        '&:hover, &:focus': {
          backgroundColor: `${theme.palette.secondary.main}15`,
        },
      }),
      ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      }),
      ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      }),
      '&:not(.Mui-selected)': {
        border: 'none',
      },
      ...(selected && {
        '&.Mui-selected': {
          // Custom styles for the selected date
          backgroundColor: `${theme.palette.secondary.main}15`,
          color: theme.palette.secondary.main,
          '&:hover, &:focus': {
            backgroundColor: `${theme.palette.secondary.main}`,
            color: 'white',
          },
        },
      }),
    })
  ) as React.ComponentType<CustomPickerDayProps>;
