import { Payment } from 'hooks/services/placements/interfaces/patchParams.request';
import {
  convertToBooleanOrUndefined,
  convertToNumberOrUndefined,
} from 'utils/string/stringConverters';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const makePaymentPatchRequest = (
  data: EditPlacementFormInput
): Payment => {
  return {
    additionalInformation: data.payment.additionalInformation,
    allowancesBonusesIncentives: data.payment.allowancesBonusesIncentives,
    averageHoursPerWeek: 
      data.payment.averageHoursPerWeek
    ,
    estimatedTip: data.payment.estimatedTip,
    grossWage: convertToNumberOrUndefined(data.payment.grossWage),
    isOvertimeAvailable: convertToBooleanOrUndefined(
      data.payment.isOvertimeAvailable
    ),
    overtimeWage: data.payment.overtimeWage,
    paymentSchedule: data.payment.paymentSchedule,
    wagePer: data.payment.wagePer,
  };
};
