import { useNavigate } from 'react-router-dom';
import { useLoading } from 'hooks/useLoading';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { useFetchAlertHandler } from './useFetchErrorHandler';
import { localStorageService } from '../../services/localStorage.service';
import { RequestModel, RequestWithBodyModel } from '../types/convertRequestToBodyProps';
import { useAuth } from '../useAuth';
import { useConfiguration } from '../useConfiguration';
import { useAlert } from 'providers/alertProvider';

function isRequestWithBody(
  req: RequestModel | RequestWithBodyModel
): req is RequestWithBodyModel {
  return (req as RequestWithBodyModel).body !== undefined;
}

function convertRequestToBody(request: RequestModel | RequestWithBodyModel): {
  body: string | FormData | undefined;
  isJson: boolean;
} {
  if (isRequestWithBody(request)) {
    if (request.body instanceof FormData) {
      return { body: request.body, isJson: false };
    } else {
      return { body: JSON.stringify(request.body), isJson: true };
    }
  }
  return { body: undefined, isJson: false };
}

export const useFetch = () => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();
  const { handleIsLoading } = useLoading();
  const configuration = useConfiguration();
  const alertHandler = useFetchAlertHandler();
  const alert = useAlert();

  const handleFetch = async <
    TRequest extends RequestModel | RequestWithBodyModel
  >(
    resource: string,
    request: TRequest,
    method: string,
    signal?: AbortSignal
  ): Promise<any> => {
    const { core } = configuration;
    const accessTokenKey = 'accessToken';
    const baseUrl = core.api.baseUrl;
    const token = localStorageService.get(accessTokenKey);
    const url = request?.params
      ? `${baseUrl}/${resource}/?${request.params}`
      : `${baseUrl}/${resource}`;

    const headers: { [k: string]: any; } = request.headers ?? {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const { body, isJson } = convertRequestToBody(request);

    if (isJson) {
      headers['Content-type'] = 'application/json';
    }

    try {
      handleIsLoading(true);
      const response = await fetch(url, { body, headers, signal, method });
      if (!response.ok) {
        throw response;
      }

      const contentType = response.headers.get('content-type');
      const contentDisposition = response.headers.get('content-disposition');

      return contentType && contentType?.indexOf('application/json') !== -1
        ? await response.json()
        : contentDisposition?.indexOf('attachment') !== -1
          ? await response.blob()
          : response;
    } catch (err: any) {

      const contentType = err.headers.get('content-type');

      if (err instanceof Response) {
        const response = err as Response;

        if (response.status === 401) {
          const emptyString = '',
            refreshTokenKey = 'refreshToken',
            refreshToken = localStorageService.get(refreshTokenKey);

          localStorageService.set(refreshTokenKey, emptyString);
          localStorageService.set(accessTokenKey, emptyString);

          if (refreshToken !== '') {
            try {
              const res: { accessToken: string; refreshToken: string; } =
                await handleFetch(
                  'accounts/login/refresh',
                  {
                    body: { refreshToken },
                  },
                  'post'
                );

              localStorageService.set(refreshTokenKey, res.refreshToken);
              localStorageService.set(accessTokenKey, res.accessToken);

              return handleFetch(resource, request, method, signal);
            } catch {
              setIsLoggedIn(false);
              navigate(ApplicationRoutes.signIn, { replace: true });
              return;
            }
          }

          if (contentType?.indexOf('application/problem+json') !== -1) {
            throw await response.json();
          }
        }

        const errorObj = await response?.json();
        const errorMsg = errorObj?.detail;
        if (response.status >= 400 && response.status < 500 && !!errorMsg) {
          alert.warning(errorMsg);
        } else {
          alertHandler(response.status);
        }
      }
      throw err;
    } finally {
      handleIsLoading(false);
    }
  };

  return {
    get: async <T>(url: string, request?: RequestModel): Promise<T> => {
      return handleFetch(url, { ...request }, 'get');
    },
    post: async <T>(
      url: string,
      request?: RequestWithBodyModel
    ): Promise<T> => {
      return handleFetch(url, { ...request }, 'post');
    },
    put: async <T>(url: string, request?: RequestWithBodyModel): Promise<T> => {
      return handleFetch(url, { ...request }, 'put');
    },
    patch: async <T>(
      url: string,
      request?: RequestWithBodyModel
    ): Promise<T> => {
      return handleFetch(url, { ...request }, 'patch');
    },
    del: async <T>(url: string, request?: RequestModel): Promise<T> => {
      return handleFetch(url, { ...request }, 'delete');
    },
  };
};
