import {
  useTheme,
  Menu,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Typography,
} from '@mui/material';
import { popoverClasses } from '@mui/material/Popover';
import changeHost from 'assets/icons/changeHost.svg';
import { useTranslations } from 'hooks/useTranslations';
import { ChangeHostProps } from './interfaces/changeHostProps';
import {HostResponse} from "../../../../hooks/services/commons/interfaces/host.response";

const ChangeHost = ({
  openMainMenu,
  openMenu,
  hosts,
  anchorEl,
  handleClickEvent,
  handleClose,
  activeHost,
  handleChangeHost,
}: ChangeHostProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const generateListItemText = (host: HostResponse) => `${host.name} - ${host.type.name} - ${host.channel.name}`;


  return (
    <>
      <Box
        component='div'
        sx={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mx: openMainMenu ? theme.spacing(-1) : 0,
          padding: openMainMenu ? theme.spacing(2.5, 2) : 0,
          background: openMainMenu ? theme.palette.grey[500] : undefined,
        }}
        id='host-button'
        data-testid='host-button'
        aria-controls={openMenu ? 'host-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClickEvent}
      >
        <Typography
          sx={{
            textTransform: 'initial',
            whiteSpace: 'normal',
            display: openMainMenu ? 'inline-block' : 'none',
            textAlign: 'center',
          }}
        >
          {`${activeHost.name} - ${activeHost.type.name}`}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mb: openMainMenu ? 0 : 2.5,
            borderRadius: openMainMenu
              ? theme.spacing(0.5)
              : theme.spacing(1.3),
            display: 'flex',
            p: openMainMenu ? 1 : theme.spacing(1, 2),
            justifyContent: openMainMenu ? 'flex-start' : 'center',
            minWidth: 'unset',
            width: openMainMenu ? '150px' : '40px',
          }}
        >
          <Box
            component='img'
            alt=''
            src={changeHost}
            sx={{ mr: openMainMenu ? 2 : 0 }}
          />
          <Typography
            sx={{
              textTransform: 'initial',
              whiteSpace: 'nowrap',
              fontWeight: theme.appDrawer.fontWeight,
              fontSize: theme.appDrawer.fontSize,
              display: openMainMenu ? 'inline-block' : 'none',
            }}
          >
            {translations.changeHostButton}
          </Typography>
        </Button>
      </Box>
      <Menu
        id='host-menu'
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'host-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          width: 200,
          ml: theme.spacing(1.5),
          [`& .${popoverClasses.paper}`]: {
            borderRadius: 0,
          },
        }}
        PaperProps={{ sx: { width: { xs: '150px', md: '200px' } } }}
      >
        {hosts.map((host) => (
          <ListItem
            key={host.id}
            data-testid='host-item'
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => {
              handleChangeHost(host);
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2,
                borderRadius: theme.spacing(1.2),
              }}
              selected={host.id === activeHost.id}
            >
              {/*<ListItemText primary={`${host.name} - ${host.type.name}`} />*/}
              <ListItemText primary={generateListItemText(host)} />
            </ListItemButton>
          </ListItem>
        ))}
      </Menu>
    </>
  );
};

export default ChangeHost;
