import { useCallback, useState } from 'react';
import { Nullable } from 'utils/interfaces';
import { ApplicantInfoResponse } from 'hooks/services/hosts/interfaces/participantSummary.response';
import { useHostService } from 'hooks/services/hosts';
import { useParams } from 'react-router-dom';

const useParticipantModal = () => {
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const { getApplicantById } = useHostService();
  const [activeItem, setActiveItem] =
    useState<Nullable<ApplicantInfoResponse>>(null);
  const [isOpen, setIsOpen] = useState(false);

  const fetchApplicant = useCallback(
    async (parsedHostId: number, activeId: number) => {
      try {
        const response = await getApplicantById(parsedHostId, activeId);
        setActiveItem(response);
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenModal = (id: number) => {
    fetchApplicant(parsedHostId, id);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setActiveItem(null);
  };

  return {
    isOpen,
    handleCloseModal,
    activeItem,
    handleOpenModal,
  };
};

export default useParticipantModal;
