import ColoredChip from 'components/coloredChip/coloredChip';
import { ChipColor } from 'components/coloredChip/interfaces/chipColor';
import { StatusProps } from 'pages/placements/placementsList/components/placementsTable/components/interfaces/statusProps';

const HousingStatus = ({ status }: { status: StatusProps }) => {
  let color: ChipColor;

  switch (status) {
    case StatusProps.Inactive:
      color = 'statusOrange';
      break;

    case StatusProps.Active:
      color = 'statusGreen';
      break;

    case StatusProps.Draft:
    default:
      color = 'statusGrayed';
      break;
  }

  return <ColoredChip text={status.toString()} color={color} />;
};

export default HousingStatus;
