import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Cross from 'assets/icons/cross.svg';
import Edit from 'assets/icons/editPlacement.svg';
import EditDisabled from 'assets/icons/editPlacementDisabled.svg';
import MobileDisplayProp from 'components/responsiveListView/components/mobileDisplayProp';
import { useTranslations } from 'hooks/useTranslations';
import { HousingRow } from 'pages/programs/housing/interfaces/housingRow';
import HousingStatus from '../../../status';

const HousingMobileDetails = ({
  housingDetails,
  handleClose,
  height,
  bottomRef,
  topRef,
}: {
  housingDetails: HousingRow;
  handleClose: () => void;
  height: number;
  topRef: (node: HTMLDivElement) => void;
  bottomRef: (node: HTMLDivElement) => void;
}) => {
  const translations = useTranslations();
  const { name, address, city, zip } =
    translations.companyHousing.companyHousingTable.columns;

  return (
    <Drawer
      anchor='bottom'
      open={!!housingDetails}
      onClose={() => handleClose()}
    >
      {housingDetails ? (
        <Box>
          <Box display='flex' p={2} ref={topRef} alignItems='center'>
            <Typography
              mr='auto'
              variant='body2'
              sx={{
                fontWeight: 700,
              }}
            >
              Housing ID: {housingDetails.id}
            </Typography>

            {housingDetails.status !== 'Active' ? (
              <Button
                onClick={() => {
                  housingDetails.edit();
                }}
                sx={{ flex: '0 0 auto', mr: 2 }}
                variant='outlined'
                disabled={!housingDetails.isEditable}
                startIcon={
                  <Box
                    component='img'
                    alt={translations.companyHousing.edit}
                    src={housingDetails.isEditable ? Edit : EditDisabled}
                  />
                }
              >
                {translations.companyHousing.edit}
              </Button>
            ) : null}

            <IconButton onClick={() => handleClose()}>
              <Box component='img' alt='Close' src={Cross} />
            </IconButton>
          </Box>

          <Divider />

          <Box
            display='grid'
            rowGap={3}
            columnGap={2}
            gridTemplateColumns='repeat(2, 1fr)'
            p={2}
            sx={{
              maxHeight: `calc(90vh - ${height}px - ${24})`,
              overflow: 'auto',
            }}
          >
            <MobileDisplayProp label={name} value={housingDetails.name} />
            <MobileDisplayProp label={address} value={housingDetails.address} />
            <MobileDisplayProp label={city} value={housingDetails.city} />
            <MobileDisplayProp
              label={zip}
              value={housingDetails.zip.toString()}
            />
          </Box>

          <Grid item display={'flex'} sx={{ padding: 2 }}>
            <MobileDisplayProp label={'Status'} />

            <Box ml={5}>
              <HousingStatus status={housingDetails.status} />
            </Box>
          </Grid>

          <Divider />

          <Grid sx={{ padding: 2 }}>
            <Button
              fullWidth
              onClick={() => {
                housingDetails.action.onClick();
              }}
              variant='contained'
              color='secondary'
            >
              {housingDetails.action.title}
            </Button>
          </Grid>
        </Box>
      ) : null}
    </Drawer>
  );
};

export default HousingMobileDetails;
