export default class ApplicationRoutes {
  static signIn = '/';
  static dashboard = '/dashboard';
  static resetPassword = '/reset-password';
  static changePassword = '/change-password';
  static badRoute = '*';
  static createPlacement = '/hosts/:hostId/placements/new';
  static editPlacement = '/hosts/:hostId/placements/:placementId';
  static clonePlacement = '/hosts/:hostId/placements/:placementId/clone';
  static placements = '/hosts/:hostId/placements';
  static placementPreview = '/hosts/:hostId/placements/:placementId/preview';
  static completeCompanyInfo = '/hosts/:hostId/info';
  static offerDetails = '/hosts/:hostId/offer-details';
  static companyHousing = '/hosts/:hostId/housing';
  static companyHousingForm = '/hosts/:hostId/housing/:housingId';
  static uploadDocument = '/hosts/:hostId/documents/:id';
  static documents = '/hosts/:hostId/documents';
  static addressHost = '/hosts/:hostId/address';
  static arrivalInstructions = '/hosts/:hostId/arrival-instructions';
  static arrivalInstructionsForm =
    '/hosts/:hostId/arrival-instructions/:arrivalInstructionsId';
  static contacts = '/hosts/:hostId/contacts';
  static contactsForm = '/hosts/:hostId/contacts/:contactId';
  static applicants = '/hosts/:hostId/applicants';
  static participants = '/hosts/:hostId/participants/:tabRoute';
  static interviews = '/hosts/:hostId/interviews/:tabRoute';
  static interviewReport = '/hosts/:hostId/interviewReport/:interviewId';
  static profile = '/hosts/:hostId/profile';
  static notifications = '/hosts/:hostId/notifications';
  static programResources = '/hosts/:hostId/program-resources';
}
