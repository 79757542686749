import { Box, Card, CardContent, CardMedia, Divider, Grid, Link, Typography, useTheme } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import DownLoadIcon from 'assets/icons/downLoad.svg';
import { useTranslations } from 'hooks/useTranslations';
import { TileComponentProps } from './interfaces/tileComponentProps';

const TileComponent = ({
  header,
  title,
  subTitle,
  imgSrc,
  videoLink,
  downloadDocument,
  isApplicant,
  avatarHeight,
  offset,
  handleInterviewAction,
  isInterviewBooked,
}: TileComponentProps) => {
  const {
    tileComponent: { downloadPDF, interview },
  } = useTranslations();
  const theme = useTheme();
  const { text, divider } = theme.palette;

  return (
    <Box
      sx={{
        width: { xs: 165, md: 220 },
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          height: offset,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardMedia
          sx={{
            height: avatarHeight,
            width: { xs: 95, md: 120 },
          }}
          image={imgSrc}
          title='photo'
        />
      </Box>
      <Card sx={{ width: '100%', pt: `${offset}px` }}>
        <CardContent
          sx={{
            textAlign: 'center',
            p: theme.spacing(2, 1, 1),
            minHeight: isApplicant ? 128 : 160,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant='body1' mb={theme.spacing(1)}>
            {header}
          </Typography>
          <Typography variant='body2' color={text.secondary}>
            {title}
          </Typography>
          <Typography variant='body2' color={text.secondary}>
            {subTitle}
          </Typography>
        </CardContent>

        <Divider />
        <Grid
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Grid item xs={isApplicant ? 8 : 12}>
            {isApplicant && (
              <>
                <Link
                  color={isInterviewBooked ? 'text.disabled' : 'secondary'}
                  underline='none'
                  sx={{
                    display: 'flex',
                    p: { xs: theme.spacing(1), md: theme.spacing(2) },
                    width: '100%',
                  }}
                  data-testid='interview-link'
                  onClick={() => {
                    if (handleInterviewAction) handleInterviewAction();
                  }}
                >
                  <Grid
                    container
                    gap={{
                      xs: 0.5,
                      md: 1,
                    }}
                    sx={{
                      alignItems: 'end',
                      flexWrap: 'nowrap',
                      flexDirection: 'row',
                    }}
                  >
                    <CalendarMonthIcon fontSize='small' />

                    <Typography
                      variant='caption'
                      sx={{
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: { md: 12 },
                      }}
                    >
                      {interview}
                    </Typography>
                  </Grid>
                </Link>
                <Divider component='div' sx={{ width: '100%' }} />
              </>
            )}

            <Grid
              container
              gap={{
                xs: 0.5,
                md: 1,
              }}
              sx={{
                p: { xs: theme.spacing(1), md: theme.spacing(2) },
                alignItems: 'end',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                justifyContent: isApplicant ? 'normal' : 'center',
              }}
              data-testid='download-pdf-link'
              onClick={(e) => {
                e.stopPropagation();
                downloadDocument();
              }}
            >
              <Box component='img' alt='' src={DownLoadIcon} />

              <Typography
                variant='caption'
                color={'secondary'}
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: { md: 12 },
                }}
              >
                {downloadPDF}
              </Typography>
            </Grid>
          </Grid>

          {isApplicant && (
            <Grid
              container
              item
              xs={4}
              sx={{
                opacity: !videoLink ? '0.4' : null,
              }}
            >
              <Link
                href={videoLink}
                color={videoLink ? 'secondary' : 'text.disabled'}
                underline='none'
                target='_blank'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: theme.spacing(2),
                  width: '100%',
                  borderLeft: `1px solid ${divider}`,
                }}
                data-testid='youtube-link'
              >
                <PlayCircleOutlinedIcon fontSize='large' />
              </Link>
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  );
};

export default TileComponent;
