import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHostService } from 'hooks/services/hosts';
import { AvailabilityRequest } from 'hooks/services/hosts/interfaces/availability.request';
import { ScheduleResponse } from 'hooks/services/hosts/interfaces/availability.response';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { useAlert } from 'providers/alertProvider';
import { parseStringToDate } from 'utils/date/convertToDate';
import { rubyToMomentMapping } from 'utils/date/timezones';
import { Nullable } from 'utils/interfaces';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { DateWeekDayPeriod } from './interfaces/DateWeekDay';
import { AvailabilityFormData } from './interfaces/FormData';
import { SetAvailabilityProps } from './interfaces/SetAvailabilityProps';
import SetAvailability from './setAvailability';
import { getCurrentWeekWithPeriods } from './utils';

const SetAvailabilityModalContainer = (props: SetAvailabilityProps) => {
  const translations = useTranslations();
  const alert = useAlert();
  const { hostIdVal, ...rest } = props;

  const { user } = useUser();
  const timeZone = rubyToMomentMapping.get(user?.timeZone || '');
  const { hostId } = useParams();
  const parsedHostId = hostIdVal || parseInt(hostId as string);

  const { getAvailability, postAvailability } = useHostService();

  const [isLoading, setIsLoading] = useState(false);
  const [calendarValue, setCalendarValue] = useState<Nullable<Date>>(
    new Date()
  );
  const [response, setResponse] = useState<Nullable<ScheduleResponse>>(null);
  const [dateSlots, setDateSlots] = useState<DateWeekDayPeriod[]>([]);
  const [updatedDatesData, setUpdatedDatesData] = useState<DateWeekDayPeriod[]>(
    []
  );
  const methods = useForm<AvailabilityFormData>({
    defaultValues: {
      dates: [],
      copyAvailability: '',
    },
  });

  const { setValue, handleSubmit, control } = methods;

  const datesDataWatch: DateWeekDayPeriod[] = useWatch<
    AvailabilityFormData,
    'dates'
  >({
    name: 'dates',
    control,
  });

  const copyWeeksArray: KeyValueItemProps[] = [
    { key: '0', value: '0 weeks' },
    { key: '1', value: '1 week' },
    { key: '2', value: '2 weeks' },
    { key: '3', value: '3 weeks' },
    { key: '4', value: '4 weeks' },
  ];

  const onChangeCalendar = (newValue: Nullable<Date>) => {
    setCalendarValue(newValue);
  };

  const fetchAvailability = useCallback(async () => {
    try {
      const response = await getAvailability({
        hostId: parsedHostId,
        timeZone,
      });
      setResponse(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone]);

  const submitHandler = async (data: AvailabilityFormData) => {
    if (response && timeZone) {
      const datesFormData = updatedDatesData.map(({ date, periods }) => ({
        date,
        periods,
      }));
      const restResponse = response.dates.filter((item) =>
        datesFormData.every((x) => x.date !== item.date)
      );
      const postDates = [...datesFormData, ...restResponse];

      const body: AvailabilityRequest = {
        timeZone,
        copyAvailability: parseInt(data.copyAvailability),
        dates: postDates,
      };
      setIsLoading(true);
      try {
        await postAvailability(parsedHostId, body);
        alert.success(translations.alerts.interviews.availabilityUpdated);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        props.onClose();
      }
    }
  };
  const onSave = handleSubmit(submitHandler);

  const availableDates = useMemo(() => {
    if (response) {
      const dates: Date[] = response?.dates.map((item) =>
        parseStringToDate(item.date)
      );
      return dates;
    } else {
      return [];
    }
  }, [response]);

  useEffect(() => {
    setUpdatedDatesData((prevDatesData) => {
      // Access the previous state form data and perform your updates
      const updatedPrevData = prevDatesData.filter((item) =>
        datesDataWatch.every((x) => x.date !== item.date)
      );
      const updatedDatesData = [...updatedPrevData, ...datesDataWatch];
      return updatedDatesData;
    });
  }, [datesDataWatch]);

  useEffect(() => {
    if (response && calendarValue) {
      const weekdaysWithDates = getCurrentWeekWithPeriods(
        calendarValue,
        response?.dates
      );
      setDateSlots(weekdaysWithDates);
      setValue('dates', weekdaysWithDates);
      setValue('copyAvailability', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarValue, response]);

  useEffect(() => {
    onChangeCalendar(new Date());
    if (rest.open) {
      fetchAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.open]);

  return (
    <FormProvider {...methods}>
      <SetAvailability
        {...{
          onSave,
          calendarValue,
          onChangeCalendar,
          dateSlots,
          copyWeeksArray,
          availableDates,
          isLoading,
          ...rest,
        }}
      />
    </FormProvider>
  );
};

export default SetAvailabilityModalContainer;
