import { useCallback, useState } from 'react';
import { useUser } from 'hooks/useUser';
import ChangeHost from './changeHost';
import { HostResponse } from 'hooks/services/commons/interfaces/host.response';
import useLocalStorage from 'hooks/useLocalStorage';
import { useParams } from "react-router-dom";

const ChangeHostContainer = ({
  openMainMenu,
  path,
}: {
  openMainMenu: boolean;
  path: string;
}) => {
  const { hosts, host } = useUser();
  const { tabRoute } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [, setHostId] = useLocalStorage('hostId', '');

  const handleClickEvent = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeHost = (host: HostResponse) => {
    setHostId(host.id.toString());
    window.location.href = path.replace(':hostId', host.id.toString()).replace(':tabRoute', tabRoute || 'all');
  };

  if (!host) return null;

  return (
    <ChangeHost
      openMainMenu={openMainMenu}
      openMenu={openMenu}
      hosts={hosts}
      handleClickEvent={handleClickEvent}
      handleClose={handleClose}
      anchorEl={anchorEl}
      activeHost={host}
      handleChangeHost={handleChangeHost}
    />
  );
};

export default ChangeHostContainer;
