import { Box, Divider, Toolbar, useTheme } from '@mui/material';
import arrivalInformation from 'assets/icons/arrivalInformation.svg';
import companyInformation from 'assets/icons/companyInformation.svg';
import home from 'assets/icons/home.svg';
import interviewsIcon from 'assets/icons/interviews.svg';
import jobOrders from 'assets/icons/jobOrders.svg';
import participantsIcon from 'assets/icons/participants.svg';
import programResources from 'assets/icons/programResources.svg';
import searchApplicants from 'assets/icons/searchApplicants.svg';
import { MenuItemsResponse } from 'hooks/services/hosts/interfaces/menu-item.response';
import { useTranslations } from 'hooks/useTranslations';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { MenuItem } from './menuItem';
import { getIconByMenuId, getRoute } from './utils';
import ChangeHost from '../changeHost';
import Menu from '../drawerMenu/drawerMenu';

const {
  placements,
  applicants,
  dashboard,
  completeCompanyInfo,
  companyHousing,
  documents,
  arrivalInstructions,
  addressHost,
  contacts,
  offerDetails,
  participants,
  interviews,
} = ApplicationRoutes;

const DrawerContent = ({
  open,
  menuItems,
  activeHostId = '',
  path,
  accessApplicants
}: {
  open: boolean;
  menuItems: MenuItemsResponse;
  activeHostId?: string;
  path: string;
  accessApplicants: boolean;
}) => {
  const translations = useTranslations();
  const theme = useTheme();

  const topMenu = []
  topMenu.push(new MenuItem(translations.menu.home, home, dashboard))

  if (!!accessApplicants) {
    topMenu.push(
      new MenuItem(
        translations.menu.searchApplicants,
        searchApplicants,
        applicants.replace(':hostId', activeHostId)
      )
    )
  }

  topMenu.push(
    new MenuItem(
      translations.menu.interviews,
      interviewsIcon,
      interviews.replace(':hostId', activeHostId).replace(':tabRoute', 'all')
    )
  )
  topMenu.push(
    new MenuItem(
      translations.menu.participants,
      participantsIcon,
      participants
        .replace(':hostId', activeHostId)
        .replace(':tabRoute', 'participants')
    )
  )
  topMenu.push(
    new MenuItem(
      translations.menu.placements,
      jobOrders,
      placements.replace(':hostId', activeHostId)
    )
  )

  const companySubMenu = [
    new MenuItem(
      translations.companySubMenu.companyInfo,
      getIconByMenuId('company_information', menuItems),
      getRoute(
        'company_information',
        menuItems,
        completeCompanyInfo.replace(':hostId', activeHostId)
      )
    ),
    new MenuItem(
      translations.companySubMenu.contacts,
      getIconByMenuId('employer_contacts', menuItems),
      contacts.replace(':hostId', activeHostId)
    ),
    new MenuItem(
      translations.companySubMenu.adresses,
      getIconByMenuId('employer_addresses', menuItems),
      addressHost.replace(':hostId', activeHostId)
    ),
    new MenuItem(
      translations.companySubMenu.documents,
      getIconByMenuId('documents', menuItems),
      documents.replace(':hostId', activeHostId)
    ),
  ];
  const programsSubMenu = [
    new MenuItem(
      translations.programsSubMenu.arrivalInstuctions,
      getIconByMenuId('employer_flight_info', menuItems),
      arrivalInstructions.replace(':hostId', activeHostId)
    ),
    new MenuItem(
      translations.programsSubMenu.housing,
      getIconByMenuId('employer_housing', menuItems),
      companyHousing.replace(':hostId', activeHostId)
    ),
    new MenuItem(
      translations.programsSubMenu.offerDetails,
      getIconByMenuId('offer_details', menuItems),
      offerDetails.replace(':hostId', activeHostId)
    ),
  ];
  const middleMenu = [
    new MenuItem(
      translations.menu.company,
      companyInformation,
      undefined,
      undefined,
      companySubMenu
    ),
    new MenuItem(
      translations.menu.programs,
      arrivalInformation,
      undefined,
      undefined,
      programsSubMenu
    ),
  ];

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Toolbar sx={{ height: theme.appBar.height }} />
      <Box
        sx={{
          padding: { xs: theme.spacing(1), md: theme.spacing(3, 1) },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: `calc(100% - ${theme.appBar.height})`,
        }}
      >
        <Menu items={topMenu} open={open} />

        <Divider
          sx={{
            mx: theme.spacing(-1),
          }}
        />

        <Menu items={middleMenu} open={open} />

        <Divider
          sx={{
            mx: theme.spacing(-1),
          }}
        />

        <Box sx={{ mb: 2 }}>
          <Menu
            items={[
              new MenuItem(
                translations.menu.programResources,
                programResources,
                ApplicationRoutes.programResources.replace(
                  ':hostId',
                  activeHostId
                )
              ),
            ]}
            open={open}
          />
        </Box>

        <ChangeHost openMainMenu={open} path={path} />
      </Box>
    </Box>
  );
};

export default DrawerContent;
