import { useHeightRef } from 'hooks/useHeightRef';
import ParticipantsTable from './participantsTable';
import useFilterHook from '../filters/hooks/useFilterHook';
import { FiltersProps } from '../filters/interfaces/filtersProps';
import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { CellHeaderId } from './interfaces/participantsCellHeader';
import { ParticipantsTableProps } from './interfaces/participantsTableProps';
import { TileViewSwitcherProps } from 'components/switchTileListView/interfaces/TileViewSwitcherProps';

const ParticipantsTableContainer = ({
  tileViewSwitcherProps,
  heightOfTopComponents,
  filterProps,
  tableProps: { rows, rowsTotal, handleTableChanged: handleTablePropsChanged },
}: {
  tileViewSwitcherProps: TileViewSwitcherProps;
  heightOfTopComponents: number;
  filterProps: FiltersProps;
  tableProps: ParticipantsTableProps;
}) => {
  const [, filtersRef, filtersHeightWithPaddings] = useHeightRef();
  const [paginationHeight, paginationRef] = useHeightRef();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    order,
    orderBy,
    sortHandler,
  } = usePaginationHook<CellHeaderId>(handleTablePropsChanged);
  const useFilterHookProps = useFilterHook(filterProps);

  const heightToSkip =
    heightOfTopComponents + filtersHeightWithPaddings + paginationHeight;

  return (
    <ParticipantsTable
      filterProps={useFilterHookProps}
      filtersRef={filtersRef}
      heightToSkip={heightToSkip}
      tileViewSwitcherProps={tileViewSwitcherProps}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      paginationRef={paginationRef}
      rowsPerPage={rowsPerPage}
      tableProps={{
        rows,
        rowsTotal,
        handleTableChanged: handleTablePropsChanged,
      }}
      order={order}
      orderBy={orderBy}
      sortHandler={sortHandler}
    />
  );
};

export default ParticipantsTableContainer;
