import { useEffect, useState } from 'react';
import FileUpload from './fileUpload';
import { FileUploadContainerProps } from './interfaces/fileUploadContainerProps';


const FileUploadContainer = ({
  inputId,
  inputLabel,
  labelHeight,
  files,
  setFile,
  errorMessage,
  register,
}: FileUploadContainerProps) => {
  const [inputKey, setInputKey] = useState('');
  const [fileName, setFileName] = useState<string | undefined>();

  const handleReset = () => { setFile(undefined) };

  useEffect(() => {
    setInputKey(Math.random().toString(36).toString());

    if (files && files.length > 0) {
      setFileName(files[0].name);
    } else {
      setFileName(undefined);
    }
  }, [files]);

  return (
    <FileUpload
      register={register}
      inputKey={inputKey}
      handleReset={handleReset}
      fileName={fileName}
      inputId={inputId}
      inputLabel={inputLabel}
      labelHeight={labelHeight}
      errorMessage={errorMessage}
    />
  );
};

export default FileUploadContainer;
