import { useState } from 'react';

const useMobileFiltersHook = () => {
  const [open, setOpen] = useState(false);

  return {
    open,
    setOpen,
  };
};

export default useMobileFiltersHook;
