import usePaginationHook from 'components/responsiveListView/hooks/usePaginationHook';
import { useHeightRef } from 'hooks/useHeightRef';
import { CellHeaderId } from './interfaces/placementsCellHeader';
import { PlacementsTableProps } from './interfaces/placementsTableProps';
import PlacementsTable from './placementsTable';
import useFilterHook from '../filters/hooks/useFilterHook';
import { FiltersProps } from '../filters/interfaces/filtersProps';

const PlacementsTableContainer = ({
  heightOfTopComponents,
  tableProps: {
    rows,
    rowsTotal,
    handleTableChanged: handleTablePropsChanged,
    handlePlacementAction,
  },
  filterProps,
}: {
  heightOfTopComponents: number;
  tableProps: PlacementsTableProps;
  filterProps: FiltersProps;
}) => {
  const [filtersHeight, filtersRef] = useHeightRef();
  const [paginationHeight, paginationRef] = useHeightRef();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    sortHandler,
    order,
    orderBy,
    page,
    rowsPerPage,
  } = usePaginationHook<CellHeaderId>(handleTablePropsChanged);

  const useFilterHookProps = useFilterHook(filterProps);

  const heightToSkip = heightOfTopComponents + filtersHeight + paginationHeight;

  return (
    <PlacementsTable
      order={order}
      orderBy={orderBy}
      sortHandler={sortHandler}
      filterProps={{ ...useFilterHookProps }}
      filtersRef={filtersRef}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      heightToSkip={heightToSkip}
      page={page}
      paginationRef={paginationRef}
      rowsPerPage={rowsPerPage}
      tableProps={{
        handlePlacementAction,
        handleTableChanged: handleTablePropsChanged,
        rows,
        rowsTotal,
      }}
    />
  );
};

export default PlacementsTableContainer;
