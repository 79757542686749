import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm';
import DropdownInput from 'components/dropdownInput';
import DropdownYesNo from 'components/dropdownYesNo';
import NumberInput from 'components/numberInput';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextAreaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import translations from 'utils/translations';
import { HousingFormDetailsProps } from './interfaces/housingFormDetailsProps';
import { HousingFormInput } from '../../interfaces/housingForm.formInput';

export const HousingFormDetails = ({
  matchMd,
  matchMdToLg,
  matchMdOnly,
  housingModels,
  housingTypes,
  rentPeriodicity: rentPeriodicityOptions,
  timeUnits,
}: HousingFormDetailsProps) => {
  const theme = useTheme();

  const {
    inputStyle,
    defaultInput,
    smallInput,
    numberInput,
    yesOrNoInput,
    halfToFullPageInput,
    inputsSection,
  } = useFormInputsStyle(theme);

  const {
    title,
    housingModel,
    housingType,
    propertyName,
    contactName,
    propertyManagement,
    canBeCoEd,
    phoneNumber,
    website,
    emailForReservationEnquiries,
    description,
    addressStreet,
    addressCity,
    addressState,
    addressZipCode,
    numberOfBedsPerRoom,
    numberOfBedrooms,
    participantsPerProperty,
    participantsPerRoom,
    bathroomsPerProperty,
    beddingAndTowels,
    costOfBeddingAndTowels,
    beddingAndTowelPaymentDue,
    kitchenFacilities,
    additionalItemsMustBring,
    additionalComments,
    housingCost,
    rentPeriodicity,
    isWeeklyRentPayrollDeducted,
    includedInCost,
    additionalItemsIncludedInCost,
    administrationFee,
    administrationFeeDue,
    housingDepositAmount,
    isHousingDepositPayrollDeducted,
    housingDepositRefundable,
    furtherInformationOnHousingRefundPolicy,
    leaseRequired,
    lengthOfLease,
    timeUnit,
    furtherInformationOnLengthOfLease,
    feesAdditionalComments,
    depositRefundPolicy,
    isRentersInsuranceRequired,
    housingAmentities,
    distanceFromWorkSiteToHousing,
    transportationDetails,
  } = translations.housingForm.sections.details;

  return (
    <ConnectForm<HousingFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownInput
                  inputId={'housingModel'}
                  inputLabel={housingModel}
                  items={housingModels}
                  errorMessage={errors?.details?.housingModel?.message}
                  control={control}
                  register={register('details.housingModel')}
                />
              </Grid>

              <Grid {...inputStyle(3.5, 2.5)}>
                <DropdownInput
                  inputId={'housingType'}
                  inputLabel={housingType}
                  items={housingTypes}
                  errorMessage={errors?.details?.housingType?.message}
                  control={control}
                  register={register('details.housingType')}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'propertyName'}
                  inputLabel={propertyName}
                  numberOfRows={1}
                  register={register('details.propertyName')}
                  errorMessage={errors?.details?.propertyName?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'contactName'}
                  inputLabel={contactName}
                  numberOfRows={1}
                  register={register('details.contactName')}
                  errorMessage={errors?.details?.contactName?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'propertyManagement'}
                  inputLabel={propertyManagement}
                  numberOfRows={1}
                  register={register('details.propertyManagement')}
                  errorMessage={errors?.details?.propertyManagement?.message}
                  singleLine
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'canBeCoEd'}
                  inputLabel={canBeCoEd}
                  errorMessage={errors?.details?.canBeCoEd?.message}
                  control={control}
                  register={register('details.canBeCoEd')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(5, 3)}>
                <TextAreaInput
                  inputId={'phoneNumber'}
                  inputLabel={phoneNumber}
                  numberOfRows={1}
                  register={register('details.phoneNumber')}
                  errorMessage={errors?.details?.phoneNumber?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'website'}
                  inputLabel={website}
                  numberOfRows={1}
                  register={register('details.website')}
                  errorMessage={errors?.details?.website?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'emailForReservationEnquiries'}
                  inputLabel={emailForReservationEnquiries}
                  numberOfRows={1}
                  register={register('details.emailForReservationEnquiries')}
                  errorMessage={
                    errors?.details?.emailForReservationEnquiries?.message
                  }
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'description'}
                  inputLabel={description}
                  register={register('details.description')}
                  errorMessage={errors?.details?.description?.message}
                  maxLength={1000}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'addressStreet'}
                  inputLabel={addressStreet}
                  numberOfRows={1}
                  register={register('details.addressStreet')}
                  errorMessage={errors?.details?.addressStreet?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'addressCity'}
                  inputLabel={addressCity}
                  numberOfRows={1}
                  register={register('details.addressCity')}
                  errorMessage={errors?.details?.addressCity?.message}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'addressState'}
                  inputLabel={addressState}
                  numberOfRows={1}
                  register={register('details.addressState')}
                  errorMessage={errors?.details?.addressState?.message}
                  singleLine
                />
              </Grid>

              <Grid {...inputStyle(5, 3)}>
                <TextAreaInput
                  inputId={'addressZipCode'}
                  inputLabel={addressZipCode}
                  numberOfRows={1}
                  register={register('details.addressZipCode')}
                  errorMessage={errors?.details?.addressZipCode?.message}
                  singleLine
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={numberOfBedsPerRoom}
                  useDecimals={false}
                  errorMessage={errors?.details?.numberOfBedsPerRoom?.message}
                  control={control}
                  name='details.numberOfBedsPerRoom'
                  getValue={() => getValues('details.numberOfBedsPerRoom')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={numberOfBedrooms}
                  useDecimals={false}
                  errorMessage={errors?.details?.numberOfBedrooms?.message}
                  control={control}
                  name='details.numberOfBedrooms'
                  getValue={() => getValues('details.numberOfBedrooms')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...numberInput}>
                <NumberInput
                  label={participantsPerProperty}
                  useDecimals={false}
                  errorMessage={
                    errors?.details?.participantsPerProperty?.message
                  }
                  control={control}
                  name='details.participantsPerProperty'
                  getValue={() => getValues('details.participantsPerProperty')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={participantsPerRoom}
                  useDecimals={false}
                  errorMessage={errors?.details?.participantsPerRoom?.message}
                  control={control}
                  name='details.participantsPerRoom'
                  getValue={() => getValues('details.participantsPerRoom')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={bathroomsPerProperty}
                  useDecimals={false}
                  errorMessage={errors?.details?.bathroomsPerProperty?.message}
                  control={control}
                  name='details.bathroomsPerProperty'
                  getValue={() => getValues('details.bathroomsPerProperty')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'beddingAndTowels'}
                  inputLabel={beddingAndTowels}
                  labelHeight={46}
                  errorMessage={errors?.details?.beddingAndTowels?.message}
                  control={control}
                  register={register('details.beddingAndTowels')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={costOfBeddingAndTowels}
                  labelHeight={46}
                  useDecimals={true}
                  errorMessage={
                    errors?.details?.costOfBeddingAndTowels?.message
                  }
                  control={control}
                  name='details.costOfBeddingAndTowels'
                  getValue={() => getValues('details.costOfBeddingAndTowels')}
                />
              </Grid>

              <Grid {...numberInput}>
                <TextAreaInput
                  inputId={beddingAndTowelPaymentDue}
                  inputLabel={beddingAndTowelPaymentDue}
                  labelHeight={46}
                  errorMessage={
                    errors?.details?.beddingAndTowelPaymentDue?.message
                  }
                  register={register('details.beddingAndTowelPaymentDue')}
                  singleLine={true}
                  numberOfRows={1}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'kitchenFacilities'}
                  inputLabel={kitchenFacilities}
                  register={register('details.kitchenFacilities')}
                  errorMessage={errors?.details?.kitchenFacilities?.message}
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'additionalItemsMustBring'}
                  inputLabel={additionalItemsMustBring}
                  register={register('details.additionalItemsMustBring')}
                  errorMessage={
                    errors?.details?.additionalItemsMustBring?.message
                  }
                  maxLength={500}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'additionalComments'}
                  inputLabel={additionalComments}
                  register={register('details.additionalComments')}
                  errorMessage={errors?.details?.additionalComments?.message}
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...numberInput}>
                <NumberInput
                  label={housingCost}
                  labelHeight={46}
                  useDecimals={true}
                  errorMessage={errors?.details?.housingCost?.message}
                  control={control}
                  name='details.housingCost'
                  getValue={() => getValues('details.housingCost')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownInput
                  inputId={'rentPeriodicity'}
                  inputLabel={rentPeriodicity}
                  labelHeight={46}
                  items={rentPeriodicityOptions}
                  register={register('details.rentPeriodicity')}
                  errorMessage={errors?.details?.rentPeriodicity?.message}
                  control={control}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'isWeeklyRentPayrollDeducted'}
                  inputLabel={isWeeklyRentPayrollDeducted}
                  labelHeight={46}
                  errorMessage={
                    errors?.details?.isWeeklyRentPayrollDeducted?.message
                  }
                  register={register('details.isWeeklyRentPayrollDeducted')}
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'includedInCost'}
                  inputLabel={includedInCost}
                  register={register('details.includedInCost')}
                  errorMessage={errors?.details?.includedInCost?.message}
                  maxLength={500}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'additionalItemsIncludedInCost'}
                  inputLabel={additionalItemsIncludedInCost}
                  register={register('details.additionalItemsIncludedInCost')}
                  errorMessage={
                    errors?.details?.additionalItemsIncludedInCost?.message
                  }
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...numberInput}>
                <NumberInput
                  label={administrationFee}
                  useDecimals={true}
                  errorMessage={errors?.details?.administrationFee?.message}
                  control={control}
                  name='details.administrationFee'
                  getValue={() => getValues('details.administrationFee')}
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextAreaInput
                  inputId={'administrationFeeDue'}
                  inputLabel={administrationFeeDue}
                  register={register('details.administrationFeeDue')}
                  errorMessage={errors?.details?.administrationFeeDue?.message}
                  numberOfRows={1}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={housingDepositAmount}
                  labelHeight={46}
                  useDecimals={true}
                  errorMessage={errors?.details?.housingDepositAmount?.message}
                  control={control}
                  name='details.housingDepositAmount'
                  getValue={() => getValues('details.housingDepositAmount')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'isHousingDepositPayrollDeducted'}
                  inputLabel={isHousingDepositPayrollDeducted}
                  labelHeight={46}
                  errorMessage={
                    errors?.details?.isHousingDepositPayrollDeducted?.message
                  }
                  control={control}
                  register={register('details.isHousingDepositPayrollDeducted')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'housingDepositRefundable'}
                  inputLabel={housingDepositRefundable}
                  labelHeight={46}
                  errorMessage={
                    errors?.details?.housingDepositRefundable?.message
                  }
                  control={control}
                  register={register('details.housingDepositRefundable')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'furtherInformationOnHousingRefundPolicy'}
                  inputLabel={furtherInformationOnHousingRefundPolicy}
                  register={register(
                    'details.furtherInformationOnHousingRefundPolicy'
                  )}
                  errorMessage={
                    errors?.details?.furtherInformationOnHousingRefundPolicy
                      ?.message
                  }
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'leaseRequired'}
                  inputLabel={leaseRequired}
                  errorMessage={errors?.details?.leaseRequired?.message}
                  control={control}
                  register={register('details.leaseRequired')}
                />
              </Grid>

              <Grid {...numberInput}>
                <NumberInput
                  label={lengthOfLease}
                  labelHeight={46}
                  useDecimals={false}
                  errorMessage={errors?.details?.lengthOfLease?.message}
                  control={control}
                  name='details.lengthOfLease'
                  getValue={() => getValues('details.lengthOfLease')}
                />
              </Grid>

              <Grid {...smallInput}>
                <DropdownInput
                  inputId={'timeUnit'}
                  inputLabel={timeUnit}
                  items={timeUnits}
                  errorMessage={errors?.details?.timeUnit?.message}
                  control={control}
                  register={register('details.timeUnit')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'furtherInformationOnLengthOfLease'}
                  inputLabel={furtherInformationOnLengthOfLease}
                  register={register(
                    'details.furtherInformationOnLengthOfLease'
                  )}
                  errorMessage={
                    errors?.details?.furtherInformationOnLengthOfLease?.message
                  }
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'feesAdditionalComments'}
                  inputLabel={feesAdditionalComments}
                  register={register('details.feesAdditionalComments')}
                  errorMessage={
                    errors?.details?.feesAdditionalComments?.message
                  }
                  maxLength={1000}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'depositRefundPolicy'}
                  inputLabel={depositRefundPolicy}
                  errorMessage={errors?.details?.depositRefundPolicy?.message}
                  control={control}
                  register={register('details.depositRefundPolicy')}
                />
              </Grid>

              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={'isRentersInsuranceRequired'}
                  inputLabel={isRentersInsuranceRequired}
                  errorMessage={
                    errors?.details?.isRentersInsuranceRequired?.message
                  }
                  control={control}
                  register={register('details.isRentersInsuranceRequired')}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'housingAmentities'}
                  inputLabel={housingAmentities}
                  register={register('details.housingAmentities')}
                  errorMessage={errors?.details?.housingAmentities?.message}
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'distanceFromWorkSiteToHousing'}
                  inputLabel={distanceFromWorkSiteToHousing}
                  register={register('details.distanceFromWorkSiteToHousing')}
                  errorMessage={
                    errors?.details?.distanceFromWorkSiteToHousing?.message
                  }
                  maxLength={250}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextAreaInput
                  inputId={'transportationDetails'}
                  inputLabel={transportationDetails}
                  register={register('details.transportationDetails')}
                  errorMessage={errors?.details?.transportationDetails?.message}
                  maxLength={250}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
