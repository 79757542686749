import { Box, Button, Grid, IconButton } from '@mui/material';
import Cross from 'assets/icons/cross.svg';
import ButtonWithProgress from 'components/button/buttonWithProgress';
import MobileBottomDrawer from 'components/responsiveListView/components/mobileBottomDrawer';
import MobileFiltersBar from 'components/responsiveListView/components/mobileFilterView';
import useMobileFiltersHook from 'components/responsiveListView/hooks/useMobileFiltersHook';
import { useTranslations } from 'hooks/useTranslations';
import {
  Programs,
  Host,
  Position,
  Country,
  StartDate,
  EndDate,
  Statuses,
  ApplicantIdSearch,
  FirstNameSearch,
  LastNameSearch,
  EmailSearch,
} from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';
import SwitchTileListView from 'components/switchTileListView';
import { TileViewSwitcherProps } from 'components/switchTileListView/interfaces/TileViewSwitcherProps';

const ParticipantsMobileFilters = ({
  onSubmit,
  control,
  getValues,
  programs,
  hosts,
  positions,
  statuses,
  countries,
  clearFilters,
  register,
  tileViewSwitcherProps,
}: FiltersFormProps & { tileViewSwitcherProps: TileViewSwitcherProps }) => {
  const translations = useTranslations();
  const { open, setOpen } = useMobileFiltersHook();

  const handleClose = () => {
    setOpen(false);
  };
  const onClearFilters = () => {
    clearFilters();
    handleClose();
  };

  return (
    <>
      <MobileBottomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        header={
          <Box display='flex' justifyContent='flex-end' m={1}>
            <IconButton onClick={() => handleClose()}>
              <Box component='img' alt='Close' src={Cross} />
            </IconButton>
          </Box>
        }
        footer={
          <Box
            display='flex'
            justifyContent='space-between'
            p={2}
            sx={{ '> *': { flex: '0 0 48%' } }}
          >
            <Button variant='outlined' onClick={() => onClearFilters()}>
              {translations.applicants.actions.clearFilters}
            </Button>
            <ButtonWithProgress
              dataTestid='submit'
              text={translations.applicants.actions.applyFilters}
              type='submit'
              onClick={() => handleClose()}
            />
          </Box>
        }
      >
        <form onSubmit={onSubmit}>
          <Box
            display='flex'
            flexDirection='column'
            p={2}
            sx={{ '> *:not(:last-child)': { mb: 2 } }}
          >
            <Box>
              <Host control={control} hosts={hosts} register={register} />
            </Box>

            <Box>
              <ApplicantIdSearch control={control} register={register} />
            </Box>

            <Box>
              <FirstNameSearch control={control} register={register} />
            </Box>

            <Box>
              <LastNameSearch control={control} register={register} />
            </Box>

            <Box>
              <Programs control={control} programs={programs} />
            </Box>
            <Box>
              <Position
                control={control}
                positions={positions}
                register={register}
              />
            </Box>

            <Box>
              <Country
                control={control}
                countries={countries}
                register={register}
              />
            </Box>

            <Box>
              <StartDate getValues={getValues} control={control} />
            </Box>

            <Box>
              <EndDate getValues={getValues} control={control} />
            </Box>

            <Box>
              <Statuses control={control} statuses={statuses} />
            </Box>
          </Box>
        </form>
      </MobileBottomDrawer>
      <MobileFiltersBar
        setOpen={setOpen}
        additionalChildren={[
          <Box ml={2.5} sx={{ flex: '1 1 auto' }} key={0}>
            <EmailSearch control={control} register={register} />
          </Box>,
          <Grid
            container
            ml={2.5}
            sx={{ flex: '1 1 auto', justifyContent: 'center', width: 'auto' }}
            key={1}
          >
            <SwitchTileListView {...tileViewSwitcherProps} />
          </Grid>,
        ]}
      />
    </>
  );
};

export default ParticipantsMobileFilters;
