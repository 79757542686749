import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FiltersFormInputs } from '../interfaces/filtersFormInput';
import { FiltersFormProps } from '../interfaces/filtersFormProps';
import { FiltersProps } from '../interfaces/filtersProps';

const useFilterHook = ({
  onApplyFilter,
  filters,
}: FiltersProps): FiltersFormProps | null => {
  const [, setIsLoading] = useState(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    resetField,
    watch,
    formState: { isDirty },
  } = useForm<FiltersFormInputs>({
    defaultValues: {
      host: '',
      position: '',
      country: '',
      programs: [],
      statuses: [],
      startDate: null,
      endDate: null,
      firstName: '',
      lastName: '',
      email: '',
      applicantId: '',
    },
  });

  const startDateValue = useWatch({ control, name: 'startDate' });
  const endDateToValue = useWatch({ control, name: 'endDate' });

  useEffect(() => {
    if (startDateValue && endDateToValue && startDateValue > endDateToValue) {
      resetField('endDate', { defaultValue: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue]);

  useEffect(() => {
    if (startDateValue && endDateToValue && startDateValue > endDateToValue) {
      resetField('startDate', { defaultValue: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateToValue]);

  const applyFilter = useCallback(
    async (data?: FiltersFormInputs) => {
      setIsLoading(true);

      await onApplyFilter(data);

      setIsLoading(false);
    },
    [onApplyFilter]
  );

  const submitHandler = handleSubmit(applyFilter);

  const handleReset = async () => {
    reset();
    await applyFilter();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const subscription = watch(() => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        handleSubmit(applyFilter)();
      }, 500);
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, applyFilter]);

  if (!filters) return null;

  return {
    control,
    getValues,
    onSubmit: submitHandler,
    register,
    isDirty,
    clearFilters: handleReset,
    ...filters,
  };
};

export default useFilterHook;
