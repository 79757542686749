import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHostService } from 'hooks/services/hosts';
import { ProgramResource } from 'hooks/services/hosts/interfaces/programResource.response';
import { downloadBlob } from 'utils/file/fileUtilities';
import ProgramResources from './programResources';

const ProgramResourcesContainer = () => {
  const { hostId } = useParams();
  const { getProgramResourceFile, getProgramResources } = useHostService();
  const [rows, setRows] = useState<ProgramResource[]>([]);

  const intHost = Number(hostId);

  const clickLink = async (row: ProgramResource) => {
    if (row.type === 'download') {
      const data = await getProgramResourceFile(intHost, row.id);

      downloadBlob(data, `program_resource_${row.id}`);
    } else {
      window.open(row.link, '_blank', 'noreferrer');
    }
  };

  const fetchProgramResources = useCallback(async (hostId: number) => {
    const response = await getProgramResources(hostId);

    setRows(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProgramResources(intHost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ProgramResources rows={rows} clickLink={clickLink} />;
};

export default ProgramResourcesContainer;
