import * as yup from 'yup';
import { convertStringToBoolean } from 'utils/string/stringConverters';

export const schema = yup.object().shape(
  {
    dressCode: yup
      .string()
      .when('dressCode', {
        is: (value?: string) => !!value,
        then: (rule) => rule.min(3).max(1000),
      })
      .label('Dress Code'),
    uniformProvided: yup
      .boolean()
      .required()
      .transform(convertStringToBoolean)
      .label('Uniform Provided'),
  },
  [['dressCode', 'dressCode']]
);
