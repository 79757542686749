import { useNavigate } from 'react-router-dom';
import { useLayout } from 'hooks/useLayout';
import { useUser } from 'hooks/useUser';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import ApplicationAppBar from './appBar';
import { useAuth } from 'hooks/useAuth';
import { useLoading } from 'hooks/useLoading';

const ApplicationAppBarContainer = () => {
  const { menuOpen, setMenuOpen } = useLayout();
  const { isLoading } = useLoading();
  const navigate = useNavigate();
  const { host } = useUser();
  const { logout } = useAuth();
  const handleLogout = () => logout();

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNotificationsOpen = () => {
    if (host) {
      navigate(
        ApplicationRoutes.notifications.replace(':hostId', host.id.toString())
      );
    }
  };
  return (
    <ApplicationAppBar
      handleMenuOpen={handleMenuOpen}
      handleNotificationsOpen={handleNotificationsOpen}
      hostChannel={host?.channel.name ?? 'AAG'}
      handleLogout={handleLogout}
      navigate={navigate}
      hostId={host?.id.toString()}
      isLoading={isLoading}
    />
  );
};

export default ApplicationAppBarContainer;
