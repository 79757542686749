export function getStringValueOrEmptyString<T>(value: T | undefined): string {
  return value !== null && value !== undefined
    ? typeof value === 'string'
      ? value
      : value.toString()
    : '';
}

export function convertToNumberOrUndefined(
  value: string | undefined
): number | undefined {
  if (!value) {
    return undefined;
  }

  return Number(value);
}

export function convertToBooleanOrUndefined(
  value: string | undefined
): boolean | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  return value === 'true';
}

export function convertToBoolean(value: string | undefined): boolean {
  return convertToBooleanOrUndefined(value) ?? false;
}

export function convertStringToBoolean(value: any, originalValue: any) {
  return convertToBooleanOrUndefined(originalValue);
}

export function convertBooleanToString(value: boolean | undefined) {
  return value?.toString() ?? 'false';
}

export function convertBooleanToYesOrNo(value: boolean | undefined) {
  return value ? 'Yes' : 'No';
}

export function convertToNumber(value: string): number {
  const convertedValue = convertToNumberOrUndefined(value);
  return convertedValue ? convertedValue : 0;
}

export function convertToNullForValidation(value: string | undefined | null) {
  if (!value) {
    return null;
  }

  return value;
}
