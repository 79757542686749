import { DeclineInterviewForm } from 'pages/interviews/declineInterviewModal/interfaces/declineInterviewForm';
import { InterviewStatus } from 'pages/interviews/interfaces/interviews';
import { AddressRequest } from './interfaces/address.request';
import { AddressResponse } from './interfaces/address.response';
import { ApplicantsFiltersResponse } from './interfaces/applicantsFilters.response';
import { ApplicantsSummaryRequest } from './interfaces/applicantsSummary.request';
import { ApplicantSummaryResponse } from './interfaces/applicantSummary.response';
import { ArrivalInstructionRequest } from './interfaces/arrivalInstruction.request';
import {
  ArrivalInstructionResponse,
  ArrivalInstructionsResponse,
} from './interfaces/arrivalInstruction.response';
import { ArrivalInstructionSummaryResponse } from './interfaces/arrivalInstructionSummary.response';
import { AvailabilityRequest } from './interfaces/availability.request';
import { ScheduleResponse } from './interfaces/availability.response';
import { CompanyResponse } from './interfaces/company.response';
import { CompanyGetRequest } from './interfaces/companyGet.request';
import { CompanyHousingResponse } from './interfaces/companyHousing.response';
import { CompanyPatchRequest } from './interfaces/companyPatch.request';
import {
  ContactPagedRequest,
  ContactRequest,
} from './interfaces/contact.request';
import { ContactResponse } from './interfaces/contact.response';
import { ContactByIdRequest } from './interfaces/contactById.request';
import { ContactCreateRequest } from './interfaces/contactCreate.request';
import { ContactUpdateRequest } from './interfaces/contactUpdate.request';
import { CreateArrivalInstructionRequest } from './interfaces/createArrivalInstructions.request';
import { CreateCompanyHousingRequest } from './interfaces/createCompanyHousing.request';
import { DocumentDetailsRequest } from './interfaces/documentDetails.request';
import { DocumentDetailsResponse } from './interfaces/documentDetails.response';
import { DocumentListRequest } from './interfaces/documentList.request';
import { DocumentListResponse } from './interfaces/documentList.response';
import { DocumentWithDynamicFieldsBody } from './interfaces/documentWithDynamicFieldsBody';
import { GetParamCompanyHousingRequests } from './interfaces/GetParamCompanyHousing.request';
import { HousingRequest } from './interfaces/housing.request';
import { HousingResponse } from './interfaces/housing.response';
import { InterviewReportRequest } from './interfaces/interviewReport.request';
import { InterviewReportResponse } from './interfaces/interviewReport.response';
import { InterviewRequest } from './interfaces/interviews.request';
import { InterviewResponse } from './interfaces/interviews.response';
import { InterviewToBookRequest } from './interfaces/interviewsToBook.request';
import { InterviewToBookResponse } from './interfaces/interviewsToBook.response';
import { InterviewToBookBodyRequest } from './interfaces/interviewToBookBody.request';
import { MenuItemsResponse } from './interfaces/menu-item.response';
import { MenuRequest } from './interfaces/menu.request';
import { OfferDetails } from './interfaces/offerDetails';
import { ParticipantDocumentType } from './interfaces/participantDocumentType';
import { ParticipantsFilters } from './interfaces/participantsFilters.response';
import { ParticipantsSummaryRequest } from './interfaces/participantsSummary.request';
import {
  ApplicantInfoResponse,
  ParticipantSummaryResponse,
} from './interfaces/participantSummary.response';
import { PositionRequest } from './interfaces/position.request';
import { ProgramRequest } from './interfaces/program.request';
import { ProgramsResponse } from './interfaces/program.response';
import { ProgramResource } from './interfaces/programResource.response';
import { UpdateCompanyHousingRequest } from './interfaces/updateCompanyHousing.request';
import { UpdateCompanyHousingStatusRequest } from './interfaces/updateCompanyHousingStatus.request';
import { VisaArrivalInfoRequest } from './interfaces/visaArrivalInfo.request';
import { VisaArrivalInfoResponse } from './interfaces/visaArrivalInfo.response';
import { VisaArrivalInfoFilters } from './interfaces/visaArrivalInfoFilters.response';
import { KeyValueItemProps } from '../../../utils/interfaces/keyItemProps';
import { useFetch } from '../../useFetch';
import {
  CommonResponse,
  CommonResponseList,
} from '../commons/interfaces/commons.response';
import { PagedRequest } from '../commons/interfaces/paged.request';
import { PagedResponse } from '../commons/interfaces/paged.response';
import {
  makeDefaultPagedRequestQuery,
  makeQueryParamsFromObject,
} from '../helpers/queryParamsHelpers';
import { RecordCreatedResponse } from '../placements/interfaces/recordCreated.response';

const useHostService = () => {
  const { get, patch, post, put } = useFetch();
  const controllerName = 'hosts';
  const subControllerName = 'programs';

  const getPrograms = async ({
    hostId,
  }: ProgramRequest): Promise<ProgramsResponse> => {
    return await get<ProgramsResponse>(
      `${controllerName}/${hostId}/${subControllerName}`
    );
  };

  const getPositions = async ({
    hostId,
    programId,
  }: PositionRequest): Promise<KeyValueItemProps[]> => {
    return await get<KeyValueItemProps[]>(
      `${controllerName}/${hostId}/positions/${programId}`
    );
  };

  const getMenu = async ({
    hostId,
  }: MenuRequest): Promise<MenuItemsResponse> => {
    return await get<MenuItemsResponse>(
      `${controllerName}/${hostId}/menu-items`
    );
  };

  const getContacts = async ({
    hostId,
  }: ContactRequest): Promise<ContactResponse[]> => {
    const query = makeQueryParamsFromObject({
      pageNumber: 0,
      pageSize: 1000,
    });
    const { records } = await get<PagedResponse<ContactResponse>>(
      `${controllerName}/${hostId}/contacts${query}`
    );

    return records;
  };

  const getArrivalInstructions = async ({
    hostId,
  }: {
    hostId: number;
  }): Promise<ArrivalInstructionsResponse> => {
    const query = makeDefaultPagedRequestQuery();

    const response = await get<PagedResponse<ArrivalInstructionResponse>>(
      `${controllerName}/${hostId}/arrival-instructions${query}`
    );

    return response.records;
  };

  const getHousings = async ({
    hostId,
  }: HousingRequest): Promise<HousingResponse[]> => {
    const query = makeDefaultPagedRequestQuery();

    const response = await get<PagedResponse<HousingResponse>>(
      `${controllerName}/${hostId}/housings${query}`
    );

    return response.records;
  };

  const mapped = (response: CommonResponseList) => {
    return response.map((option: CommonResponse) => ({
      key: option.key.toString(),
      value: option.value,
    }));
  };

  const mappedContacts = (response: ContactResponse[]) => {
    return response.map((option) => ({
      key: option.id.toString(),
      value: option.name,
    }));
  };

  const getCompany = async ({
    hostId,
  }: CompanyGetRequest): Promise<CompanyResponse> => {
    return await get<CompanyResponse>(`${controllerName}/${hostId}`);
  };

  const patchCompany = async ({
    hostId,
    body,
    action,
  }: CompanyPatchRequest): Promise<void> => {
    await patch(`${controllerName}/${hostId}/${action}`, { body });
  };

  const postCreateHousing = async ({
    hostId,
    body,
  }: CreateCompanyHousingRequest): Promise<void> => {
    await post(`${controllerName}/${hostId}/housings`, { body });
  };

  const patchUpdateHousing = async ({
    hostId,
    housingId,
    body,
  }: UpdateCompanyHousingRequest): Promise<void> => {
    await patch(`${controllerName}/${hostId}/housings/${housingId}`, {
      body,
    });
  };

  const getCompanyHousingById = async ({
    hostId,
    housingId,
  }: GetParamCompanyHousingRequests) => {
    return await get<CompanyHousingResponse>(
      `${controllerName}/${hostId}/housings/${housingId}`
    );
  };

  const patchCompanyHousingUpdateStatus = async ({
    hostId,
    housingId,
    body,
  }: UpdateCompanyHousingStatusRequest): Promise<void> => {
    await patch(`${controllerName}/${hostId}/housings/${housingId}/status`, {
      body,
    });
  };

  const getDocuments = async ({ hostId }: DocumentListRequest) => {
    return await get<DocumentListResponse>(
      `${controllerName}/${hostId}/documents`
    );
  };

  const getDocumentDetails = async ({ hostId, id }: DocumentDetailsRequest) => {
    return await get<DocumentDetailsResponse>(
      `${controllerName}/${hostId}/documents/${id}`
    );
  };

  const uploadDocument = async ({
    hostId,
    id,
    formFile,
    extras,
  }: DocumentDetailsRequest & DocumentWithDynamicFieldsBody) => {
    const formData = new FormData();

    formData.append('formFile', formFile);
    formData.append('extras', JSON.stringify(extras));

    await patch(`${controllerName}/${hostId}/documents/${id}`, {
      body: formData,
    });
  };

  const getAddress = async ({ hostId }: AddressRequest) => {
    return await get<AddressResponse>(`${controllerName}/${hostId}/address`);
  };

  const getArrivalInstructionsSummaries = async (
    params: { hostId: number } & PagedRequest
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/arrival-instructions-summaries${query}`;

    return await get<PagedResponse<ArrivalInstructionSummaryResponse>>(
      resource
    );
  };

  const getArrivalInstruction = async (hostId: number, id: number) => {
    return await get<ArrivalInstructionResponse>(
      `${controllerName}/${hostId}/arrival-instructions/${id}`
    );
  };

  const createArrivalInstruction = async ({
    hostId,
    body,
  }: CreateArrivalInstructionRequest): Promise<void> => {
    await post<RecordCreatedResponse>(
      `${controllerName}/${hostId}/arrival-instructions`,
      { body }
    );
  };

  const updateArrivalInstruction = async ({
    hostId,
    id,
    ...body
  }: ArrivalInstructionRequest & { hostId: number; id: number }) => {
    return await patch(
      `${controllerName}/${hostId}/arrival-instructions/${id}`,
      {
        body,
      }
    );
  };

  const cloneArrivalInstruction = async (hostId: number, id: number) => {
    return await post<RecordCreatedResponse>(
      `${controllerName}/${hostId}/arrival-instructions/${id}/clone`
    );
  };

  const getContactById = async ({
    hostId,
    contactId,
  }: ContactByIdRequest): Promise<ContactResponse> => {
    return await get<ContactResponse>(
      `${controllerName}/${hostId}/contacts/${contactId}`
    );
  };

  const patchUpdateContactById = async ({
    hostId,
    contactId,
    body,
  }: ContactUpdateRequest): Promise<void> => {
    await patch(`${controllerName}/${hostId}/contacts/${contactId}`, {
      body,
    });
  };

  const postCreateContact = async ({
    hostId,
    body,
  }: ContactCreateRequest): Promise<void> => {
    await post(`${controllerName}/${hostId}/contacts`, {
      body,
    });
  };

  const createOfferDetails = async (
    request: OfferDetails & { hostId: number }
  ): Promise<void> => {
    const { hostId, ...body } = request;

    await post(`${controllerName}/${hostId}/offer-details`, {
      body,
    });
  };

  const getOfferDetails = async (hostId: number) => {
    return await get<OfferDetails>(`${controllerName}/${hostId}/offer-details`);
  };

  const getApplicantsFilters = async (hostId: number) => {
    return await get<ApplicantsFiltersResponse>(
      `${controllerName}/${hostId}/applicants-filters`
    );
  };

  const getApplicants = async (
    params: ApplicantsSummaryRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/applicants${query}`;

    return await get<PagedResponse<ApplicantSummaryResponse>>(resource);
  };

  const getApplicantById = async (hostId: number, id: number) => {
    const resource = `${controllerName}/${hostId}/applicants/${id}`;
    return await get<ApplicantInfoResponse>(resource);
  };

  const getParticipantsFilters = async (hostId: number) => {
    return await get<ParticipantsFilters>(
      `${controllerName}/${hostId}/participants-filters`
    );
  };

  const getParticipants = async (
    params: ParticipantsSummaryRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/participants${query}`;

    return await get<PagedResponse<ParticipantSummaryResponse>>(resource);
  };

  const getPaginatedContacts = async ({
    hostId,
    ...rest
  }: ContactPagedRequest): Promise<PagedResponse<ContactResponse>> => {
    const query = makeQueryParamsFromObject(rest);
    return await get<PagedResponse<ContactResponse>>(
      `${controllerName}/${hostId}/contacts${query}`
    );
  };

  const getVisaArrivalInfoFilters = async (hostId: number) => {
    return await get<VisaArrivalInfoFilters>(
      `${controllerName}/${hostId}/visa-and-arrival-info-filters`
    );
  };

  const getVisaArrivalInfo = async (
    params: VisaArrivalInfoRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/visa-and-arrival-info${query}`;

    return await get<PagedResponse<VisaArrivalInfoResponse>>(resource);
  };

  const getInterviewsToBook = async (
    params: InterviewToBookRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/interviews-to-book${query}`;

    return await get<PagedResponse<InterviewToBookResponse>>(resource);
  };

  const postInterviewToBook = async (
    hostId: number,
    body: InterviewToBookBodyRequest
  ): Promise<void> => {
    return await post(`${controllerName}/${hostId}/interviews-to-book`, {
      body,
    });
  };

  const patchChangeInterviewTime = async (
    hostId: number,
    applicantId: number,
    body: {
      startDate: string;
    }
  ): Promise<void> => {
    return await patch(
      `${controllerName}/${hostId}/interviews/${applicantId}/change-time`,
      {
        body,
      }
    );
  };

  const getInterviews = async (
    params: InterviewRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/interviews${query}`;

    return await get<PagedResponse<InterviewResponse>>(resource);
  };

  const updateInterview = async (
    hostId: string,
    interviewId: number,
    entity: string,
    status: InterviewStatus,
    body?: DeclineInterviewForm
  ) => {
    const resource = `${controllerName}/${hostId}/${entity}/${interviewId}/${status}`;
    return await put(resource, {
      body,
    });
  };

  const getDeclineReasons = async (
    hostId: number,
    entity: string,
    interviewId: number
  ): Promise<KeyValueItemProps[]> => {
    const resource = `${controllerName}/${hostId}/${entity}/${interviewId}/decline-reasons`;
    return await get<KeyValueItemProps[]>(resource);
  };

  const getDropdownOptions = async (
    hostId: string,
    fieldSystemName: string
  ): Promise<CommonResponseList> => {
    const resource = `${controllerName}/${hostId}/dropdown-options?fieldSystemName=${fieldSystemName}`;
    return await get<CommonResponseList>(resource);
  };

  const getAvailability = async (params: {
    timeZone?: string;
    hostId: number;
  }): Promise<ScheduleResponse> => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/interviews-availability${query}`;
    return await get<ScheduleResponse>(resource);
  };

  const getBookedSlots = async (params: {
    timeZone?: string;
    hostId: number;
  }): Promise<ScheduleResponse> => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${controllerName}/${hostId}/interviews-to-book/booked-slots${query}`;
    return await get<ScheduleResponse>(resource);
  };

  const postAvailability = async (
    hostId: number,
    body: AvailabilityRequest
  ): Promise<ScheduleResponse> => {
    return await post(`${controllerName}/${hostId}/interviews-availability`, {
      body,
    });
  };

  const getJobsOptions = async (
    hostId: number,
    participantId: number
  ): Promise<KeyValueItemProps[]> => {
    const resource = `${controllerName}/${hostId}/applicants/available-jobs?applicantId=${participantId}`;
    return await get<KeyValueItemProps[]>(resource);
  };

  const getInterviewReport = async (
    hostId: number,
    interviewId: number
  ): Promise<InterviewReportResponse> => {
    const resource = `${controllerName}/${hostId}/interviews/${interviewId}/interview-report`;
    return await get<InterviewReportResponse>(resource);
  };

  const updateInterviewReport = async (
    hostId: number,
    interviewId: number,
    body: InterviewReportRequest
  ): Promise<InterviewReportResponse> => {
    const resource = `${controllerName}/${hostId}/interviews/${interviewId}/complete-report`;
    return await put<InterviewReportResponse>(resource, { body });
  };

  const getDocumentFile = async (hostId: number, id: string) => {
    return await get<Blob>(
      `${controllerName}/${hostId}/documents/${id}/download-file`
    );
  };

  const getApplicantPdf = async (
    hostId: number,
    applicantId: number | string
  ) => {
    return await get<Blob>(
      `${controllerName}/${hostId}/applicants/${applicantId}/download-pdf`
    );
  };

  const getParticipantDocument = async (
    hostId: number,
    participantId: number | string,
    participantDocument: keyof typeof ParticipantDocumentType
  ) => {
    const query = makeQueryParamsFromObject({ participantDocument });

    return await get<Blob>(
      `${controllerName}/${hostId}/participants/${participantId}/download-document${query}`
    );
  };

  const getProgramResources = async (hostId: number) => {
    return await get<ProgramResource[]>(
      `${controllerName}/${hostId}/program-resources`
    );
  };

  const getProgramResourceFile = async (hostId: number, fileId: number) => {
    return await get<Blob>(
      `${controllerName}/${hostId}/program-resources/${fileId}/download-file`
    );
  };

  return {
    getPrograms,
    getPositions,
    getContacts,
    getArrivalInstructions,
    getHousings,
    mapped,
    mappedContacts,
    getCompany,
    patchCompany,
    postCreateHousing,
    patchUpdateHousing,
    getCompanyHousingById,
    patchCompanyHousingUpdateStatus,
    getDocuments,
    getDocumentDetails,
    uploadDocument,
    getAddress,
    getArrivalInstructionsSummaries,
    getMenu,
    getArrivalInstruction,
    createArrivalInstruction,
    cloneArrivalInstruction,
    updateArrivalInstruction,
    getContactById,
    patchUpdateContactById,
    postCreateContact,
    createOfferDetails,
    getOfferDetails,
    getApplicantsFilters,
    getApplicants,
    getParticipantsFilters,
    getParticipants,
    getPaginatedContacts,
    getVisaArrivalInfoFilters,
    getVisaArrivalInfo,
    getInterviewsToBook,
    getInterviews,
    updateInterview,
    getDropdownOptions,
    getAvailability,
    postAvailability,
    getApplicantById,
    getJobsOptions,
    postInterviewToBook,
    patchChangeInterviewTime,
    getDeclineReasons,
    getInterviewReport,
    updateInterviewReport,
    getDocumentFile,
    getApplicantPdf,
    getParticipantDocument,
    getProgramResources,
    getProgramResourceFile,
    getBookedSlots,
  };
};

export { useHostService };
