import progressBlank from 'assets/icons/progressBlank.svg';
import progressGreen from 'assets/icons/progressGreen.svg';
import progressYellow from 'assets/icons/progressYellow.svg';
import { MenuItemsResponse } from 'hooks/services/hosts/interfaces/menu-item.response';
import progressLocked from 'assets/icons/progressLocked.svg';

export type MenuId =
  | 'employer_contacts'
  | 'employer_addresses'
  | 'company_information'
  | 'employer_flight_info'
  | 'offer_details'
  | 'employer_housing'
  | 'documents'
  | 'host_resources';

export const getProgressIconSrc = (percentage?: number) => {
  if (percentage && Math.round(percentage) === 100) {
    return progressGreen;
  } else if (
    percentage &&
    Math.round(percentage) < 100 &&
    Math.round(percentage) >= 50
  ) {
    return progressYellow;
  } else {
    return progressBlank;
  }
};

export const getIconByMenuId = (
  menuId: MenuId | null,
  menuItems: MenuItemsResponse
) => {
  const menuItem = menuItems.find((item) => item.key === menuId);
  if (menuItem?.isLocked) {
    return progressLocked;
  } else {
    const percentage = menuItem?.percentage;
    const srcImg = getProgressIconSrc(percentage);
    return srcImg;
  }
};

export const getRoute = (
  menuId: MenuId | null,
  menuItems: MenuItemsResponse,
  route: string
): string => {
  const menuItem = menuItems.find((item) => item.key === menuId);

  if (menuItem === undefined || menuItem.isLocked === true) return '#';

  return route;
};
