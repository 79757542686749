import { useTheme } from '@mui/material';

export const useVerticalScrollBar = () => {
  const theme = useTheme();
  return {
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '0',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eee',
    },
    /* Styles for the horizontal scrollbar thumb (the draggable handle) */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: '5px',
    },
    /* Styles for the vertical scrollbar track */
    '&::-webkit-scrollbar-track:vertical': {
      backgroundColor: theme.palette.grey[100],
    },
    /* Styles for the vertical scrollbar thumb */
    '&::-webkit-scrollbar-thumb:vertical': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: '5px',
    },
  };
};

