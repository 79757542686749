import { Paper, TableContainer, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import { ResponsiveContainerProps } from './responsiveContainerProps';

const ResponsiveTable = ({
  heightToSkip,
  desktopTable,
  mobileTable = undefined,
}: {
  heightToSkip: number;
  desktopTable: ReactElement;
  mobileTable?: ReactElement;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <TableContainer
      component={Paper}
      sx={{ ...ResponsiveContainerProps(heightToSkip) }}
    >
      {isMobile && !!mobileTable ? mobileTable : desktopTable}
    </TableContainer>
  );
};

export default ResponsiveTable;
