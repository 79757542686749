import { createTheme, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    statusGrayed: true;
    statusPurple: true;
    statusOrange: true;
    statusGreen: true;
    statusBlue: true;
    statusRed: true;
    statusGrayedWithWhiteLabel: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    table: true;
  }
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    statusGrayed: PaletteColorOptions;
    statusPurple: PaletteColorOptions;
    statusOrange: PaletteColorOptions;
    statusGreen: PaletteColorOptions;
    statusBlue: PaletteColorOptions;
    statusRed: PaletteColorOptions;
    statusGrayedWithWhiteLabel: PaletteColorOptions;
  }

  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypographyVariants {
    table: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    table?: React.CSSProperties;
  }

  interface PaletteColor {
    lighter?: string;
    darker?: string;
    lighter2?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    lighter2?: string;
    darker?: string;
  }

  interface Theme {
    box: {
      boxShadow: string;
      boxBackground: string;
      borderColor: string;
    };
    appBar: {
      height: string;
    };
    appDrawer: {
      openWidth: string;
      closeWidth: string;
      fontSize: string;
      fontWeight: number;
    };
    header: {
      fontWeight: number;
      pageHeader: {
        fontSize: string;
        lineHeight: string;
      };
      pageHeaderSubtitle: {
        fontSize: string;
        lineHeight: string;
      };
      sectionHeader: {
        fontSize: string;
        lineHeight: string;
      };
    };
    input: {
      label: {
        fontSize: string;
        lineHeight: string;
        fontWeight: number;
      };
      control: {
        backgroundColor: string;
        fontSize: string;
        lineHeight: string;
        fontWeight: number;
      };
      validationMessage: {
        fontSize: string;
        lineHeight: string;
        fontWeight: number;
      };
    };
    tables: {
      collapsibleTable: {
        backgroundColor: string;
        fontweight: string;
        textTransform: string;
      };
      arrowDown: {
        color: string;
        '&:hover': {
          backgroundColor: string;
        };
      };
      arrowUp: {
        backgroundColor: string;
        color: string;
      };
      checkbox: {
        '&.Mui-checked': {
          color: string;
        };
        color: string;
      };
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    box: {
      boxShadow: string;
      boxBackground: string;
      borderColor: string;
    };
    appBar: {
      height: string;
    };
    appDrawer: {
      openWidth: string;
      closeWidth: string;
      fontSize: string;
      fontWeight: number;
    };
    header: {
      fontWeight: number;
      pageHeader: {
        fontSize: string;
        lineHeight: string;
      };
      pageHeaderSubtitle: {
        fontSize: string;
        lineHeight: string;
      };
      sectionHeader: {
        fontSize: string;
        lineHeight: string;
      };
    };
    input: {
      label: {
        fontSize: string;
        lineHeight: string;
        fontWeight: number;
      };
      control: {
        fontSize: string;
        lineHeight: string;
        fontWeight: number;
      };
      validationMessage: {
        fontSize: string;
        lineHeight: string;
        fontWeight: number;
      };
    };
    tables: {
      collapsibleTable: {
        backgroundColor: string;
        fontweight: string;
        textTransform: string;
      };
      arrowDown: {
        color: string;
        '&:hover': {
          backgroundColor: string;
        };
      };
      arrowUp: {
        backgroundColor: string;
        color: string;
      };
      checkbox: {
        '&.Mui-checked': {
          color: string;
        };
        color: string;
      };
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 750,
      lg: 1200,
      xl: 1640,
    },
  },
  palette: {
    primary: {
      main: '#010D53',
    },
    secondary: {
      main: '#0928E0',
      light: '#0928E005',
    },
    grey: {
      '200': '#D9D9D921',
      '300': '#BDBDBD33',
      '400': '#BDBDBD',
      '500': '#D9D9D933',
      '600': '#7B7B7B',
      '700': '#E6E6E6',
    },
    error: {
      main: '#E00909',
      light: '#E0090933',
    },
    warning: {
      main: '#FFCB11',
      light: '#FFCB1133',
      dark: '#FF9E6D',
      darker: '#FF8648',
    },
    success: {
      lighter2: '#7ACAAC',
      lighter: '#59BD97',
      main: '#00B712',
      light: '#00B71233',
    },
    text: {
      primary: '#1D2B58',
      secondary: '#7B7B7B',
    },
    statusGrayed: {
      main: '#E6E6E6',
      contrastText: '#1D2B58',
    },
    statusPurple: {
      main: '#9747FF',
      contrastText: '#FFF',
    },
    statusOrange: {
      main: '#F1B211',
      contrastText: '#FFF',
    },
    statusGreen: {
      main: '#00B712',
      contrastText: '#FFF',
    },
    statusBlue: {
      main: '#0093ED',
      contrastText: '#FFF',
    },
    statusRed: {
      main: '#EA5050',
      contrastText: '#FFF',
    },
    statusGrayedWithWhiteLabel: {
      main: '#BDBDBD',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: 'Trebuchet MS',
    h1: {
      fontSize: 48,
      color: '#1D2B58',
    },
    h2: {
      fontSize: 40,
      color: '#1D2B58',
    },
    h3: {
      fontSize: 32,
      color: '#1D2B58',
    },
    h4: {
      fontSize: 26,
      color: '#1D2B58',
    },
    subtitle1: {
      fontSize: 20,
      color: '#1D2B58',
    },
    subtitle2: {
      fontSize: 18,
      color: '#1D2B58',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    table: {
      fontSize: 12,
      color: '#1D2B58',
    },
    caption: {
      fontSize: 10,
    },
    button: {
      textTransform: 'unset',
      fontSize: 16,
    },
    fontWeightBold: 700,
    fontWeightRegular: 400,
  },
  box: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    boxBackground: '#F1F0F1',
    borderColor: '#E6E6E6',
  },
  appBar: {
    height: '80px',
  },
  appDrawer: {
    openWidth: '260px',
    closeWidth: '72px',
    fontSize: '14px',
    fontWeight: 400,
  },
  header: {
    fontWeight: 700,
    pageHeader: {
      fontSize: '26px',
      lineHeight: '30px',
    },
    pageHeaderSubtitle: {
      fontSize: '18px',
      lineHeight: '22px',
    },
    sectionHeader: {
      fontSize: '20px',
      lineHeight: '23px',
    },
  },
  input: {
    label: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 400,
    },
    control: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    validationMessage: {
      fontSize: '12px',
      lineHeight: '12px',
      fontWeight: 400,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInput-root.Mui-disabled': {
            ':before': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root.Mui-disabled:before': {
            borderBottom: 'none',
          },
        },
      },
    },

    MuiStepper: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 10,
          height: '18px',
        },
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#1D2B58',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
          fill: 'transparent',
          border: '1px solid #bdbdbd',
          borderRadius: '50%',

          '&.Mui-active': {
            fill: '#0928E0',
            border: 'none !important',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          'span > span': {
            padding: 0,
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#010D53',
          fontSize: '20px',
          '&.Mui-selected': {
            fontWeight: 700,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#F9FBFD',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid #D9D9D9',
          color: '#1D2B58',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: '#1D2B58',
        },
        stickyHeader: {
          backgroundColor: '#F9FBFD',
        },
        head: {
          fontWeight: 700,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontSize: '12px',
          color: '#1D2B58',
        },
        selectLabel: {
          fontSize: '12px',
          color: '#1D2B58',
        },
        menuItem: {
          fontSize: '12px',
          color: '#1D2B58',
        },
        displayedRows: {
          fontSize: '12px',
          color: '#1D2B58',
        },
        actions: {
          fontSize: '12px',
          color: '#1D2B58',
        },
        selectIcon: {
          color: '#1D2B58',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: '#1D2B58',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 400,
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 32px',
          borderRadius: 'unset',
          whiteSpace: 'nowrap',
        },
        sizeMedium: {
          height: 40,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          boxShadow: '0 7px 7px 0 rgb(9 40 224 / 16%)',
          borderRadius: '50%',
        },
      },
    },
  },
  tables: {
    collapsibleTable: {
      backgroundColor: 'rgba(29, 43, 88, 0.05)',
      fontweight: 'bold',
      textTransform: 'capitalize',
    },
    arrowDown: {
      color: '#00148B',
      '&:hover': {
        backgroundColor: 'rgba(29, 43, 88, 0.05)',
      },
    },
    arrowUp: {
      backgroundColor: '#00148B',
      color: '#FFF',
    },
    checkbox: {
      '&.Mui-checked': {
        color: '#00148B',
      },
      color: '#BDBDBD',
    },
  },
});

export default theme;
