export function getBoundingClientRectWithPadding(element: HTMLElement): DOMRect {
    const rect: DOMRect = element.getBoundingClientRect();
    const styles: CSSStyleDeclaration = window.getComputedStyle(element);
    const paddingTop: number = parseFloat(styles.paddingTop);
    const paddingRight: number = parseFloat(styles.paddingRight);
    const paddingBottom: number = parseFloat(styles.paddingBottom);
    const paddingLeft: number = parseFloat(styles.paddingLeft);
  
    const paddedRect: DOMRect = {
        top: rect.top - paddingTop,
        right: rect.right + paddingRight,
        bottom: rect.bottom + paddingBottom,
        left: rect.left - paddingLeft,
        width: rect.width + paddingLeft + paddingRight,
        height: rect.height + paddingTop + paddingBottom,
        x: rect.x,
        y: rect.y,
        toJSON: function () {
            throw new Error("Function not implemented.");
        }
    };
  
    return paddedRect;
  }