import { InterviewRequirements } from 'hooks/services/placements/interfaces/patchParams.request';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';
import { convertToBooleanOrUndefined } from 'utils/string/stringConverters';

export const makeInterviewsPatchRequest = (
  data: EditPlacementFormInput
): InterviewRequirements => {
  return {
    isSponsorInterviewRequired: convertToBooleanOrUndefined(data.interviews.isSponsorInterviewRequired),
    isHostCompanyInterviewRequired: convertToBooleanOrUndefined(data.interviews.isHostCompanyInterviewRequired),
    isApplicationVideoRequired: convertToBooleanOrUndefined(data.interviews.isApplicationVideoRequired),
  };
};
