import { TablePagination } from '@mui/material';

const PaginationBar = ({
  paginationRef,
  page,
  rowsPerPage,
  rowsTotal,
  handleChangePage,
  handleChangeRowsPerPage,
}: {
  page: number;
  rowsPerPage: number;
  rowsTotal: number;
  paginationRef: (node: HTMLDivElement) => void;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
}) => {
  return (
    <TablePagination
      ref={paginationRef}
      size='small'
      rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
      component='div'
      count={rowsTotal}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(_, newPage) => handleChangePage(newPage)}
      onRowsPerPageChange={(e) =>
        handleChangeRowsPerPage(parseInt(e.target.value, 10))
      }
      showFirstButton
      showLastButton
    />
  );
};

export default PaginationBar;
