import { addDays, format, isPast, startOfWeek } from 'date-fns';
import { ScheduleDate } from 'hooks/services/hosts/interfaces/availability.response';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { DateWeekDay, DateWeekDayPeriod } from '../interfaces/DateWeekDay';

export function getAllWeekdaysWithDatesByCurrentDate(
  date: Date
): DateWeekDay[] {
  const startDate = startOfWeek(date, { weekStartsOn: 0 });

  const weekdaysWithDates: DateWeekDay[] = [...Array(7)].map((_, index) => {
    const currentDate = addDays(startDate, index);
    return {
      date: format(currentDate, 'yyyy-MM-dd'),
      weekDayLabel: format(currentDate, 'EE d').toUpperCase(),
      disabled: isPast(currentDate),
    };
  });

  return weekdaysWithDates;
}

export function getCurrentWeekWithPeriods(
  date: Date,
  availableSlots: ScheduleDate[]
): DateWeekDayPeriod[] {
  const currentWeek: DateWeekDay[] = getAllWeekdaysWithDatesByCurrentDate(date);
  return currentWeek.map((item: DateWeekDay) => {
    const availableSlot: ScheduleDate = availableSlots.filter(
      (x) => x.date === item.date
    )[0];
    const periods = availableSlot ? availableSlot?.periods : [];
    return {
      ...item,
      periods,
    };
  });
}

export interface SelectedRange {
  startHour?: string;
  endHour?: string;
  id: string;
}

export const createKeyValueItems = (
  selectedRanges: SelectedRange[],
  isStartTime: boolean
): KeyValueItemProps[] => {
  const currentYear = new Date().getFullYear();
  const keyValueItems: KeyValueItemProps[] = [];

  let hour = 0;
  let minute = 0;

  for (let i = 0; i < 96; i++) {
    const isAM = hour < 12; // Check if it's AM or PM
    const formattedHour = (hour % 12 === 0 ? 12 : hour % 12)
      .toString()
      .padStart(1, '0'); // Convert to 12-hour format with leading zero
    const formattedMinute = minute.toString().padStart(2, '0');
    const period = isAM ? 'am' : 'pm';

    const time = `${formattedHour}:${formattedMinute} ${period}`;

    // Check if the current time slot falls within any of the selected ranges
    const isInSelectedRange = selectedRanges.some((range) => {
      if (range.startHour === '' || range.endHour === '') return false;
      const startTime = new Date(`${currentYear}-01-01 ${range.startHour}`);
      const endTime = new Date(`${currentYear}-01-01 ${range.endHour}`);
      const currentTime = new Date(`${currentYear}-01-01 ${time}`);

      return currentTime >= startTime && currentTime < endTime;
    });

    keyValueItems.push({
      key: time,
      value: time,
      disabled: isInSelectedRange,
    });

    minute += 15;
    if (minute >= 60) {
      minute = 0;
      hour++;
    }

    if (hour === 23 && minute === 45 && i !== 95) {
      // Add the "11:45 pm" item before the last iteration
      const prevHour = 11;
      const prevMinute = 45;
      const prevIsAM = false; // 11:45 pm is always PM
      const prevFormattedHour = prevHour.toString().padStart(1, '0');
      const prevFormattedMinute = prevMinute.toString().padStart(2, '0');
      const prevPeriod = prevIsAM ? 'am' : 'pm';
      const prevTime = `${prevFormattedHour}:${prevFormattedMinute} ${prevPeriod}`;

      keyValueItems.push({
        key: prevTime,
        value: prevTime,
        disabled: isInSelectedRange,
      });
    }
  }

  // Update the last item to be 12:00 am
  keyValueItems[keyValueItems.length - 1].key = '12:00 am';
  keyValueItems[keyValueItems.length - 1].value = '12:00 am';

  if (isStartTime) {
    keyValueItems.pop(); // Remove last item '12:00 am'
  } else {
    keyValueItems.shift(); // Remove first item '12:00 am'
  }

  return keyValueItems;
};

export function getTimeInMillis(time: string): number | null {
  const regex = /^(\d{1,2}):(\d{2}) (am|pm)$/i;
  const match = time.match(regex);

  if (match) {
    let [, hours, minutes, period] = match;
    let hoursNumber = parseInt(hours);
    const isPM = period.toLowerCase() === 'pm';

    if (isPM && hoursNumber !== 12) {
      hoursNumber += 12;
    } else if (!isPM && hoursNumber === 12) {
      hoursNumber = 0;
    }

    const date = new Date();
    date.setHours(hoursNumber, parseInt(minutes), 0, 0);
    return date.getTime();
  }

  return null; // Invalid time format
}

export function isStartTimeGreater(
  startNameValue: string,
  endNameValue: string
): boolean {
  if (startNameValue === '11:45 pm') return false;
  const startTime = getTimeInMillis(startNameValue);
  const endTime = getTimeInMillis(endNameValue);
  return startTime !== null && endTime !== null && startTime > endTime;
}

export const adjustTimeBy15Minutes = (
  time: string,
  isIncrement: boolean
): string => {
  const [hours, minutes, period] = time.split(/:| /);
  let hoursNumber = parseInt(hours);
  let minutesNumber = parseInt(minutes);

  if (isIncrement) {
    if (time === '11:45 pm') {
      return '12:00 am';
    }
    if (minutesNumber === 45) {
      hoursNumber = (hoursNumber + 1) % 12;
      minutesNumber = 0;
    } else {
      minutesNumber += 15;
    }
  } else {
    if (time === '12:00 am') {
      return '12:15 am';
    }
    if (minutesNumber === 0) {
      hoursNumber = (hoursNumber - 1 + 12) % 12;
      minutesNumber = 45;
    } else {
      minutesNumber -= 15;
    }
  }

  const formattedHours =
    hoursNumber === 0 ? 12 : hoursNumber.toString().padStart(1, '0');
  const formattedMinutes = minutesNumber.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes} ${period}`;
};
