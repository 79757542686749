export function transformStringToNumber(value: any, originalValue: any) {
  if (!!originalValue) {
    return Number(originalValue);
  }

  return 0;
}

export function transformStringToNumberOrUndefined(
  value: any,
  originalValue: any
) {
  if (!!originalValue) {
    return Number(originalValue);
  }

  return undefined;
}
