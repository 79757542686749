import { Grid, InputLabel, Typography, useTheme } from '@mui/material';
import { InputWrapperProps } from './interfaces/inputWrapperProps';

const InputWrapper = ({
  inputId,
  inputLabel,
  labelHeight,
  errorMessage,
  children,
}: InputWrapperProps) => {
  const theme = useTheme();
  return (
    <Grid>
      {inputLabel && (
        <InputLabel
          htmlFor={inputId}
          sx={{
            height: { md: labelHeight && labelHeight > 0 ? labelHeight : 46 },
            mb: 1,
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {inputLabel}
        </InputLabel>
      )}

      {children}

      {errorMessage ? (
        <Typography
          data-testid={`alert-${inputId}`}
          role='alert'
          color={theme.palette.error.main}
          sx={{
            height: 20,
            mt: 1,
            mb: {
              xs: 0,
              md: 1,
            },
            fontSize: theme.input.validationMessage.fontSize,
            lineHeight: theme.input.validationMessage.lineHeight,
            fontWeight: theme.input.validationMessage.fontWeight,
          }}
        >
          {errorMessage ? errorMessage : ''}
        </Typography>
      ) : null}
    </Grid>
  );
};

export default InputWrapper;
