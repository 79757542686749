import { UseFormSetValue } from 'react-hook-form';
import { Payment } from 'hooks/services/placements/interfaces/placement.response';
import {
  convertBooleanToString,
  getStringValueOrEmptyString,
} from 'utils/string/stringConverters';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const setPaymentValues = (
  values: Payment,
  setValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!values) {
    return;
  }

  setValue('payment.grossWage', getStringValueOrEmptyString(values.grossWage));
  setValue('payment.wagePer', getStringValueOrEmptyString(values.wagePer));
  setValue(
    'payment.averageHoursPerWeek',
    getStringValueOrEmptyString(values.averageHoursPerWeek)
  );
  setValue(
    'payment.estimatedTip',
    getStringValueOrEmptyString(values.estimatedTip)
  );
  setValue(
    'payment.paymentSchedule',
    getStringValueOrEmptyString(values.paymentSchedule)
  );
  setValue(
    'payment.allowancesBonusesIncentives',
    getStringValueOrEmptyString(values.allowancesBonusesIncentives)
  );
  setValue(
    'payment.additionalInformation',
    getStringValueOrEmptyString(values.additionalInformation)
  );
  setValue(
    'payment.isOvertimeAvailable',
    convertBooleanToString(values.isOvertimeAvailable)
  );
  setValue(
    'payment.overtimeWage',
    getStringValueOrEmptyString(values.overtimeWage)
  );
};
