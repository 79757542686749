import { Button, Grid, TablePagination } from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import { useTranslations } from 'hooks/useTranslations';
import { ContainerSpacing } from 'utils/formHeader';
import ArrivalTable from './components/arrivalTable/arrivalTable';
import { TableProps } from './components/interfaces/tableProps';

const ArrivalInstructions = ({
  tableProps,
  rowsTotal,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  buttonRef,
  headerRef,
  paginationRef,
  tableMaxHeight,
  handleAddNewArrivalInformation,
}: {
  tableProps: TableProps;
  rowsTotal: number;
  page: number;
  rowsPerPage: number;
  handleAddNewArrivalInformation: () => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  paginationRef: (node: HTMLDivElement) => void;
  headerRef: (node: HTMLDivElement) => void;
  buttonRef: (node: HTMLDivElement) => void;
  tableMaxHeight: string;
}) => {
  const translations = useTranslations();
  const containerProps = { ...ContainerSpacing, height: 'unset' };

  return (
    <Grid {...containerProps}>
      <Grid item xs={12} md={9} ref={headerRef}>
        <PageHeader>{translations.arrivalInformation.header}</PageHeader>
      </Grid>
      <Grid item xs={12} md={3} ref={buttonRef}>
        <Button
          fullWidth
          type='button'
          variant='contained'
          color='primary'
          onClick={() => handleAddNewArrivalInformation()}
        >
          {translations.arrivalInformation.addNew}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ArrivalTable {...tableProps} tableMaxHeight={tableMaxHeight} />
        <TablePagination
          ref={paginationRef}
          size='small'
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

export default ArrivalInstructions;
