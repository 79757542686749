import { Grid, useTheme } from '@mui/material';
import PrimarySubmitButton from 'components/button';
import { PageHeader } from 'components/page/header/styledHeader';
import {
  ButtonsContainer,
  ContainerSpacing,
  PrimaryButtonResponsiveMargin,
} from 'components/page/header/utils';
import Stepper from 'components/stepper';
import { useTranslations } from 'hooks/useTranslations';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import SectionContainerStyle from 'utils/styles/sectionContainerStyle/sectionContainerStyle';
import { OfferDetailsFormProps } from './interfaces/offerDetailsFormProps';

const OfferDetailsForm = ({
  pageHeaderRef,
  buttonsRef,
  stepperRef,
  matchDownMd,
  general,
  offerDetailsSections,
  sectionOfferDetailsComponents,
  onSubmit,
  isDisabled,
}: OfferDetailsFormProps) => {
  const theme = useTheme();
  const translations = useTranslations();
  const { titleCreate, title } = translations.offerDetailsForm;

  return (
    <form onSubmit={onSubmit} style={{ height: '100%', overflow: 'hidden' }}>
      <Grid {...ContainerSpacing}>
        <Grid item xs={12} md={4} order={1} pb={{ md: 4 }} ref={pageHeaderRef}>
          <PageHeader> {isDisabled ? title : titleCreate}</PageHeader>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 3, md: 2 }}
          pt={{ xs: 0 }}
          ref={buttonsRef}
          pb={4}
        >
          {!isDisabled && (
            <Grid {...ButtonsContainer}>
              <Grid
                sx={{
                  width: { xs: '100%', md: 'unset' },
                  ...PrimaryButtonResponsiveMargin,
                }}
              >
                <PrimarySubmitButton
                  dataTestid='save'
                  isLoading={false}
                  text={translations.saveButton}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          order={{ xs: 2, md: 3 }}
          ref={stepperRef}
          sx={{
            height: { md: `calc(100% - ${theme.spacing(4)} - 15px)` },
            overflowY: { md: 'auto' },
            paddingRight: theme.spacing(2),
            '&.MuiGrid-item': {
              paddingTop: 0,
            },
            ...NoScrollBar,
          }}
        >
          <Stepper steps={offerDetailsSections} />
        </Grid>

        <Grid
          item
          order={4}
          xs={12}
          md={10}
          px={0.3}
          sx={{
            overflowY: 'hidden',
            height: matchDownMd
              ? `calc(100% - ${general}px)`
              : `calc(100% - ${theme.spacing(9)})`,
            '&.MuiGrid-item': {
              paddingTop: '0 ',
            },
          }}
        >
          <Grid id='containerElement' sx={{ ...SectionContainerStyle }}>
            {sectionOfferDetailsComponents}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default OfferDetailsForm;
