import { useState } from 'react';
import { PagedRequest } from 'hooks/services/commons/interfaces/paged.request';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';

const usePaginationHook = <T extends string>(
  handleTablePropsChanged: (pagedRequest: PagedRequest) => void,
  initialOrder?: SortOrder,
  initialOrderBy?: T
) => {
  const [order, setOrder] = useState<SortOrder>(initialOrder ?? 'asc');
  const [orderBy, setOrderBy] = useState<T | undefined>(initialOrderBy);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  console.log(order)
  console.log(orderBy)
  const sortHandler = (property: T) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    handleTablePropsChanged({
      pageNumber: page,
      pageSize: rowsPerPage,
      sortDirection: newOrder,
      sortField: property,
    });
  };

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);

    handleTablePropsChanged({
      pageNumber,
      pageSize: rowsPerPage,
      sortDirection: order,
      sortField: orderBy,
    });
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    const pageNumber = 0;

    setRowsPerPage(pageSize);
    setPage(pageNumber);

    handleTablePropsChanged({
      pageNumber,
      pageSize,
      sortDirection: order,
      sortField: orderBy,
    });
  };

  return {
    order,
    orderBy,
    rowsPerPage,
    page,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

export default usePaginationHook;
