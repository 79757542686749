import { useTheme } from '@mui/material';

export const useHorizontalScrollBar = () => {
  const theme = useTheme();
  return {
    '&::-webkit-scrollbar': {
      width: '0',
      height: '10px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eee',
    },
    /* Styles for the vertical scrollbar thumb (the draggable handle) */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: '5px',
    },
    /* Styles for the horizontal scrollbar track */
    '&::-webkit-scrollbar-track:horizontal': {
      backgroundColor: theme.palette.grey[100],
    },
    /* Styles for the horizontal scrollbar thumb */
    '&::-webkit-scrollbar-thumb:horizontal': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: '5px',
    },
  };
};
