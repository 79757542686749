import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { CellHeaderId } from 'pages/participantsVisa/visaArrivalInfo/components/visaArrivalInfoTable/interfaces/visaArrivalInfoCellHeader';

export const getCells = ({
  applicantFirstName,
  applicantLastName,
  program,
  position,
  hostEntity,
  applicantId,
  email,
  dateOfBirth,
  gender,
  statusCode,
  countryOfResidence,
  jobId,
  programStartDate,
  programEndDate,
  embassyDate,
  visaApproved,
  embassy,
  visaExpirationDate,
  arrivalDate,
  arrivalTime,
  arrivalAirport,
  airline,
  flightNumber,
  additionalInfo,
}: {
  applicantId: string;
  applicantFirstName: string;
  applicantLastName: string;
  program: string;
  position: string;
  jobId: string;
  programStartDate: string;
  programEndDate: string;
  hostEntity: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  statusCode: string;
  countryOfResidence: string;
  embassyDate: string;
  visaApproved: string;
  embassy: string;
  visaExpirationDate: string;
  arrivalDate: string;
  arrivalTime: string;
  arrivalAirport: string;
  airline: string;
  flightNumber: string;
  additionalInfo: string;
}): CellHeader<CellHeaderId>[] => {
  return [
    {
      id: 'hostEntity',
      label: hostEntity,
      sortable: true,
    },
    {
      id: 'program',
      label: program,
      sortable: true,
    },
    {
      id: 'applicantId',
      label: applicantId,
      sortable: true,
    },
    {
      id: 'applicantFirstName',
      label: applicantFirstName,
      sortable: true,
    },
    {
      id: 'applicantLastName',
      label: applicantLastName,
      sortable: true,
    },
    {
      id: 'email',
      label: email,
      sortable: true,
    },
    {
      id: 'gender',
      label: gender,
      sortable: true,
    },
    {
      id: 'dateOfBirth',
      label: dateOfBirth,
      sortable: true,
    },
    {
      id: 'statusCode',
      label: statusCode,
      sortable: true,
    },
    {
      id: 'countryOfResidence',
      label: countryOfResidence,
      sortable: true,
    },
    {
      id: 'jobId',
      label: jobId,
      sortable: true,
    },
    {
      id: 'position',
      label: position,
      sortable: true,
    },
    {
      id: 'startsOn',
      label: programStartDate,
      sortable: true,
    },
    {
      id: 'endsOn',
      label: programEndDate,
      sortable: true,
    },
    {
      id: 'embassyDate',
      label: embassyDate,
      sortable: true,
    },
    {
      id: 'visaApproved',
      label: visaApproved,
      sortable: true,
    },
    {
      id: 'embassy',
      label: embassy,
      sortable: true,
    },
    {
      id: 'visaExpirationDate',
      label: visaExpirationDate,
      sortable: true,
    },
    {
      id: 'arrivalDate',
      label: arrivalDate,
      sortable: true,
    },
    {
      id: 'arrivalTime',
      label: arrivalTime,
      sortable: true,
    },
    {
      id: 'arrivalAirport',
      label: arrivalAirport,
      sortable: true,
    },
    {
      id: 'airline',
      label: airline,
      sortable: true,
    },
    {
      id: 'flightNumber',
      label: flightNumber,
      sortable: true,
    },
    {
      id: 'additionalInfo',
      label: additionalInfo,
      sortable: false,
    },
  ];
};
