import { addDays } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonService } from 'hooks/services/commons';
import { useHostService } from 'hooks/services/hosts';
import { InterviewToBookBodyRequest } from 'hooks/services/hosts/interfaces/interviewToBookBody.request';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { useAlert } from 'providers/alertProvider';
import {
  convertDateTimeUserTimezoneToUTC,
  parseStringWeekDayMMDD,
} from 'utils/date/convertToDate';
import { rubyToMomentMapping, rubyToWindows } from 'utils/date/timezones';
import { Nullable } from 'utils/interfaces';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { InterviewProps } from './interfaces/interviewProps';
import { ScheduleFormData } from './interfaces/scheduleFormData';
import { Slot, SlotsToShow } from './interfaces/slotsToShow';
import ScheduleInterview from './scheduleInterview';
import { getTimeSlots } from './utils';
import { schema } from './validation/schema.validation';

const ScheduleInterviewContainer = (props: InterviewProps) => {
  const { getPlatformTypesOptions } = useCommonService();
  const [platformTypes, setPlatformTypes] = useState<KeyValueItemProps[]>([]);
  const translations = useTranslations();
  const alert = useAlert();
  const {
    isJobsFieldShow,
    applicantId,
    interviewId,
    fetchData,
    actionType = 'book',
    ...rest
  } = props;

  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const {
    getBookedSlots,
    getJobsOptions,
    postInterviewToBook,
    patchChangeInterviewTime,
  } = useHostService();

  const { host, user } = useUser();
  const isEmployer = host?.type.name === 'Employer';
  const timeZoneWindows = rubyToWindows.get(user?.timeZone ?? '') ?? '';
  const timeZoneMoment = rubyToMomentMapping.get(user?.timeZone ?? '') ?? '';
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<KeyValueItemProps[]>([]);
  const [bookedSlots, setBookedSlots] = useState<Map<string, Set<string>>>();
  const [calendarValue, setCalendarValue] = useState<Nullable<Date>>(
    new Date()
  );
  const [slotsToShow, setSlotsToShow] = useState<Nullable<SlotsToShow>>(null);

  const methods = useForm<ScheduleFormData>({
    defaultValues: {
      date: null,
      time: '',
      jobId: '',
    },
    resolver: isJobsFieldShow && isEmployer ? yupResolver(schema) : undefined,
  });

  const { setValue, handleSubmit, control, reset } = methods;

  const timeValue = useWatch({
    control,
    name: 'time',
  });

  const interviewTypeValue = useWatch({
    control,
    name: 'interviewType',
  });

  const jobIdValue = useWatch({
    control,
    name: 'jobId',
  });
  
  const minEnabledDate = addDays(new Date(), 2);

  const fetchPlatformTypes = useCallback(async () => {
    try {
      const response = await getPlatformTypesOptions();
      setPlatformTypes(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchJobs = useCallback(async () => {
    try {
      const response = await getJobsOptions(parsedHostId, applicantId);
      setJobs(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchHost = useCallback(async () => {
    try {
      const response = await getJobsOptions(parsedHostId, applicantId);
      setJobs(response);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBookedSlots = useCallback(async () => {
    try {
      const response = await getBookedSlots({
        hostId: parsedHostId,
        timeZone: timeZoneWindows,
      });

      const data = response?.dates.reduce((acc, curr) => {
        const key = parseStringWeekDayMMDD(curr.date);
        const value = new Set(getTimeSlots(curr.periods));

        acc.set(key, value);

        return acc;
      }, new Map<string, Set<string>>());
      setBookedSlots(data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCalendar = (newValue: Nullable<Date>) => {
    if (newValue) {
      const date = parseStringWeekDayMMDD(newValue.toISOString());
      const dateSlots = getTimeSlots([
        {
          startHour: '12:00 am',
          endHour: '11:59 pm',
        },
      ]);

      const slotsToDisable = bookedSlots?.get(date);

      const slots: Slot[] = dateSlots.map((value) => {
        const disabled = slotsToDisable?.has(value) ?? false;

        return {
          value,
          disabled,
        };
      });

      const obj: SlotsToShow = {
        date,
        slots,
      };

      setSlotsToShow(obj);
      setCalendarValue(newValue);
      setValue('date', newValue);
    }
  };

  const submitHandler = async (data: ScheduleFormData) => {
    if (data.date && data.time) {
      setIsLoading(true);
      try {
        if (actionType === 'change' && interviewId) {
          await patchChangeInterviewTime(parsedHostId, interviewId, {
            startDate: convertDateTimeUserTimezoneToUTC(
              data.date,
              data.time,
              timeZoneMoment
            ),
          });
        } else {
          const body: InterviewToBookBodyRequest = {
            applicantId,
            jobId: data.jobId ? parseInt(data.jobId) : undefined,
            interviewType: data.interviewType,
            otherPlatformLink: data.otherPlatformLink,
            startsAt: convertDateTimeUserTimezoneToUTC(
              data.date,
              data.time,
              timeZoneMoment
            ),
          };
          await postInterviewToBook(parsedHostId, body);
        }

        fetchData();

        alert.success(translations.alerts.interviews.interviewBooked);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        props.onClose();
      }
    }
  };
  const onSave = handleSubmit(submitHandler);

  useEffect(() => {
    if (props.open) {
      fetchJobs();
      fetchPlatformTypes();
      fetchBookedSlots();
    } else {
      reset();
      setSlotsToShow(null);
      setCalendarValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <FormProvider {...methods}>
      <ScheduleInterview
        {...{
          jobs,
          platformTypes,
          calendarValue,
          onChangeCalendar,
          slotsToShow,
          disabledSubmitBtn: !timeValue || !interviewTypeValue || (!jobIdValue && isJobsFieldShow),
          onSave,
          isLoading,
          isJobsFieldShow,
          minDate: minEnabledDate,
          disablePast: true,
          ...rest,
        }}
      />
    </FormProvider>
  );
};

export default ScheduleInterviewContainer;
