import { Grid } from '@mui/material';
import { PageHeader } from 'components/page/header/styledHeader';
import ResponsiveTable from 'components/responsiveListView/components/responsiveTable';
import SimpleTable from 'components/tables/simpleTable';
import { useHeightRef } from 'hooks/useHeightRef';
import { ContainerSpacing } from 'utils/formHeader';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import MobileViewItem from './components/mobileViewItem';
import { DocumentListProps } from './interfaces/documentListProps';

const containerProps = { ...ContainerSpacing, height: 'unset' };

const DocumentList = ({
  rows,
  headers,
  title,
  mobileRows,
}: DocumentListProps) => {
  const [, pageHeaderRef, heightWithPaddings] = useHeightRef();
  const heightToSkip = heightWithPaddings + 30;

  return (
    <Grid
      {...containerProps}
      sx={{
        overflow: 'auto',
        ...NoScrollBar,
      }}
    >
      <Grid item xs={12} ref={pageHeaderRef}>
        <PageHeader>{title}</PageHeader>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveTable
          desktopTable={
            <SimpleTable headers={headers} rows={rows} align='left' />
          }
          heightToSkip={heightToSkip}
          mobileTable={
            <>
              {mobileRows.map((item) => (
                <MobileViewItem key={item.type} {...item} />
              ))}
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default DocumentList;
