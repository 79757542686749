import { Grid, Typography, useTheme } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ParticipantDocumentType } from 'hooks/services/hosts/interfaces/participantDocumentType';

const ResourcesItem = ({
  id,
  value,
  disabled,
  downloadDocument
}: {
  id: keyof typeof ParticipantDocumentType;
  value: string;
  disabled: boolean;
  downloadDocument: (key: keyof typeof ParticipantDocumentType) => void;
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      container
      xs={12}
      alignItems={'center'}
      gap={2}
      sx={{
        cursor: !disabled ? 'pointer' : undefined
      }}
      onClick={() => {
        if (disabled) return;
        downloadDocument(id);
      }}
    >
      <CloudDownloadIcon
        color='secondary'
        sx={{ color: !disabled ? 'secondary' : theme.palette.grey['400'] }}
      />
      <Typography
        variant='body2'
        color={!disabled ? 'secondary' : theme.palette.grey['400']}
        fontWeight={700}
      >
        {value}
      </Typography>
    </Grid>
  );
};

export default ResourcesItem;
