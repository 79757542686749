import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  color: theme.typography.h3.color,
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));
