import { Grid, Typography, useTheme } from '@mui/material';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import HeaderFooterModal from 'components/modal/headerFooterModal';
import ModalHeader from 'components/modal/modalHeader';
import { useTranslations } from 'hooks/useTranslations';
import { useFormContext } from 'react-hook-form';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { DeclineInterviewForm } from './interfaces/declineInterviewForm';

const DeclineInterviewModal = ({
  modalOpen,
  handleCloseModal,
  onSave,
  participantName,
  jobPosition,
  reasons,
  isLoading,
}: {
  modalOpen: boolean;
  handleCloseModal: () => void;
  onSave: () => void;
  participantName: string;
  jobPosition: string;
  reasons: KeyValueItemProps[];
  isLoading: boolean;
}) => {
  const theme = useTheme();
  const { allInterviews, modal } = useTranslations();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<DeclineInterviewForm>();

  return (
    <HeaderFooterModal
      open={modalOpen}
      onClose={handleCloseModal}
      saveBtnText={modal.buttons.submit}
      cancelBtnText={modal.buttons.no}
      onSave={onSave}
      sx={{
        width: { xs: `calc(100% - ${theme.spacing(4)})`, md: 600 },
      }}
      isLoading={isLoading}
      scrollableContent={false}
    >
      <Grid container sx={{ p: theme.spacing(2) }}>
        <Grid item xs={12}>
          <ModalHeader title={allInterviews.actions.decline} />
        </Grid>
        <Grid container gap={3} sx={{ py: theme.spacing(2) }}>
          <Grid item xs={12}>
            <Typography variant='body2'>
              {allInterviews.modal.sorryToDecline}
            </Typography>
          </Grid>
          <Grid item xs={12} gap={2}>
            <Grid container item xs={12} gap={2}>
              <Typography variant='body1' fontWeight={700}>
                {allInterviews.modal.participantNameLabel}
              </Typography>
              <Typography variant='body1'>{participantName}</Typography>
            </Grid>
            <Grid container item xs={12} gap={2}>
              <Typography variant='body1' fontWeight={700}>
                {allInterviews.modal.jobPositionLabel}
              </Typography>
              <Typography variant='body1'>{jobPosition}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <DropdownInput
              inputId={'interview.reason'}
              labelHeight={20}
              inputLabel={allInterviews.modal.selectDeclineReason}
              items={reasons}
              errorMessage={errors?.interview?.reason?.message as string}
              control={control}
              register={register('interview.reason')}
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderFooterModal>
  );
};

export default DeclineInterviewModal;
