import { TextField, Typography, useTheme } from '@mui/material';
import { FieldValues, Path, useWatch } from 'react-hook-form';
import InputWrapper from 'components/inputWrapper/inputWrapper';
import { TextareaInputProps } from './interfaces/textareaInputProps';

function TextareaInput<TFieldValues extends FieldValues>({
  inputId,
  inputLabel,
  labelHeight,
  numberOfRows,
  register,
  errorMessage,
  singleLine,
  control,
  maxLength,
  disabled = false,
}: TextareaInputProps<TFieldValues>) {
  const theme = useTheme();
  const rowsNumber = numberOfRows ?? 6;
  const { name } = register;

  const value = useWatch({
    control,
    name: name as Path<TFieldValues>,
  });

  return (
    <>
      <InputWrapper
        inputId={inputId}
        inputLabel={inputLabel}
        labelHeight={labelHeight}
        errorMessage={errorMessage}
      >
        <TextField
          multiline={!singleLine}
          fullWidth
          variant='standard'
          id={inputId}
          sx={{
            boxShadow: 3,
            '& .MuiInputBase-root': {
              padding: theme.spacing(2),
              height: singleLine ? 52 : 'unset',
            },
            '& .MuiInputBase-root:before': {
              borderBottom: 'none',
            },
          }}
          minRows={rowsNumber}
          maxRows={rowsNumber}
          inputProps={{
            maxLength: maxLength && maxLength + 1,
            'data-testid': inputId,
          }}
          {...register}
          error={!!errorMessage}
          autoComplete='off'
          disabled={disabled}
        />
      </InputWrapper>
      {maxLength && (
        <Typography
          data-testid='char-amount'
          variant='body2'
          sx={{
            pt: 1,
            textAlign: 'right',
          }}
        >
          {value ? value.length : '0'}/{maxLength}
        </Typography>
      )}
    </>
  );
}

export default TextareaInput;
