import { Box, Grid, useTheme } from '@mui/material';
import logo from 'assets/logo/alliance-strategies.png';
import { Subtitle } from '../subtitle/subtitle';
import { Title } from '../title/title';

const Header = ({ title, subtitle }: { title: string; subtitle: string }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        component='img'
        sx={{
          marginBottom: { md: 2 },
          width: '250px'
        }}
        alt='Alliance-Strategies-logo'
        src={logo}
      />

      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: {
            xl: theme.spacing(2, 0),
          },
        }}
      >
        <Title>Alliance Strategies</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Grid>
    </>
  );
};

export default Header;
