
import {
  convertToBoolean,
  convertToNumber,
} from 'utils/string/stringConverters';

import { OfferDetailsFormInput, OfferDetailsGroomingFormInput, OfferDetailsHousingFormInput, OfferDetailsScreeningFormInput, OfferDetailsSocialSecurityFormInput } from '../interfaces/offerDetailsForm.formInput';
import { Grooming, Housing, OfferDetails, Screening, SocialSecurity } from 'hooks/services/hosts/interfaces/offerDetails';

export const makeRequest = (
  data: OfferDetailsFormInput
): OfferDetails => {
  const {screening, grooming, housing, socialSecurity} = data;
  return {
    isLocked: true,
    screening: makeScreeningRequest(screening),
    grooming: makeGroomingRequest(grooming),
    housing: makeHousingRequest(housing),
    socialSecurity: makeSocialSecurityRequest(socialSecurity)
  };
};

const makeScreeningRequest = (
  data: OfferDetailsScreeningFormInput
): Screening => {
  const {
    willEVIncurCostForScreening,
    willCompanyRequireDrugTest,
    drugScreeningPolicy,
    isDrugTestProvidedByHostCompany,
    drugScreeningCostToStudent,
    drugScreeningCostDue,
  } = data;

  return {
    willEVIncurCostForScreening: convertToBoolean(willEVIncurCostForScreening),
    willCompanyRequireDrugTest: convertToBoolean(willCompanyRequireDrugTest),
    drugScreeningPolicy,
    isDrugTestProvidedByHostCompany: convertToBoolean(isDrugTestProvidedByHostCompany),
    drugScreeningCostToStudent: convertToNumber(drugScreeningCostToStudent),
    drugScreeningCostDue: convertToNumber(drugScreeningCostDue)
  };
};

const makeGroomingRequest = (
  data: OfferDetailsGroomingFormInput
): Grooming => {
  const {
    groomingStandards
  } = data;

  return {
    groomingStandards
  };
};

const makeHousingRequest = (
  data: OfferDetailsHousingFormInput
): Housing => {
  const {
    doesHostEntityProvideTemporaryHousing,
    durationOfTemporaryHousing,
    costOfTemporaryHousing
  } = data;

  return {
    doesHostEntityProvideTemporaryHousing: convertToBoolean(doesHostEntityProvideTemporaryHousing),
    durationOfTemporaryHousing,
    costOfTemporaryHousing: convertToNumber(costOfTemporaryHousing)
  };
};

const makeSocialSecurityRequest = (
  data: OfferDetailsSocialSecurityFormInput
): SocialSecurity => {
  const {
    addressOfNearestSocialSecurityOffice,
    distanceOfSSOFromSOA,
    willHostEntityProvideTransportationToTheNearestSocialSecurityOffice,
    willHostEntityHelpEVMakeCopiesOfNecessaryDocuments,
    otherSocialSecurityAssistanceProvided,
  } = data;

  return {
    addressOfNearestSocialSecurityOffice,
    distanceOfSSOFromSOA,
    willHostEntityProvideTransportationToTheNearestSocialSecurityOffice: convertToBoolean(willHostEntityProvideTransportationToTheNearestSocialSecurityOffice),
    willHostEntityHelpEVMakeCopiesOfNecessaryDocuments: convertToBoolean(willHostEntityHelpEVMakeCopiesOfNecessaryDocuments),
    otherSocialSecurityAssistanceProvided
  };
};


