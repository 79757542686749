import { useMediaQuery, useTheme } from '@mui/material';
import { useHeightRef } from 'hooks/useHeightRef';
import { ScrollableContentContainerProps } from './interfaces/scrollableContentContainerProps';
import ScrollableContent from './scrollableContent';

const ScrollableContentContainer = (props: ScrollableContentContainerProps) => {
  const theme = useTheme();
  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();
  const general = pageHeaderHeight + buttonsHeight;
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  return (
    <ScrollableContent
      pageHeaderRef={pageHeaderRef}
      buttonsRef={buttonsRef}
      general={general}
      matchDownMd={matchDownMd}
      {...props}
    />
  );
};

export default ScrollableContentContainer;
