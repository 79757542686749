import { Grid, useTheme } from '@mui/material';
import { PageHeader, PageHeaderSubtitle } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import NoScrollBar from 'utils/interfaces/noScrollBar';
import { CommonAddressForm } from './commonAddressForm/commonAddressForm';
import { AddressFormProps } from './interfaces/addressFormProps';

export const AddressForm = ({
  commonLabes,
  physicalAddress,
  mailingAddress,
  title,
  subtitle,
}: AddressFormProps) => {
  const theme = useTheme();
  const { body1 } = theme.typography;

  return (
    <Grid
      {...ContainerSpacing}
      sx={{
        height: '100%',
        overflow: 'auto',
        ...NoScrollBar,
      }}
    >
      <Grid item xs={12} mb={1}>
        <PageHeader>{title}</PageHeader>
        <PageHeaderSubtitle sx={body1}>{subtitle}</PageHeaderSubtitle>
      </Grid>

      <Grid item xs={12} mb={4}>
        <CommonAddressForm
          labels={{ ...{ title: physicalAddress.title, ...commonLabes } }}
          formAddressType='physical'
        />
      </Grid>
      <Grid item xs={12} mb={4}>
        <CommonAddressForm
          labels={{ ...{ title: mailingAddress.title, ...commonLabes } }}
          formAddressType='mailing'
        />
      </Grid>
    </Grid>
  );
};
