import { TableCell, TableRow } from '@mui/material';
import { Fragment } from 'react';
import { CellType } from '../../interfaces/cellType';

export const Row = ({
  rows,
  align,
}: {
  rows: CellType[];
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
}) => {
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {rows &&
          rows.map((value, index) => (
            <TableCell key={index} align={align}>
              {value}
            </TableCell>
          ))}
      </TableRow>
    </Fragment>
  );
};
