export enum DynamicFieldType {
  Number = 'Number',
  Float = 'Float',
  String = 'String',
  TextArea = 'TextArea',
  Date = 'Date',
  YesNo = 'YesNo',
  Dropdown = 'Dropdown',
  MultiselectDropdown = 'MultiselectDropdown',
}
