import { Box, Button, IconButton } from '@mui/material';
import Cross from 'assets/icons/cross.svg';
import ButtonWithProgress from 'components/button/buttonWithProgress';
import MobileBottomDrawer from 'components/responsiveListView/components/mobileBottomDrawer';
import MobileFiltersBar from 'components/responsiveListView/components/mobileFilterView';
import useMobileFiltersHook from 'components/responsiveListView/hooks/useMobileFiltersHook';
import { useTranslations } from 'hooks/useTranslations';
import {
  Programs,
  Host,
  PositionSearch,
  EmbassyDate,
  ArrivalDate,
  ApplicantIdSearch,
  EmailSearch,
  PlacementIdSearch,
  ArrivalAirportSearch,
  AirlineSearch,
  FlightNumberSearch,
} from './availableFilters';
import { FiltersFormProps } from './interfaces/filtersFormProps';

const VisaArrivalInfoMobileFilters = ({
  onSubmit,
  control,
  getValues,
  programs,
  hostEntities,
  clearFilters,
  register,
}: FiltersFormProps) => {
  const translations = useTranslations();
  const { open, setOpen } = useMobileFiltersHook();

  const handleClose = () => {
    setOpen(false);
  };
  const onClearFilters = () => {
    clearFilters();
    handleClose();
  };

  return (
    <>
      <MobileBottomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        header={
          <Box display='flex' justifyContent='flex-end' m={1}>
            <IconButton onClick={() => handleClose()}>
              <Box component='img' alt='Close' src={Cross} />
            </IconButton>
          </Box>
        }
        footer={
          <Box
            display='flex'
            justifyContent='space-between'
            p={2}
            sx={{ '> *': { flex: '0 0 48%' } }}
          >
            <Button variant='outlined' onClick={() => onClearFilters()}>
              {translations.applicants.actions.clearFilters}
            </Button>
            <ButtonWithProgress
              dataTestid='submit'
              text={translations.applicants.actions.applyFilters}
              type='submit'
              onClick={() => handleClose()}
            />
          </Box>
        }
      >
        <form onSubmit={onSubmit}>
          <Box
            display='flex'
            flexDirection='column'
            p={2}
            sx={{ '> *:not(:last-child)': { mb: 2 } }}
          >
            <Box>
              <Host
                control={control}
                hostEntities={hostEntities}
                register={register}
              />
            </Box>

            <Box>
              <ApplicantIdSearch control={control} register={register} />
            </Box>

            <Box>
              <Programs control={control} programs={programs} />
            </Box>

            <Box>
              <PlacementIdSearch control={control} register={register} />
            </Box>

            <Box>
              <PositionSearch control={control} register={register} />
            </Box>

            <Box>
              <ArrivalAirportSearch control={control} register={register} />
            </Box>

            <Box>
              <AirlineSearch control={control} register={register} />
            </Box>

            <Box>
              <FlightNumberSearch control={control} register={register} />
            </Box>

            <Box>
              <EmbassyDate getValues={getValues} control={control} />
            </Box>

            <Box>
              <ArrivalDate getValues={getValues} control={control} />
            </Box>
          </Box>
        </form>
      </MobileBottomDrawer>
      <MobileFiltersBar
        setOpen={setOpen}
        additionalChildren={[
          <Box ml={2.5} sx={{ flex: '1 1 auto' }} key={0}>
            <EmailSearch control={control} register={register} />
          </Box>,
        ]}
      />
    </>
  );
};

export default VisaArrivalInfoMobileFilters;
