import { Grid, Link, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const LoginLink = ({
  link,
  text,
  onClick,
}: {
  link: string;
  text: string;
  onClick?: () => void;
}) => {
  const theme = useTheme();

  return (
    <Grid
      item
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: theme.spacing(10),
      }}
    >
      <Link component={RouterLink} to={link} onClick={onClick}>
        <Typography variant='body1'>{text}</Typography>
      </Link>
    </Grid>
  );
};

export default LoginLink;
