import * as yup from 'yup';
import { transformDate } from 'utils/date/convertToDate';
import { transformStringToNumberOrUndefined } from 'utils/extensions/numberInputTransformer';
import {
  convertToBooleanOrUndefined,
  convertToNullForValidation,
} from 'utils/string/stringConverters';

export const schema = yup.object().shape({
  videoEmbed: yup.string().max(100).nullable().label('Video embed code - website'),
  sector: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .label('Sector'),
  industry: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(30)
    .min(4)
    .label('Industry'),
  orientationRequired: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label('Orientation Required'),
  yearEstablished: yup
    .number()
    .nullable()
    .transform(transformStringToNumberOrUndefined)
    .min(1000)
    .max(9999)
    .label('Year Established'),
  totalCurrentEmployees: yup
    .number()
    .nullable()
    .transform(transformStringToNumberOrUndefined)
    .min(1)
    .max(6)
    .label('Number of Employees (not including EV)'),
  totalExchangeVisitors: yup
    .number()
    .nullable()
    .transform(transformStringToNumberOrUndefined)
    .min(1)
    .max(6)
    .label('Number of EV Host Entity hosts annually if any'),
  summerWork: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(250)
    .min(1)
    .label('Summer Work Travel Program'),
  internProgram: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(250)
    .min(1)
    .label('Intern and Trainee Programs'),
  teacherProgram: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(250)
    .min(1)
    .label('Teacher Program'),
  url: yup
    .string()
    .url()
    .max(100)
    .nullable()
    .transform(convertToNullForValidation)
    .label('URL'),
  irsTaxNumber: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(50)
    .min(1)
    .label('IRS Tax Number'),
  siteRequirement: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .label('Site Visit Requirement Satisfied'),
  annualRevenue: yup
    .string()
    .nullable()
    .label('Approximate annual revenue of the previous year'),
  expirationDate: yup
    .date()
    .nullable()
    .transform(transformDate)
    .label('Policy Expiration Date'),
  workmanCompId: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(50)
    .min(1)
    .label(`Workman's Comp Policy ID`),
  workmanCompPhone: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(15)
    .min(1)
    .label(`Workman's Comp Policy Provider Phone`),
  insuranceCompany: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(250)
    .min(1)
    .label('Insurance Company'),
  description: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(1000)
    .label('Company Description'),
  comments: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(1000)
    .label('Comments'),
  businessLicense: yup
    .date()
    .nullable()
    .required()
    .transform(transformDate)
    .label('Business License (or Host School Accreditation) expiration date'),
  termsConditionsExpiration: yup
    .date()
    .nullable()
    .transform(transformDate)
    .label('Terms & Conditions expiration date'),
  seasonalityReportExpiration: yup
    .date()
    .nullable()
    .transform(transformDate)
    .label('Seasonality Report expiration date'),
  hostAddress: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(250)
    .label('Host Entity Head Quarters address (including county)'),
  hostIncorporateInUsa: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label('Is the Host Entity incorporated in the USA?'),
  hostIncorporateNotUsa: yup
    .string()
    .notRequired()
    .max(50)
    .label('If no, where is the Host Entity incorporated?'),
  layoffs: yup
    .boolean()
    .nullable()
    .transform(convertToBooleanOrUndefined)
    .label('Have you had any layoffs in the past 120 days?'),
  otherSponsors: yup
    .string()
    .nullable()
    .transform(convertToNullForValidation)
    .max(500)
    .label('Which other sponsors are you currently working with?'),
  howLongHiringParticipants: yup
    .string()
    .nullable()
    .max(250)
    .transform(convertToNullForValidation)
    .label('How long have you been hiring participants for the J1 programs?'),
});
