import { Box, Grid, useTheme } from '@mui/material';

import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextareaInput from 'components/textAreaInput';
import NumberInput from 'components/numberInput';

import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';

import { CompleteInfoFormInput } from '../../interfaces/completeInfo.formInput';

export const CompanyProgram = ({
  isUsingAnotherVisaProgramValue,
  isHostEntityProvidedHousing,
}: {
  isHostEntityProvidedHousing: boolean;
  isUsingAnotherVisaProgramValue: boolean;
}) => {
  const translations = useTranslations();
  const theme = useTheme();
  const {
    defaultInput,
    inputsGridFullWidth,
    smallInput,
    halfToFullPageInput,
    yesOrNoInput,
    inputsSection,
  } = useFormInputsStyle(theme);

  const {
    title,
    address,
    hasSupervisor,
    aproximateCostOfHousingPerMonthPerExchangeVisitor,
    existsAnyAffordableHousingOptionsNearby,
    isHousingProvided,
    hasAnyOtherVisaPrograms,
    hasHelpWithCulturalActivities,
    isEnglishPrimaryLanguage,
    isProvided32HoursPerWeek,
    isTrainingOrWorkOverNightRequired,
    otherVisaProgramsDescription,
  } = translations.companyCompleteInfo.program;

  return (
    <ConnectForm<CompleteInfoFormInput>>
      {({ register, control, getValues, watch, formState: { errors } }) => (
        <Box>
          <SectionHeader>{title}</SectionHeader>
          <Grid {...inputsGridFullWidth}>
            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextareaInput
                  errorMessage={errors.program?.address?.message}
                  register={register('program.address')}
                  inputLabel={address}
                  inputId='program-address'
                  control={control}
                  maxLength={150}
                  singleLine
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  control={control}
                  register={register('program.hasSupervisor')}
                  inputId='program-hasSupervisor'
                  errorMessage={errors.program?.hasSupervisor?.message}
                  inputLabel={hasSupervisor}
                />
              </Grid>
              <Grid {...defaultInput}>
                <DropdownYesNo
                  control={control}
                  register={register('program.isEnglishPrimaryLanguage')}
                  inputId='program-isEnglishPrimaryLanguage'
                  errorMessage={
                    errors.program?.isEnglishPrimaryLanguage?.message
                  }
                  inputLabel={isEnglishPrimaryLanguage}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownYesNo
                  control={control}
                  register={register('program.hasHelpWithCulturalActivities')}
                  inputId='program-hasHelpWithCulturalActivities'
                  errorMessage={
                    errors.program?.hasHelpWithCulturalActivities?.message
                  }
                  inputLabel={hasHelpWithCulturalActivities}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownYesNo
                  control={control}
                  register={register('program.isProvided32HoursPerWeek')}
                  inputId='program-isProvided32HoursPerWeek'
                  errorMessage={
                    errors.program?.isProvided32HoursPerWeek?.message
                  }
                  inputLabel={isProvided32HoursPerWeek}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownYesNo
                  control={control}
                  register={register(
                    'program.isTrainingOrWorkOverNightRequired'
                  )}
                  inputId='program-isTrainingOrWorkOverNightRequired'
                  errorMessage={
                    errors.program?.isTrainingOrWorkOverNightRequired?.message
                  }
                  inputLabel={isTrainingOrWorkOverNightRequired}
                />
              </Grid>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  control={control}
                  register={register('program.isHousingProvided')}
                  inputId='program-isHousingProvided'
                  errorMessage={errors.program?.isHousingProvided?.message}
                  inputLabel={isHousingProvided}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...smallInput}>
                <NumberInput
                  control={control}
                  label={aproximateCostOfHousingPerMonthPerExchangeVisitor}
                  name='program.aproximateCostOfHousingPerMonthPerExchangeVisitor'
                  errorMessage={
                    errors.program
                      ?.aproximateCostOfHousingPerMonthPerExchangeVisitor
                      ?.message
                  }
                  useDecimals
                  getValue={() => {
                    return getValues(
                      'program.aproximateCostOfHousingPerMonthPerExchangeVisitor'
                    );
                  }}
                  disabled={!isHostEntityProvidedHousing}
                />
              </Grid>
              <Grid {...defaultInput}>
                <DropdownYesNo
                  control={control}
                  register={register(
                    'program.existsAnyAffordableHousingOptionsNearby'
                  )}
                  inputId='program-existsAnyAffordableHousingOptionsNearby'
                  errorMessage={
                    errors.program?.existsAnyAffordableHousingOptionsNearby
                      ?.message
                  }
                  inputLabel={existsAnyAffordableHousingOptionsNearby}
                  disabled={isHostEntityProvidedHousing}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <DropdownYesNo
                  control={control}
                  register={register('program.hasAnyOtherVisaPrograms')}
                  inputId='program-hasAnyOtherVisaPrograms'
                  errorMessage={
                    errors.program?.hasAnyOtherVisaPrograms?.message
                  }
                  inputLabel={hasAnyOtherVisaPrograms}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSection}>
              <Grid {...halfToFullPageInput}>
                <TextareaInput
                  errorMessage={
                    errors.program?.otherVisaProgramsDescription?.message
                  }
                  register={register('program.otherVisaProgramsDescription')}
                  inputLabel={otherVisaProgramsDescription}
                  inputId='program-otherVisaProgramsDescription'
                  control={control}
                  maxLength={250}
                  disabled={!isUsingAnotherVisaProgramValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
