import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHostService } from 'hooks/services/hosts';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import ArrivalInstructionsForm from './arrivalInstructionsForm';
import { ArrivalInstructionsFormInput } from './interfaces/arrivalInstructionsForm.formInput';
import { arrivalInstructionsInitEmptyValues } from './utils/initValues';
import { makeRequest } from './utils/makeRequest';
import { setArrivalInstructionsValues } from './utils/setArrivalInstructionsValues';
import { schema } from './validation/validation.schema';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const ArrivalInstructionsFormContainer = () => {
  const translations = useTranslations();
  const alert = useAlert();
  const [availablePrograms, setAvailablePrograms] = useState<
    KeyValueItemProps[]
  >([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [arrivalInfoStatus, setArrivalInfoStatus] = useState<string>('');

  const { hostId, arrivalInstructionsId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const matchXs = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const matchMdToLg = useMediaQuery(
    '(min-width: 969px) and (max-width: 1199px)',
    { noSsr: true }
  );
  const matchLgAbove = useMediaQuery(
    '(min-width: 1582px) and (max-width: 2000px)',
    { noSsr: true }
  );

  const {
    getPrograms,
    createArrivalInstruction,
    getArrivalInstruction,
    updateArrivalInstruction,
  } = useHostService();

  const formMethods = useForm<ArrivalInstructionsFormInput>({
    mode: 'onSubmit',
    defaultValues: arrivalInstructionsInitEmptyValues,
    resolver: yupResolver(schema),
  });

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = formMethods;

  const arrivalInstructionPageList =
    ApplicationRoutes.arrivalInstructions.replace(':hostId', hostId as string);

  const handleCancelButton = () => {
    navigate(arrivalInstructionPageList);
  };

  const submitHandler = async (
    data: ArrivalInstructionsFormInput,
    event: any
  ) => {
    event.preventDefault();

    const body = makeRequest(data);

    if (isEditMode) {
      try {
        await updateArrivalInstruction({
          hostId: Number(hostId),
          id: Number(arrivalInstructionsId),
          ...body,
        });
        alert.success(translations.alerts.programs.arrivalInfoUpdated);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await createArrivalInstruction({ hostId: Number(hostId), body });
        alert.success(translations.alerts.programs.arrivalInfoCreated);
      } catch (error) {
        console.log(error);
      }
    }

    navigate(arrivalInstructionPageList);
  };

  const handleGetPrograms = async () => {
    const response = await getPrograms({
      hostId: Number(hostId),
    });

    const programs: KeyValueItemProps[] = [];

    response.forEach((each) => {
      const program = {
        key: each.id.toString(),
        value: each.name,
      };

      programs.push(program);
    });

    setAvailablePrograms(programs);
  };

  const handleGetArrivalInstructionsById = async () => {
    const response = await getArrivalInstruction(
      Number(hostId),
      Number(arrivalInstructionsId)
    );

    setArrivalInfoStatus(response.status);
    setArrivalInstructionsValues(response, setValue);
  };

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    handleGetPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostId]);

  useEffect(() => {
    const isEditMode: boolean =
      !!arrivalInstructionsId && !isNaN(Number(arrivalInstructionsId));

    if (isEditMode) {
      handleGetArrivalInstructionsById();
    }

    setIsEditMode(isEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  return (
    <ArrivalInstructionsForm
      matchXs={matchXs}
      matchMdToLg={matchMdToLg}
      matchLgAbove={matchLgAbove}
      isEditMode={isEditMode}
      hostId={hostId!}
      arrivalInfoStatus={arrivalInfoStatus}
      availablePrograms={availablePrograms}
      handleCancelButton={handleCancelButton}
      onSubmit={onSubmit}
      errors={errors}
      control={control}
      register={register}
      getValues={getValues}
    />
  );
};

export default ArrivalInstructionsFormContainer;
