import { Drawer, ModalProps, useMediaQuery, useTheme } from '@mui/material';
import CustomModal from 'components/modal';
import ModalContent from './modalContent';
import { Nullable } from 'utils/interfaces';
import CloseIcon from '@mui/icons-material/Close';
import { ApplicantInfoResponse } from 'hooks/services/hosts/interfaces/participantSummary.response';

interface Props extends Omit<ModalProps, 'children'> {
  item: Nullable<ApplicantInfoResponse>;
  onClose: () => void;
}

const ParticipantModal = ({ item, ...props }: Props) => {
  const theme = useTheme();
  const { onClose } = props;
  const matchTablet = useMediaQuery('(max-width: 850px)');
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  if (matchDownMd) {
    return (
      <Drawer
        anchor='bottom'
        variant='temporary'
        open={props.open}
        onClose={() => onClose()}
      >
        <ModalContent item={item} isMobile />
        <CloseIcon
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            color: '#fff',
          }}
          role='button'
          onClick={onClose}
        />
      </Drawer>
    );
  } else {
    return (
      <CustomModal
        {...props}
        sx={{ background: 'none', width: matchTablet ? '720px' : '765px' }}
      >
        <ModalContent item={item} />
      </CustomModal>
    );
  }
};

export default ParticipantModal;
