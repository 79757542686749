import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';
import { CellHeaderId } from 'pages/participantsVisa/participants/components/participantsTable/interfaces/participantsCellHeader';

export const getCells = ({
  firstName,
  lastName,
  program,
  position,
  hostEntity,
  id,
  ds2019,
  email,
  dateOfBirth,
  gender,
  statusCode,
  skypeId,
  countryOfResidence,
  jobId,
  programStartDate,
  programEndDate,
}: {
  id: string;
  firstName: string;
  lastName: string;
  program: string;
  position: string;
  jobId: string;
  programStartDate: string;
  programEndDate: string;
  hostEntity: string;
  ds2019: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  statusCode: string;
  skypeId: string;
  countryOfResidence: string;
}): CellHeader<CellHeaderId>[] => {
  return [
    {
      id: 'hostEntity',
      label: hostEntity,
      sortable: true,
    },
    {
      id: 'program',
      label: program,
      sortable: true,
    },
    {
      id: 'id',
      label: id,
      sortable: true,
    },
    {
      id: 'ds2019',
      label: ds2019,
      sortable: true,
    },
    {
      id: 'applicantFirstName',
      label: firstName,
      sortable: true,
    },
    {
      id: 'applicantLastName',
      label: lastName,
      sortable: true,
    },
    {
      id: 'email',
      label: email,
      sortable: true,
    },
    {
      id: 'dateOfBirth',
      label: dateOfBirth,
      sortable: true,
    },
    {
      id: 'gender',
      label: gender,
      sortable: true,
    },
    {
      id: 'statusCode',
      label: statusCode,
      sortable: true,
    },
    {
      id: 'skypeId',
      label: skypeId,
      sortable: true,
    },
    {
      id: 'countryOfResidence',
      label: countryOfResidence,
      sortable: true,
    },
    {
      id: 'jobId',
      label: jobId,
      sortable: true,
    },
    {
      id: 'position',
      label: position,
      sortable: true,
    },
    {
      id: 'programStartDate',
      label: programStartDate,
      sortable: true,
    },
    {
      id: 'programEndDate',
      label: programEndDate,
      sortable: true,
    },
  ];
};
