import { lazy, Suspense } from 'react';

const Home = lazy(() => import('./home'));

const HomeContainer = () => {
  return (
    <Suspense fallback={<div>Home is loading...</div>}>
      <Home />
    </Suspense>
  );
};

export default HomeContainer;
