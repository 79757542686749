import { FieldValues } from 'react-hook-form';
import DropdownInput from 'components/dropdownInput';
import { useTranslations } from 'hooks/useTranslations';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { DropdownYesNoProps } from './interfaces/dropdownYesNoProps';


export default function DropdownYesNo<TFieldValues extends FieldValues>(
  props: DropdownYesNoProps<TFieldValues>
) {
  const translations = useTranslations();

  const items: KeyValueItemProps[] = [
    {
      key: 'true',
      value: translations.dropdownYesValue,
    },
    {
      key: 'false',
      value: translations.dropdownNoValue,
    },
  ];
  return <DropdownInput {...props} items={items} />;
}
