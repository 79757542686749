import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import NotRequired from 'assets/icons/notRequired.svg';
import Required from 'assets/icons/required.svg';
import Trash from 'assets/icons/trash.svg';
import { useTranslations } from 'hooks/useTranslations';
import { DocumentsTableProps } from './interfaces/documentsTableProps';

const DocumentsTable = ({
  rows,
  handleDelete,
  isReadOnly,
  handleDownload,
}: DocumentsTableProps) => {
  const translations = useTranslations();

  return (
    <TableContainer component={Box}>
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              {translations.documentsSection.documentColumn}
            </TableCell>
            <TableCell>
              {translations.documentsSection.descriptionColumn}
            </TableCell>
            <TableCell align='right'>
              {translations.documentsSection.signatureColumn}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component='th' scope='row'>
                  <Link
                    component='button'
                    variant='table'
                    underline='always'
                    color='inherit'
                    onClick={() => handleDownload(row)}
                  >
                    {row.title}
                  </Link>
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align='right'>
                  {row.signatureRequired && (
                    <Box
                      component='img'
                      alt='Signature Required'
                      src={row.signatureRequired ? Required : NotRequired}
                    />
                  )}
                </TableCell>
                <TableCell align='right'>
                  {!isReadOnly && (
                    <IconButton
                      onClick={() => handleDelete(row)}
                      disabled={isReadOnly}
                    >
                      <Box component='img' alt='' src={Trash}></Box>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align='center' colSpan={4}>
                {translations.documentsSection.noDocuments}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsTable;
