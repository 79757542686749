import { useFetch } from 'hooks/useFetch';
import { NotificationResponse } from './interfaces/notificationResponse';
import { NotificationsRequest } from './interfaces/notificationsRequest';
import { PagedResponse } from '../commons/interfaces/paged.response';
import { makeQueryParamsFromObject } from '../helpers/queryParamsHelpers';

const useNotificationsService = () => {
  const { get, patch } = useFetch();
  const parentControllerName = 'hosts';
  const controllerName = 'notifications';

  const getNotifications = async (
    params: NotificationsRequest & { hostId: number }
  ) => {
    const { hostId, ...rest } = params;

    const query = makeQueryParamsFromObject(rest);

    const resource = `${parentControllerName}/${hostId}/${controllerName}${query}`;

    return await get<PagedResponse<NotificationResponse>>(resource);
  };

  const archive = async (hostId: number, id: number) => {
    return await patch(
      `${parentControllerName}/${hostId}/${controllerName}/${id}`
    );
  };

  return {
    getNotifications,
    archive,
  };
};

export { useNotificationsService };
