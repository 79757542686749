import { Box, Grid, useTheme } from '@mui/material';
import ConnectForm from 'components/connectForm/connectForm';
import { SectionHeader } from 'components/page/header/styledHeader';
import TextareaInput from 'components/textAreaInput/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { AddressInputsProps } from '../interfaces/addressInputsProps';
import { CommonAddressSectionProps } from '../interfaces/commonAddressProps';

export const CommonAddressForm = ({
  disabledFields = true,
  labels,
  formAddressType,
}: CommonAddressSectionProps) => {
  const theme = useTheme();

  const {
    inputStyle,
    defaultInput,
    inputsSection,
  } = useFormInputsStyle(theme);

  return (
    <ConnectForm<AddressInputsProps>>
      {({ register, formState: { errors } }) => (
        <Box>
          <SectionHeader>{labels.title}</SectionHeader>

          <Grid item xs={12}>
            <Grid {...inputsSection}>
              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={`${formAddressType}.address1Id`}
                  errorMessage={errors?.[formAddressType]?.address1?.message}
                  inputLabel={labels.address1}
                  register={register(`${formAddressType}.address1`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>

              <Grid {...defaultInput}>
                <TextareaInput
                  inputId={`${formAddressType}.address2Id`}
                  errorMessage={errors?.[formAddressType]?.address2?.message}
                  inputLabel={labels.address2}
                  register={register(`${formAddressType}.address2`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(4, 3)}>
                <TextareaInput
                  inputId={`${formAddressType}.cityId`}
                  errorMessage={errors?.[formAddressType]?.city?.message}
                  inputLabel={labels.city}
                  register={register(`${formAddressType}.city`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>

              <Grid {...inputStyle(2, 1)}>
                <TextareaInput
                  inputId={`${formAddressType}.stateId`}
                  errorMessage={errors?.[formAddressType]?.state?.message}
                  inputLabel={labels.state}
                  register={register(`${formAddressType}.state`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>

              <Grid {...inputStyle(3, 2)}>
                <TextareaInput
                  inputId={`${formAddressType}.postalCodeId`}
                  errorMessage={errors?.[formAddressType]?.postalCode?.message}
                  inputLabel={labels.zipCode}
                  register={register(`${formAddressType}.postalCode`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>

              <Grid {...inputStyle(3, 2)}>
                <TextareaInput
                  inputId={`${formAddressType}.countryId`}
                  errorMessage={errors?.[formAddressType]?.country?.message}
                  inputLabel={labels.country}
                  register={register(`${formAddressType}.country`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputStyle(2.5, 1.5)}>
                <TextareaInput
                  inputId={`${formAddressType}.telephoneCountryCodeId`}
                  errorMessage={errors?.[formAddressType]?.phone?.countryCode?.message}
                  inputLabel={labels.phone.countryCode}
                  register={register(`${formAddressType}.phone.countryCode`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>

              <Grid {...inputStyle(3.5, 2)}>
                <TextareaInput
                  inputId={`${formAddressType}.telephoneCountryNumberId`}
                  errorMessage={errors?.[formAddressType]?.phone?.number?.message}
                  inputLabel={labels.phone.number}
                  register={register(`${formAddressType}.phone.number`)}
                  disabled={disabledFields}
                  singleLine
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};
