import { ContactInputsProps } from '../interfaces/ContactInputsProps';

export const initWithEmptyValues: ContactInputsProps = {
  name: '',
  type: '',
  email: '',
  directPhone: '',
  mobilePhone: '',
  department: '',
  title: '',
  fax: '',
  skype: '',
};
