import { InterviewReportResponse } from 'hooks/services/hosts/interfaces/interviewReport.response';
import { UseFormSetValue } from 'react-hook-form';
import { BE_DATE_FORMAT, convertToDate } from 'utils/date/convertToDate';

export const setValues = (
  values: InterviewReportResponse,
  setValue: UseFormSetValue<InterviewReportResponse>
) => {
  setValue('overallEnglishLevelInpersonInterview', values.overallEnglishLevelInpersonInterview);
  setValue('furtherCommentsInpersonInterview', values.furtherCommentsInpersonInterview);
  setValue('interviewerSignatureInpersonInterview', values.interviewerSignatureInpersonInterview);
  setValue('interviewDateInpersonInterviewReportData',
    convertToDate(values.interviewDateInpersonInterviewReportData, BE_DATE_FORMAT)
  );
};
