import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { initWithEmptyValues } from './utils/initValues';
import { setValues } from './utils/setValues';
import { useCommonService } from 'hooks/services/commons';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { useHostService } from 'hooks/services/hosts';
import InterviewReportModal from './interviewReportModal';
import { schema } from './validation/schema.validation';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import { InterviewReportRequest } from 'hooks/services/hosts/interfaces/interviewReport.request';
import { InterviewReportResponse } from 'hooks/services/hosts/interfaces/interviewReport.response';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const InterviewReportModalContainer = ({
  interviewId,
  modalOpen,
  setModalOpen,
  actionAfterClose,
}: {
  interviewId: number;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  actionAfterClose?: () => void;
}) => {
  const alert = useAlert();
  const translations = useTranslations();
  const { hostId } = useParams();
  const parsedHostId = parseInt(hostId as string);

  const [isLoading, setIsLoading] = useState(false);
  const [englishLevels, setEnglishLevels] = useState<KeyValueItemProps[]>([]);

  const { getInterviewReport, updateInterviewReport } = useHostService();
  const { getEnglishProficiencyOptions } = useCommonService();

  const formMethods = useForm<InterviewReportResponse>({
    mode: 'onSubmit',
    defaultValues: initWithEmptyValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = formMethods;

  const handleCloseModal = () => {
    setModalOpen(false);
    if (actionAfterClose) {
      actionAfterClose();
    }
  };

  const fetchEnglishLevels = async () => {
    try {
      const response = await getEnglishProficiencyOptions();
      const mapped: KeyValueItemProps[] = response.map((option) => ({
        key: option.key.toString(),
        value: option.value,
      }));
      setEnglishLevels(mapped);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInterviewReport = async () => {
    try {
      const address = await getInterviewReport(parsedHostId, interviewId);
      setValues(address, formMethods.setValue);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandle = async (data: InterviewReportResponse) => {
    const body: InterviewReportRequest = {
      ...data,
      interviewDateInpersonInterviewReportData: formatDate(
        data.interviewDateInpersonInterviewReportData,
        DEFAULT_FORMAT,
        BE_DATE_FORMAT
      ),
    };

    try {
      await updateInterviewReport(parsedHostId, interviewId, body);

      alert.success(translations.alerts.interviews.interviewUpdated);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const onSave = handleSubmit(submitHandle);

  useEffect(() => {
    fetchInterviewReport();
    fetchEnglishLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <InterviewReportModal
        {...{
          modalOpen,
          handleCloseModal,
          englishLevels,
          onSave,
          isLoading,
        }}
      />
    </FormProvider>
  );
};

export default InterviewReportModalContainer;
