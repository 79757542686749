import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import './styles/global.css';
import { AuthProvider } from 'providers/auth';
import AppRoutes from 'routes/appRoutes';
import theme from 'theme/theme';
import ConfigurationProvider from 'providers/configuration.provider';
import { LayoutProvider } from 'providers/layoutProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import UserProvider from 'providers/user.provider';
import AlertProvider from 'providers/alertProvider';
import TagManager from 'react-gtm-module';
import { LoadingProvider } from 'providers/loading.provider';

const tagManagerArgs = {
  gtmId: 'GTM-MW6FHNK',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <LoadingProvider>
      <ConfigurationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <LayoutProvider>
                  <AlertProvider>
                    <AppRoutes />
                  </AlertProvider>
                </LayoutProvider>
              </ThemeProvider>
            </UserProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ConfigurationProvider>
    </LoadingProvider>
  </BrowserRouter>
);
