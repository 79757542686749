import { FC } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { FieldValues } from 'react-hook-form';

import { Header } from '../components/header';
import { Row } from './components/row';
import { CollapsibleTableProps } from './interfaces/CollapsibleTableProps';
import { RowNotFound } from '../components/rowNotFound';

const CollapsibleTable: FC<CollapsibleTableProps<FieldValues>> = ({
  headers,
  rows,
  align,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <Header columns={headers} align={align} isFirstColumnEmpty />
        <TableBody>
          {rows && rows.map((row, index) => <Row key={index} {...row} />)}
          {rows && rows.length === 0 && (
            <RowNotFound colSpan={headers.length + 1} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
