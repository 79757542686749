import { Grid, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import HeaderFooterModal from 'components/modal/headerFooterModal';
import ModalHeader from 'components/modal/modalHeader';
import { StaticDatePicker } from 'components/staticDatePicker';
import { renderSelectedDay } from 'components/staticDatePicker/utils/renderSelectedDays';
import { useTranslations } from 'hooks/useTranslations';
import { useVerticalScrollBar } from 'hooks/useVerticalScrollBar';
import { Nullable } from 'utils/interfaces';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import TimeSlots from './components/timeSlots';
import { InterviewProps } from './interfaces/interviewProps';
import { SlotsToShow } from './interfaces/slotsToShow';
import { getGMTOffset } from './utils';
import TextAreaInput from "../../../components/textAreaInput/textAreaInput";

interface Props
  extends Omit<
    InterviewProps,
    'applicantId' | 'fetchData' | 'actionType' | 'interviewId'
  > {
  onSave: () => void;
  jobs: KeyValueItemProps[];
  platformTypes: KeyValueItemProps[];
  calendarValue: Nullable<Date>;
  onChangeCalendar: (newValue: Nullable<Date>) => void;
  slotsToShow: Nullable<SlotsToShow>;
  isLoading?: boolean;
  disabledSubmitBtn?: boolean;
  minDate?: Date;
  disablePast: boolean;
}

const ScheduleInterview = ({
  applicantName,
  applicantTimeZone,
  applicantTimeZoneOffset,
  countryOfResidence,
  jobs,
  platformTypes,
  isJobsFieldShow,
  calendarValue,
  onChangeCalendar,
  slotsToShow,
  isLoading,
  disabledSubmitBtn,
  onSave,
  minDate,
  disablePast,
  ...rest
}: Props) => {
  const { modal, scheduleInterview } = useTranslations();
  const theme = useTheme();
  const {
    control,
    formState: { errors },
    register,
    watch
  } = useFormContext();
  const scrollbar = useVerticalScrollBar();

  const interviewType = watch('interviewType'); 

  return (
    <HeaderFooterModal
      {...rest}
      sx={{
        width: { xs: `calc(100% - ${theme.spacing(4)})`, lg: 800 },
        height: { xs: `calc(100% - ${theme.spacing(4)})`, md: 'auto' },
      }}
      saveBtnText={modal.buttons.confirm}
      cancelBtnText={modal.buttons.cancel}
      onSave={onSave}
      isLoading={isLoading}
      disabledSubmitBtn={disabledSubmitBtn}
    >
      <Grid
        container
        p={theme.spacing(2, 3)}
        sx={{
          height: { xs: `calc(100% - ${theme.spacing(9)})`, md: 'auto' },
          overflow: 'auto',
          position: { xs: 'absolute', md: 'relative' },
          pt: theme.spacing(1),
          ...scrollbar,
        }}
      >
        <Grid item xs={12}>
          <ModalHeader title={scheduleInterview.title} />
        </Grid>

        <Grid container item md={6} xs={12}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography
                variant='subtitle1'
                color={theme.palette.primary.main}
              >
                {applicantName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' color={theme.palette.primary.main}>
                {countryOfResidence}
                {countryOfResidence && ', '}
                {applicantTimeZone} Time,{' '}
                {getGMTOffset(applicantTimeZoneOffset)}
              </Typography>
            </Grid>

            {isJobsFieldShow && (
              <Grid item xs={12} my={theme.spacing(2)}>
                <Typography
                  variant='body1'
                  color={theme.palette.primary.main}
                  mb={theme.spacing(1)}
                >
                  {scheduleInterview.chooseAJob}
                </Typography>
                <Grid item xs={12}>
                  <DropdownInput
                    control={control}
                    inputId={'jobId'}
                    items={jobs}
                    register={register('jobId')}
                    errorMessage={errors?.jobId?.message as string}
                  />
                                  </Grid>
              </Grid>
            )}

            <Grid item xs={12} my={theme.spacing(2)}>
              <Typography variant='body1' color={theme.palette.primary.main}>
                {scheduleInterview.selectDay}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StaticDatePicker
                value={calendarValue}
                onChange={onChangeCalendar}
                renderDayCustom={renderSelectedDay}
                disablePast={disablePast}
                minDate={minDate}
              />
            </Grid>
            <Grid item xs={12} my={theme.spacing(2)}>
              <DropdownInput
                inputLabel={'Interview type'}
                control={control}
                inputId={'interviewType'}
                items={platformTypes}
                register={register('interviewType')}
              />
            </Grid>
            {interviewType === 'other_communications_platform' && (
            <Grid item xs={12} my={theme.spacing(2)}>
              <TextAreaInput
                inputLabel={'Link to other platform'}
                inputId={'other_platform_link'}
                maxLength={1000}
                register={register('otherPlatformLink')}
              />
            </Grid>
              )}
          </Grid>
        </Grid>
        <Grid container item md={4.5} xs={12} ml={{ md: theme.spacing(8) }}>
          <TimeSlots {...{ slotsToShow }} />
        </Grid>
      </Grid>
    </HeaderFooterModal>
  );
};

export default ScheduleInterview;
