import { PlacementResponse } from '../interfaces/placement.response';
import { PlacementStatus } from '../interfaces/placementStatus';
import { PlacementSummaryResponse } from '../interfaces/placementSummary.response';

export function convertPlacementStatus<
  T extends PlacementSummaryResponse | PlacementResponse
>(response: T): T {
  return {
    ...response,
    status: PlacementStatus[response.status as keyof typeof PlacementStatus],
  };
}
