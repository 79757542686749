import { AppBar, Box, Grid, IconButton, LinearProgress, Menu, MenuItem, popoverClasses, useTheme } from '@mui/material';
import React from 'react';
import Hamburger from 'assets/icons/hamburger.svg';
import Notifications from 'assets/icons/notification.svg';
import Profile from 'assets/icons/profile.svg';
import AagDesktopLogo from 'assets/logo/aag_desktop.svg';
import AagGloblProgramsDesktopLogo from 'assets/logo/aag_global_programs_desktop.svg';
import AagMobileLogo from 'assets/logo/aag_mobile.svg';
import GvDesktopLogo from 'assets/logo/gv_desktop.svg';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import { AppBarProps } from './interfaces/appBarProps';

const ApplicationAppBar = ({
  handleMenuOpen,
  handleNotificationsOpen,
  hostChannel,
  handleLogout,
  navigate,
  hostId,
  isLoading,
}: AppBarProps) => {
  const theme = useTheme();

  let logoIconDesktop;
  let logoIconMobile;
  switch (hostChannel) {
    case 'AAG – Global Programs':
      logoIconDesktop = AagGloblProgramsDesktopLogo;
      logoIconMobile = AagGloblProgramsDesktopLogo;

      break;
    case 'Geovisions':
      logoIconDesktop = GvDesktopLogo;
      logoIconMobile = GvDesktopLogo;

      break;

    default:
      logoIconDesktop = AagDesktopLogo;
      logoIconMobile = AagMobileLogo;
      break;
  }
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar
        position='fixed'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        color='inherit'
      >
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: theme.appBar.height,
          }}
        >
          <IconButton onClick={handleMenuOpen}>
            <Box component='img' alt='Open Menu' src={Hamburger} />
          </IconButton>
          <IconButton sx={{ marginRight: 'auto' }}>
            <Box
              component='img'
              alt='Logo'
              src={logoIconDesktop}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            />
            <Box
              component='img'
              alt='Logo'
              src={logoIconMobile}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            />
          </IconButton>
          <IconButton onClick={() => handleNotificationsOpen()}>
            <Box component='img' alt='Notifications' src={Notifications} />
          </IconButton>
          <IconButton
            ref={buttonRef}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Box component='img' alt='Profile' src={Profile} />
          </IconButton>
        </Grid>

        {isLoading ? <LinearProgress /> : ''}
      </AppBar>
      <Menu
        id='profile-menu'
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        sx={{
          width: 200,
          ml: theme.spacing(1.5),
          [`& .${popoverClasses.paper}`]: {
            borderRadius: 0,
          },
        }}
        PaperProps={{ sx: { width: { xs: '150px', md: '200px' } } }}
      >
        <MenuItem
          onClick={() =>
            hostId
              ? navigate(ApplicationRoutes.profile.replace(':hostId', hostId))
              : null
          }
        >
          My Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ApplicationAppBar;
