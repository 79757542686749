import * as yup from 'yup';

export default function createProgramTypesValidation(
  checkSelectedProgram: (value: string) => boolean
) {
  return yup
    .array()
    .when('program', {
      is: checkSelectedProgram,
      then: yup.array().required().min(1),
      otherwise: yup.array().notRequired(),
    })
    .label('Program types');
}
