import { Grid, useTheme } from '@mui/material';

import { PageHeader } from 'components/page/header/styledHeader';
import PrimarySubmitButton from 'components/button';
import {
  ButtonResponsiveProps,
  ButtonsContainer,
  ContainerSpacing,
  PrimaryButtonResponsiveMargin,
} from 'components/page/header/utils';
import Stepper from 'components/stepper';
import { CompleteInfoProps } from './interfaces/completeInfoProps';
import { useTranslations } from 'hooks/useTranslations';
import NoScrollBar from '../../../utils/interfaces/noScrollBar';

const CompleteInfoForm = ({
  onSubmit,
  onChangeAction,
  buttonsRef,
  pageHeaderRef,
  stepperRef,
  isSubmitting,
  isLoading,
  sectionComponents,
  sections,
  general,
  matchDownMd,
}: CompleteInfoProps) => {
  const translations = useTranslations();
  const theme = useTheme();
  const { title } = translations.companyCompleteInfo;

  const buttonsSection = isLoading ? (
    <div></div>
  ) : (
    <Grid {...ButtonsContainer}>
      <Grid
        sx={{
          ...ButtonResponsiveProps,
        }}
      >
        <PrimarySubmitButton
          dataTestid='save'
          variant='outlined'
          isLoading={isSubmitting}
          onClick={() => {
            onChangeAction('');
          }}
          text={translations.saveButton}
        />
      </Grid>
      <Grid
        sx={{
          ...ButtonResponsiveProps,
          ...PrimaryButtonResponsiveMargin,
        }}
      >
        <PrimarySubmitButton
          dataTestid='submit'
          variant='contained'
          isLoading={isSubmitting}
          onClick={() => {
            onChangeAction('submit');
          }}
          text={translations.submitButton}
        />
      </Grid>
    </Grid>
  );

  return (
    <form onSubmit={onSubmit} style={{ height: '100%', overflow: 'hidden' }}>
      <Grid {...ContainerSpacing}>
        <Grid item xs={12} md={4} order={1} pb={{ md: 4 }} ref={pageHeaderRef}>
          <PageHeader>{title}</PageHeader>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 3, md: 2 }}
          pt={{ xs: 0 }}
          pb={4}
          ref={buttonsRef}
        >
          {buttonsSection}
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          order={{ xs: 2, md: 3 }}
          ref={stepperRef}
          sx={{
            height: { md: `calc(100% - ${theme.spacing(4)} - 15px)` },
            overflowY: { md: 'auto' },
            paddingRight: theme.spacing(2),
            '&.MuiGrid-item': {
              paddingTop: 0,
            },
            ...NoScrollBar,
          }}
        >
          <Stepper steps={sections} />
        </Grid>
        <Grid
          item
          order={4}
          xs={12}
          md={10}
          px={0.3}
          sx={{
            overflowY: 'hidden',
            height: matchDownMd
              ? `calc(100% - ${general}px)`
              : `calc(100% - ${theme.spacing(9)})`,
            '&.MuiGrid-item': {
              paddingTop: '0 ',
            },
          }}
        >
          <Grid
            id='containerElement'
            sx={{
              position: 'relative',
              height: '100%',
              overflow: 'auto',
              mb: 1,
              '> *:not(:last-child)': { mb: { xs: 5, md: 7 } },
            }}
          >
            {sectionComponents}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompleteInfoForm;
