/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useHostService } from 'hooks/services/hosts';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { convertBackEndKeyItemsToKeyItems } from 'utils/keyItemProps/keyItemConverter';
import { CompleteInfoFormInput } from './interfaces/completeInfo.formInput';
import { schema } from './validation/validation.schema';
import { initWithEmptyValues } from './utils/initValues';
import { makeRequest } from './utils/makeRequest';
import { setValues } from './utils/setValues';
import { CompanyDetailsSection } from './sections/companyDetails';
import { Element } from 'react-scroll';
import CompleteInfoForm from './completeInfo';
import { useHeightRef } from 'hooks/useHeightRef';
import { useMediaQuery, useTheme } from '@mui/material';
import { CompanyProgram } from './sections/companyProgram';
import { useTranslations } from 'hooks/useTranslations';
import { convertToBoolean } from '../../../utils/string/stringConverters';
import { useAlert } from 'providers/alertProvider';

const CompleteInfoContainer = () => {
  const alert = useAlert();
  const [sectors, setSectors] = useState<KeyValueItemProps[]>([]);
  const [annualRevenues, setAnnualRevenues] = useState<KeyValueItemProps[]>([]);
  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();
  const [stepperHeight, stepperRef] = useHeightRef();
  const [isHostEntityProvidedHousing, setIsHostEntityProvidedHousing] =
    useState(false);
  const [isUsingAnotherVisaProgramValue, setIsUsingAnotherVisaProgramValue] =
    useState(false);
  const [action, setAction] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const general = pageHeaderHeight + buttonsHeight + stepperHeight;

  const theme = useTheme();
  const {
    companyCompleteInfo: {
      program: { title: programTitle },
      details: { title: detailsTitle },
    },
    alerts: {
      company: { informationSubmitted },
    },
  } = useTranslations();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const { hostId } = useParams();
  const { getCompany, patchCompany, getDropdownOptions } = useHostService();

  const formMethods = useForm<CompleteInfoFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: initWithEmptyValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = formMethods;

  const getSectorList = async () => {
    if (!hostId) return;
    const response = await getDropdownOptions(hostId, 'employers_sector');
    console.log("Response coming")
    console.log(response)
    const items = convertBackEndKeyItemsToKeyItems(response);
    setSectors(items);
  };

  const getAnnualRevenues = async () => {
    if (!hostId) return;
    const response = await getDropdownOptions(hostId, 'gross_income_usd');
    const items = convertBackEndKeyItemsToKeyItems(response);
    setAnnualRevenues(items);
  };

  const getCompanyInfo = async () => {
    if (!hostId) return;
    try {
      setIsLoading(true);
      const response = await getCompany({ hostId });
      setValues(response, setValue);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandle = async (data: CompleteInfoFormInput, event: any) => {
    event.preventDefault();

    const body = makeRequest(data);

    try {
      await patchCompany({ hostId: Number(hostId), body, action });
      alert.success(informationSubmitted);
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeAction = (action: string): void => {
    setAction(action);
  };

  const onSubmit = handleSubmit(submitHandle, (errors) => {
    console.log(errors);
  });

  useEffect(() => {
    getSectorList();
    getAnnualRevenues();
    getCompanyInfo();
  }, []);

  useEffect(() => {
    const isHostEntityProvidedHousingValue = convertToBoolean(
      watch('program.isHousingProvided')
    );
    setIsHostEntityProvidedHousing(isHostEntityProvidedHousingValue);

    const isUsingAnotherVisaProgramValue = convertToBoolean(
      watch('program.hasAnyOtherVisaPrograms')
    );
    setIsUsingAnotherVisaProgramValue(isUsingAnotherVisaProgramValue);

    if (isHostEntityProvidedHousingValue) {
      setValue('program.existsAnyAffordableHousingOptionsNearby', '');
    } else {
      setValue('program.existsAnyAffordableHousingOptionsNearby', 'false');
      setValue('program.aproximateCostOfHousingPerMonthPerExchangeVisitor', '');
    }

    if (!isUsingAnotherVisaProgramValue) {
      setValue('program.otherVisaProgramsDescription', '');
    }
  }, [
    watch('program.isHousingProvided'),
    watch('program.hasAnyOtherVisaPrograms'),
  ]);

  const sectionsFormComponent: { [key: string]: ReactElement } = {
    CompanyDetails: <CompanyDetailsSection {...{ sectors, annualRevenues }} />,
    CompanyProgram: (
      <CompanyProgram
        {...{ isHostEntityProvidedHousing, isUsingAnotherVisaProgramValue }}
      />
    ),
  };

  const sections = [
    {
      id: 'CompanyDetails',
      title: detailsTitle,
    },
    {
      id: 'CompanyProgram',
      title: programTitle,
    },
  ];

  const sectionComponents = sections.map((section: any) => (
    <Element key={section.id} name={section.id}>
      {sectionsFormComponent[section.id]}
    </Element>
  ));

  return (
    <FormProvider {...formMethods}>
      <CompleteInfoForm
        {...{
          onSubmit,
          isSubmitting,
          isLoading,
          sections,
          sectionComponents,
          pageHeaderRef,
          buttonsRef,
          stepperRef,
          pageHeaderHeight,
          general,
          matchDownMd,
          onChangeAction,
        }}
      />
    </FormProvider>
  );
};

export default CompleteInfoContainer;
