import { Tooltip, useTheme } from '@mui/material';
import { ReactElement } from 'react';

const OptionalTooltip = ({
  children,
  text,
  show,
  placement,
}: {
  children: ReactElement;
  text: string;
  show: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}) => {
  const theme = useTheme();
  const position = placement ?? 'right-start';

  return show ? (
    <Tooltip
      title={text}
      placement={position}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.grey['600'],
            '& .MuiTooltip-arrow': {
              color: theme.palette.grey['600'],
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export { OptionalTooltip };
