import * as yup from 'yup';

import { transformStringToNumberOrUndefined } from 'utils/extensions/numberInputTransformer';

export const schema = yup.object().shape({
  doesHostEntityProvideTemporaryHousing: yup
  .string()
  .required()
  .label('Does Host Entity provide temporary housing?'),

  durationOfTemporaryHousing: yup.string().nullable().label('Duration of temporary housing').max(250),

  costOfTemporaryHousing: yup
  .number()
  .transform(transformStringToNumberOrUndefined)
  .required()
  .label('Cost of temporary housing')
  .max(9999.99)
  .min(0.1),
});