import { Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { DateRangeProps } from './interfaces/dateRangeProps';
import { convertToDateOrUndefined } from '../../utils/date/convertToDate';
import { DatePickerInput } from '../datePickerInput/datePickerInput';

export const DateRange = <TFieldValues extends FieldValues>({
  startDate,
  endDate,
  handleChangeEndDate,
  handleChangeStartDate,
  labelBetween,
  handleOpenEndDate,
  handleOpenStartDate,
  openEndDate,
  openStartDate,
  format,
  labelEndDate,
  labelStartDate,
  maxDate,
  minDate,
  control,
  nameEndDate,
  nameStartDate,
  startDateValidationMessage,
  endDateValidationMessage,
  disabled,
}: DateRangeProps<TFieldValues>) => {
  return (
    <Grid
      item
      xs={12}
      justifyContent='space-between'
      alignItems='center'
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Grid item sx={{ height: 105 }}>
        <DatePickerInput
          value={startDate}
          handleChange={handleChangeStartDate}
          handleOpen={handleOpenStartDate}
          open={openStartDate}
          format={format}
          label={labelStartDate}
          control={control}
          name={nameStartDate}
          validationMessage={startDateValidationMessage}
          maxDate={maxDate ? maxDate : convertToDateOrUndefined(endDate)}
          minDate={minDate}
          disabled={disabled}
        />
      </Grid>

      <Grid>
        <p>{labelBetween ? labelBetween : 'to'}</p>
      </Grid>

      <Grid item sx={{ height: 105 }}>
        <DatePickerInput
          value={endDate}
          handleChange={handleChangeEndDate}
          handleOpen={handleOpenEndDate}
          open={openEndDate}
          format={format}
          label={labelEndDate}
          control={control}
          name={nameEndDate}
          validationMessage={endDateValidationMessage}
          maxDate={maxDate}
          minDate={convertToDateOrUndefined(startDate)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
