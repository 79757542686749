import { ArrivalInstructionRequest } from 'hooks/services/hosts/interfaces/arrivalInstruction.request';
import {
  BE_DATE_FORMAT,
  DEFAULT_FORMAT,
  formatDate,
} from 'utils/date/convertToDate';
import {
  convertToBoolean,
  convertToNumber,
  convertToNumberOrUndefined,
} from 'utils/string/stringConverters';
import { ArrivalInstructionsFormInput } from '../interfaces/arrivalInstructionsForm.formInput';

export const makeRequest = (
  data: ArrivalInstructionsFormInput
): ArrivalInstructionRequest => {
  const {
    program,
    description,
    nearestInternationalAirport,
    nearestAirportToJobsite,
    isPickUpProvided,
    latestPreferredArrivalDate,
    generalArrivalInstructions,
    shouldContactHCBeforeArrival,
    contactPhoneNumber,
    transportPickUpCost,
    preferredArrivalDays,
    preferredArrivalTimes,
    reportingTo,
  } = data;

  return {
    programId: convertToNumber(program),
    description,
    nearestInternationalAirport,
    nearestAirportToJobsite,
    isPickUpProvided: convertToBoolean(isPickUpProvided),
    latestPreferredArrivalDate: formatDate(
      latestPreferredArrivalDate,
      DEFAULT_FORMAT,
      BE_DATE_FORMAT
    ),
    generalArrivalInstructions,
    shouldContactHCBeforeArrival: convertToBoolean(
      shouldContactHCBeforeArrival
    ),
    contactPhoneNumber,
    transportPickUpCost: convertToNumberOrUndefined(transportPickUpCost),
    preferredArrivalDays,
    preferredArrivalTimes,
    reportingTo,
  };
};
