import { Step, StepButton, Stepper, useTheme } from '@mui/material';
import { Link } from 'react-scroll';
import StepperProps from './interfaces/stepperProps';

const MuiStepper = ({ activeStep, handleStep, steps }: StepperProps) => {
  const theme = useTheme();

  return (
    <Stepper
      nonLinear
      activeStep={activeStep}
      orientation='vertical'
      sx={{
        alignItems: 'flex-end',

        '.MuiStepConnector-root': {
          marginRight: theme.spacing(1),
        },

        '.MuiStepButton-root': {
          justifyContent: 'flex-end',
        },
      }}
    >
      {steps &&
        steps.map((item, index) => (
          <Step key={item.id}>
            <Link
              to={item.id}
              spy={true}
              smooth={true}
              duration={250}
              containerId='containerElement'
              onSetActive={() => handleStep(index)}
            >
              <StepButton
                onClick={() => handleStep(index)}
                icon={' '}
                sx={{
                  padding: theme.spacing(2, 1),

                  '.MuiStepLabel-root': {
                    flexDirection: 'row-reverse',
                  },

                  '.MuiStepLabel-labelContainer': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: 150,
                    height: 35,
                  },

                  '.MuiStepLabel-label': {
                    fontSize: 12,
                    textAlign: 'right',
                    marginRight: 2,
                    wordWrap: 'break-word',
                  },
                }}
              >
                {item.title}
              </StepButton>
            </Link>
          </Step>
        ))}
    </Stepper>
  );
};

export default MuiStepper;
