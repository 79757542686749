import { Typography, useTheme } from '@mui/material';
import ChangeRequested from 'assets/icons/checkCircle.svg';
import Locked from 'assets/icons/lockedCircle.svg';
import Unlocked from 'assets/icons/unLockedCircle.svg';
import ColoredChip from 'components/coloredChip';
import { ChipColor } from 'components/coloredChip/interfaces/chipColor';
import { ArrivalInstructionStatus } from 'hooks/services/hosts/interfaces/arrivalInstructionSummary.response';
import { useTranslations } from 'hooks/useTranslations';

const StatusComponent = ({
  status,
  isPrev = false,
}: {
  status: ArrivalInstructionStatus;
  isPrev?: boolean;
}) => {
  const theme = useTheme();
  const {
    arrivalInformation: {
      status: { changeRequested, locked, unlocked },
      requestedChanges,
    },
  } = useTranslations();

  let color: ChipColor;
  let icon: string;
  let text: string;

  switch (status) {
    case 'ChangeRequested':
      color = 'statusGreen';
      icon = ChangeRequested;
      text = changeRequested;
      break;
    case 'Locked':
      color = 'statusGrayedWithWhiteLabel';
      icon = Locked;
      text = locked;
      break;
    case 'Unlocked':
      color = 'statusBlue';
      icon = Unlocked;
      text = unlocked;
      break;
  }

  if (!isPrev && status === 'ChangeRequested') {
    return (
      <Typography
        variant='caption'
        fontWeight={theme.typography.fontWeightBold}
        mt={2}
      >
        {requestedChanges}
      </Typography>
    );
  }

  return <ColoredChip color={color} img={icon} text={text} />;
};

export default StatusComponent;
