import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { CellHeader } from '../interfaces/cellHeader';

const SortableTableHeader = <T extends string>({
  order,
  orderBy,
  sortHandler,
  cells,
  align = 'left',
}: {
  order: SortOrder | undefined;
  orderBy: T | undefined;
  sortHandler: (property: T) => void;
  cells: CellHeader<T>[];
  align?: 'left' | 'center' | 'right';
}) => {
  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => (
          <TableCell
            key={cell.id}
            sortDirection={orderBy === cell.id ? order : false}
            align={align}
          >
            {cell.sortable ? (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={() => sortHandler(cell.id)}
              >
                {cell.label}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SortableTableHeader;
