import { useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonService } from 'hooks/services/commons';
import { useHostService } from 'hooks/services/hosts';
import { useHeightRef } from 'hooks/useHeightRef';
import { Element } from 'react-scroll';
import { KeyValueItemProps } from 'utils/interfaces/keyItemProps';
import { convertBackEndKeyItemsToKeyItems } from 'utils/keyItemProps/keyItemConverter';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import HousingForm from './housingForm';
import { HousingFormInput } from './interfaces/housingForm.formInput';
import { HousingFormDetails } from './sections/details/housingFormDetails';
import { HousingFormSecurity } from './sections/security/housingFormSecurity';
import { HousingFormUtilities } from './sections/utilities/housingFormUtilities';
import { initWithEmptyValues } from './utils/initValues';
import { makeRequest } from './utils/makeRequest';
import { setValues } from './utils/setValues';
import { schema } from './validation/validation.schema';

const HousingFormContainer = () => {
  const [housingTypes, setHousingTypes] = useState<KeyValueItemProps[]>([]);
  const [rentPeriodicity, setRentPeriodicity] = useState<KeyValueItemProps[]>(
    []
  );
  const [housingModels, setHousingModels] = useState<KeyValueItemProps[]>([]);
  const [timeUnits, setTimeUnits] = useState<KeyValueItemProps[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const theme = useTheme();
  const { hostId, housingId } = useParams();
  const navigate = useNavigate();
  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();
  const [stepperHeight, stepperRef] = useHeightRef();
  const general = pageHeaderHeight + buttonsHeight + stepperHeight;
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const matchMd = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });
  const matchMdToLg = useMediaQuery(
    '(min-width: 1034px) and (max-width: 1200px)',
    { noSsr: true }
  );
  const matchMdOnly = useMediaQuery(
    '(min-width: 1141px) and (max-width: 1199px)',
    { noSsr: true }
  );

  const housingFormSections = [
    {
      id: 'Details',
      title: 'Details',
    },
    {
      id: 'Utilities',
      title: 'Utilities',
    },
    {
      id: 'Security',
      title: 'Security',
    },
  ];

  const housingFormComponents: { [key: string]: ReactElement } = {
    Details: (
      <HousingFormDetails
        {...{
          housingModels,
          housingTypes,
          rentPeriodicity,
          timeUnits,
          matchMd,
          matchMdToLg,
          matchMdOnly,
        }}
      />
    ),
    Utilities: <HousingFormUtilities />,
    Security: <HousingFormSecurity />,
  };

  const sectionHousingFormComponents = housingFormSections.map(
    (section: any) => (
      <Element key={section.id} name={section.id}>
        {housingFormComponents[section.id]}
      </Element>
    )
  );

  const {
    getHousingTypes,
    getRentPeriodicity,
    getHousingOptions,
    getTimeUnitsOptions,
  } = useCommonService();

  const { postCreateHousing, patchUpdateHousing, getCompanyHousingById } =
    useHostService();

  const formMethods = useForm<HousingFormInput>({
    mode: 'onSubmit',
    defaultValues: initWithEmptyValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const { handleSubmit, setValue } = formMethods;

  const submitHandler = async (data: HousingFormInput, event: any) => {
    event.preventDefault();
    const body = makeRequest(data);

    if (isEditMode) {
      await patchUpdateHousing({
        hostId: Number(hostId),
        housingId: Number(housingId),
        body,
      });
    } else {
      await postCreateHousing({ hostId: Number(hostId), body });
    }

    navigate(
      ApplicationRoutes.companyHousing.replace(':hostId', hostId as string)
    );
  };

  const onSubmit = handleSubmit(submitHandler);

  const fetchHousingTypes = async () => {
    const response = await getHousingTypes();
    const convertedItems = convertBackEndKeyItemsToKeyItems(response);
    setHousingTypes(convertedItems);
  };

  const fetchRentPeriodicity = async () => {
    const response = await getRentPeriodicity();
    const convertedItems = convertBackEndKeyItemsToKeyItems(response);
    setRentPeriodicity(convertedItems);
  };

  const fetchHousingOptions = async () => {
    const response = await getHousingOptions();
    const convertedItems = convertBackEndKeyItemsToKeyItems(response);
    setHousingModels(convertedItems);
  };

  const fetchTimeUnits = async () => {
    const response = await getTimeUnitsOptions();
    const convertedItems = convertBackEndKeyItemsToKeyItems(response);
    setTimeUnits(convertedItems);
  };

  const fetchHousingById = async () => {
    const response = await getCompanyHousingById({
      hostId: Number(hostId),
      housingId: Number(housingId),
    });

    setValues(response, setValue);
  };

  const handleCancelButton = (hostId: string) => {
    navigate(ApplicationRoutes.companyHousing.replace(':hostId', hostId));
  };

  useEffect(() => {
    const isEditMode: boolean = !!housingId && !isNaN(Number(housingId));

    fetchHousingTypes();
    fetchRentPeriodicity();
    fetchHousingOptions();
    fetchTimeUnits();

    if (isEditMode) {
      fetchHousingById();
    }

    setIsEditMode(isEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <HousingForm
        onSubmit={onSubmit}
        isEditMode={isEditMode}
        hostId={hostId!}
        housingId={Number(housingId)}
        handleCancelButton={handleCancelButton}
        pageHeaderRef={pageHeaderRef}
        buttonsRef={buttonsRef}
        stepperRef={stepperRef}
        housingFormSections={housingFormSections}
        matchDownMd={matchDownMd}
        general={general}
        sectionHousingFormComponents={sectionHousingFormComponents}
      />
    </FormProvider>
  );
};

export default HousingFormContainer;
