import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import ConnectForm from 'components/connectForm';
import DropdownYesNo from 'components/dropdownYesNo';
import { SectionHeader } from 'components/page/header/styledHeader';
import { ContainerSpacing } from 'components/page/header/utils';
import TextareaInput from 'components/textAreaInput';
import { useFormInputsStyle } from 'hooks/useFormInputsStyle';
import { useTranslations } from 'hooks/useTranslations';
import EditPlacementContext from '../../editPlacement.context';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

const TrainingOperation = () => {
  const theme = useTheme();

  const {
    smallInput,
    numberInput,
    yesOrNoInput,
    inputsGridFullWidth,
    inputsSection,
  } = useFormInputsStyle(theme);

  const { trainingOperationSection } = useTranslations();
  const {
    state: { isReadOnly },
  } = useContext(EditPlacementContext);

  const matchXsToMd = useMediaQuery(
    '(min-width: 750px) and (max-width: 916px)',
    { noSsr: true }
  );
  const matchMdToUp = useMediaQuery(
    '(min-width: 917px) and (max-width: 985px)',
    { noSsr: true }
  );

  return (
    <ConnectForm<EditPlacementFormInput>>
      {({ register, control, getValues, formState: { errors } }) => (
        <Box>
          <SectionHeader>
            {trainingOperationSection.trainingSectionTitle}
          </SectionHeader>

          <Grid {...ContainerSpacing}>
            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  errorMessage={errors.trainingOrientation?.provided?.message}
                  inputId={trainingOperationSection.trainingProvided}
                  inputLabel={trainingOperationSection.trainingProvided}
                  register={register('trainingOrientation.provided')}
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...inputsGridFullWidth}>
                <TextareaInput
                  errorMessage={
                    errors.trainingOrientation?.description?.message
                  }
                  inputId={trainingOperationSection.trainingDescription}
                  inputLabel={trainingOperationSection.trainingDescription}
                  register={register('trainingOrientation.description')}
                  control={control}
                  maxLength={1000}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={trainingOperationSection.trainingEvPaid}
                  inputLabel={trainingOperationSection.trainingEvPaid}
                  errorMessage={
                    errors.trainingOrientation?.isEVPaidDuring?.message
                  }
                  control={control}
                  register={register('trainingOrientation.isEVPaidDuring')}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...numberInput}>
                <TextareaInput
                  inputId={trainingOperationSection.trainingPayRate}
                  inputLabel={trainingOperationSection.trainingPayRate}
                  errorMessage={errors?.trainingOrientation?.payRate?.message}
                  control={control}
                  register={register('trainingOrientation.payRate')}
                  singleLine={true}
                  numberOfRows={1}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...smallInput}>
                <TextareaInput
                  inputId={trainingOperationSection.trainingLength}
                  inputLabel={trainingOperationSection.trainingLength}
                  errorMessage={errors?.trainingOrientation?.length?.message}
                  register={register('trainingOrientation.length')}
                  singleLine
                  numberOfRows={1}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSection}>
              <Grid {...yesOrNoInput}>
                <DropdownYesNo
                  inputId={trainingOperationSection.trainingEVIncurCost}
                  inputLabel={trainingOperationSection.trainingEVIncurCost}
                  labelHeight={matchXsToMd ? 90 : matchMdToUp ? 69 : 46}
                  errorMessage={
                    errors.trainingOrientation?.willParticipantIncurCost
                      ?.message
                  }
                  control={control}
                  register={register(
                    'trainingOrientation.willParticipantIncurCost'
                  )}
                  disabled={isReadOnly}
                />
              </Grid>

              <Grid {...numberInput}>
                <TextareaInput
                  inputId={trainingOperationSection.trainingProvidedCost}
                  inputLabel={trainingOperationSection.trainingProvidedCost}
                  labelHeight={matchXsToMd ? 90 : matchMdToUp ? 69 : 46}
                  errorMessage={
                    errors?.trainingOrientation?.providedCost?.message
                  }
                  disabled={isReadOnly}
                  register={register('trainingOrientation.providedCost')}
                  control={control}
                  numberOfRows={1}
                  singleLine={true}
                />
              </Grid>

              <Grid {...numberInput}>
                <TextareaInput
                  inputId={trainingOperationSection.trainingCostDue}
                  inputLabel={trainingOperationSection.trainingCostDue}
                  labelHeight={matchXsToMd ? 90 : matchMdToUp ? 69 : 46}
                  errorMessage={errors?.trainingOrientation?.costDue?.message}
                  disabled={isReadOnly}
                  register={register('trainingOrientation.costDue')}
                  control={control}
                  numberOfRows={1}
                  singleLine={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ConnectForm>
  );
};

export default TrainingOperation;
