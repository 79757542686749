import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';

import SortableTableHeader from 'components/responsiveListView/components/sortableTableHeader';
import { CellHeader } from 'components/responsiveListView/interfaces/cellHeader';

import { SortOrder } from 'hooks/services/commons/interfaces/sortOrder';
import { useTranslations } from 'hooks/useTranslations';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';
import { RowNotFound } from 'components/tables/components/rowNotFound';

import { getCells } from '../../utils/table.cells';
import { BE_DATE_FORMAT, formatDate } from 'utils/date/convertToDate';
import { useHorizontalScrollBar } from 'hooks/useHorizontalScrollBar';
import { CellHeaderId } from '../interviewsToBookTable/interfaces/interviewsToBookCellHeader';
import { InterviewToBookResponse } from 'hooks/services/hosts/interfaces/interviewsToBook.response';

export const InterviewsToBookListView = ({
  order,
  orderBy,
  sortHandler,
  rows,
  heightToSkip,
  handleClick,
  handleInterviewAction,
  openDeclineInterviewModal,
}: {
  order: SortOrder | undefined;
  orderBy: CellHeaderId | undefined;
  sortHandler: (property: CellHeaderId) => void;
  rows: InterviewToBookResponse[];
  heightToSkip: number;
  handleClick: (applicantId: number) => void;
  handleInterviewAction: (row: InterviewToBookResponse) => void;
  openDeclineInterviewModal: (row: InterviewToBookResponse) => void;
}) => {
  const theme = useTheme();
  const horizontalScrollBar = useHorizontalScrollBar();
  const {
    interviewsToBook: {
      interviewsToBookTable: { columns, buttonsActions },
    },
  } = useTranslations();

  const cells: CellHeader<CellHeaderId>[] = getCells(columns);
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const margins = isSmallScreen ? 0 : 16 + 16 + 8;

  return (
    <Box
      mt={2}
      pb={1}
      px={'1px'}
      sx={{
        ...ResponsiveContainerProps(heightToSkip + margins),
        ...horizontalScrollBar,
      }}
    >
      <Table stickyHeader>
        <SortableTableHeader<CellHeaderId>
          {...{ order, orderBy, sortHandler, cells }}
        />
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row: InterviewToBookResponse) => (
              <TableRow key={row.id}>
                <TableCell sx={{ minWidth: 200 }}>{row.hostEntity}</TableCell>
                <TableCell sx={{ minWidth: 250 }}>{row.program}</TableCell>
                <TableCell
                  sx={{ minWidth: 100, cursor: 'pointer' }}
                  onClick={() => handleClick(row.applicantId)}
                >
                  {row.applicantId}
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  {row.applicantName}
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.email}</TableCell>
                <TableCell sx={{ minWidth: 250 }}>{row.status}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>{row.skype}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {row.countryOfResidence}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>{row.jobId}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.position}</TableCell>

                <TableCell sx={{ minWidth: 120 }}>
                  {formatDate(row.startsOn,
                    BE_DATE_FORMAT,
                    'MMM d, yyyy'
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 120 }}>
                  {formatDate(row.endsOn,
                    BE_DATE_FORMAT,
                    'MMM d, yyyy'
                  )}                </TableCell>
                <TableCell align='center'>
                  <Grid
                    container
                    justifyContent={'space-around'}
                    flexWrap={'nowrap'}
                    gap={1}
                  >
                    <Grid item>
                      <Button
                        variant='outlined'
                        color='secondary'
                        sx={{ px: theme.spacing(1.5) }}
                        onClick={() => { openDeclineInterviewModal(row); }}
                      >
                        {buttonsActions.decline}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='secondary'
                        sx={{ px: theme.spacing(2) }}
                        onClick={() => {
                          handleInterviewAction(row);
                        }}
                      >
                        {buttonsActions.interview}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <RowNotFound colSpan={15} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
