import {
  CompanyHousingBodyRequest,
  CompanyHousingDetailsRequest,
  CompanyHousingSecurityRequest,
  CompanyHousingUtilitiesRequest,
} from 'hooks/services/hosts/interfaces/companyHousingBody.request';
import {
  convertToBoolean,
  convertToNumber,
} from 'utils/string/stringConverters';
import {
  HousingDetailsFormInput,
  HousingFormInput,
  HousingSecurityFormInput,
  HousingUtilitiesFormInput,
} from '../interfaces/housingForm.formInput';

export const makeRequest = (
  data: HousingFormInput
): CompanyHousingBodyRequest => {
  return {
    details: makeDetailsRequest(data.details),
    utilities: makeUtilitiesRequest(data.utilities),
    security: makeSecurityRequest(data.security),
  };
};

const makeDetailsRequest = (
  data: HousingDetailsFormInput
): CompanyHousingDetailsRequest => {
  const {
    housingModel,
    housingType,
    propertyName,
    contactName,
    propertyManagement,
    canBeCoEd,
    phoneNumber,
    website,
    emailForReservationEnquiries,
    description,
    addressStreet,
    addressCity,
    addressState,
    addressZipCode,
    numberOfBedsPerRoom,
    numberOfBedrooms,
    participantsPerProperty,
    participantsPerRoom,
    bathroomsPerProperty,
    beddingAndTowels,
    costOfBeddingAndTowels,
    beddingAndTowelPaymentDue,
    kitchenFacilities,
    additionalItemsMustBring,
    additionalComments,
    housingCost,
    rentPeriodicity,
    isWeeklyRentPayrollDeducted,
    includedInCost,
    additionalItemsIncludedInCost,
    administrationFee,
    administrationFeeDue,
    housingDepositAmount,
    isHousingDepositPayrollDeducted,
    housingDepositRefundable,
    furtherInformationOnHousingRefundPolicy,
    leaseRequired,
    lengthOfLease,
    timeUnit,
    furtherInformationOnLengthOfLease,
    feesAdditionalComments,
    depositRefundPolicy,
    isRentersInsuranceRequired,
    housingAmentities,
    distanceFromWorkSiteToHousing,
    transportationDetails,
  } = data;

  return {
    housingModel,
    housingType,
    propertyName,
    contactName,
    propertyManagement,
    canBeCoEd: convertToBoolean(canBeCoEd),
    phoneNumber,
    website,
    emailForReservationEnquiries,
    description,
    addressStreet,
    addressCity,
    addressState,
    addressZipCode,
    numberOfBedsPerRoom: numberOfBedsPerRoom,
    numberOfBedrooms: numberOfBedrooms,
    participantsPerProperty: participantsPerProperty,
    participantsPerRoom: participantsPerRoom,
    bathroomsPerProperty: bathroomsPerProperty,
    beddingAndTowels: convertToBoolean(beddingAndTowels),
    costOfBeddingAndTowels: convertToNumber(costOfBeddingAndTowels),
    beddingAndTowelPaymentDue: beddingAndTowelPaymentDue,
    kitchenFacilities,
    additionalItemsMustBring,
    additionalComments,
    housingCost: convertToNumber(housingCost),
    rentPeriodicity,
    isWeeklyRentPayrollDeducted: convertToBoolean(isWeeklyRentPayrollDeducted),
    includedInCost,
    additionalItemsIncludedInCost,
    administrationFee: convertToNumber(administrationFee),
    administrationFeeDue,
    housingDepositAmount: convertToNumber(housingDepositAmount),
    isHousingDepositPayrollDeducted: convertToBoolean(
      isHousingDepositPayrollDeducted
    ),
    isHousingDepositRefundable: convertToBoolean(housingDepositRefundable),
    furtherInformationOnHousingRefundPolicy:
      furtherInformationOnHousingRefundPolicy ?? '',
    isLeaseRequired: convertToBoolean(leaseRequired),
    lengthOfLease: convertToNumber(lengthOfLease),
    timeUnit,
    furtherInformationOnLengthOfLease: furtherInformationOnLengthOfLease ?? '',
    feesAdditionalComments,
    depositRefundPolicy: convertToBoolean(depositRefundPolicy),
    isRentersInsuranceRequired: convertToBoolean(isRentersInsuranceRequired),
    housingAmentities,
    distanceFromWorkSiteToHousing,
    transportationDetails,
  };
};

const makeUtilitiesRequest = (
  data: HousingUtilitiesFormInput
): CompanyHousingUtilitiesRequest => {
  const {
    gas,
    electric,
    water,
    sewer,
    trash,
    internet,
    tv,
    telephone,
    isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants,
    theAverageMonthlyCostOfAnyUtilitiesNotIncluded,
  } = data;

  return {
    gas: convertToBoolean(gas),
    electric: convertToBoolean(electric),
    water: convertToBoolean(water),
    sewer: convertToBoolean(sewer),
    trash: convertToBoolean(trash),
    internet: convertToBoolean(internet),
    tv: convertToBoolean(tv),
    telephone: convertToBoolean(telephone),
    isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants:
      convertToBoolean(
        isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants
      ),
    theAverageMonthlyCostOfAnyUtilitiesNotIncluded: convertToNumber(
      theAverageMonthlyCostOfAnyUtilitiesNotIncluded
    ),
  };
};

const makeSecurityRequest = (
  data: HousingSecurityFormInput
): CompanyHousingSecurityRequest => {
  const {
    gated,
    areThereActiveSecurityCamerasOnSite,
    secured,
    isThereARepresentativeOnSite24For7,
    contactName,
    contactPhone,
    contactEmail,
    bus,
    frequencyOfBus,
    subway,
    frequencyOfSubway,
    train,
    frequencyOfTrain,
    otherPublicTransport,
    isThereAWebsiteForPublicTransportation,
    isItConsideredSafeToWalkToTheNearestPublicTransportationStop,
    howFarIsIt,
    doesItRunToTheJobsite,
  } = data;

  return {
    gated: convertToBoolean(gated),
    areThereActiveSecurityCamerasOnSite: convertToBoolean(
      areThereActiveSecurityCamerasOnSite
    ),
    secured: convertToBoolean(secured),
    isThereARepresentativeOnSite24For7: convertToBoolean(
      isThereARepresentativeOnSite24For7
    ),
    contactName,
    contactPhone,
    contactEmail,
    bus: convertToBoolean(bus),
    frequencyOfBus,
    subway: convertToBoolean(subway),
    frequencyOfSubway,
    train: convertToBoolean(train),
    frequencyOfTrain,
    otherPublicTransport,
    isThereAWebsiteForPublicTransportation,
    isItConsideredSafeToWalkToTheNearestPublicTransportationStop:
      convertToBoolean(
        isItConsideredSafeToWalkToTheNearestPublicTransportationStop
      ),

    howFarIsIt,
    doesItRunToTheJobsite: convertToBoolean(doesItRunToTheJobsite),
  };
};
