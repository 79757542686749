import { UseFormSetValue } from 'react-hook-form';
import { EmploymentPosition } from 'hooks/services/placements/interfaces/placement.response';
import { BE_DATE_FORMAT, convertToDate } from 'utils/date/convertToDate';
import {
  convertBooleanToString,
  getStringValueOrEmptyString,
} from 'utils/string/stringConverters';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export const setEmploymentValues = (
  values: EmploymentPosition,
  setValue: UseFormSetValue<EditPlacementFormInput>
) => {
  if (!values) {
    return;
  }

  setValue('employment.program', values.programId?.toString());
  setValue(
    'employment.programTypes',
    values.programTypes?.map((i) => i.toString()) as []
  );

  setValue(
    'employment.supervisor',
    getStringValueOrEmptyString(values.supervisorId)
  );

  setValue('employment.totalPositions', values.totalPositions.toString());

  setValue(
    'employment.department',
    getStringValueOrEmptyString(values.department)
  );
  setValue('employment.englishLevel', values.englishProficiencyLevel || '');
  setValue(
    'employment.positionDescription',
    getStringValueOrEmptyString(values.positionDescription)
  );
  setValue(
    'employment.positionTitle',
    getStringValueOrEmptyString(values.position)
  );
  setValue(
    'employment.selfArranged',
    convertBooleanToString(values.isSelfArranged)
  );
  setValue(
    'employment.positionDescription',
    getStringValueOrEmptyString(values.positionDescription)
  );
  setValue(
    'employment.scheduleDescription',
    getStringValueOrEmptyString(values.scheduleDescription)
  );
  setValue(
    'employment.requiredSkills',
    getStringValueOrEmptyString(values.requiredSkills)
  );
  setValue(
    'employment.requiredExperience',
    getStringValueOrEmptyString(values.requiredExperience)
  );
  setValue(
    'employment.specialRequirements',
    getStringValueOrEmptyString(values.specialSkills)
  );
  setValue(
    'employment.startDate',
    convertToDate(values.earliestStartDate, BE_DATE_FORMAT)
  );
  setValue(
    'employment.endDate',
    convertToDate(values.earliestEndDate, BE_DATE_FORMAT)
  );
  setValue(
    'employment.latestStartDate',
    convertToDate(values.latestStartDate, BE_DATE_FORMAT)
  );
  setValue(
    'employment.latestEndDate',
    convertToDate(values.latestEndDate, BE_DATE_FORMAT)
  );
};
