import { Button } from '@mui/material';
import { PlacementStatus } from 'hooks/services/placements/interfaces/placementStatus';
import { PlacementSummaryResponse } from 'hooks/services/placements/interfaces/placementSummary.response';
import { useTranslations } from 'hooks/useTranslations';
import { PlacementAction } from 'pages/placements/placementsList/interfaces/placementAction';

const ActionButton = ({
  placement,
  handlePlacementAction,
}: {
  placement: PlacementSummaryResponse;
  handlePlacementAction: (
    placement: PlacementSummaryResponse,
    action: PlacementAction
  ) => void;
}) => {
  const translations = useTranslations();

  let buttonText: string | undefined = undefined;
  let onClickHandler:
    | ((placement: PlacementSummaryResponse) => void)
    | undefined;

  if (!placement.confirmed) {
    switch (placement.status) {
      case PlacementStatus.Draft:
        onClickHandler = (placement: PlacementSummaryResponse) =>
          handlePlacementAction(placement, PlacementAction.Submit);
        buttonText = translations.placementsList.placementTable.buttons.submit;
        break;
      case PlacementStatus.Open:
      case PlacementStatus.PendingReview:
      case PlacementStatus.OpenPendingReview:
        onClickHandler = (placement: PlacementSummaryResponse) =>
          handlePlacementAction(placement, PlacementAction.Confirm);
        buttonText = translations.placementsList.placementTable.buttons.confirm;
        break;
      case PlacementStatus.NeedsReview:
        onClickHandler = (placement: PlacementSummaryResponse) =>
          handlePlacementAction(placement, PlacementAction.Review);
        buttonText = translations.placementsList.placementTable.buttons.review;
        break;
    }
  }

  return !!onClickHandler ? (
    <Button
      variant='contained'
      color='secondary'
      onClick={() => onClickHandler!(placement)}
    >
      {buttonText}
    </Button>
  ) : (
    <></>
  );
};

export default ActionButton;
