import { useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHostService } from 'hooks/services/hosts';
import { useHeightRef } from 'hooks/useHeightRef';
import { useTranslations } from 'hooks/useTranslations';
import { Element } from 'react-scroll';
import { OfferDetailsFormInput } from './interfaces/offerDetailsForm.formInput';
import OfferDetailsForm from './offerDetailsForm';
import { GroomingOfferDetails } from './sections/grooming/groomingOfferDetails';
import { HousingOfferDetails } from './sections/housing/housingOfferDetails';
import { ScreeningOfferDetails } from './sections/screening/screeningOfferDetails';
import { SocialSecurityOfferDetails } from './sections/socialSecurity/socialSecurityOfferDetails';
import { initWithEmptyValues } from './utils/initValues';
import { makeRequest } from './utils/makeRequest';
import { setValues } from './utils/setValues';
import { schema } from './validation/validation.schema';

const OfferDetailsContainer = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const theme = useTheme();
  const { hostId } = useParams();
  const [pageHeaderHeight, pageHeaderRef] = useHeightRef();
  const [buttonsHeight, buttonsRef] = useHeightRef();
  const [stepperHeight, stepperRef] = useHeightRef();
  const general = pageHeaderHeight + buttonsHeight + stepperHeight;
  const translations = useTranslations();
  const { stepper } = translations.offerDetailsForm;

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const matchBetweenXsMdForHousing = useMediaQuery(
    '(min-width: 750px) and (max-width: 845px)',
    {
      noSsr: true,
    }
  );

  const matchBetweenMdLgForHousing = useMediaQuery(
    '(min-width: 846px) and (max-width: 2000px)',
    {
      noSsr: true,
    }
  );

  const matchMdAboveForSecurity = useMediaQuery(
    '(min-width: 923px) and (max-width: 2000px)',
    {
      noSsr: true,
    }
  );

  const offerDetailsSections = [
    {
      id: 'screening',
      title: stepper.screening,
    },
    {
      id: 'grooming',
      title: stepper.grooming,
    },
    {
      id: 'housing',
      title: stepper.housing,
    },
    {
      id: 'socialSecurity',
      title: stepper.socialSecurity,
    },
  ];

  const offerDetailsFormComponents: { [key: string]: ReactElement } = {
    screening: <ScreeningOfferDetails isDisabled={isDisabled} />,
    grooming: <GroomingOfferDetails isDisabled={isDisabled} />,
    housing: (
      <HousingOfferDetails
        isDisabled={isDisabled}
        matchBetweenXsMdForHousing={matchBetweenXsMdForHousing}
        matchBetweenMdLgForHousing={matchBetweenMdLgForHousing}
      />
    ),
    socialSecurity: (
      <SocialSecurityOfferDetails
        isDisabled={isDisabled}
        matchMdAboveForSecurity={matchMdAboveForSecurity}
      />
    ),
  };

  const sectionOfferDetailsComponents = offerDetailsSections.map(
    (section: any) => (
      <Element key={section.id} name={section.id}>
        {offerDetailsFormComponents[section.id]}
      </Element>
    )
  );

  const { createOfferDetails, getOfferDetails } = useHostService();

  const formMethods = useForm<OfferDetailsFormInput>({
    mode: 'onSubmit',
    defaultValues: initWithEmptyValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setValue } = formMethods;

  const getOfferDetailsInfo = async () => {
    if (!hostId) return;
    try {
      const response = await getOfferDetails(parseInt(hostId));
      setValues(response, setValue);
      setIsDisabled(response.isLocked);
    } catch (e) {
      return;
    }
  };

  const submitHandle = async (data: OfferDetailsFormInput, event: any) => {
    event.preventDefault();
    const body = makeRequest(data);
    await createOfferDetails({ hostId: Number(hostId), ...body });
    setIsDisabled(true);
  };

  const onSubmit = handleSubmit(submitHandle);

  useEffect(() => {
    getOfferDetailsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <OfferDetailsForm
        onSubmit={onSubmit}
        pageHeaderRef={pageHeaderRef}
        buttonsRef={buttonsRef}
        stepperRef={stepperRef}
        offerDetailsSections={offerDetailsSections}
        matchDownMd={matchDownMd}
        general={general}
        sectionOfferDetailsComponents={sectionOfferDetailsComponents}
        isDisabled={isDisabled}
      />
    </FormProvider>
  );
};

export default OfferDetailsContainer;
