import { TextField, TextFieldProps } from '@mui/material';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker as MuiDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { StaticDatePickerProps } from './interfaces/staticDatePickerProps';

export const StaticDatePicker = ({
  value,
  onChange,
  renderDayCustom,
  minDate,
  disablePast,
}: StaticDatePickerProps) => {
  return (
    <MuiDatePicker
      data-testid='date-input'
      displayStaticWrapperAs='desktop'
      value={value}
      onChange={onChange}
      disablePast={disablePast}
      minDate={minDate}
      renderDay={(
        day: Date,
        selectedDays: Date[],
        pickersDayProps: PickersDayProps<Date>
      ) => {
        return renderDayCustom(day, selectedDays, pickersDayProps);
      }}
      renderInput={(params: TextFieldProps) => <TextField {...params} />}
    />
  );
};
