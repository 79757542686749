import * as yup from 'yup';
import createEmploymentSchema from '../sections/employment/validation/validation.schema';
import { schema as grooming } from '../sections/grooming/validation/validation.schema';
import { schema as interviews } from '../sections/interviews/validation/validation.schema';
import { schema as payment } from '../sections/payment/validation/validation.schema';
import { schema as housing } from '../sections/housing/validation/validation.schema';
import { schema as trainingOrientation } from '../sections/trainingOrientation/validation/validation.schema';

export default function createSchema(
  checkSelectedProgram: (value: string) => boolean
) {
  return yup.object().shape({
    employment: createEmploymentSchema(checkSelectedProgram),
    payment,
    grooming,
    interviews,
    housing,
    trainingOrientation
  });
}
