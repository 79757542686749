import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import MobileStepper from './mobileStepper';
import MuiStepper from './stepper';

const StepperContainer = ({
  steps,
  breakPoint,
}: {
  steps?: {
    title: string;
    id: string;
  }[];
  breakPoint?: 'xs' | 'sm' | 'md' | 'lg';
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const theme = useTheme();
  const matchDownMd = useMediaQuery(
    theme.breakpoints.down(breakPoint ?? 'md'),
    { noSsr: true }
  );

  const handleStep = (step: number) => setActiveStep(step);

  return (
    <>
      {matchDownMd ? (
        <Grid item>
          <MobileStepper
            activeStep={activeStep}
            handleStep={handleStep}
            steps={steps}
          />
        </Grid>
      ) : (
        <Grid item>
          <MuiStepper
            activeStep={activeStep}
            handleStep={handleStep}
            steps={steps}
          />
        </Grid>
      )}
    </>
  );
};

export default StepperContainer;
