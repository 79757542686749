import { UseFormSetValue } from 'react-hook-form';

import { TrainingOrientation } from 'hooks/services/placements/interfaces/placement.response';
import {
  convertBooleanToString,
  getStringValueOrEmptyString,
} from 'utils/string/stringConverters';
import { EditPlacementFormInput } from '../../interfaces/editPlacementFormInput';

export function setTrainingOrientationValues(
  data: TrainingOrientation,
  setValue: UseFormSetValue<EditPlacementFormInput>
) {
  if (!data) {
    return;
  }

  setValue(
    'trainingOrientation.costDue',
    getStringValueOrEmptyString(data.costDue)
  );
  setValue(
    'trainingOrientation.description',
    getStringValueOrEmptyString(data.description)
  );
  setValue(
    'trainingOrientation.isEVPaidDuring',
    convertBooleanToString(data.isEVPaidDuring)
  );
  setValue(
    'trainingOrientation.length',
    getStringValueOrEmptyString(data.length)
  );
  setValue(
    'trainingOrientation.payRate',
    getStringValueOrEmptyString(data.payRate)
  );
  setValue(
    'trainingOrientation.provided',
    getStringValueOrEmptyString(data.provided)
  );
  setValue(
    'trainingOrientation.providedCost',
    getStringValueOrEmptyString(data.providedCost)
  );
  setValue(
    'trainingOrientation.willParticipantIncurCost',
    convertBooleanToString(data.willParticipantIncurCost)
  );
}
