import { TableCell, TableHead, TableRow } from '@mui/material';
import { HousingCellHeader } from '../../interfaces/housingCellHeader';

const HousingTableHeader = ({

  cells,
}: {
  cells: readonly HousingCellHeader[];
}) => {
  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => (
          <TableCell key={cell.id} >
            {cell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default HousingTableHeader;
