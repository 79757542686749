import { Paper, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import NoScrollBar from 'utils/interfaces/noScrollBar';

const ResponsiveFiltersBar = ({
  desktopView,
  filtersBarRef,
  mobileView = undefined,
  sx,
}: {
  filtersBarRef: (node: HTMLDivElement) => void;
  desktopView: ReactElement;
  mobileView?: ReactElement;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <Paper
      ref={filtersBarRef}
      elevation={4}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        mb: 1,
        ...NoScrollBar,
        ...sx,
      }}
    >
      {isMobile && !!mobileView ? mobileView : desktopView}
    </Paper>
  );
};
export default ResponsiveFiltersBar;
