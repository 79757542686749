import { Box } from '@mui/material';

export const MobileIcon = ({
  icon,
  backgroundColor,
}: {
  icon: string;
  backgroundColor: string;
}) => {
  const svgStyle = {
    height: 25,
    width: 25,
  };

  const boxStyle = {
    height: 38,
    width: 41,
    borderRadius: 1,
    backgroundColor,
  };

  return (
    <Box
      justifyContent='center'
      alignItems='center'
      display='flex'
      sx={boxStyle}
    >
      <Box component='img' src={icon} style={svgStyle} />
    </Box>
  );
};
