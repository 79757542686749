import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  useTheme,
  ListItemText,
  Link,
} from '@mui/material';

import { OptionalTooltip } from 'components/tooltip/tooltip';
import { useTranslations } from 'hooks/useTranslations';

const SingleLevelMenuItem = ({
  open,
  text,
  route,
  icon,
  isLocked,
}: {
  open: boolean;
  text: string;
  route?: string;
  icon: string;
  isLocked?: boolean;
}) => {
  const theme = useTheme();
  const { lockedMenu } = useTranslations();

  return (
    <OptionalTooltip
      show={!open || !!isLocked}
      text={!!isLocked ? lockedMenu : text}
      key={text}
    >
      <ListItem
        data-testid='list-item'
        component={Link}
        href={route}
        key={text}
        disablePadding
        sx={{ display: 'block' }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2,
            borderRadius: theme.spacing(1.2),
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.5 : 'auto',
              justifyContent: 'center',
            }}
          >
            <Box component='img' alt='' src={icon} />
          </ListItemIcon>
          {open && <ListItemText primary={text} />}
        </ListItemButton>
      </ListItem>
    </OptionalTooltip>
  );
};

export default SingleLevelMenuItem;
