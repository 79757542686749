import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePlacementService } from 'hooks/services/placements';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';
import CreatePlacement from './createPlacement';
import { CreatePlacementFormInput } from './interfaces/createPlacementFormInput';
import { CreatePlacementRequest } from './interfaces/createPlacementRequest';
import createValidationSchema from './validation/validation.schema';
import useProgramWithTypeHook from '../common/hooks/useProgramWithTypeHook';
import { useAlert } from 'providers/alertProvider';
import { useTranslations } from 'hooks/useTranslations';

const CreatePlacementContainer = () => {
  const translations = useTranslations();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const { hostId } = useParams();
  const { postPlacement } = usePlacementService();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    getValues,
    reset,
    resetField,
  } = useForm<CreatePlacementFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(
      createValidationSchema((value) => validateSelectedProgram(value))
    ),
    defaultValues: {
      program: '',
      positionDescription: '',
      totalPositions: '',
      programTypes: [],
    },
  });
  const program = useWatch({ control, name: 'program' });

  const { availableProgramTypes, availablePrograms, validateSelectedProgram } =
    useProgramWithTypeHook({
      program,
      hostId: Number(hostId),
      resetProgramTypes: () => resetField('programTypes', { defaultValue: [] }),
    });

  const submitHandler = async (data: CreatePlacementFormInput) => {
    setIsLoading(true);

    const body: CreatePlacementRequest = {
      positionDescription: data.positionDescription,
      programId: parseInt(data.program),
      totalPositions: parseInt(data.totalPositions),
      programTypes:
        data.programTypes?.length === 0
          ? undefined
          : data.programTypes?.map((i) => i as string),
    };

    try {
      const response = await postPlacement({
        body,
        hostId: parseInt(hostId as string),
      });

      alert.success(translations.alerts.placements.placementCreated);

      const placementId = response.id;

      navigate(
        !!hostId
          ? ApplicationRoutes.editPlacement
              .replace(':hostId', hostId)
              .replace(':placementId', placementId.toString())
          : ApplicationRoutes.placements
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelBtn = () => {
    reset();
    navigate(ApplicationRoutes.placements.replace(':hostId', hostId as string));
  };

  const onSubmit = handleSubmit(submitHandler);

  return (
    <CreatePlacement
      availablePrograms={availablePrograms}
      availableProgramTypes={availableProgramTypes}
      errors={errors}
      onSubmit={onSubmit}
      control={control}
      register={register}
      setValue={setValue}
      getValues={getValues}
      handleCancelBtn={handleCancelBtn}
      isLoading={isLoading}
    />
  );
};

export default CreatePlacementContainer;
