import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ResetPassword from './resetPassword';
import { ResetPasswordRequest } from './interfaces/resetPasswordRequest';
import { validationSchema } from './validation/validationSchema';
import { useFetch } from '../../hooks/useFetch';
import EmailHasBeenSent from 'pages/emailHasBeenSent/emailHasBeenSent';

const ResetPasswordContainer = () => {
  const [isEmailHasBeenSent, setIsEmailHasBeenSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { post } = useFetch();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<ResetPasswordRequest>({
    resolver: yupResolver(validationSchema),
  });

  const handleOnSubmit = async (body: ResetPasswordRequest) => {
    setIsLoading(true);

    try {
      await post<ResetPasswordRequest>('accounts/reset-password', { body });
      setIsEmailHasBeenSent(true);
    } catch (error: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetIsEmailHasBeenSent = () => setIsEmailHasBeenSent(false);

  const onSubmit = () => handleSubmit(handleOnSubmit);

  return (
    <>
      {isEmailHasBeenSent ? (
        <EmailHasBeenSent
          handleSetIsEmailHasBeenSent={handleSetIsEmailHasBeenSent}
        />
      ) : (
        <ResetPassword
          onSubmit={onSubmit}
          register={register}
          formErrors={formErrors}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ResetPasswordContainer;
