import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ResponsiveContainerProps } from 'components/responsiveListView/components/responsiveContainerProps';
import { useGridStyles } from '../hooks/useGridStyles';
import { ReactElement } from 'react';
import { NoFoundMsg } from 'components/tables/components/rowNotFound/NoFoundMsg';

const TileViewWrapper = <T,>({
  items,
  heightToSkip,
  children,
}: {
  items?: T[];
  heightToSkip: number;
  children: ReactElement;
}) => {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));

  const margins = isSmallScreen ? 0 : 16 + 16 + 8;
  const styles = useGridStyles(isSmallScreen);

  if (items && items?.length > 0) {
    return (
      <Box
        mt={2}
        pb={1}
        px={'1px'}
        sx={{ ...ResponsiveContainerProps(heightToSkip + margins), ...styles }}
      >
        {children}
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <NoFoundMsg />
      </Box>
    );
  }
};

export default TileViewWrapper;
