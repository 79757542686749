import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import HeaderFooterModal from 'components/modal/headerFooterModal';
import { Grid, Typography, useTheme } from '@mui/material';
import ModalHeader from 'components/modal/modalHeader';
import { useHostService } from 'hooks/services/hosts';
import { useParams } from 'react-router-dom';
import { Action } from 'pages/interviews/interfaces/interviews';
import { Nullable } from 'utils/interfaces';
import { InterviewResponse } from 'hooks/services/hosts/interfaces/interviews.response';
import { useAlert } from 'providers/alertProvider';

const ActionsModal = ({
  interview,
  fetchInterviews,
  handleCloseActionsDropdown,
  selectedAction,
  setSelectedAction,
  modalOpen,
  setModalOpen,
}: {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  selectedAction: Nullable<Action>;
  setSelectedAction: (value: Nullable<Action>) => void;
  handleCloseActionsDropdown: () => void;
  interview: Nullable<InterviewResponse>;
  fetchInterviews: () => void;
}) => {
  const translations = useTranslations();
  const alert = useAlert();
  const { hostId } = useParams();
  const theme = useTheme();
  const { updateInterview } = useHostService();
  const { allInterviews, modal } = useTranslations();

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    if (hostId && selectedAction && interview?.id) {
      setIsLoading(true);
      try {
        await updateInterview(
          hostId,
          interview?.id,
          'interviews',
          selectedAction.id
        );

        if (selectedAction.id === 'cancel')
          alert.success(translations.alerts.interviews.interviewCancelled);

        await fetchInterviews();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
      setModalOpen(false);
      handleCloseActionsDropdown();
    }
  };

  const handleCloseModal = () => {
    setSelectedAction(null);
    setModalOpen(false);
    handleCloseActionsDropdown();
  };

  return (
    <HeaderFooterModal
      open={modalOpen}
      onClose={handleCloseModal}
      saveBtnText={modal.buttons.yes}
      cancelBtnText={modal.buttons.no}
      onSave={handleConfirm}
      sx={{ width: 500 }}
      isLoading={isLoading}
    >
      <Grid container sx={{ p: theme.spacing(2) }}>
        <Grid item xs={12}>
          {selectedAction && <ModalHeader title={selectedAction?.label} />}
        </Grid>
        <Grid item xs={12} sx={{ py: theme.spacing(2) }}>
          <Typography variant='body1'>
            {allInterviews.modal.areYouSure}
          </Typography>
        </Grid>
      </Grid>
    </HeaderFooterModal>
  );
};

export default ActionsModal;
