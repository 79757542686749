import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 670,

  [theme.breakpoints.up("lg")]: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: theme.spacing(4, 3),
  },
}));
