import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { TabRoute } from './interfaces/tabRoute';

const TabPanel: React.FC<{ routes: TabRoute[] }> = ({ routes }) => {
  const { tabRoute } = useParams<{
    hostId: string;
    tabRoute: string;
  }>();

  const currentTabRoute = routes.find((route) => route.path === tabRoute);
  const selectedComponent = currentTabRoute ? currentTabRoute.component : null;

  return (
    <Grid item xs={12}>
      {selectedComponent}
    </Grid>
  );
};

export default TabPanel;
