import { ContactBodyRequest } from '../../../../hooks/services/hosts/interfaces/contactBody.request';
import { ContactInputsProps } from '../interfaces/ContactInputsProps';

export const makeRequest = ({
  name,
  type,
  email,
  directPhone,
  mobilePhone,
  fax,
  department,
  title,
  skype,
}: ContactInputsProps): ContactBodyRequest => {
  return {
    name,
    type: type as 'Primary' | 'Secondary',
    email,
    directPhone,
    mobilePhone,
    department,
    title,
    fax,
    skype,
  };
};
