import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ChangePassword from './changePassword';
import { ChangePasswordRequest } from './interfaces/changePasswordRequest';
import { ChangePasswordViewModel } from './interfaces/changePasswordViewModel';
import validationSchema from './validation/validationSchema';
import LoginResponse from 'pages/signIn/interfaces/loginResponse';
import { useFetch } from 'hooks/useFetch';
import ApplicationRoutes from 'utils/navigation/applicationRoutes';

const ChangePasswordContainer = () => {
  const token = new URLSearchParams(window.location.search).get('token');
  const shouldDisplayForm = !!token;
  const { post } = useFetch();
  const [errors, setErrors] = useState(
    shouldDisplayForm ? [] : ['The token is not provided']
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<ChangePasswordViewModel>({
    resolver: yupResolver(validationSchema),
  });

  const handleOnSubmit = async (data: ChangePasswordViewModel) => {
    if (!token) {
      return;
    }

    setIsLoading(true);

    const body: ChangePasswordRequest = {
      password: data.newPassword,
      token: token,
    };

    try {
      await post<LoginResponse>('accounts/change-password', {
        body,
      });

      setErrors([]);
      navigate(ApplicationRoutes.signIn);
    } catch (error: any) {
      setErrors(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => handleSubmit(handleOnSubmit);

  useEffect(() => {
    if (!token) {
      navigate(ApplicationRoutes.signIn);
    }
  }, [token, navigate]);

  return (
    <ChangePassword
      onSubmit={onSubmit}
      register={register}
      formErrors={formErrors}
      shouldDisplayForm={shouldDisplayForm}
      errors={errors}
      isLoading={isLoading}
    />
  );
};

export default ChangePasswordContainer;
