const IFrame = ({
  src,
  title,
  width = '100%',
  height = '100%',
}: {
  src: string;
  title: string;
  width?: string;
  height?: string;
}) => {
  return <iframe title={title} src={src} width={width} height={height} />;
};

export default IFrame;
