import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Fragment } from 'react';
import { RowNotFound } from 'components/tables/components/rowNotFound';
import { TableProps } from '../interfaces/tableProps';

const ArrivalTable = ({
  headers,
  rows,
  tableMaxHeight,
}: TableProps & { tableMaxHeight: string }) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: tableMaxHeight }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell align='left' key={header}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, rowIndex) => (
              <Fragment key={rowIndex}>
                {row.hasPrev && (
                  <TableRow key={`${rowIndex}-prev`}>
                    {row.previous &&
                      row.previous.map((cell, index) => (
                        <TableCell
                          sx={{ borderBottom: cell.shouldSpan ? undefined : 0 }}
                          key={`${index}-prev-cell`}
                          align='left'
                          rowSpan={cell.shouldSpan ? 2 : undefined}
                        >
                          {cell.value}
                        </TableCell>
                      ))}
                  </TableRow>
                )}

                <TableRow key={`${rowIndex}-curr`}>
                  {row.current &&
                    row.current
                      .filter((cell) => !!cell)
                      .map((cell, index) => (
                        <TableCell key={`${index}-curr-cell`} align='left'>
                          {cell.value}
                        </TableCell>
                      ))}
                </TableRow>
              </Fragment>
            ))}
          {rows && rows.length === 0 && (
            <RowNotFound colSpan={headers.length} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ArrivalTable;
