import { UseFormSetValue } from 'react-hook-form';

import {
  CompanyDetailsResponse,
  CompanyProgramResponse,
  CompanyResponse,
} from 'hooks/services/hosts/interfaces/company.response';

import { BE_DATE_FORMAT, convertToDate } from 'utils/date/convertToDate';
import {
  convertBooleanToString,
  getStringValueOrEmptyString,
} from 'utils/string/stringConverters';

import { CompleteInfoFormInput } from '../interfaces/completeInfo.formInput';

const setValuesCompanyDetails = (
  details: CompanyDetailsResponse,
  setValue: UseFormSetValue<CompleteInfoFormInput>
) => {
  setValue(
    'details.videoEmbed',
    getStringValueOrEmptyString(details.embedVideo)
  );
  setValue('details.sector', getStringValueOrEmptyString(details.sector));
  setValue('details.industry', getStringValueOrEmptyString(details.industry));
  setValue(
    'details.orientationRequired',
    convertBooleanToString(details.isOrientationRequired)
  );
  setValue(
    'details.yearEstablished',
    getStringValueOrEmptyString(details.yearEstablished)
  );
  setValue(
    'details.totalCurrentEmployees',
    getStringValueOrEmptyString(details.totalNumberOfFullTimeEmployees)
  );
  setValue(
    'details.totalExchangeVisitors',
    getStringValueOrEmptyString(details.totalNumberOfExchangeVisitors)
  );
  setValue(
    'details.summerWork',
    getStringValueOrEmptyString(details.summerWorkTravelProgram)
  );
  setValue(
    'details.internProgram',
    getStringValueOrEmptyString(details.internAndTraineePrograms)
  );
  setValue(
    'details.teacherProgram',
    getStringValueOrEmptyString(details.teacherProgram)
  );
  setValue(
    'details.irsTaxNumber',
    getStringValueOrEmptyString(details.irsTaxNumber)
  );
  setValue(
    'details.siteRequirement',
    convertBooleanToString(details.isSiteVisitRequirementSatisfied)
  );
  setValue(
    'details.annualRevenue',
    getStringValueOrEmptyString(details.approximateAnnualRevenueOfPreviousYear)
  );
  setValue(
    'details.expirationDate',
    convertToDate(details.policyExpiration, BE_DATE_FORMAT)
  );
  setValue(
    'details.workmanCompId',
    getStringValueOrEmptyString(details.workmansCompPolicyID)
  );
  setValue(
    'details.workmanCompPhone',
    getStringValueOrEmptyString(details.workmansCompPolicyProviderPhone)
  );
  setValue(
    'details.insuranceCompany',
    getStringValueOrEmptyString(details.insuranceCompany)
  );
  setValue(
    'details.description',
    getStringValueOrEmptyString(details.companyDescription)
  );
  setValue('details.comments', getStringValueOrEmptyString(details.comments));
  setValue(
    'details.businessLicense',
    convertToDate(
      details.businessLicenseOrHostSchoolAccreditationExpirationDate,
      BE_DATE_FORMAT
    )
  );
  setValue(
    'details.termsConditionsExpiration',
    convertToDate(details.termsConditionsExpirationDate, BE_DATE_FORMAT)
  );
  setValue(
    'details.seasonalityReportExpiration',
    convertToDate(details.seasonalityReportExpirationDate, BE_DATE_FORMAT)
  );
  setValue(
    'details.hostAddress',
    getStringValueOrEmptyString(
      details.hostEntityHeadQuartersAddressIncludingCounty
    )
  );
  setValue(
    'details.hostIncorporateInUsa',
    convertBooleanToString(details.isTheHostEntityIncorporatedInTheUSA)
  );
  setValue(
    'details.otherSponsors',
    getStringValueOrEmptyString(
      details.whichOtherSponsorsAreYouCurrentlyWorkingWith
    )
  );
  setValue(
    'details.howLongHiringParticipants',
    getStringValueOrEmptyString(
      details.howLongHaveYouBeenHiringParticipantsForTheJ1Programs
    )
  );
  setValue(
    'details.layoffs',
    convertBooleanToString(details.haveYouHadAnyLayoffsInThePast120Days)
  );
  setValue('details.url', getStringValueOrEmptyString(details.companyUrl));
  setValue(
    'details.hostIncorporateNotUsa',
    getStringValueOrEmptyString(details.whereIsHostEntityIncorporated)
  );
};
const setValuesProgram = (
  program: CompanyProgramResponse,
  setValue: UseFormSetValue<CompleteInfoFormInput>
) => {
  setValue('program.address', getStringValueOrEmptyString(program.address));
  setValue(
    'program.hasSupervisor',
    convertBooleanToString(program.hasSupervisor)
  );
  setValue(
    'program.isEnglishPrimaryLanguage',
    convertBooleanToString(program.isEnglishPrimaryLanguage)
  );
  setValue(
    'program.hasHelpWithCulturalActivities',
    convertBooleanToString(program.hasHelpWithCulturalActivities)
  );
  setValue(
    'program.isProvided32HoursPerWeek',
    convertBooleanToString(program.isProvided32HoursPerWeek)
  );
  setValue(
    'program.isTrainingOrWorkOverNightRequired',
    convertBooleanToString(program.isTrainingOrWorkOverNightRequired)
  );
  setValue(
    'program.isHousingProvided',
    convertBooleanToString(program.isHousingProvided)
  );
  setValue(
    'program.aproximateCostOfHousingPerMonthPerExchangeVisitor',
    getStringValueOrEmptyString(
      program.aproximateCostOfHousingPerMonthPerExchangeVisitor
    )
  );
  setValue(
    'program.existsAnyAffordableHousingOptionsNearby',
    convertBooleanToString(program.existsAnyAffordableHousingOptionsNearby)
  );
  setValue(
    'program.otherVisaProgramsDescription',
    getStringValueOrEmptyString(program.otherVisaProgramsDescription)
  );
  setValue(
    'program.hasAnyOtherVisaPrograms',
    getStringValueOrEmptyString(program.hasAnyOtherVisaPrograms)
  );
};

export const setValues = (
  { details, program }: CompanyResponse,
  setValue: UseFormSetValue<CompleteInfoFormInput>
) => {
  setValuesCompanyDetails(details, setValue);
  setValuesProgram(program, setValue);
};
